import Button, { ButtonType } from "./Button";

interface ButtonItemType {
    tag?: ButtonType['tag']
    children?: ButtonType['children']
    isActive?: boolean
    [attrName: string]: any // HTML attributes  
}

export function ButtonItem({ tag, children, color, shape, isActive = false, ...rest }: ButtonItemType) {
    return (
        <Button tag={tag} color={color} shape={isActive ? 'filled' : 'outline'} {...rest}>{children}</Button>
    )
}


interface ButtonsGroupType {
    color?: ButtonType['color']
    shape?: ButtonType['shape']
    [attrName: string]: any // HTML attributes  
}
export default function ButtonsGroup({ children, className, shape, color, ...rest }: ButtonsGroupType) {
    let classes = 'btn-group' + (className ? ` ${className}` : '')
    return (
        <div className={classes} {...rest}>
            {
                Array.isArray(children) ?
                    children.map((child: any, idx: number) => {
                        if (child && child.props) {
                            return (
                                <ButtonItem key={idx} color={color} shape={shape} {...child.props} />
                            )
                        }
                        return <></>
                    }) :
                    (
                        children && children.props ?
                            <ButtonItem color={color} shape={shape} {...children.props} />
                            : <></>
                    )
            }
        </div>
    )
}