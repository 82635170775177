import { isAfter, isBefore } from "date-fns"
import changeTz from "./change-tz"

interface Invoice {
    expiredAt: string
    paidAt: string
    [key: string]: string
}
export default function getOrderStatus(order: MiscObj, invoice: Invoice) {
    const formatStr = 'dd-MM-yyyy HH:mm'
    const status = {
        text: '',
        date: '',
        color: '',
        backgroundColor: ''
    }
    if (!order || !invoice) {
        return null
    }
    if (order.refundedAt) {
        status.text = 'Refunded'
        status.backgroundColor = '#BB032A'
        status.color = '#FFFFFF'
        status.date = changeTz(order.refundedAt, 'Asia/Singapore', formatStr)
    }
    else if (order.cancelledAt) {
        status.text = 'Cancelled'
        status.backgroundColor = '#BB032A'
        status.color = '#FFFFFF'
        status.date = changeTz(order.cancelledAt, 'Asia/Singapore', formatStr)
    }
    else if (order.completedAt) {
        status.text = 'Completed'
        status.backgroundColor = '#24663B'
        status.color = '#FFFFFF'
        status.date = changeTz(order.completedAt, 'Asia/Singapore', formatStr)
    }
    else if (isBefore(new Date(), invoice.expiredAt) && !invoice.paidAt) {
        status.text = 'Pending Payment'
        status.backgroundColor = '#F5D9A8'
        status.color = '#8F4700'
        status.date = changeTz(invoice.expiredAt, 'Asia/Singapore', formatStr)
    }
    else if (isAfter(new Date(), invoice.expiredAt) && !invoice.paidAt) {
        status.text = 'Expired'
        status.backgroundColor = '#535158'
        status.color = '#FFFFFF'
        status.date = changeTz(invoice.expiredAt, 'Asia/Singapore', formatStr)
    }
    else {
        status.text = 'Ongoing'
        status.backgroundColor = '#1954A1'
        status.color = '#FFFFFF'
        status.date = changeTz(order.createdAt, 'Asia/Singapore', formatStr)
    }
    return status
}