import { AdminIcon } from "components/Svg"
import ListAdmin from "components/pages/admins/ListAdmin"
import { ResourceType } from "resources-config"

export const ADMINS_CONFIG: ResourceType = {
    name: 'admins', meta: { icon: <AdminIcon /> },
    actions: {
        list: {
            path: 'admins', page: <ListAdmin />,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }
    }
}

