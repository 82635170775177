import { TechnicianAvatar } from "components/avatars"
import { BaseCard } from "components/cards"
import { FilterIcon, NoticeIcon } from "components/Svg"
import { format } from "date-fns"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { formatFullDate } from "utils/date"

type Props = {
    setFilter: Dispatch<SetStateAction<'pending' | 'accepted' | 'rejected' | undefined>>
    filter: 'pending' | 'accepted' | 'rejected' | undefined
}

const FILTERS = [undefined, 'accepted', 'rejected']

const DashboardHeader = (props: Props) => {
    const [activeFilterIndex, setActiveFilterIndex] = useState(0)
    const { setFilter, filter } = props

    useEffect(() => {
        // @ts-ignore
        setFilter(FILTERS[activeFilterIndex])
    }, [activeFilterIndex])

    return <BaseCard className="grid grid-cols-5 place-items-stretch text-body border">
        <div>
            <h3 className="font-medium text-th-lg">{format(new Date(), 'MMMM')}</h3>
            <h5 className="text-th-sm">Today is {formatFullDate(new Date(), { showTime: false })}</h5>
        </div>
        <div className="border-x-2 col-span-3 flex items-center justify-evenly">
            <div className="flex items-center gap-2">
                <span className="font-medium text-th-lg">Board - </span>
                <span> Daily Updates</span>
            </div>
            <div className="flex">
                <TechnicianAvatar imageSrc="/images/airple-icon.png" />
                <TechnicianAvatar imageSrc="/images/airple-icon.png" className="w-14 h-14 -translate-x-2" />
                <TechnicianAvatar imageSrc="/images/airple-icon.png" className="w-14 h-14 -translate-x-4" />
                <TechnicianAvatar imageSrc="/images/airple-icon.png" className="w-14 h-14 -translate-x-6" />
            </div>
        </div>
        <div className="flex justify-end self-center text-light">
            {activeFilterIndex == 0 &&
                <button onClick={() => setActiveFilterIndex(1)} type="button" className="group flex-ct-y gap-1.5 rounded-full py-1.5 px-4 border border-danger text-danger hover:bg-danger hover:text-white transition duration-300">
                    <NoticeIcon className="fill-danger group-hover:fill-white transition duration-300" />
                    Pending
                </button>
            }
            {activeFilterIndex == 1 &&
                <button type="button" onClick={() => setActiveFilterIndex(2)} className="group flex-ct-y gap-1.5 rounded-full py-1.5 px-4 border border-accepted text-accepted hover:bg-accepted hover:text-white transition duration-300">
                    <NoticeIcon className="fill-accepted group-hover:fill-white transition duration-300" />
                    Accepted
                </button>
            }
            {activeFilterIndex == 2 &&
                <button type="button" onClick={() => setActiveFilterIndex(0)} className="group flex-ct-y gap-1.5 rounded-full py-1.5 px-4 border border-warning text-warning hover:bg-warning hover:text-white transition duration-300">
                    <NoticeIcon className="fill-warning group-hover:fill-white transition duration-300" />
                    Rejected
                </button>
            }
        </div>
    </BaseCard>
}

export default DashboardHeader