import { useModalReturnType, useCustom, CrudFilters } from "@refinedev/core"
import { Button } from "components/buttons"
import { SelectField, Checkbox } from "components/forms"
import { Modal } from "components/popups"
import { format } from "date-fns"
import { baseApi } from "providers/customDataProvider"
import { useState, useEffect } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import { numOrderSuffix } from "utils/data-formatter"

type Props = {
    modal: useModalReturnType,
    dateTime: string,
    scheduleId: number | null,
    refetchDailySched: () => void
    setFilters: ((filters: CrudFilters) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void)
}

function EditScheduleModal({ modal, dateTime, scheduleId, refetchDailySched, setFilters }: Props) {
    const { control, setValue, getValues } = useForm()
    const fieldArr = useFieldArray({ control: control, name: 'items' })
    const [schedule, setSchedule] = useState<MiscObj | null>(null)

    const { data, isFetching, isFetched, refetch } = useCustom({
        url: `service-schedules/get-order-schedule/${scheduleId || 0}`,
        method: "get",
    })

    const complete = async () => {
        if (!schedule) return
        try {
            const res = await baseApi.post(`/admin/service-schedules/${schedule.scheduleId}/complete`)
            if (res.data.message == 'Success') {
                toast.success('Successfully completed the schedule')
                refetchDailySched()
            } else {
            }
        }
        catch (err: any) {
            toast.error(err.message)
            console.log(err)
        } finally { modal.close() }
    }

    const handleCopySchedule = (orderId: string, scheduleId: string) => {
        setFilters((state) =>
            // @ts-ignore
            [...state.filter((filter) => filter.field != 'order' && filter.field != 'moveSchedule'),
            {
                field: 'order',
                operator: 'eq',
                value: orderId
            },
            {
                field: 'moveSchedule',
                operator: 'eq',
                value: scheduleId
            },
            ])
        toast.success('Schedule copied, you can select a date by clicking plus button')
        modal.close()
    }

    const reschedule = async () => {
        try {
            const body = getValues()
            body.order = schedule ? schedule.orderId : null
            body.schedule = schedule ? schedule.scheduleId : null
            body.startDate = schedule ? schedule.startDate : null
            body.technicianTeam = schedule ? schedule.technicianTeamId : null
            body.user = schedule ? schedule.userId : null
            await baseApi.post('/admin/service-schedules/reschedule', body)
            refetchDailySched()
            modal.close()
        } catch (error: any) {
            toast.error(error.message)
            console.log(error)
        }
    }
    useEffect(() => {
        if (scheduleId) refetch()
    }, [scheduleId, refetch])

    useEffect(() => {
        if (isFetching) {
            setSchedule(null)
            fieldArr.replace([])
        }
        else if (isFetched && data) {
            const schedule: MiscObj = data
            const order = schedule.order
            let formattedSchedule: MiscObj = {}
            let allScheds: MiscObj[] = []
            let selectedItems: number[] = []
            formattedSchedule.scheduleId = +schedule.id
            formattedSchedule.startDate = schedule.startDate
            formattedSchedule.orderId = +order.id
            formattedSchedule.technicianTeamId = +schedule.technicianTeam.id
            formattedSchedule.userId = +schedule.user.id
            formattedSchedule.items = []
            formattedSchedule.periods = []
            formattedSchedule.currentPeriod = +schedule.period
            order.items.forEach((item: MiscObj) => {
                const { scheduleTasks, ...itemdata } = item
                const itemStartedScheds = scheduleTasks.filter((task: MiscObj) => {
                    if (+task.schedule.id === scheduleId) {
                        selectedItems.push(+item.id)
                    }
                    if (!allScheds.find((sched: MiscObj) => sched.period === task.schedule.period)) {
                        allScheds.push(task.schedule)
                    }
                    return (
                        task.schedule.teamStartDate !== null
                    )
                })
                formattedSchedule.items.push({
                    ...itemdata,
                    selectable: item.frequency !== itemStartedScheds.length
                })
            })
            formattedSchedule.items.sort((a: MiscObj, b: MiscObj) => {
                return b.frequency - a.frequency
            })
            for (let period = 0; period < formattedSchedule.items[0].frequency; period++) {
                const periodSchedule = allScheds.find((sched: MiscObj) => sched.period === period)
                formattedSchedule.periods.push({
                    period: period,
                    teamStartDate: periodSchedule && periodSchedule.teamStartDate ? periodSchedule.teamStartDate : null,
                    startDate: periodSchedule && periodSchedule.startDate ? periodSchedule.startDate : null
                })
            }
            setSchedule(formattedSchedule)
            fieldArr.replace(selectedItems)
        }
    }, [data, isFetching, isFetched, scheduleId, fieldArr.replace])

    return (
        <Modal visible={modal.visible} heading={'Edit Schedule'} close={modal.close} width="34rem">
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                    <div>Manage Schedule for <b>{dateTime}</b></div>
                    {schedule &&
                        <div className="flex items-center gap-2">
                            <Link to={`/service-orders/${schedule.orderId}`} className="text-primary font-medium">View Order Details</Link>
                            <span>•</span>
                            <button type="button" onClick={() => handleCopySchedule(schedule.orderId, schedule.scheduleId)} className="text-primary font-medium">Move Schedule</button>
                        </div>

                    }
                </div>
                {
                    !schedule ? <></> :
                        <>
                            <SelectField control={control} fieldName="period" label={'Job'}
                                defaultCfg={{ setValue: setValue, value: { value: schedule.currentPeriod, label: '' } }}
                                options={schedule.periods.map((period: MiscObj) => ({
                                    value: period.period,
                                    label: `${numOrderSuffix(period.period + 1)} job ${period.startDate ? format(new Date(period.startDate), '(eee, dd MMMM yyyy)') : '(Unscheduled)'}`,
                                    isDisabled: period.teamStartDate ? true : false
                                }))}
                            />
                            {
                                schedule.items.map((item: MiscObj, index: number) => (
                                    <div className="justify-between flex-ct-y" key={index}>
                                        <Checkbox
                                            key={index} fieldArr={fieldArr} control={control} fieldName="items"
                                            cbVal={+item.id} label={`${item.service?.name}${item.serviceCategory ? ` (${item.serviceCategory.name})` : ''}`}
                                            disabled={!item.selectable}
                                        />
                                        <span className="font-medium text-th-xs text-disable">
                                            {item.frequency || 0}x time(s) {item.frequency || 0} unit(s)
                                        </span>
                                    </div>
                                ))
                            }
                        </>

                }
                <div className="flex flex-col justify-stretch gap-2 items-stretch">
                    <Button confirm className="mx-auto w-full" onClick={reschedule}>Save</Button>
                    <Button confirm shape="outline" className="mx-auto w-full" onClick={complete}>Complete</Button>
                </div>
            </div>
        </Modal>
    )
}

export default EditScheduleModal