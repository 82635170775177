import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"
import { useModal, useOne } from "@refinedev/core"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import InvoiceCard from "components/cards/InvoiceCard"
import RecordPaymentModal from "components/custom-modals/RecordPaymentModal"
import InvoiceDocument from "components/pdfs/InvoiceDocument"
import QuotationDocument from "components/pdfs/QuotationDocument"
import { InfoCircleIcon, CustomerDetailIcon, ServiceListIcon } from "components/Svg"
import { LabeledInfo } from "components/texts"
import { TextTooltip } from "components/tooltips"
import { baseApi } from "providers/customDataProvider"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { QUOTATION_CONFIG } from "resources-config/quotation/quotation-config"
import { toast } from "sonner"
import { formatAddressAny } from "utils/address"
import { formatFullDate } from "utils/date"


const ShowInvoice = () => {
    const { id } = useParams()
    const { data, refetch: refetchQuotation } = useOne({
        resource: QUOTATION_CONFIG.name,
        id: id
    })
    const { quotation, amountDue } = (data && data.data) || {} as any
    const [viewedInvoice, setViewedInvoice] = useState<MiscObj>()
    const recordPaymentModal = useModal()

    const handleCopyPaymentLink = () => {
        try {
            if (quotation) {
                navigator.clipboard.writeText(quotation.quotationInvoices[0].paymentGtwRes.url)
                toast.success('Successfuly copied payment link')
            }
        }
        catch (err) {
            toast.error('Failed to copy payment link')
        }
    }

    const handleRemovePayment = async (quotationId: string, invoiceId: string, invoiceHistoryId: string) => {
        try {
            await baseApi.delete(`/admin/quotations/${quotationId}/invoices/${invoiceId}/invoice-histories/${invoiceHistoryId}`)
            toast.success('Successfully removed payment')
            refetchQuotation()
        } catch (err: any) {
            toast.error(err.message)
        }
    }

    return <BaseCard>
        {quotation &&
            <>
                <header className="flex items-center justify-between border-b pb-4">
                    <h1 className="text-th-2xl font-bold">INVOICE #INV-{quotation.id}</h1>
                    <div className="flex items-center gap-2">

                    </div>
                </header>
                <section className="grid grid-cols-5 mt-4 gap-4">
                    <LabeledInfo label="Customer" className="flex items-center gap-2">
                        <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{quotation.user.name}</span>
                        <TextTooltip text={quotation.user.name}>
                            <InfoCircleIcon width={16} height={16} />
                        </TextTooltip>
                    </LabeledInfo>
                    <LabeledInfo label="Address" className="flex items-center gap-2">
                        <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{formatAddressAny(quotation)}</span>
                        <TextTooltip text={formatAddressAny(quotation)}>
                            <InfoCircleIcon width={16} height={16} />
                        </TextTooltip>
                    </LabeledInfo>
                    <LabeledInfo label="Amount Due">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">$ {parseFloat(amountDue).toFixed(2)}</span>
                    </LabeledInfo>
                    <LabeledInfo label="Quotation Date">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">{formatFullDate(quotation.quotationDate, { showTime: false, showDay: false })}</span>
                    </LabeledInfo>
                    <LabeledInfo label="Valid Until">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">{formatFullDate(quotation.validUntil, { showTime: false, showDay: false })}</span>
                    </LabeledInfo>
                </section>
                <section className="relative mt-8 space-y-6">
                    <div className="border-l-[3px] absolute h-full top-0 left-6" />
                    <div className="bg-white relative z-10">
                        <div className="border rounded-lg p-4 flex items-center justify-between">
                            <div className='flex flex-col gap-1'>
                                <div className="flex items-center gap-3">
                                    <CustomerDetailIcon />
                                    <h3 className="text-th-lg text-light">Create Quotation</h3>
                                </div>
                                <span><span className="font-medium">Created at:</span> {formatFullDate(quotation.createdAt)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white relative z-10 border rounded-lg p-4">
                        <div className='flex items-start justify-between border-b pb-4'>
                            <div className="flex items-center gap-3">
                                <div className='flex flex-col gap-1'>
                                    <div className="flex items-center gap-3">
                                        <CustomerDetailIcon />
                                        <h3 className="text-th-lg text-light">Converted to Invoice</h3>
                                    </div>
                                    <div><span className="font-medium">Converted at:</span> {formatFullDate(quotation.confirmedAt)}</div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Button shape="outline" onClick={handleCopyPaymentLink}>
                                    Copy Payment Link
                                </Button>
                                {quotation && quotation.quotationInvoices[0] &&
                                    <PDFDownloadLink download={false} target='_blank' document={<InvoiceDocument isPaid={quotation.quotationInvoices[0].paidAt != null} quotation={quotation} />}>
                                        <Button>
                                            Download Invoice
                                        </Button>
                                    </PDFDownloadLink>
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 border-y py-3">
                            <div className=""><span className="font-medium">Amount Due:</span> $ {parseFloat(amountDue).toFixed(2)} • <button className="text-primary font-medium" onClick={() => { recordPaymentModal.show(); setViewedInvoice(quotation.quotationInvoices[0]) }}>Record a payment</button></div>
                            <div><span className="font-medium">Fully Paid Date:</span> {quotation.quotationInvoices[0] && quotation.quotationInvoices[0].paidAt != null ? formatFullDate(quotation.quotationInvoices[0].paidAt) : '-'}</div>
                        </div>
                        {quotation && quotation.quotationInvoices[0] && quotation.quotationInvoices[0].quotationInvoiceHistories.length > 0 &&
                            <div className="py-3 border-b flex flex-col gap-1">
                                {quotation.quotationInvoices[0].quotationInvoiceHistories.map((invHis: any) => {
                                    return <div className="flex flex-col text-th-sm text-light items-start">
                                        <span>
                                            <span className="font-medium text-black">{invHis.createdBy ? 'Manual' : 'Hitpay'} {invHis.type == 1 ? 'Payment' : invHis.type == 2 ? 'Refund' : 'Compensation'} : </span>
                                            {formatFullDate(invHis.paidAt)} - A {invHis.type == 1 ? 'payment' : invHis.type == 2 ? 'refund' : 'compensation'} for
                                            <span className="font-medium text-black"> $ {parseFloat(invHis.amount).toFixed(2)} </span>
                                            was made using {invHis.paymentMethod} by
                                            <span className="font-medium text-black"> {invHis.createdBy ? invHis.createdBy.name : 'System'}</span>
                                        </span>
                                        {invHis.notes &&
                                            <span>Notes : {invHis.notes}</span>
                                        }
                                        <div className="flex items-center gap-4">
                                            {invHis.cancelledBy == null && invHis.type != 2 && invHis.createdBy &&
                                                <Button shape="raw" type="button" confirm onClick={() => handleRemovePayment(quotation.id, quotation.quotationInvoices[0].id, invHis.id)} className="text-primary font-medium">Remove {invHis.type == 1 ? 'Payment' : 'Compensation'}</Button>
                                            }
                                        </div>
                                        {invHis.cancelledBy != null &&
                                            <span>{invHis.cancelledBy.name} removed this {invHis.type == 1 ? 'payment' : invHis.type == 2 ? 'refund' : 'compensation'} on {formatFullDate(invHis.updatedAt)}</span>
                                        }
                                    </div>
                                })}
                            </div>
                        }

                        {quotation && quotation.quotationInvoices[0] &&
                            <PDFViewer className='w-full h-[75vw] mt-8' children={<InvoiceDocument isPaid={quotation.quotationInvoices[0].paidAt != null} quotation={quotation} />} />
                        }
                    </div>
                </section>
            </>
        }
        <RecordPaymentModal
            isQuotationHistory
            viewedInvoice={viewedInvoice}
            modal={recordPaymentModal}
            refetchOrder={refetchQuotation}
            quotationId={quotation && quotation.id}
        />
    </BaseCard>
}

export default ShowInvoice