import { FaCheck } from "react-icons/fa";
import { Button } from "components/buttons";
import { changeTz } from "utils/data-formatter";
import { PromoInterface } from "./types";

type Props = {
    title: string
    subtitle: string
    startDate: Date
    endDate: Date
    description: string
    isSelected?: boolean
    code: string
    onSelect?: (promo?: PromoInterface) => void
    promo: PromoInterface
    openTerms?: () => void
    className?: string
}

const PromoCard = (props: Props) => {
    const { title, subtitle, startDate, endDate, description, isSelected, code, onSelect, promo, openTerms, className = 'relative', ...rest } = props

    return <section className={`${className} overflow-x-hidden`}>
        <div className="bg-secondary h-2.5 w-full rounded-t-lg border-b-2 border-dashed border-primary-3-surface" />
        <div className="w-[3rem] h-[3rem] bg-white z-10 absolute rounded-full -left-[2rem] top-1/2 -translate-y-1/2" />
        <div className="w-[3rem] h-[3rem] bg-white z-10 absolute rounded-full -right-[2rem] top-1/2 -translate-y-1/2" />
        <div className="p-8 flex flex-col gap-5 justify-between bg-[#EBFAF9] rounded-b-lg relative  overflow-x-hidden">
            <div className="flex items-center justify-between">
                <div>
                    <h6 className="text-th-sm font-bold">{title}</h6>
                    <span className="text-th-xs text-body">{subtitle}</span>
                </div>
                {onSelect &&
                    <Button onClick={isSelected ? () => onSelect(undefined) : () => onSelect(promo)} shape={isSelected ? 'filled' : 'outline'} className="flex justify-center">
                        {isSelected ? <FaCheck /> : <span className="mx-auto text-th-xs">Use</span>}
                    </Button>
                }
            </div>
            <div className="flex items-center justify-between text-primary text-th-xs">
                <span>Expired on {changeTz(endDate, 'Asia/Singapore', 'dd.MM.yyyy')}</span>
                {openTerms &&
                    <button type="button" onClick={openTerms}>T&C</button>
                }
            </div>
        </div>
    </section>
}

export default PromoCard