import { useTable } from "@refinedev/core";
import { BaseCard } from "components/cards";
import PageTitle from "components/PageTitle";
import Pagination from "components/Pagination";
import Pill from "components/Pill";
import { Table, THead, Th, TBody, Td, TActionItem, TAction } from "components/Table";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { PENDING_PAYMENT_CONFIG } from "resources-config/pending-payment-config";
import { formatFullDate } from "utils/date";


export default function ListPendingPayment() {
    const { tableQueryResult, pageSize, current, setCurrent } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: PENDING_PAYMENT_CONFIG.name,
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])
    return <>
        <PageTitle />
        <BaseCard>
            <Table>
                <THead>
                    <Th>Order ID</Th>
                    <Th>Invoice ID</Th>
                    <Th>Customer Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Created At</Th>
                    <Th>Pending Amount</Th>
                    <Th>Technician</Th>
                    <Th>Job Completed</Th>
                    <Th>Actions</Th>
                </THead>
                {tableQueryResult && tableQueryResult.data ? (tableQueryResult.data.data).map((invoice, index) => {
                    return <TBody key={index}>
                        <Td>{invoice.order.id}</Td>
                        <Td>{invoice.id}</Td>
                        <Td>{invoice.user.name}</Td>
                        <Td>{invoice.user.phoneNumber}</Td>
                        <Td>{formatFullDate(invoice.createdAt)}</Td>
                        <Td>$ {invoice.amount.toFixed(2)}</Td>
                        <Td>{invoice.customerDoesntWantToPayTechnician ? invoice.customerDoesntWantToPayTechnician.name : '-'} </Td>
                        <Td>
                            {invoice.customerDoesntWantToPay ?
                                <Pill backgroundColor="#FFE1DE" textColor="#BB032A" className="justify-center">
                                    Job is done
                                </Pill> :
                                <Pill backgroundColor="#F3A5BC" textColor="#A0153E" className="justify-center">
                                    Job is not done
                                </Pill>

                            }
                        </Td>
                        <TAction id={`pending-payment-invoice-${invoice.id}`}>
                            <TActionItem access={{ resource: PENDING_PAYMENT_CONFIG.name, action: 'edit' }}>
                                <NavLink className={'text-sm'} to={`/users/${invoice.user.id}`}>View Customer Info</NavLink>
                            </TActionItem>
                            <TActionItem access={{ resource: PENDING_PAYMENT_CONFIG.name, action: 'edit' }}>
                                <NavLink className={'text-sm'} to={`/service-orders/${invoice.order.id}?scroll=invoice-${invoice.id}`}>View Order Info</NavLink>
                            </TActionItem>
                        </TAction >
                    </TBody>
                }) : <></>}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard >
    </>
}