import CreateTechnicianTeam from "components/pages/technician-teams/CreateTechnicianTeam"
import EditTechnicianTeam from "components/pages/technician-teams/EditTechnicianTeam"
import ListTechnicianTeam from "components/pages/technician-teams/ListTechnicianTeam"
import { ResourceType } from "resources-config"

export const TECHNICIAN_TEAMS_CONFIG: ResourceType = {
    name: 'technician-teams', meta: { parent: 'technicians', label: 'Teams' },
    actions: {
        list: {
            path: 'technician-teams', page: <ListTechnicianTeam />,
            allowedRoles: ['super admin']
        },
        create: {
            path: 'technician-teams/create', page: <CreateTechnicianTeam />,
            allowedRoles: ['super admin']
        },
        edit: {
            path: 'technician-teams/edit/:id', page: <EditTechnicianTeam />,
            allowedRoles: ['super admin']
        },
    }
}