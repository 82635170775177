import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"

import { SERVICE_CATEGORIES_CONFIG } from 'resources-config/service-categories-config'

import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { TextField } from 'components/forms'
import { handleModalMutation } from 'utils/handle-mutation'
import { Modal } from 'components/popups'
import { getErrorNotif, getSuccessNotif } from 'utils/data-formatter'

type FieldType = {
    name: string
}

export default function ListServiceCategory(){
    const createServiceCatModal = useModal();
    const editServiceCatModal = useModal();
    const [editedServiceCat, setEditedServiceCat] = useState<{[key: string]: any}>({})

    const {tableQueryResult, pageSize, current, setCurrent} = useTable({
        queryOptions: {retry: false,},
        pagination: {current: 1, pageSize: 20},
        resource: SERVICE_CATEGORIES_CONFIG.name,
    })      
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    return (<>
        <PageTitle/>
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft></TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={SERVICE_CATEGORIES_CONFIG.name} action='create'>
                        <Button onClick={() => {createServiceCatModal.show()}}>Add service category</Button>
                        <CreateServiceCatModal modal={createServiceCatModal}/>
                    </CanAccess>  
                </TabletWidgetRight>
            </TabletWidgets>         
            <Table>
                <THead>
                    <Th>ID</Th><Th>Name</Th><Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((serviceCat, index) => (
                    <TBody key={index}>
                        <Td>{serviceCat.id}</Td>
                        <Td>{serviceCat.name}</Td>
                        <TAction id={`edit-service-cat-${serviceCat.id}`}>
                            <TActionItem access={{resource: SERVICE_CATEGORIES_CONFIG.name, action: 'edit'}}>
                                <button type='button' className='text-sm' onClick={() => {
                                    setEditedServiceCat(serviceCat)
                                    editServiceCatModal.show()
                                }}>
                                    Edit
                                </button>                                    
                            </TActionItem>
                        </TAction>
                    </TBody>                        
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination 
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0} 
                    clickHandler={changePage} range={5}
                />              
            </div>             
            <EditServiceCatModal modal={editServiceCatModal} serviceCat={editedServiceCat}/>        
        </BaseCard>
    </>)
}

const CreateServiceCatModal = ({modal}: {modal: any}) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, setError,
        control, clearErrors
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: SERVICE_CATEGORIES_CONFIG.name,
            successNotification() {
                return getSuccessNotif('update', 'service category')
            },
            errorNotification(data) {
                return getErrorNotif('update', 'service category', data)
            },              
        },  
           
    }); 
    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])

    return (
        <Modal heading={'Create Service Category'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField control={control} fieldName='name' label={'Name'} required/>
                <div className='justify-end flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

const EditServiceCatModal = (
    {modal, serviceCat}: 
    {modal: any, serviceCat: {[key: string]: string}}
) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, reset, setError,
        control, clearErrors
    } = useForm<FieldType>({
        defaultValues: {name: serviceCat.name},
        refineCoreProps: {
            resource: SERVICE_CATEGORIES_CONFIG.name, 
            action:  !serviceCat.id ? 'create' : 'edit', id: serviceCat.id || 0,
            successNotification() {
                return getSuccessNotif('update', 'service category')
            },
            errorNotification(data) {
                return getErrorNotif('update', 'service category', data)
            },            
        },     
    }); 
    useEffect(() => {
        const {id, ...fields} = serviceCat
        reset(fields)
    }, [serviceCat, reset])

    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])        

    return (
        <Modal heading={'Edit Service Category'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col text-th-sm">
                <TextField control={control} fieldName='name' label={'Name'} required/>
                <div className='justify-end mt-4 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

