import PageTitle from "components/PageTitle";
import { useForm } from "@refinedev/react-hook-form"
import { TECHNICIAN_TEAMS_CONFIG } from "resources-config/technician-teams-config";
import { CreateEditCard } from "components/cards";
import FormFieldTechnicianTeam, { TechnicianTeamFieldType } from "./FormFieldTechnicianTeam";
import { Button } from "components/buttons";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";
import { getErrorNotif, getSuccessNotif } from "utils/data-formatter";

export default function CreateTechnicianTeam(){
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { queryResult, mutationResult, onFinish }, handleSubmit, 
        control, setError, setValue, clearErrors
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: TECHNICIAN_TEAMS_CONFIG.name,
            successNotification(data) {
                let actionUrl = undefined
                if(data && data.data){
                    actionUrl = `/technician-teams/edit/${data.data.id}`
                }
                return getSuccessNotif('create', 'team', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('create', 'tean', data)
            },              
        },
    });     

    const onFinishHandler: SubmitHandler<FieldValues> = (data) => {
        onFinish(data)
    }

    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])    
    return (<>
        <PageTitle title='Create Technician Team'/>
        <CreateEditCard
            cardHeading="Add Technician Team"
            actions={<>
                <Button type='submit' form='tech-team-form' isLoading={btnDisabled}>
                    Save
                </Button>
            </>}
        >
            <FormFieldTechnicianTeam
                formId={'tech-team-form'}
                queryResult={queryResult}
                setValue={setValue}
                control={control}
                handleSubmit={handleSubmit}
                onFinishHandler={onFinishHandler}
            />
        </CreateEditCard>        
    </>)
}