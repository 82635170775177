import { useRef, useEffect } from "react"
import { Button } from "../buttons"
import { ChevronRightIcon } from "../Svg"
import { useNavigate } from "react-router-dom"
import BaseCard from "./BaseCard"

interface CreateEditCardProps {
    children?: JSX.Element | JSX.Element[]
    tag?: keyof JSX.IntrinsicElements
    cardHeading?: string,
    subHeading?: string,
    actions?: JSX.Element | JSX.Element[]
    className?: string
    showBackButton?: boolean
    [key: string]: any
}
export default function CreateEditCard({ children, tag, cardHeading, subHeading, actions, className, showBackButton = true, ...rest }: CreateEditCardProps) {
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null)
    const classes = 'create-edit' + (className ? ' ' + className : '')
    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            ref.current?.getBoundingClientRect()
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);
    return (
        <BaseCard tag={tag} className={classes} {...rest}>
            <div ref={ref} className="content">
                <div className="header">
                    {/* {showBackButton &&
                        <Button color="gray" shape="outline" onClick={() => { navigate(-1) }} className="mb-4">
                            <ChevronRightIcon style={{ transform: 'rotate(180deg)' }} />
                            Back
                        </Button>
                    } */}
                    <div className="main">
                        <div>
                            <h6 className="heading">{cardHeading}</h6>
                            {subHeading &&
                                <h6 className="sub-heading">{subHeading}</h6>
                            }
                        </div>

                        <div className="actions">
                            {actions}
                        </div>
                    </div>
                </div>
                <div className="body mt-4">{children}</div>
            </div>
        </BaseCard>
    )
}