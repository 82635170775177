import { BaseRecord, CustomResponse } from "@refinedev/core"
import Pill from "components/Pill"
import { ClockOutlineIcon, ExclamationIcon, FlagIcon, MoneyIcon, PinCheckIcon, TimeRunIcon, TruckIcon, UsersIcon } from "components/Svg"
import { TextTooltip } from "components/tooltips"
import { differenceInHours, differenceInMinutes } from "date-fns"
import { difference, flatten } from "lodash"
import { useMemo } from "react"


type Props = {
    technicianAssignments?: CustomResponse<BaseRecord> | any
    dailyStatistic: MiscObj | null
    schedules: any
}

const ScheduleFooter = (props: Props) => {
    const { technicianAssignments, dailyStatistic, schedules } = props

    const { totalIncome, totalHours, totalTechnicians } = useMemo(() => {
        let totalIncome;
        let totalHours = 0;
        let totalTechnicians = 0;
        if (dailyStatistic) {
            totalIncome = parseFloat(dailyStatistic.summary.totalIncome).toFixed(2)
            // totalHours = dailyStatistic.summary.totalJobs
            // totalTechnicians = dailyStatistic.summary.userIds.length
        }
        if (technicianAssignments) {
            totalTechnicians = technicianAssignments.map((assignment: any) => assignment.assignedTechnicians.length).reduce((current: any, acc: any) => current + acc, 0)
        }
        if (schedules && schedules.teams) {
            const jobs = flatten(schedules.teams.map((team: any) => team.serviceSchedules))
            totalHours = 0
            jobs.forEach((job: any) => {
                totalHours += differenceInMinutes(job.estimatedCompletionDate, job.startDate) / 60
            })
        }

        return { totalIncome, totalHours, totalTechnicians }
    }, [dailyStatistic, technicianAssignments, schedules])

    return <div className="bg-white sticky mt-4 py-4 px-6 rounded-lg bottom-0 flex items-center justify-between z-20">
        <div className="bg-primary-2-surface p-4 rounded-lg flex items-center gap-6">
            <TextTooltip text="Total Sales" position="top" className="flex items-center gap-2 font-medium">
                <MoneyIcon />
                {totalIncome ?? '0'}
            </TextTooltip>
            <TextTooltip text="Total Working Hours" position="top" className="flex items-center gap-2 font-medium">
                <ClockOutlineIcon />
                {totalHours ?? '0'}
            </TextTooltip>
            <TextTooltip text="Total Assigned Technicians" position="top" className="flex items-center gap-2 font-medium">
                <UsersIcon width={20} height={20} color="#36A9E1" />
                {totalTechnicians ?? '0'}
            </TextTooltip>
        </div>
        <div className="flex flex-wrap items-center font-medium gap-2">
            <Pill backgroundColor="#FDC521" textColor="#404040">
                Priority Booking
            </Pill>
            <Pill backgroundColor="#404040" textColor="#C2C2C2">
                Pay On-Site
            </Pill>
            <Pill backgroundColor="#CD7B2E" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <TruckIcon color="white" width={16} height={16} />
                    On The Way
                </div>
            </Pill>
            <Pill backgroundColor="#3267E3" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <PinCheckIcon color="white" width={16} height={16} />
                    Tech Arrived
                </div>
            </Pill>
            <Pill backgroundColor="#36A9E1" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <TimeRunIcon color="white" width={16} height={16} />
                    Job Started
                </div>
            </Pill>
            <Pill backgroundColor="#00A19A" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <FlagIcon color="white" width={16} height={16} />
                    Job Completed
                </div>
            </Pill>
            <Pill backgroundColor="#CB3A31" textColor="#FFFFFF">
                <div className="flex items-center gap-2 whitespace-nowrap">
                    <ExclamationIcon color="white" width={16} height={16} />
                    Schedule Conflict
                </div>
            </Pill>
        </div>
    </div>
}

export default ScheduleFooter