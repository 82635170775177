import { useLogin } from "@refinedev/core";
import PageTitle from "components/PageTitle";
import { BaseCard } from 'components/cards';
import { Button } from "components/buttons";
import { useForm } from 'react-hook-form';
import { TextField } from 'components/forms';
import { useState } from "react";
import useFcmToken from "hooks/useFcmToken";

export default function Login() {
    const [hideLoginErr, setHideLoginErr] = useState(true)
    const { fcmToken, oldFcmToken, replaceOldToken } = useFcmToken();
    const { control, getValues } = useForm<any>();
    const { mutate: login } = useLogin()
    const requestLogin = () => {
        const body = getValues()
        login({ ...body, deviceToken: fcmToken, oldDeviceToken: oldFcmToken }, {
            onSuccess: (data) => {
                replaceOldToken()
                setHideLoginErr(data.success)
            }
        })
    }

    return (<>
        <PageTitle title="Login" />
        <div className='w-screen h-screen flex-ct-all'>
            <BaseCard className='flex flex-col gap-4 min-w-[24rem] mobile-below:min-w-[80%] mobile-below:w-[90%]'>
                <h1 className='font-medium text-center text-dark text-th-lg'>Airple Admin Panel</h1>
                <TextField control={control} label='Email' required fieldName='email' />
                <TextField control={control} label='Password' required type="password" fieldName='password' />
                <Button className='w-full' type='button' onClick={requestLogin}>
                    Login
                </Button>
                <div className="text-center text-danger">
                    {hideLoginErr ? '' : 'Invalid credentials'}
                </div>
            </BaseCard>
        </div>
    </>)
}