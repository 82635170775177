import { baseApi } from "providers/customDataProvider"


export const postReschedule = async (body: any) => {
    const res = await baseApi.post(`/admin/service-schedules/reschedule`, body)
    const message = res.data.message
    return (message == 'Success')
}

export const postAddSchedule = async (body: any) => {
    const res = await baseApi.post(`/admin/service-schedules`, body)
    const message = res.data.message
    return (message == 'Success')
}

export const getLateTechSchedules = async () => {
    const res = await baseApi.get('/admin/service-schedules/tech-late')
    return res.data || []
}