import { ComponentProps, CSSProperties, ReactNode } from "react"

type Props = ComponentProps<'div'> & {
    backgroundColor: string
    textColor: string
    borderColor?: string
    borderWidth?: string
    Icon?: ReactNode
    children: ReactNode
    fontSize?: string | number
    padding?: string
}

const Pill = (props: Props) => {
    const { backgroundColor, textColor, padding = '4px 10px', borderColor, borderWidth = '1px', fontSize = '0.75rem', children, Icon = <></>, className, ...rest } = props
    const styles: CSSProperties = {
        backgroundColor: backgroundColor,
        color: textColor,
        border: borderColor ? `border solid ${borderColor}` : undefined,
        borderColor: borderColor,
        padding: padding
    }

    return <div style={styles} className={`flex items-center rounded-full gap-[0.19rem] ${className}`} {...rest}>
        {Icon}
        <span style={{ fontSize: fontSize }}>
            {children}
        </span>
    </div>
}

export default Pill