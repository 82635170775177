import { useModal, useTable } from "@refinedev/core"
import Badge from "components/Badge"
import { Button } from "components/buttons"
import { FilterModal } from "components/custom-modals"
import { Checkbox, SelectField, TextField } from "components/forms"
import Pagination from "components/Pagination"
import { FilterIcon } from "components/Svg"
import { Table, THead, Th, TBody, Td, TAction, TActionItem } from "components/Table"
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets"
import { useCallback } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { NavLink } from "react-router-dom"
import { SERVICE_ORDER_CONFIG } from "resources-config/service-order/service-order-config"
import { getOrderStatus } from "utils/data-formatter"
import { formatFullDate } from "utils/date"


const ShowUserOrderList = ({ userId }: { userId?: any }) => {
    const { control } = useForm()
    const fieldArr = useFieldArray({ control: control, name: 'status' })
    const filterModal = useModal()
    const { tableQueryResult, pageSize, current, setCurrent, sorters, setSorters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 10 },
        resource: SERVICE_ORDER_CONFIG.name,
        filters: {
            initial: [{
                field: 'userId',
                operator: 'eq',
                value: userId,
            }]
        },
    })

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const doFilter = () => {
        console.log(fieldArr)
    }

    return <>
        <TabletWidgets>
            <TabletWidgetLeft>
                <TextField
                    control={control} fieldName='search' placeholder="Search by Order ID"
                    className='w-full' handleEnter={doFilter}
                />
            </TabletWidgetLeft>
            <TabletWidgetRight>
                <Button shape='outline' onClick={filterModal.show}>
                    <FilterIcon />Filter
                </Button>
                <Button href={`/service-orders/create?user=${userId}`}>+ New order</Button>
            </TabletWidgetRight>
        </TabletWidgets>
        <Table>
            <THead>
                <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>Order ID</Th>
                <Th>Purchase Date</Th>
                <Th>Grand Total</Th>
                <Th>Job Type</Th>
                <Th>Status</Th>
                <Th>Action</Th>
            </THead>
            {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((order: any) => {
                const firstInvoice = order.invoices.sort((a: any, b: any) => parseInt(a) - parseInt(b))[0]
                const status = getOrderStatus(order, firstInvoice)
                return <TBody>
                    <Td>{order.id}</Td>
                    <Td>{formatFullDate(order.createdAt, { showTime: false })}</Td>
                    <Td>$ {order.grandTotal}</Td>
                    <Td>
                        {order.items[0] && order.items[0].service.jobType &&
                            <Badge color={order.items[0].service.jobType.colorHex}>{order.items[0].service.jobType.name}</Badge>
                        }
                    </Td>
                    <Td>
                        {status &&
                            <Badge fullOpacityBackground textColor={status.color} color={status.backgroundColor} className='inline-block mr-4 text-th-xs'>
                                {status.text}
                            </Badge>
                        }
                    </Td>
                    <TAction id={`service-order-${order.id}`}>
                        <TActionItem access={{ resource: SERVICE_ORDER_CONFIG.name, action: 'show' }}>
                            <NavLink className={'text-sm'} to={`/service-orders/${order.id}`}>View</NavLink>
                        </TActionItem>
                    </TAction>
                </TBody>
            })}
        </Table>
        <div className='justify-end mt-4 flex-ct-y'>
            <Pagination
                pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                clickHandler={changePage} range={5}
            />
        </div>
        <FilterModal visible={filterModal.visible} close={filterModal.close} resourceName="Order" doFilter={() => { }}>
            <div className='mb-2 font-medium'>Status</div>
            <div className='flex-wrap gap-3 mb-6 flex-ct-y'>
                <Checkbox fieldArr={fieldArr}
                    cbVal={'ongoing'} fieldName='status' control={control}
                    label={'Ongoing'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'cancelled'} fieldName='status' control={control}
                    label={'Cancelled'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'completed'} fieldName='status' control={control}
                    label={'Completed'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'expired'} fieldName='status' control={control}
                    label={'Expired'}
                />
                <Checkbox fieldArr={fieldArr}
                    cbVal={'refunded'} fieldName='status' control={control}
                    label={'Refunded'}
                />
            </div>
            <SelectField className='mb-6' control={control} label={'Payment'} fieldName='payment' options={[
                { value: 'all', label: 'All' },
                { value: 'paid', label: 'Paid' },
                { value: 'unpaid', label: 'Unpaid' }
            ]} />
        </FilterModal>
    </>
}

export default ShowUserOrderList