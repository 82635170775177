import PageTitle from "components/PageTitle";
import FormFieldService from "./FormFieldService";
import { useForm, } from "@refinedev/react-hook-form"
import { SERVICES_CONFIG } from "resources-config/services-config";
import { CreateEditCard } from "components/cards";
import { Button } from "components/buttons";
import { useParams } from "react-router-dom";
import { formatToFormData, getErrorNotif, getSuccessNotif } from "utils/data-formatter";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";

export default function EditService() {
    const params = useParams()
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [defaultSelectValues, setDefaultSelectValues] = useState<MiscObj[]>([])
    const [showForm, setShowForm] = useState(false)
    const {
        refineCore: { onFinish, queryResult, mutationResult }, register, handleSubmit, control, watch,
        setError, clearErrors, setValue,
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: SERVICES_CONFIG.name,
            action: 'edit', id: params.id,
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/services/edit/${data.data.id}`
                }
                return getSuccessNotif('update', 'service', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('update', 'service', data)
            },
        },
    });
    const onFinishHandler = (data: any) => {
        if (data.frequencies) {
            data.frequencies = data.frequencies.map((freq: MiscObj) => {
                let catArr = freq.category || ''
                catArr = catArr.split('__')
                const catId = catArr[0]
                return {
                    ...freq, category: catId || ''
                }
            })
        }
        const formData = formatToFormData(data, ['icon', 'thumbnail', 'gallery'], ['frequencies'])
        onFinish(formData)
    }
    useEffect(() => {
        try {
            handleMutation(
                mutationResult, setError, clearErrors, setBtnDisabled
            )
        } catch (err: any) {
            console.log(err)
            setBtnDisabled(false)
        }

    }, [mutationResult, setError, clearErrors, setBtnDisabled])
    useEffect(() => {
        if (showForm == true) return

        if (!queryResult || !queryResult.data || !queryResult.data.data) {
            setDefaultSelectValues([])
            return
        }
        const defaultValues: MiscObj[] = []

        defaultValues.push({
            name: 'thumbnail',
            defaultValues: {
                id: null,
                src: queryResult.data.data.thumbnail
            }
        })

        defaultValues.push({
            name: 'icon',
            defaultValues: {
                id: null,
                src: queryResult.data.data.icon
            }
        })

        defaultValues.push({
            name: 'gallery',
            defaultValues: queryResult.data.data.gallery.map((media: any) => ({
                id: media.id,
                src: media.mediaUrl
            }))
        })

        setDefaultSelectValues(defaultValues)
        setShowForm(true)
    }, [queryResult])

    return (<>
        <PageTitle title="Edit Service" />
        <CreateEditCard
            cardHeading="Edit Service"
            actions={<>
                <Button type='submit' form='service-form' isLoading={btnDisabled}>Save</Button>
            </>}
        >
            <FormFieldService
                formId={'service-form'}
                register={register}
                control={control}
                onFinish={onFinish}
                handleSubmit={handleSubmit}
                onFinishHandler={onFinishHandler}
                watch={watch}
                setValue={setValue}
                queryResult={queryResult}
                setError={setError}
                defaultValues={defaultSelectValues}
                showForm={showForm}
            />
        </CreateEditCard>
    </>)
}