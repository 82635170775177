import { useController } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { BaseFormProps } from './types'

type Props = BaseFormProps & {

}

const CountryPhoneInput = (props: Props) => {
    const { control, fieldName, label, required, disabled } = props
    const { field: { onChange, value }, fieldState: { error } } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required && `${label ? label : fieldName} is required`,
            // pattern: {
            //     value: /^65[689]\d{7}$/,
            //     message: 'Please enter a valid Singapore phone number'
            // }
        }
    })

    return <div className={`form-group ${required ? 'required' : ''}`}>
        {label ? <label className={`form-label`}>{label}</label> : ''}
        <PhoneInput
            onChange={onChange}
            inputClass='form-control disabled:bg-gray-100'
            containerClass='w-full'
            country={'sg'}
            prefix='+'
            value={value}
            disabled={disabled}
            // enableSearch
            inputProps={{
                id: fieldName
            }}
            countryCodeEditable={false}
            placeholder="Phone Number"
        />
        {
            !error ? <></> :
                <span className={`err-msg`}>
                    {error.message}
                </span>
        }
    </div>
}

export default CountryPhoneInput