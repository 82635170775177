import ListPendingPayment from "components/pages/pending-payment/ListPendingPayment"
import { PendingPaymentIcon, TaskBoardIcon } from "components/Svg"
import { ResourceType } from "resources-config"

export const PENDING_PAYMENT_CONFIG: ResourceType = {
    name: 'pending-payment',
    meta: {
        icon: <PendingPaymentIcon />,
        label: 'Pending Payment',
        apiEndpoints: {
            getList: '/service-invoices'
        }
    },
    actions: {
        list: {
            path: 'pending-payment', page: <ListPendingPayment />,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }
    }
}

