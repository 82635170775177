import { useCallback } from 'react'
import { useTable, CanAccess, BaseKey, useNotification } from "@refinedev/core"

import { SERVICES_CONFIG } from 'resources-config/services-config'

import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { baseApi } from 'providers/customDataProvider'
import { BaseCard } from 'components/cards'
import Badge from 'components/Badge'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { Button } from 'components/buttons'
import { NavLink } from 'react-router-dom'
import { patchServiceOrdering } from 'api/service'
import { toast } from 'sonner'

export default function ListService() {
    const { tableQueryResult, pageSize, current, setCurrent, sorters, setSorters, } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: SERVICES_CONFIG.name,
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const toggleActiveService = useCallback(async (id: BaseKey, isActive: boolean) => {
        try {
            await baseApi.post('admin/services/toggle-active/' + id, { isActive: isActive })
            tableQueryResult.refetch()
        } catch (error) {
            console.log(error)
        }
    }, [tableQueryResult])

    const handleMoveOrder = async (serviceId: string, order: number) => {
        try {
            if (await patchServiceOrdering(serviceId, order)) {
                toast.success('Ordering successfully updated')
            }
        }
        catch (err: any) {
            console.log(err)
            toast.error(err.message)
        } finally {
            tableQueryResult.refetch()
        }
    }

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft></TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={SERVICES_CONFIG.name} action='create'>
                        <Button href='/services/create'>Create Service</Button>
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', sorters: sorters, setSorters: setSorters }}>ID</Th>
                    <Th sorting={{ column: 'name', sorters: sorters, setSorters: setSorters }}>Name</Th>
                    <Th>Total Frequencies</Th>
                    <Th>Show Order</Th>
                    <Th>Status</Th><Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((service, index) => (
                    <TBody key={index}>
                        <Td>{service.id}</Td>
                        <Td>{service.name}</Td>
                        <Td>{service.frequencies.length}</Td>
                        <Td>{service.order.toString()}</Td>
                        <Td>
                            <Badge color={(service.isActive ? 'success' : 'danger')} className='text-th-xs'>
                                {(service.isActive ? 'Active' : 'Inactive')}
                            </Badge>
                        </Td>
                        <TAction id={`edit-admin-${service.id}`}>
                            <TActionItem access={{ resource: SERVICES_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm'
                                    onClick={() => { toggleActiveService(service.id || 0, !service.isActive) }}
                                >
                                    {service.isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </TActionItem>
                            <TActionItem access={{ resource: SERVICES_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm'
                                    onClick={() => handleMoveOrder((service.id || '').toString(), +service.order - 1)}
                                >
                                    Move Up
                                </button>
                            </TActionItem>
                            <TActionItem access={{ resource: SERVICES_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm'
                                    onClick={() => handleMoveOrder((service.id || '').toString(), +service.order + 1)}
                                >
                                    Move Down
                                </button>
                            </TActionItem>
                            <TActionItem access={{ resource: SERVICES_CONFIG.name, action: 'edit' }}>
                                <NavLink className='text-sm' to={`/services/edit/${service.id}`}>
                                    Edit
                                </NavLink>
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
    </>)
}

