import { useModalReturnType } from "@refinedev/core"
import { getActiveServices } from "api/service"
import { Button } from "components/buttons"
import { ChooseItemEffects } from "components/effects"
import { AsyncSelectField, CounterField, FormsGrid, SelectField, TextField } from "components/forms"
import Pill from "components/Pill"
import { Modal } from "components/popups"
import { FieldInfo } from "components/texts"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import useSWR from "swr"
import { calculateDiscount } from "utils/discounts"

type Props = {
    modal: useModalReturnType
    onFinish: (data: any, serviceDetail: MiscObj, id?: string) => void
    defaultValue: MiscObj | null
}

const AddEditItemModal
    = (props: Props) => {
        const { modal, onFinish, defaultValue = {} } = props
        const { control, handleSubmit, setValue, watch, reset } = useForm()
        const { data: services = [] } = useSWR('active-services', getActiveServices)
        const serviceId = watch(`service`)
        const categoryId = watch(`category`)
        const frequencyId = watch(`frequency`)
        const quantity = watch(`quantity`)
        const [initialPricePerUnit, setInitialPricePerUnit] = useState<number>(0)
        const pricePerUnit = watch(`pricePerUnit`)
        const [serviceDetail, setServiceDetail] = useState<MiscObj>()
        const [availableCategories, setAvailableCategories] = useState<any[]>([])
        const [availableFrequencies, setAvailableFrequencies] = useState<any[]>([])
        const finalPrice = watch(`price`)

        useEffect(() => {
            reset()
        }, [modal.visible])


        useEffect(() => {
            if (!defaultValue) return
            const defaultPair = Object.entries(defaultValue)
            if (defaultPair.length > 0) defaultPair.forEach(([key, value]) => setValue(key, value))
        }, [defaultValue])

        const handleAddNewItem = handleSubmit((data) => serviceDetail && onFinish(data, serviceDetail, defaultValue && defaultValue.id ? defaultValue.id : undefined))

        return <Modal visible={modal.visible} close={modal.close} heading={defaultValue != null ? 'Edit Item' : 'Add Item'} width="32rem" renderChildOnHidden>
            <ChooseItemEffects
                categoryId={categoryId}
                serviceId={serviceId}
                setAvailableCategories={setAvailableCategories}
                setAvailableFrequencies={setAvailableFrequencies}
                setValue={setValue}
                name={``}
                availableFrequencies={availableFrequencies}
                quantity={quantity}
                frequencyId={frequencyId}
                pricePerUnit={pricePerUnit}
                setServiceDetail={setServiceDetail}
                setInitialPricePerUnit={setInitialPricePerUnit}
            >
                <form onSubmit={handleAddNewItem} className="space-y-4" id={'add-edit-item-form'}>
                    <SelectField
                        control={control}
                        options={services.map((service: any) => ({ value: +service.id, label: service.name }))}
                        fieldName={`service`}
                        className="w-full"
                        defaultCfg={{ value: null, setValue: setValue }}
                        label='New Item'
                        placeholder='Select an item to add'
                        enableMenuPortalTarget={false}
                    />
                    <FormsGrid col={2}>
                        <SelectField
                            disabled={!availableCategories || availableCategories.length == 0 || !serviceId}
                            defaultCfg={{ value: null, setValue: setValue }}
                            control={control}
                            label={'Category'}
                            options={availableCategories.map(category => ({ value: +category.id, label: category.name }))}
                            fieldName={`category`} className="w-full" required
                            placeholder="Select category"
                        />
                        <SelectField
                            disabled={!availableFrequencies || availableFrequencies.length == 0}
                            control={control}
                            options={availableFrequencies.map(freq => ({ value: +freq.id, label: `${freq.frequency} times` }))}
                            fieldName={`frequency`}
                            label={'Frequency'}
                            className="w-full" required
                            defaultCfg={{ value: null, setValue: setValue }}
                            placeholder="Select frequency"
                        />
                        <CounterField
                            disabled={!availableFrequencies || availableFrequencies.length == 0}
                            control={control}
                            label={'Quantity'}
                            fieldName={`quantity`}
                            required
                            placeholder="Select quantity"
                        />
                        <div>
                            <TextField
                                required
                                disabled={!frequencyId}
                                fieldName={`pricePerUnit`}
                                label={'Price per unit'}
                                control={control}
                                isNumeric
                                className="w-full"
                                placeholder="Price per unit"
                                defaultValue={''}
                            />
                            <div className="flex items-center justify-between mt-2">
                                <span className="text-[0.625rem] text-light">Initial price/unit/freq : $ {initialPricePerUnit}</span>
                                {calculateDiscount(pricePerUnit, initialPricePerUnit) > 0 &&
                                    <Pill backgroundColor="#FF6666" textColor="white" fontSize={10}>{calculateDiscount(pricePerUnit, initialPricePerUnit)} %</Pill>
                                }
                            </div>
                        </div>
                    </FormsGrid>

                    <FieldInfo label="Total Price" value={`$ ${finalPrice ?? 0}`} fieldClassName="w-full bg-gray-100" />

                    <TextField
                        control={control}
                        label={'Notes for technician (Optional)'}
                        fieldName={`notes`}
                        placeholder='Enter a note'
                    />
                    <div className="flex items-center justify-end gap-2">
                        <Button shape="outline" onClick={modal.close}>
                            Cancel
                        </Button>
                        <Button type="submit" form={'add-edit-item-form'}>
                            {defaultValue ? 'Save' : 'Add Item'}
                        </Button>
                    </div>
                </form>
            </ChooseItemEffects>
        </Modal>

    }

export default AddEditItemModal
