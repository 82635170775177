import { AirconIcon, AirconListIcon } from "components/Svg"
import ListAirconBrand from "components/pages/aircon/ListAirconBrand"
import { ResourceType } from "resources-config"

export const AIRCON_BRAND_CONFIG: ResourceType = {
    name: 'aircon-brands', meta: {label: 'Aircon', labelAsChild: 'Brands', icon: <AirconListIcon/>},
    actions: {
        list: {
            path: 'aircon-brands', page: <ListAirconBrand/>,
            allowedRoles: ['super admin'],
        },
        create: {
            path: 'aircon-brands', allowedRoles: ['super admin'],
        },
        edit: {
            path: 'aircon-brands', allowedRoles: ['super admin'],
        }            
    }
}