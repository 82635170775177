import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess, BaseKey } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"


import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { TextField } from 'components/forms'
import { handleModalMutation } from 'utils/handle-mutation'
import useTableQuery from 'utils/useTableQuery'
import { Modal } from 'components/popups'
import { getErrorNotif, getSuccessNotif } from 'utils/data-formatter'
import { LEAD_SOURCES_CONFIG } from 'resources-config/lead-sources-config'
import { formatFullDate } from 'utils/date'
import Badge from 'components/Badge'
import { baseApi } from 'providers/customDataProvider'

type FieldType = {
    name: string
}

export default function ListLeadSource() {
    const createLeadSourceModal = useModal();
    const editLeadSourceModal = useModal();
    const [editedLeadSource, setEditedLeadSource] = useState<{ [key: string]: any }>({})

    const {
        tableQueryResult, pageSize, current, setCurrent, filters, setFilters,
    } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: LEAD_SOURCES_CONFIG.name,
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const toggleActiveLeadSource = useCallback(async (id: BaseKey, isActive: boolean) => {
        try {
            await baseApi.post(`admin/lead-sources/${id}/toggle-active/`, { isActive: isActive })
            tableQueryResult.refetch()
        } catch (error) {
            console.log(error)
        }
    }, [tableQueryResult.refetch])

    const { control, doFilter } = useTableQuery(filters, setFilters)

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        control={control} fieldName='name' placeholder="Search by name"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={LEAD_SOURCES_CONFIG.name} action='create'>
                        <Button onClick={() => { createLeadSourceModal.show() }}>Add Lead Source</Button>
                        <CreateLeadSourceModal modal={createLeadSourceModal} />
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th>Notes</Th>
                    <Th>Created At</Th>
                    <Th>Created By</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((leadSource, index) => (
                    <TBody key={index}>
                        <Td>{leadSource.id}</Td>
                        <Td>{leadSource.name}</Td>
                        <Td>{leadSource.notes ?? "--"}</Td>
                        <Td>{formatFullDate(leadSource.createdAt, { showDay: false, showTime: false })}</Td>
                        <Td>{leadSource.creator.name}</Td>
                        <Td>
                            <Badge color={(leadSource.isActive ? 'success' : 'danger')} className='text-th-xs'>
                                {(leadSource.isActive ? 'Active' : 'Inactive')}
                            </Badge>
                        </Td>
                        <TAction id={`edit-lead-source-${leadSource.id}`}>
                            <TActionItem access={{ resource: LEAD_SOURCES_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm' onClick={() => {
                                    setEditedLeadSource(leadSource)
                                    editLeadSourceModal.show()
                                }}>
                                    Edit
                                </button>
                            </TActionItem>
                            <TActionItem access={{ resource: LEAD_SOURCES_CONFIG.name, action: 'edit' }}>
                                <button type='button' className='text-sm' onClick={() => { toggleActiveLeadSource(leadSource.id || 0, !leadSource.isActive) }}>
                                    {leadSource.isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
            <EditLeadSourceModal modal={editLeadSourceModal} leadSource={editedLeadSource} />
        </BaseCard>
    </>)
}

const CreateLeadSourceModal = ({ modal }: { modal: any }) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult, queryResult }, handleSubmit, setError,
        clearErrors, control, setValue
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: LEAD_SOURCES_CONFIG.name,
            successNotification() {
                return getSuccessNotif('create', 'lead source')
            },
            errorNotification(data) {
                return getErrorNotif('create', 'lead source', data)
            }
        },
    });
    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])
    return (
        <Modal heading={'Create Lead Source'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField required label={'Name'} control={control} fieldName='name' />
                <TextField label={'Notes'} control={control} fieldName='notes' />
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>
    )
}

const EditLeadSourceModal = (
    { modal, leadSource }:
        { modal: any, leadSource: { [key: string]: string } }
) => {
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { onFinish, mutationResult, queryResult }, handleSubmit, reset, setError,
        control, clearErrors, setValue
    } = useForm<FieldType>({
        defaultValues: { name: leadSource.name },
        refineCoreProps: {
            resource: LEAD_SOURCES_CONFIG.name,
            action: !leadSource.id ? 'create' : 'edit', id: leadSource.id || 0,
            successNotification() {
                return getSuccessNotif('update', 'lead source')
            },
            errorNotification(data) {
                return getErrorNotif('update', 'lead source', data)
            }
        },
    });

    useEffect(() => {
        const { id, ...fields } = leadSource
        reset(fields)
    }, [leadSource, reset])

    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])
    return (
        <Modal heading={'Edit Lead Source'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(data => onFinish({ ...data, brand: +data.brand }))} className="flex flex-col gap-4 text-th-sm">
                <TextField required label={'Name'} control={control} fieldName='name' />
                <TextField label={'Notes'} control={control} fieldName='notes' />
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>
    )
}
