import ListServiceJobType from "components/pages/service-job-types/ListServiceJobType"
import { ResourceType } from "resources-config"

export const SERVICE_JOB_TYPES_CONFIG: ResourceType = {
    name: 'service-job-types', meta: {parent: 'services', label: "Service Job Types"},
    actions: {
        list: {
            path: 'service-job-types', page: <ListServiceJobType/>,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }        
    }
}

