'use client'
import { useCallback, useEffect, useState } from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import { app } from 'firebaseConfig';
import useLocalStorage from './useLocalStorage';

const useFcmToken = () => {
    const [token, setToken] = useState('');
    const [oldToken, setOldToken] = useLocalStorage('oldDeviceToken', '')
    const [notificationPermissionStatus, setNotificationPermissionStatus] = useState('');

    const replaceOldToken = useCallback(() => {
        if (token && token == oldToken)
            setOldToken(token)
    }, [token, oldToken])

    useEffect(() => {
        const retrieveToken = async () => {
            try {
                if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
                    const messaging = getMessaging(app);

                    // Retrieve the notification permission status
                    const permission = await Notification.requestPermission();
                    setNotificationPermissionStatus(permission);

                    // Check if permission is granted before retrieving the token
                    if (permission === 'granted') {
                        const currentToken = await getToken(messaging, {
                            vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
                        });
                        if (!oldToken) setOldToken(currentToken)
                        if (currentToken) {
                            setToken(currentToken);
                        } else {
                            console.log(
                                'No registration token available. Request permission to generate one.'
                            );
                        }
                    }
                }
            } catch (error) {
                console.log('An error occurred while retrieving token:', error);
            }
        };

        retrieveToken();
    }, []);

    return { fcmToken: token, oldFcmToken: oldToken, notificationPermissionStatus, replaceOldToken };
};

export default useFcmToken;