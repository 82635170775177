import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"

import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { PROMO_CONFIG } from 'resources-config/promo-config'
import { NavLink } from 'react-router-dom'

export default function ListServicePromo() {
    const { tableQueryResult, pageSize, current, setCurrent, sorters, setSorters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: PROMO_CONFIG.name,
    })
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft></TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={PROMO_CONFIG.name} action='create'>
                        <Button tag='a' href="/promos/create" >Create Promo</Button>
                    </CanAccess>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th sorting={{ column: 'id', setSorters: setSorters, sorters: sorters }}>ID</Th>
                    <Th sorting={{ column: 'code', setSorters: setSorters, sorters: sorters }}>Promo Code</Th>
                    <Th>Amount</Th>
                    <Th>Title</Th>
                    <Th>Description</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((servicePromo, index) => (
                    <TBody key={index}>
                        <Td>{servicePromo.id}</Td>
                        <Td>{servicePromo.code}</Td>
                        <Td>{servicePromo.amount}{servicePromo.type == '2' ? ' %' : ' SGD'}</Td>
                        <Td>{servicePromo.title}</Td>
                        <Td className='line-clamp-1'>{servicePromo.description}</Td>
                        <TAction id={`edit-service-promo-${servicePromo.id}`}>
                            <TActionItem access={{ resource: PROMO_CONFIG.name, action: 'edit' }}>
                                <NavLink to={`/promos/edit/${servicePromo.id}`}>
                                    Edit
                                </NavLink>
                            </TActionItem>
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
    </>)
}

