import { AsyncMultiSelectField, FormsGrid, TextField } from "components/forms"
import UploadField from "components/forms/UploadField"
import { baseApi } from "providers/customDataProvider"
import { useMemo } from "react"

interface FormFieldTechnician extends FormFieldTypeNew {
    action: string
    register: FormHookType['register']
    defaultValues?: MiscObj
    setValue: FormHookType['setValue']
    watch: FormHookType['watch']
}

export type TechnicianFieldType = {
    name: string
    email: string
    password: string
    phoneNumber: string
}

export default function FormFieldTechnician(props: FormFieldTechnician) {
    const { defaultValues = [], showForm = true } = props

    const loadSkillOptions = async (inputValue: string) => {
        const skillRes = await baseApi.get(`/admin/technician-skills?search=${inputValue}`)
        return skillRes.data.map((skill: any) => ({ value: +skill.id, label: skill.name }))
    }

    const defaults = useMemo(() => {
        let skillsDefaults: any = defaultValues.find((def: any) => def.name == 'skills')
        skillsDefaults = skillsDefaults ? skillsDefaults.defaultValues : null

        let pictureDefault = defaultValues.find((def: any) => def.name == 'profilePicture')
        pictureDefault = pictureDefault ? pictureDefault.defaultValues : null

        return { skillsDefaults, pictureDefault }
    }, [defaultValues])

    return (
        <form onSubmit={props.handleSubmit(props.onFinishHandler)} className="flex flex-col" id={props.formId}>
            <FormsGrid>
                <TextField control={props.control} fieldName="name" label={'Name'} required />
                <TextField control={props.control} fieldName="email" label={'Email'} required />
                <TextField control={props.control} fieldName="phoneNumber" label={'Phone Number'} required />
                <TextField label={'VRN'} control={props.control} fieldName="vrn" desc="Vehicle registration number" />
                {(defaults && showForm) &&
                    <AsyncMultiSelectField
                        control={props.control}
                        fieldName="skills"
                        loadOptions={loadSkillOptions}
                        creatable
                        label={'Skills'}
                        defaultCfg={{
                            value: defaults.skillsDefaults,
                            setValue: props.setValue
                        }}
                    />
                }

                <TextField control={props.control} fieldName="password" label={props.action === 'edit' ? 'Update Password' : 'Password'} required={props.action === 'edit' ? false : true} />
                {(defaults && showForm) &&
                    <UploadField defaultSrc={[defaults.pictureDefault]} control={props.control} fieldName="profilePicture" accept="image/png, image/jpeg" label={props.action === 'edit' ? 'Update Profile Picture' : 'Profile Picture'} />
                }
            </FormsGrid>
        </form>
    )
}