import ListServiceUnit from "components/pages/service-units/ListServiceUnit"
import { ResourceType } from "resources-config"

export const SERVICE_UNITS_CONFIG: ResourceType = {
    name: 'service-units', meta: {parent: 'services', label: "Service Units"},
    actions: {
        list: {
            path: 'service-units', page: <ListServiceUnit/>,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }        
    }
}

