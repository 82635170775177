import { PromoInterface } from "components/promo/types"


const promoData: PromoInterface[] = [
    {
        title: "AIRPLE2024 Discount up to 30%",
        subtitle: "Min. Purchase $50",
        startDate: new Date('01-01-2024'),
        endDate: new Date('02-01-2024'),
        description: "Lorem ipsum dolor sit amet consectetur. Mattis suscipit cursus nascetur dolor. Nunc erat cursus convallis mollis viverra facilisi ornare praesent. Neque lobortis dictum molestie consequat ipsum mauris. Vitae at tellus nibh in eu at. Curabitur eu lacus neque diam. Amet ac eget neque facilisis eleifend. Praesent diam maecenas in luctus accumsan semper velit malesuada habitasse. Malesuada at mauris amet et sem velit sit. Porta mauris sed elementum quam.",
        code: "AIRPLE2024",
        discountPercentage: 30
    },
    {
        title: "New User First Order Discount 20%",
        subtitle: "Min. Purchase $30",
        startDate: new Date(),
        endDate: new Date('12-31-2024'),
        description: "Lorem ipsum dolor sit amet consectetur. Mattis suscipit cursus nascetur dolor. Nunc erat cursus convallis mollis viverra facilisi ornare praesent. Neque lobortis dictum molestie consequat ipsum mauris. Vitae at tellus nibh in eu at. Curabitur eu lacus neque diam. Amet ac eget neque facilisis eleifend. Praesent diam maecenas in luctus accumsan semper velit malesuada habitasse. Malesuada at mauris amet et sem velit sit. Porta mauris sed elementum quam.",
        code: "NEWUSER",
        discountPercentage: 20
    },
]

export default promoData