import { Button } from "components/buttons"
import { placesServiceConfig } from "components/consts/map"
import { Modal } from "components/popups"
import PopUp, { PopUpProps } from "components/popups/PopUp"
import { Dispatch, SetStateAction, useState, useEffect, ChangeEvent } from "react"
import { PlaceInterface, nearbySearch, extractPlaceDetails } from "utils/map"
import AddressLocation from "./AddressLocation"
import MapLocationPicker from "./MapLocationPicker"
import { CoordinateType } from "./types"
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { CloseIcon, TargetIcon } from "components/Svg"
import { IoChevronBack, IoSearch } from "react-icons/io5"
import { formatBuildingAddress, formatCountry } from "utils/address"


interface Props extends PopUpProps {
    defaultCoordinate?: CoordinateType
    openFindAddress: () => void
    handleSetAddress: (place: PlaceInterface) => void
    close: () => void
}

// This coordinate points to Changi Airport
const DEFAULT_LATITUDE = 1.3545
const DEFAULT_LONGITUDE = 103.9886
// TODO: Change to Airple HQ coordinates


const PinpointPopup = (props: Props) => {
    const {
        visible,
        close,
        openFindAddress,
        handleSetAddress,
        defaultCoordinate = {
            lat: DEFAULT_LATITUDE,
            lng: DEFAULT_LONGITUDE
        }
    } = props
    const [placeOptions, setPlaceOptions] = useState<PlaceInterface[]>([])
    const [selectedPlace, setSelectedPlace] = useState<PlaceInterface | null | undefined>()
    const [coordinate, setCoordinate] = useState<CoordinateType>(defaultCoordinate)
    const { placesService, placePredictions, getPlacePredictions } = usePlacesService(placesServiceConfig)



    // Fill in default selected place
    useEffect(() => {
        if (visible && !selectedPlace && placesService) {
            navigator.geolocation.getCurrentPosition((position) => {
                nearbySearch(position.coords.latitude, position.coords.longitude, placesService, setCoordinate, setSelectedPlace)
            }, () => {
                nearbySearch(DEFAULT_LATITUDE, DEFAULT_LONGITUDE, placesService, setCoordinate, setSelectedPlace)
            })
        }
    }, [visible])


    const handleGetCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            nearbySearch(position.coords.latitude, position.coords.longitude, placesService!, setCoordinate, setSelectedPlace)
        })
    }

    const handleSelectPlace = (place: PlaceInterface) => {
        setPlaceOptions([])
        setSelectedPlace(place)
        setCoordinate({
            lat: place.lat || 0,
            lng: place.lng || 0
        })
    }

    const handleGetPlacePrediction = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value)
            getPlacePredictions({ input: e.currentTarget.value })
        else {
            getPlacePredictions({ input: '' })
            setPlaceOptions([])
        }
    }

    useEffect(() => {
        if (placePredictions.length > 0 && placesService) {
            setPlaceOptions([])
            placePredictions.forEach(place => {
                placesService.getDetails(
                    {
                        placeId: place.place_id,
                    },
                    (placeDetails) => {
                        setPlaceOptions(state => [...state, extractPlaceDetails(placeDetails)])
                    }
                )
            })
        }
    }, [placePredictions]);


    return <PopUp mobileFullScreen drawerView visible={visible} width={'41.325rem'} close={close} >
        <div className='flex flex-col justify-between h-full'>
            <div className="flex justify-between">
                <button onClick={() => { close(); openFindAddress(); }} className='flex tablet-below:hidden'>
                    <div>
                        <IoChevronBack />
                    </div>
                </button>
                <div className="flex gap-4 items-center relative">
                    <h1 className="text-th-lg font-medium tablet-below:hidden">Select pinpoint location</h1>
                </div>
                <button type='button' onClick={close}>
                    <CloseIcon className='w-8 tablet-below:hidden' />
                </button>
            </div>
            <div className='tablet-below:flex py-8 gap-10 justify-between items-center hidden section-bg-2'>
                <div className="flex items-center gap-2">
                    <button type="button" onClick={() => { close(); openFindAddress() }}>
                        <div>
                            <IoChevronBack />
                        </div>
                    </button>
                    <p className='text-th-lg font-medium'>Select Pinpoint Location</p>
                </div>
            </div>
            <div className="w-full gap-4 flex flex-col tablet-below:px-0 grow">
                {selectedPlace && placesService &&
                    <>
                        <div className="relative w-full h-full mt-10 tablet-below:mt-0">
                            <MapLocationPicker containerClassName='h-[35vh] min-h-[300px]' coordinate={coordinate} setCoordinate={setCoordinate} place={selectedPlace} searchFunction={(lat: number, lng: number) => nearbySearch(lat, lng, placesService, setCoordinate, setSelectedPlace)} />
                            <div className="absolute flex gap-2 w-11/12 bottom-6 left-1/2 -translate-x-1/2 tablet-below:bottom-[5rem]">
                                <div className="bg-white relative flex items-center rounded-lg border-[0.032rem] px-2 py-2 w-7/12 tablet-below:w-1/2">
                                    <div className='flex mr-2'>
                                        <IoSearch width={20} height={20} />
                                    </div>
                                    <input
                                        type="text"
                                        onChange={handleGetPlacePrediction}
                                        className=" w-full text-th-xs placeholder-light"
                                        placeholder="Write the street name / building / housing" >
                                    </input>
                                    <div className='w-full top-9 tablet-below:-translate-y-[100%] tablet-below:top-0 left-0 absolute flex flex-col rounded border-[0.032rem] bg-white max-h-[400px] overflow-auto'>
                                        {placeOptions.map((place) => (
                                            <button key={place.id} onClick={() => handleSelectPlace(place)} className='bg-white py-1.5 px-3 hover:bg-[#F1F1F1] transition duration-300 flex items-center justify-start gap-1'>
                                                <div className='flex flex-col gap-0.5 text-start relative'>
                                                    <span className='font-medium text-sm'>{place.name}</span>
                                                    <span className='text-light text-[12px] line-clamp-2'>{place.address}</span>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <button type="button" onClick={handleGetCurrentPosition} className="bg-white flex items-center rounded-lg border-[0.032rem] px-4 py-2 grow tablet-below:w-1/2 text-start">
                                    <div className='flex mr-2'>
                                        <TargetIcon color='#C2C2C2' width={20} height={20} />
                                    </div>
                                    <p className="text-light text-th-xs line-clamp-1">Use Current Location</p>
                                </button>
                            </div>
                            <div className="absolute hidden tablet-below:flex bottom-[0.5rem] left-1/2 -translate-x-1/2 w-11/12 z-20">
                                <AddressLocation name={selectedPlace ? formatBuildingAddress(selectedPlace.buildingNumber, selectedPlace.street) : 'Not Selected'} address={selectedPlace ? formatCountry(selectedPlace.country, selectedPlace.postalCode) : 'Not Selected'} className="border-[#ECF0F8] border rounded-lg h-[3.875rem] w-full bg-white relative" />
                            </div>
                        </div>
                        <div className='tablet-below:hidden'>
                            <AddressLocation name={selectedPlace ? formatBuildingAddress(selectedPlace.buildingNumber, selectedPlace.street) : 'Not Selected'} address={selectedPlace ? formatCountry(selectedPlace.country, selectedPlace.postalCode) : 'Not Selected'} className="border-[#ECF0F8] border rounded-lg h-[3.875rem]" />
                        </div>
                    </>
                }
            </div>
            <div className="w-full tablet-below:p-4 mt-8 tablet-below:mt-2 tablet-below:pb-0">
                <Button onClick={() => handleSetAddress(selectedPlace!)} disabled={!selectedPlace} className='w-full items-center flex justify-center' shape="filled"><p className="text-th-xs font-medium">Select & proceed to fill the address</p></Button>
            </div>
        </div>
    </PopUp>
}


export default PinpointPopup
