import { TechnicianAvatar } from "components/avatars"
import CountdownTimer from "components/CountdownTimer"
import Pill from "components/Pill"
import { addHours } from "date-fns"
import { ComponentProps } from "react"
import { formatFullDate } from "utils/date"

export type KanbanDataProps = {
    id: string
    user: any
    team?: any
    techName?: string
    date: Date
    orderId: string
    scheduleId: string
    subTitle: string
    timerMode?: 'increment' | 'decrement'
    topic: string
    isCompleted?: boolean
    submitId: string
    onClick?: () => void
}

type Props = ComponentProps<'button'> & KanbanDataProps & {
    accentColor: string
}



const KanbanCard = (props: Props) => {
    const { accentColor, title, user = {}, techName, subTitle, team = {}, date, orderId, scheduleId, timerMode, className = '', topic, isCompleted, ...rest } = props

    const timerDate = timerMode != undefined ? timerMode == 'increment' ? date : addHours(date, 1) : undefined

    return <button {...rest} type="button"
        className={`w-full text-start bg-white rounded-lg border p-3 relative overflow-hidden flex-col-ct-y gap-3 transition duration-300 ${className}`}
    >
        {timerDate &&
            <Pill backgroundColor="#FFE1DE" textColor="#BB032A" className="absolute right-3 top-3">
                <CountdownTimer date={timerDate} mode={timerMode} />
            </Pill>
        }
        <div className="flex items-center gap-2 flex-wrap">
            {topic == 'tech-late' &&
                <Pill backgroundColor="#BB032A" textColor="#FFFFFF">
                    Late Technician
                </Pill>
            }
            {topic == 'priority' &&
                <Pill backgroundColor="#FFC94A" textColor="#805900">
                    Priority Booking Request
                </Pill>
            }
            {topic == 'reschedule' &&
                <Pill backgroundColor="#CBE2F9" textColor="#0B5CAD">
                    Reschedule Request
                </Pill>
            }
            {topic == 'item-request' &&
                <Pill backgroundColor="#E1D8F9" textColor="#5943B6">
                    New Item Request
                </Pill>
            }
            {topic == 'pending-payment' &&
                <Pill backgroundColor="#F5D9A8" textColor="#8F4700">
                    Pending Payment
                </Pill>
            }
            {topic == 'technician-carry-cash' &&
                <Pill backgroundColor="#AFE3E9" textColor="#247881">
                    Technician Carry Cash
                </Pill>
            }
            {isCompleted === true &&
                <Pill backgroundColor="#FFE1DE" textColor="#BB032A">
                    Job is done
                </Pill>
            }
            {isCompleted === false &&
                <Pill backgroundColor="#F3A5BC" textColor="#A0153E">
                    Job is not done
                </Pill>
            }
        </div>

        <div className="text-th-sm">
            <h5>{user.name} - {user.phoneNumber}</h5>
            <h5>{subTitle}</h5>
        </div>
        <div className="flex items-end justify-between w-full">
            <div className="flex-col-ct-y gap-1.5">
                {team && team.name &&
                    <div className="flex-ct-y gap-2 font-medium">
                        <TechnicianAvatar className="w-6 h-6" imageSrc="/images/airple-icon.png" />
                        {techName &&
                            <span>{techName} -</span>
                        }
                        <span>{team ? team.name : '-'}</span>
                    </div>
                }
                <span className="text-th-xs">{formatFullDate(date, { showDay: true })}</span>
            </div>
            <div className="font-medium text-th-xs">
                OID {orderId} {scheduleId && <>- JID {scheduleId}</>}
            </div>
        </div>
    </button>
}

export default KanbanCard