import { round } from "lodash"

export const calculateDiscount = (price: number, initialPrice?: number) => {
    return initialPrice && initialPrice != 0 ? round((initialPrice - price) / initialPrice * 100) : 0
}

export const calculatePercentageDiscount = (price: number, discountPercentage: number) => {
    return price * discountPercentage / 100
}

export const calculateGST = (price: number) => {
    return price - (price / (1 + parseFloat(process.env.NEXT_PUBLIC_GST_RATE || '9') / 100))
}