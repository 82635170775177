import { useForm, } from "@refinedev/react-hook-form"

import PageTitle from "components/PageTitle";
import { USERS_CONFIG } from "resources-config/users-config";
import { CreateEditCard } from "components/cards";
import { Button } from "components/buttons";
import FormFieldUser from "./FormFieldUser";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";
import { getErrorNotif, getSuccessNotif } from "utils/data-formatter";

export default function CreateUser() {
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { onFinish, queryResult, mutationResult }, handleSubmit, control,
        clearErrors, setError, setValue, watch
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: USERS_CONFIG.name,
            action: 'create',
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/users/edit/${data.data.id}`
                }
                return getSuccessNotif('create', 'user', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('create', 'user', data)
            },
        },
    });

    const onFinishHandler: any = (data: FieldType) => {
        onFinish({
            ...data,
            email: data.email ?? null
        })
    }
    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])
    return (<>
        <PageTitle title="Create User" />
        <CreateEditCard
            cardHeading="Create User"
            actions={<>
                <Button type='submit' form='user-form' isLoading={btnDisabled}>Save</Button>
            </>}
        >
            <FormFieldUser
                formId={'user-form'}
                queryResult={queryResult}
                control={control}
                handleSubmit={handleSubmit}
                onFinishHandler={onFinishHandler}
                setValue={setValue}
                watch={watch}
                isEdit={false}
            />
        </CreateEditCard>
    </>)
}

type FieldType = {
    name: string
    email: string
    phoneNumber: string,
    addresses: { [key: string]: string }[]
}