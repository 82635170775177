type Props = {
    color?: string,
    width?: number,
    height?: number,
    className?: string
    [attrName: string]: any // HTML attributes  
}

export const CloseIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path className="stroke" stroke={color ? color : 'black'} d="M7 7L17 17M7 17L17 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const HomeIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 18 18" fill="none">
        <path className="fill" opacity="0.4" d="M15.6917 4.68306L10.9 1.32472C9.59169 0.408058 7.59169 0.458058 6.33335 1.43306L2.15835 4.69139C1.31669 5.34139 0.666687 6.67472 0.666687 7.72472V13.4747C0.666687 15.5997 2.39169 17.3331 4.50835 17.3331H13.4834C15.6 17.3331 17.325 15.6081 17.325 13.4831V7.83306C17.3334 6.70806 16.6084 5.32472 15.6917 4.68306Z" fill="#D2E0F2" />
        <path className="fill" d="M10.1667 6.09137H7.83338C6.23338 6.09137 4.92505 7.39137 4.92505 8.9997V11.3247C4.92505 12.9247 6.23338 14.233 7.83338 14.233H10.1584C11.7584 14.233 13.0667 12.933 13.0667 11.3247V8.9997C13.0667 7.3997 11.7667 6.09137 10.1667 6.09137ZM11.9084 8.9997V9.58304H9.58338V7.25804H10.1667C11.125 7.25804 11.9084 8.04137 11.9084 8.9997ZM7.83338 7.25804H8.41672V9.58304H6.09172V8.9997C6.09172 8.04137 6.87505 7.25804 7.83338 7.25804ZM6.09172 11.3247V10.7414H8.41672V13.0664H7.83338C6.87505 13.0664 6.09172 12.283 6.09172 11.3247ZM10.1667 13.0664H9.58338V10.7414H11.9084V11.3247C11.9084 12.283 11.125 13.0664 10.1667 13.0664Z" />
    </svg>
)

export const TaskBoardIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M6.89163 18.9584H3.52496C1.84996 18.9584 1.04163 18.1834 1.04163 16.5834V3.41669C1.04163 1.81669 1.85829 1.04169 3.52496 1.04169H6.89163C8.56663 1.04169 9.37496 1.81669 9.37496 3.41669V16.5834C9.37496 18.1834 8.55829 18.9584 6.89163 18.9584ZM3.52496 2.29169C2.46663 2.29169 2.29163 2.57502 2.29163 3.41669V16.5834C2.29163 17.425 2.46663 17.7084 3.52496 17.7084H6.89163C7.94996 17.7084 8.12496 17.425 8.12496 16.5834V3.41669C8.12496 2.57502 7.94996 2.29169 6.89163 2.29169H3.52496Z" fill="#36A9E1" />
        <path className="fill" d="M16.475 9.37502H13.1083C11.4333 9.37502 10.625 8.63335 10.625 7.10002V3.31669C10.625 1.78335 11.4417 1.04169 13.1083 1.04169H16.475C18.15 1.04169 18.9583 1.78335 18.9583 3.31669V7.09169C18.9583 8.63335 18.1417 9.37502 16.475 9.37502ZM13.1083 2.29169C11.9917 2.29169 11.875 2.60835 11.875 3.31669V7.09169C11.875 7.80835 11.9917 8.11669 13.1083 8.11669H16.475C17.5917 8.11669 17.7083 7.80002 17.7083 7.09169V3.31669C17.7083 2.60002 17.5917 2.29169 16.475 2.29169H13.1083Z" fill="#36A9E1" />
        <path className="fill" d="M16.475 18.9583H13.1083C11.4333 18.9583 10.625 18.1417 10.625 16.475V13.1083C10.625 11.4333 11.4417 10.625 13.1083 10.625H16.475C18.15 10.625 18.9583 11.4417 18.9583 13.1083V16.475C18.9583 18.1417 18.1417 18.9583 16.475 18.9583ZM13.1083 11.875C12.125 11.875 11.875 12.125 11.875 13.1083V16.475C11.875 17.4583 12.125 17.7083 13.1083 17.7083H16.475C17.4583 17.7083 17.7083 17.4583 17.7083 16.475V13.1083C17.7083 12.125 17.4583 11.875 16.475 11.875H13.1083Z" fill="#36A9E1" />
    </svg>
}

export const UserIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 20 20" fill="none">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M18.3327 6.50841V13.4918C18.3327 15.8334 17.2577 17.4417 15.366 18.0501C14.816 18.2417 14.1827 18.3334 13.491 18.3334H6.50768C5.81601 18.3334 5.18268 18.2417 4.63268 18.0501C2.74101 17.4417 1.66602 15.8334 1.66602 13.4918V6.50841C1.66602 3.47508 3.47435 1.66675 6.50768 1.66675H13.491C16.5244 1.66675 18.3327 3.47508 18.3327 6.50841Z" />
        <path className="fill" fill={color ? color : 'black'} d="M15.3661 18.0499C14.8161 18.2416 14.1828 18.3333 13.4911 18.3333H6.50781C5.81615 18.3333 5.18281 18.2416 4.63281 18.0499C4.92448 15.8499 7.22448 14.1416 9.99948 14.1416C12.7745 14.1416 15.0745 15.8499 15.3661 18.0499Z" />
        <path className="fill" fill={color ? color : 'black'} d="M12.9842 9.6501C12.9842 11.3001 11.6509 12.6417 10.0009 12.6417C8.35091 12.6417 7.01758 11.3001 7.01758 9.6501C7.01758 8.0001 8.35091 6.66675 10.0009 6.66675C11.6509 6.66675 12.9842 8.0001 12.9842 9.6501Z" />
    </svg>
)

export const SecurityIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 20 20" fill="none">
        <path className="fill" opacity="0.4" d="M9.99935 18.3332C14.6017 18.3332 18.3327 14.6022 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39698 1.6665 1.66602 5.39746 1.66602 9.99984C1.66602 14.6022 5.39698 18.3332 9.99935 18.3332Z" />
        <path className="fill" d="M13.1259 8.94183V8.3335C13.1259 7.5585 13.1259 5.2085 10.0009 5.2085C6.87592 5.2085 6.87592 7.5585 6.87592 8.3335V8.94183C5.85925 9.16683 5.51758 9.82516 5.51758 11.2502V12.0835C5.51758 13.9168 6.08425 14.4835 7.91758 14.4835H12.0842C13.9176 14.4835 14.4843 13.9168 14.4843 12.0835V11.2502C14.4843 9.82516 14.1426 9.16683 13.1259 8.94183ZM10.0009 12.5835C9.49258 12.5835 9.08427 12.1752 9.08427 11.6668C9.08427 11.1585 9.49258 10.7502 10.0009 10.7502C10.5092 10.7502 10.9176 11.1585 10.9176 11.6668C10.9176 12.1752 10.5092 12.5835 10.0009 12.5835ZM11.8759 8.85016H8.12592V8.3335C8.12592 7.11683 8.42592 6.4585 10.0009 6.4585C11.5759 6.4585 11.8759 7.11683 11.8759 8.3335V8.85016Z" />
    </svg>
)

export const ChevronRightIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 17" fill="none">
        <path className="stroke" stroke={color ? color : 'black'} d="M6.66667 11.5332L10 8.19987" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        <path className="stroke" stroke={color ? color : 'black'} d="M10 8.19922L6.66667 4.86589" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const ChevronDownIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 16" fill="none">
        <path className="fill" fill={color ? color : 'black'} fillRule="evenodd" clipRule="evenodd" d="M4.21967 6.21967C4.51256 5.92678 4.98744 5.92678 5.28033 6.21967L8 8.93934L10.7197 6.21967C11.0126 5.92678 11.4874 5.92678 11.7803 6.21967C12.0732 6.51256 12.0732 6.98744 11.7803 7.28033L8.53033 10.5303C8.23744 10.8232 7.76256 10.8232 7.46967 10.5303L4.21967 7.28033C3.92678 6.98744 3.92678 6.51256 4.21967 6.21967Z" />
    </svg>
)

export const HamburgerIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 22 22" fill="none">
        <path className="fill" fill={color ? color : 'black'} d="M3.04248 5.69531H18.9576H3.04248Z" />
        <path className="stroke" stroke={color ? color : 'black'} d="M3.04248 5.69531H18.9576" strokeWidth="1.5" strokeLinecap="round" />
        <path className="fill" fill={color ? color : 'black'} d="M3.04248 11.8843H18.9576H3.04248Z" />
        <path className="stroke" stroke={color ? color : 'black'} d="M3.04248 11.8843H18.9576" strokeWidth="1.5" strokeLinecap="round" />
        <path className="fill" fill={color ? color : 'black'} d="M3.04248 18.0737H18.9576H3.04248Z" />
        <path className="stroke" stroke={color ? color : 'black'} d="M3.04248 18.0737H18.9576" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
)

export const LogoutIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 16" fill="none">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M6 4.79992V11.1933C6 13.3333 7.33333 14.6666 9.46667 14.6666H11.1933C13.3267 14.6666 14.66 13.3333 14.66 11.1999V4.79992C14.6667 2.66659 13.3333 1.33325 11.2 1.33325H9.46667C7.33333 1.33325 6 2.66659 6 4.79992Z" />
        <path className="fill" fill={color ? color : 'black'} d="M3.71427 5.41343L1.48094 7.64676C1.2876 7.84009 1.2876 8.16009 1.48094 8.35343L3.71427 10.5868C3.9076 10.7801 4.2276 10.7801 4.42094 10.5868C4.61427 10.3934 4.61427 10.0734 4.42094 9.88009L3.04094 8.50009H10.1676C10.4409 8.50009 10.6676 8.27343 10.6676 8.00009C10.6676 7.72676 10.4409 7.50009 10.1676 7.50009H3.04094L4.42094 6.12009C4.52094 6.02009 4.5676 5.89343 4.5676 5.76676C4.5676 5.64009 4.52094 5.50676 4.42094 5.41343C4.2276 5.21343 3.91427 5.21343 3.71427 5.41343Z" />
    </svg>
)
export const UsersIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 20 20" fill="none">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M7.50008 1.66669C5.31675 1.66669 3.54175 3.44169 3.54175 5.62502C3.54175 7.76669 5.21675 9.50002 7.40008 9.57502C7.46675 9.56669 7.53341 9.56669 7.58341 9.57502C7.60008 9.57502 7.60841 9.57502 7.62508 9.57502C7.63341 9.57502 7.63341 9.57502 7.64175 9.57502C9.77508 9.50002 11.4501 7.76669 11.4584 5.62502C11.4584 3.44169 9.68341 1.66669 7.50008 1.66669Z" />
        <path className="fill" fill={color ? color : 'black'} d="M11.7333 11.7917C9.4083 10.2417 5.61663 10.2417 3.27497 11.7917C2.21663 12.5 1.6333 13.4583 1.6333 14.4833C1.6333 15.5083 2.21663 16.4583 3.26663 17.1583C4.4333 17.9417 5.96663 18.3333 7.49997 18.3333C9.0333 18.3333 10.5666 17.9417 11.7333 17.1583C12.7833 16.45 13.3666 15.5 13.3666 14.4667C13.3583 13.4417 12.7833 12.4917 11.7333 11.7917Z" />
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M16.6583 6.11665C16.7916 7.73331 15.6416 9.14998 14.05 9.34165C14.0416 9.34165 14.0416 9.34165 14.0333 9.34165H14.0083C13.9583 9.34165 13.9083 9.34165 13.8666 9.35831C13.0583 9.39998 12.3166 9.14165 11.7583 8.66665C12.6166 7.89998 13.1083 6.74998 13.0083 5.49998C12.95 4.82498 12.7166 4.20831 12.3666 3.68331C12.6833 3.52498 13.05 3.42498 13.425 3.39165C15.0583 3.24998 16.5166 4.46665 16.6583 6.11665Z" />
        <path className="fill" fill={color ? color : 'black'} d="M18.3251 13.825C18.2584 14.6333 17.7418 15.3333 16.8751 15.8083C16.0418 16.2667 14.9918 16.4833 13.9501 16.4583C14.5501 15.9167 14.9001 15.2417 14.9668 14.525C15.0501 13.4917 14.5584 12.5 13.5751 11.7083C13.0168 11.2667 12.3668 10.9167 11.6584 10.6583C13.5001 10.125 15.8168 10.4833 17.2418 11.6333C18.0084 12.25 18.4001 13.025 18.3251 13.825Z" />
    </svg>
)

export const UserHexIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M15.5635 6.14742V11.8474C15.5635 12.7807 15.0635 13.6474 14.2552 14.1224L9.30521 16.9808C8.49688 17.4474 7.49688 17.4474 6.68021 16.9808L1.73021 14.1224C0.921875 13.6558 0.421875 12.7891 0.421875 11.8474V6.14742C0.421875 5.21409 0.921875 4.34739 1.73021 3.87239L6.68021 1.01406C7.48854 0.547396 8.48854 0.547396 9.30521 1.01406L14.2552 3.87239C15.0635 4.34739 15.5635 5.20576 15.5635 6.14742Z" />
        <path className="fill" fill={color ? color : 'black'} d="M8.00417 9.0005C9.07652 9.0005 9.94583 8.13117 9.94583 7.05882C9.94583 5.98646 9.07652 5.11719 8.00417 5.11719C6.93181 5.11719 6.0625 5.98646 6.0625 7.05882C6.0625 8.13117 6.93181 9.0005 8.00417 9.0005Z" />
        <path className="fill" fill={color ? color : 'black'} d="M10.2336 12.8807C10.9086 12.8807 11.3003 12.1307 10.9253 11.5724C10.3586 10.7307 9.25863 10.1641 8.0003 10.1641C6.74196 10.1641 5.64196 10.7307 5.0753 11.5724C4.7003 12.1307 5.09196 12.8807 5.76696 12.8807H10.2336Z" />
    </svg>
)

export const CloseCircleIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 16" fill="none">
        <path className="fill" fill={color ? color : 'black'} d="M8.00016 1.33333C4.32683 1.33333 1.3335 4.32667 1.3335 8C1.3335 11.6733 4.32683 14.6667 8.00016 14.6667C11.6735 14.6667 14.6668 11.6733 14.6668 8C14.6668 4.32667 11.6735 1.33333 8.00016 1.33333ZM10.2402 9.53333C10.4335 9.72667 10.4335 10.0467 10.2402 10.24C10.1402 10.34 10.0135 10.3867 9.88683 10.3867C9.76016 10.3867 9.6335 10.34 9.5335 10.24L8.00016 8.70667L6.46683 10.24C6.36683 10.34 6.24016 10.3867 6.1135 10.3867C5.98683 10.3867 5.86016 10.34 5.76016 10.24C5.56683 10.0467 5.56683 9.72667 5.76016 9.53333L7.2935 8L5.76016 6.46667C5.56683 6.27333 5.56683 5.95333 5.76016 5.76C5.9535 5.56667 6.2735 5.56667 6.46683 5.76L8.00016 7.29333L9.5335 5.76C9.72683 5.56667 10.0468 5.56667 10.2402 5.76C10.4335 5.95333 10.4335 6.27333 10.2402 6.46667L8.70683 8L10.2402 9.53333Z" />
    </svg>
)

export const FilterIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 20 20" fill="none">
        <path className="fill" fill={color ? color : 'black'} d="M17.1665 3.41636V5.2497C17.1665 5.91636 16.7498 6.7497 16.3332 7.16636L12.7498 10.333C12.2498 10.7497 11.9165 11.583 11.9165 12.2497V15.833C11.9165 16.333 11.5832 16.9997 11.1665 17.2497L9.99982 17.9997C8.91649 18.6664 7.41649 17.9164 7.41649 16.583V12.1664C7.41649 11.583 7.08316 10.833 6.74982 10.4164L6.35816 10.008C6.09982 9.73303 6.04982 9.31636 6.25816 8.99136L10.5248 2.14136C10.6748 1.89969 10.9415 1.74969 11.2332 1.74969H15.4998C16.4165 1.74969 17.1665 2.49969 17.1665 3.41636Z" />
        <path className="fill" fill={color ? color : 'black'} d="M8.62492 3.02469L5.66659 7.76636C5.38325 8.22469 4.73325 8.29136 4.35825 7.89969L3.58325 7.08303C3.16659 6.66636 2.83325 5.91636 2.83325 5.41636V3.49969C2.83325 2.49969 3.58325 1.74969 4.49992 1.74969H7.91658C8.56659 1.74969 8.96659 2.46636 8.62492 3.02469Z" />
    </svg>
)

export const DocumentIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 20 20" fill="none">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M15.083 7.4915H12.6747C10.6997 7.4915 9.09134 5.88317 9.09134 3.90817V1.49984C9.09134 1.0415 8.71634 0.666504 8.25801 0.666504H4.72467C2.15801 0.666504 0.0830078 2.33317 0.0830078 5.30817V12.6915C0.0830078 15.6665 2.15801 17.3332 4.72467 17.3332H11.2747C13.8413 17.3332 15.9163 15.6665 15.9163 12.6915V8.32484C15.9163 7.8665 15.5413 7.4915 15.083 7.4915Z" />
        <path className="fill" fill={color ? color : 'black'} d="M11.1667 0.842068C10.8251 0.500401 10.2334 0.733735 10.2334 1.20873V4.11707C10.2334 5.33373 11.2667 6.34207 12.5251 6.34207C13.3167 6.3504 14.4167 6.3504 15.3584 6.3504C15.8334 6.3504 16.0834 5.79207 15.7501 5.45873C14.5501 4.2504 12.4001 2.0754 11.1667 0.842068Z" />
    </svg>
)

export const PlayIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 74 100" fill="none">
        <path className="fill" fill={color ? color : 'black'} d="M1.37001 99.7299C1.71316 99.9071 2.09376 99.9997 2.48001 99.9999C2.96206 99.9992 3.43266 99.8528 3.83001 99.5799L72.88 51.9999C73.1976 51.7811 73.4573 51.4883 73.6367 51.1469C73.8162 50.8055 73.9099 50.4256 73.9099 50.0399C73.9099 49.6542 73.8162 49.2742 73.6367 48.9328C73.4573 48.5914 73.1976 48.2987 72.88 48.0799L3.83001 0.419858C3.46904 0.172727 3.04717 0.0294091 2.61036 0.00552003C2.17355 -0.0183691 1.73856 0.0780876 1.3528 0.284379C0.967032 0.490671 0.645286 0.798885 0.422617 1.17544C0.199948 1.55199 0.0848991 1.98243 0.0900067 2.41986V97.6199C0.0901063 98.055 0.209481 98.4817 0.435154 98.8537C0.660827 99.2257 0.984161 99.5288 1.37001 99.7299Z" />
    </svg>
)

export const DollarCircleIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M9.125 12.2666H9.66667C10.2083 12.2666 10.6583 11.7833 10.6583 11.1999C10.6583 10.4749 10.4 10.3333 9.975 10.1833L9.13333 9.8916V12.2666H9.125Z" />
        <path className="fill" fill={color ? color : 'black'} d="M8.47489 0.58351C3.87489 0.600177 0.149893 4.34184 0.16656 8.94184C0.183226 13.5418 3.92489 17.2668 8.52489 17.2502C13.1249 17.2335 16.8499 13.4918 16.8332 8.89184C16.8166 4.29184 13.0749 0.575177 8.47489 0.58351ZM10.3832 9.00018C11.0332 9.22518 11.9082 9.70851 11.9082 11.2002C11.9082 12.4835 10.8999 13.5168 9.66656 13.5168H9.12489V14.0002C9.12489 14.3418 8.84156 14.6252 8.49989 14.6252C8.15823 14.6252 7.87489 14.3418 7.87489 14.0002V13.5168H7.57489C6.20823 13.5168 5.09989 12.3668 5.09989 10.9502C5.09989 10.6085 5.38323 10.3252 5.72489 10.3252C6.06656 10.3252 6.34989 10.6085 6.34989 10.9502C6.34989 11.6752 6.89989 12.2668 7.57489 12.2668H7.87489V9.45018L6.61656 9.00018C5.96656 8.77518 5.09156 8.29184 5.09156 6.80018C5.09156 5.51684 6.09989 4.48351 7.33323 4.48351H7.87489V4.00018C7.87489 3.65851 8.15823 3.37518 8.49989 3.37518C8.84156 3.37518 9.12489 3.65851 9.12489 4.00018V4.48351H9.42489C10.7916 4.48351 11.8999 5.63351 11.8999 7.05018C11.8999 7.39184 11.6166 7.67518 11.2749 7.67518C10.9332 7.67518 10.6499 7.39184 10.6499 7.05018C10.6499 6.32518 10.0999 5.73351 9.42489 5.73351H9.12489V8.55018L10.3832 9.00018Z" />
        <path className="fill" fill={color ? color : 'black'} d="M6.3501 6.8084C6.3501 7.5334 6.60843 7.67507 7.03343 7.82507L7.8751 8.11673V5.7334H7.33343C6.79176 5.7334 6.3501 6.21673 6.3501 6.8084Z" />
    </svg>
)

export const ClockIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M8.50008 0.666504C3.90841 0.666504 0.166748 4.40817 0.166748 8.99984C0.166748 13.5915 3.90841 17.3332 8.50008 17.3332C13.0917 17.3332 16.8334 13.5915 16.8334 8.99984C16.8334 4.40817 13.0917 0.666504 8.50008 0.666504ZM12.1251 11.9748C12.0084 12.1748 11.8001 12.2832 11.5834 12.2832C11.4751 12.2832 11.3667 12.2582 11.2667 12.1915L8.68342 10.6498C8.04175 10.2665 7.56675 9.42484 7.56675 8.68317V5.2665C7.56675 4.92484 7.85008 4.6415 8.19175 4.6415C8.53342 4.6415 8.81675 4.92484 8.81675 5.2665V8.68317C8.81675 8.98317 9.06675 9.42484 9.32508 9.57484L11.9084 11.1165C12.2084 11.2915 12.3084 11.6748 12.1251 11.9748Z" />
    </svg>
)

export const LoadIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M39.5511 79.9922C22.9031 79.9922 7.32109 69.5042 1.66409 52.8902L0.492188 49.4488L7.37499 47.1011L8.54689 50.5425C11.3633 58.8198 17.2344 65.4995 25.0739 69.3545C32.9216 73.2139 41.7889 73.7881 50.0619 70.9678C67.1359 65.1553 76.2999 46.5268 70.4879 29.4518C64.6715 12.3738 46.0469 3.21779 28.9719 9.02579L25.5305 10.1977L23.1867 3.31489L26.6281 2.14299C47.5031 -4.96251 70.2691 6.24069 77.3741 27.108C84.4796 47.979 73.2843 70.749 52.4091 77.854C48.1513 79.3032 43.8153 79.9907 39.5541 79.9946L39.5511 79.9922Z" />
    </svg>
)

export const CheckMarkCircleIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M5 0C2.245 0 0 2.245 0 5C0 7.755 2.245 10 5 10C7.755 10 10 7.755 10 5C10 2.245 7.755 0 5 0ZM7.39 3.85L4.555 6.685C4.485 6.755 4.39 6.795 4.29 6.795C4.19 6.795 4.095 6.755 4.025 6.685L2.61 5.27C2.465 5.125 2.465 4.885 2.61 4.74C2.755 4.595 2.995 4.595 3.14 4.74L4.29 5.89L6.86 3.32C7.005 3.175 7.245 3.175 7.39 3.32C7.535 3.465 7.535 3.7 7.39 3.85Z" />
    </svg>
)

export const EyeIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M19.25 6.15018C16.94 2.52017 13.56 0.430176 10 0.430176C8.22 0.430176 6.49 0.950176 4.91 1.92018C3.33 2.90018 1.91 4.33018 0.75 6.15018C-0.25 7.72018 -0.25 10.2702 0.75 11.8402C3.06 15.4802 6.44 17.5602 10 17.5602C11.78 17.5602 13.51 17.0402 15.09 16.0702C16.67 15.0902 18.09 13.6602 19.25 11.8402C20.25 10.2802 20.25 7.72018 19.25 6.15018ZM10 13.0402C7.76 13.0402 5.96 11.2302 5.96 9.00018C5.96 6.77018 7.76 4.96018 10 4.96018C12.24 4.96018 14.04 6.77018 14.04 9.00018C14.04 11.2302 12.24 13.0402 10 13.0402Z" />
        <path className="fill" fill={color ? color : 'black'} d="M9.9999 6.14014C8.4299 6.14014 7.1499 7.42014 7.1499 9.00014C7.1499 10.5701 8.4299 11.8501 9.9999 11.8501C11.5699 11.8501 12.8599 10.5701 12.8599 9.00014C12.8599 7.43014 11.5699 6.14014 9.9999 6.14014Z" />
    </svg>
)

export const EyeClosedIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M19.2699 7.17981C18.9799 6.71981 18.6699 6.28981 18.3499 5.88981C17.9799 5.41981 17.2799 5.37981 16.8599 5.79981L13.8599 8.79981C14.0799 9.45981 14.1199 10.2198 13.9199 11.0098C13.5699 12.4198 12.4299 13.5598 11.0199 13.9098C10.2299 14.1098 9.46995 14.0698 8.80995 13.8498C8.80995 13.8498 7.37995 15.2798 6.34995 16.3098C5.84995 16.8098 6.00995 17.6898 6.67995 17.9498C7.74995 18.3598 8.85995 18.5698 9.99995 18.5698C11.7799 18.5698 13.5099 18.0498 15.0899 17.0798C16.6999 16.0798 18.1499 14.6098 19.3199 12.7398C20.2699 11.2298 20.2199 8.68981 19.2699 7.17981Z" />
        <path className="fill" fill={color ? color : 'black'} d="M12.0199 7.98014L7.97989 12.0201C7.46989 11.5001 7.13989 10.7801 7.13989 10.0001C7.13989 8.43014 8.41989 7.14014 9.99989 7.14014C10.7799 7.14014 11.4999 7.47014 12.0199 7.98014Z" />
        <path className="fill" fill={color ? color : 'black'} d="M16.25 3.75018L12.86 7.14018C12.13 6.40018 11.12 5.96018 10 5.96018C7.76 5.96018 5.96 7.77018 5.96 10.0002C5.96 11.1202 6.41 12.1302 7.14 12.8602L3.76 16.2502H3.75C2.64 15.3502 1.62 14.2002 0.75 12.8402C-0.25 11.2702 -0.25 8.72018 0.75 7.15018C1.91 5.33018 3.33 3.90018 4.91 2.92018C6.49 1.96018 8.22 1.43018 10 1.43018C12.23 1.43018 14.39 2.25018 16.25 3.75018Z" />
        <path className="fill" fill={color ? color : 'black'} d="M12.8601 10.0001C12.8601 11.5701 11.5801 12.8601 10.0001 12.8601C9.94008 12.8601 9.89008 12.8601 9.83008 12.8401L12.8401 9.83008C12.8601 9.89008 12.8601 9.94008 12.8601 10.0001Z" />
        <path className="fill" fill={color ? color : 'black'} d="M19.7699 0.229883C19.4699 -0.0701172 18.9799 -0.0701172 18.6799 0.229883L0.229883 18.6899C-0.0701172 18.9899 -0.0701172 19.4799 0.229883 19.7799C0.379883 19.9199 0.569883 19.9999 0.769883 19.9999C0.969883 19.9999 1.15988 19.9199 1.30988 19.7699L19.7699 1.30988C20.0799 1.00988 20.0799 0.529883 19.7699 0.229883Z" />
    </svg>
)

export const TruckIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.333 10.3335C14.5197 10.3335 14.6663 10.4802 14.6663 10.6668V11.3335C14.6663 12.4402 13.773 13.3335 12.6663 13.3335C12.6663 12.2335 11.7663 11.3335 10.6663 11.3335C9.56634 11.3335 8.66634 12.2335 8.66634 13.3335H7.33301C7.33301 12.2335 6.43301 11.3335 5.33301 11.3335C4.23301 11.3335 3.33301 12.2335 3.33301 13.3335C2.22634 13.3335 1.33301 12.4402 1.33301 11.3335V10.0002C1.33301 9.6335 1.63301 9.3335 1.99967 9.3335H8.33301C9.25301 9.3335 9.99967 8.58683 9.99967 7.66683V4.00016C9.99967 3.6335 10.2997 3.3335 10.6663 3.3335H11.2263C11.7063 3.3335 12.1463 3.5935 12.3863 4.00683L12.813 4.7535C12.873 4.86016 12.793 5.00016 12.6663 5.00016C11.7463 5.00016 10.9997 5.74683 10.9997 6.66683V8.66683C10.9997 9.58683 11.7463 10.3335 12.6663 10.3335H14.333Z" fill={color} />
        <path d="M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z" fill={color} />
        <path d="M10.6663 14.6667C11.4027 14.6667 11.9997 14.0697 11.9997 13.3333C11.9997 12.597 11.4027 12 10.6663 12C9.92996 12 9.33301 12.597 9.33301 13.3333C9.33301 14.0697 9.92996 14.6667 10.6663 14.6667Z" fill={color} />
        <path d="M14.6667 8.35333V9.33333H12.6667C12.3 9.33333 12 9.03333 12 8.66667V6.66667C12 6.3 12.3 6 12.6667 6H13.5267L14.4933 7.69333C14.6067 7.89333 14.6667 8.12 14.6667 8.35333Z" fill={color} />
        <path d="M8.72065 1.3335H3.79398C2.60065 1.3335 1.60065 2.18683 1.38065 3.32016H4.29398C4.54732 3.32016 4.74732 3.52683 4.74732 3.78016C4.74732 4.0335 4.54732 4.2335 4.29398 4.2335H1.33398V5.1535H3.06732C3.32065 5.1535 3.52732 5.36016 3.52732 5.6135C3.52732 5.86683 3.32065 6.06683 3.06732 6.06683H1.33398V6.98683H1.84732C2.10065 6.98683 2.30732 7.1935 2.30732 7.44683C2.30732 7.70016 2.10065 7.90016 1.84732 7.90016H1.33398V8.0535C1.33398 8.42016 1.63398 8.72016 2.00065 8.72016H8.10065C8.78065 8.72016 9.33398 8.16683 9.33398 7.48683V1.94683C9.33398 1.60683 9.06065 1.3335 8.72065 1.3335Z" fill={color} />
        <path d="M1.38033 3.32031H1.28033H0.626992C0.373659 3.32031 0.166992 3.52698 0.166992 3.78031C0.166992 4.03365 0.373659 4.23365 0.626992 4.23365H1.23366H1.33366V3.79365C1.33366 3.63365 1.35366 3.47365 1.38033 3.32031Z" fill={color} />
        <path d="M1.23366 5.15381H0.626992C0.373659 5.15381 0.166992 5.36048 0.166992 5.61381C0.166992 5.86714 0.373659 6.06714 0.626992 6.06714H1.23366H1.33366V5.15381H1.23366Z" fill={color} />
        <path d="M1.23366 6.9873H0.626992C0.373659 6.9873 0.166992 7.19397 0.166992 7.44731C0.166992 7.70064 0.373659 7.90064 0.626992 7.90064H1.23366H1.33366V6.9873H1.23366Z" fill={color} />
    </svg>

)

export const PinCheckIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_1789_73733)">
            <path d="M8.0013 1.33398C5.2013 1.33398 2.66797 3.48065 2.66797 6.80065C2.66797 8.92065 4.3013 11.414 7.5613 14.2873C7.81464 14.5073 8.19463 14.5073 8.44797 14.2873C11.7013 11.414 13.3346 8.92065 13.3346 6.80065C13.3346 3.48065 10.8013 1.33398 8.0013 1.33398ZM6.8213 8.44065L5.8813 7.50065C5.6213 7.24065 5.6213 6.82065 5.8813 6.56065C6.1413 6.30065 6.5613 6.30065 6.8213 6.56065L7.29463 7.03398L9.1813 5.14732C9.4413 4.88732 9.8613 4.88732 10.1213 5.14732C10.3813 5.40732 10.3813 5.82732 10.1213 6.08732L7.7613 8.44732C7.50797 8.70065 7.0813 8.70065 6.8213 8.44065Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_1789_73733">
                <rect width={16} height={16} fill={'white'} />
            </clipPath>
        </defs>
    </svg>
)

export const TimeRunIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_1789_195713)">
            <path d="M11.1973 3.20975C11.1973 3.47375 11.3733 3.70575 11.6293 3.77775C13.4533 4.31375 14.7973 6.00175 14.7973 8.00175C14.7973 10.0018 13.4533 11.6898 11.6293 12.2258C11.3733 12.2978 11.1973 12.5298 11.1973 12.7938C11.1973 13.1938 11.5813 13.4818 11.9733 13.3698C14.2933 12.6898 15.9973 10.5458 15.9973 8.00175C15.9973 5.45775 14.2933 3.31375 11.9733 2.63375C11.5813 2.52175 11.1973 2.80975 11.1973 3.20975Z" fill={color} />
            <path d="M5.59805 2.40137C2.50205 2.40137 -0.00195312 4.90537 -0.00195312 8.00137C-0.00195312 11.0974 2.50205 13.6014 5.59805 13.6014C8.69405 13.6014 11.198 11.0974 11.198 8.00137C11.198 4.90537 8.69405 2.40137 5.59805 2.40137ZM6.83805 9.84137L5.23005 8.23337C5.07805 8.08137 4.99805 7.88137 4.99805 7.66537V5.40137C4.99805 5.07337 5.27005 4.80137 5.59805 4.80137C5.92605 4.80137 6.19805 5.07337 6.19805 5.40137V7.50537L7.68605 8.99337C7.91805 9.22537 7.91805 9.60937 7.68605 9.84137C7.45405 10.0734 7.07005 10.0734 6.83805 9.84137Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_1789_195713">
                <rect width={16} height={16} fill={color} />
            </clipPath>
        </defs>
    </svg>
)

export const FlagIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_1789_72287)">
            <path d="M9.59674 3.99837L9.43674 3.19837C9.37674 2.89171 9.10341 2.66504 8.78341 2.66504H3.99674C3.63008 2.66504 3.33008 2.96504 3.33008 3.33171V13.3317C3.33008 13.6984 3.63008 13.9984 3.99674 13.9984C4.36341 13.9984 4.66341 13.6984 4.66341 13.3317V9.33171H8.39675L8.55675 10.1317C8.61674 10.445 8.89008 10.665 9.21008 10.665H12.6634C13.0301 10.665 13.3301 10.365 13.3301 9.99837V4.66504C13.3301 4.29837 13.0301 3.99837 12.6634 3.99837H9.59674Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_1789_72287">
                <rect width={16} height={16} fill={color} />
            </clipPath>
        </defs>
    </svg>
)

export const ExclamationIcon = ({ color, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_1789_157727)">
            <path d="M8.0013 14.0007C8.73768 14.0007 9.33464 13.4037 9.33464 12.6673C9.33464 11.9309 8.73768 11.334 8.0013 11.334C7.26492 11.334 6.66797 11.9309 6.66797 12.6673C6.66797 13.4037 7.26492 14.0007 8.0013 14.0007Z" fill={color} />
            <path d="M8.0013 2C7.26797 2 6.66797 2.6 6.66797 3.33333V8.66667C6.66797 9.4 7.26797 10 8.0013 10C8.73464 10 9.33464 9.4 9.33464 8.66667V3.33333C9.33464 2.6 8.73464 2 8.0013 2Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_1789_157727">
                <rect width={16} height={16} fill={color} />
            </clipPath>
        </defs>
    </svg>
)

export const LocationIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M8.99984 17.3332C13.6022 17.3332 17.3332 13.6022 17.3332 8.99984C17.3332 4.39746 13.6022 0.666504 8.99984 0.666504C4.39746 0.666504 0.666504 4.39746 0.666504 8.99984C0.666504 13.6022 4.39746 17.3332 8.99984 17.3332Z" />
        <path className="fill" fill={color ? color : 'black'} d="M8.99988 2.5415C7.59154 2.5415 6.24988 2.99151 5.12488 3.83317C4.84988 4.04151 4.79155 4.43318 4.99989 4.70818C5.20822 4.98318 5.59988 5.0415 5.87488 4.83317C6.78321 4.14984 7.85821 3.7915 8.99988 3.7915C11.8749 3.7915 14.2082 6.12484 14.2082 8.99984C14.2082 11.8748 11.8749 14.2082 8.99988 14.2082C6.12488 14.2082 3.79154 11.8748 3.79154 8.99984C3.79154 8.29984 3.9332 7.60817 4.19987 6.9665L5.14988 7.50818C4.96655 7.98318 4.86655 8.48317 4.86655 8.99984C4.86655 11.2748 6.71654 13.1248 8.99154 13.1248C11.2665 13.1248 13.1165 11.2748 13.1165 8.99984C13.1165 6.72484 11.2665 4.87484 8.99154 4.87484C8.37487 4.87484 7.78322 5.00817 7.23322 5.26651C6.92488 5.41651 6.7832 5.78317 6.9332 6.09984C7.0832 6.40817 7.44987 6.54151 7.76654 6.39984C8.14987 6.21651 8.5582 6.12484 8.99154 6.12484C10.5749 6.12484 11.8665 7.4165 11.8665 8.99984C11.8665 10.5832 10.5749 11.8748 8.99154 11.8748C7.4082 11.8748 6.11655 10.5832 6.11655 8.99984C6.11655 8.70817 6.17487 8.42483 6.2582 8.14983L8.68321 9.5415C8.98321 9.7165 9.36656 9.60817 9.53322 9.30817C9.70822 9.00817 9.59987 8.62484 9.29987 8.45818L4.24155 5.54984C3.94155 5.37484 3.5582 5.48317 3.39154 5.78317C2.8332 6.75817 2.5332 7.8665 2.5332 8.99984C2.5332 12.5582 5.4332 15.4582 8.99154 15.4582C12.5499 15.4582 15.4499 12.5582 15.4499 8.99984C15.4499 5.4415 12.5582 2.5415 8.99988 2.5415Z" />
    </svg>
)

export const UserCircleIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M8.99984 17.3415C13.6022 17.3415 17.3332 13.6105 17.3332 9.00814C17.3332 4.40577 13.6022 0.674805 8.99984 0.674805C4.39746 0.674805 0.666504 4.40577 0.666504 9.00814C0.666504 13.6105 4.39746 17.3415 8.99984 17.3415Z" />
        <path className="fill" fill={color ? color : 'black'} d="M9 4.7832C7.275 4.7832 5.875 6.1832 5.875 7.9082C5.875 9.59987 7.2 10.9749 8.95833 11.0249C8.98333 11.0249 9.01667 11.0249 9.03333 11.0249C9.05 11.0249 9.075 11.0249 9.09167 11.0249C9.1 11.0249 9.10833 11.0249 9.10833 11.0249C10.7917 10.9665 12.1167 9.59987 12.125 7.9082C12.125 6.1832 10.725 4.7832 9 4.7832Z" />
        <path className="fill" fill={color ? color : 'black'} d="M14.6501 15.1335C13.1668 16.5002 11.1834 17.3419 9.0001 17.3419C6.81676 17.3419 4.83343 16.5002 3.3501 15.1335C3.5501 14.3752 4.09176 13.6835 4.88343 13.1502C7.15843 11.6335 10.8584 11.6335 13.1168 13.1502C13.9168 13.6835 14.4501 14.3752 14.6501 15.1335Z" />
    </svg>
)

export const CubeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M10 11.0834C9.8917 11.0834 9.78336 11.0584 9.68336 11L2.32503 6.74171C2.03336 6.56671 1.92503 6.18338 2.10003 5.88338C2.27503 5.58338 2.65836 5.48338 2.95836 5.65838L10 9.73338L17 5.68338C17.3 5.50838 17.6834 5.61671 17.8584 5.90838C18.0334 6.20838 17.925 6.59171 17.6334 6.76671L10.325 11C10.2167 11.05 10.1084 11.0834 10 11.0834Z" fill="#757575" />
        <path className="fill" d="M10 18.6335C9.65833 18.6335 9.375 18.3502 9.375 18.0085V10.4502C9.375 10.1085 9.65833 9.8252 10 9.8252C10.3417 9.8252 10.625 10.1085 10.625 10.4502V18.0085C10.625 18.3502 10.3417 18.6335 10 18.6335Z" fill="#757575" />
        <path className="fill" d="M10 18.9582C9.2667 18.9582 8.5417 18.7998 7.9667 18.4832L3.5167 16.0082C2.30837 15.3415 1.3667 13.7332 1.3667 12.3498V7.6415C1.3667 6.25817 2.30837 4.65817 3.5167 3.98317L7.9667 1.5165C9.10837 0.883171 10.8917 0.883171 12.0334 1.5165L16.4834 3.9915C17.6917 4.65817 18.6334 6.2665 18.6334 7.64984V12.3582C18.6334 13.7415 17.6917 15.3415 16.4834 16.0165L12.0334 18.4832C11.4584 18.7998 10.7334 18.9582 10 18.9582ZM10 2.2915C9.47503 2.2915 8.95837 2.39984 8.57503 2.60817L4.12503 5.08317C3.32503 5.52484 2.6167 6.72484 2.6167 7.6415V12.3498C2.6167 13.2665 3.32503 14.4665 4.12503 14.9165L8.57503 17.3915C9.33337 17.8165 10.6667 17.8165 11.425 17.3915L15.875 14.9165C16.675 14.4665 17.3834 13.2748 17.3834 12.3498V7.6415C17.3834 6.72484 16.675 5.52484 15.875 5.07484L11.425 2.59984C11.0417 2.39984 10.525 2.2915 10 2.2915Z" fill="#757575" />
    </svg>
)

export const AdminIcon = ({ width = 20, height = 20, color = "#757575" }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M10 18.9669C9.09171 18.9669 8.19171 18.7002 7.48337 18.1752L3.90004 15.5002C2.95004 14.7919 2.20837 13.3085 2.20837 12.1335V5.93353C2.20837 4.6502 3.15004 3.28353 4.35837 2.83353L8.51671 1.2752C9.34171 0.966862 10.6417 0.966862 11.4667 1.2752L15.625 2.83353C16.8334 3.28353 17.775 4.6502 17.775 5.93353V12.1252C17.775 13.3085 17.0334 14.7835 16.0834 15.4919L12.5 18.1669C11.8084 18.7002 10.9084 18.9669 10 18.9669ZM8.95837 2.4502L4.80004 4.00853C4.09171 4.2752 3.46671 5.1752 3.46671 5.94186V12.1335C3.46671 12.9252 4.02504 14.0335 4.65004 14.5002L8.23337 17.1752C9.19171 17.8919 10.8084 17.8919 11.775 17.1752L15.3584 14.5002C15.9917 14.0252 16.5417 12.9252 16.5417 12.1335V5.93353C16.5417 5.1752 15.9167 4.2752 15.2084 4.0002L11.05 2.44186C10.4834 2.24186 9.51671 2.24186 8.95837 2.4502Z" fill={color} />
        <path className="fill" d="M8.88328 11.8581C8.72495 11.8581 8.56662 11.7998 8.44162 11.6748L7.09995 10.3331C6.85828 10.0914 6.85828 9.69144 7.09995 9.44977C7.34162 9.20811 7.74162 9.20811 7.98328 9.44977L8.88328 10.3498L12.025 7.20811C12.2666 6.96644 12.6666 6.96644 12.9083 7.20811C13.15 7.44977 13.15 7.84977 12.9083 8.09144L9.32495 11.6748C9.19995 11.7998 9.04162 11.8581 8.88328 11.8581Z" fill={color} />
    </svg>
)

export const TruckShipIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M18.3332 10.6665V13.1665C18.3332 14.5498 17.2165 15.6665 15.8332 15.6665H14.9998C14.9998 14.7498 14.2498 13.9998 13.3332 13.9998C12.4165 13.9998 11.6665 14.7498 11.6665 15.6665H8.33317C8.33317 14.7498 7.58317 13.9998 6.6665 13.9998C5.74984 13.9998 4.99984 14.7498 4.99984 15.6665H4.1665C2.78317 15.6665 1.6665 14.5498 1.6665 13.1665V10.6665H10.8332C11.7498 10.6665 12.4998 9.9165 12.4998 8.99984V3.1665H14.0332C14.6332 3.1665 15.1832 3.49151 15.4832 4.00818L16.9082 6.49984H15.8332C15.3748 6.49984 14.9998 6.87484 14.9998 7.33317V9.83317C14.9998 10.2915 15.3748 10.6665 15.8332 10.6665H18.3332Z" />
        <path className="fill" fill={color ? color : 'black'} d="M6.66667 17.3333C7.58714 17.3333 8.33333 16.5871 8.33333 15.6667C8.33333 14.7462 7.58714 14 6.66667 14C5.74619 14 5 14.7462 5 15.6667C5 16.5871 5.74619 17.3333 6.66667 17.3333Z" />
        <path className="fill" fill={color ? color : 'black'} d="M13.3332 17.3333C14.2536 17.3333 14.9998 16.5871 14.9998 15.6667C14.9998 14.7462 14.2536 14 13.3332 14C12.4127 14 11.6665 14.7462 11.6665 15.6667C11.6665 16.5871 12.4127 17.3333 13.3332 17.3333Z" />
        <path className="fill" fill={color ? color : 'black'} d="M18.3333 9.44169V10.6667H15.8333C15.375 10.6667 15 10.2917 15 9.83333V7.33333C15 6.875 15.375 6.5 15.8333 6.5H16.9083L18.1167 8.61665C18.2583 8.86665 18.3333 9.15002 18.3333 9.44169Z" />
        <path className="fill" fill={color ? color : 'black'} d="M11.6667 0.666504H5.00008C3.37508 0.666504 2.0251 1.83317 1.73343 3.37484H5.83341C6.17508 3.37484 6.45841 3.65817 6.45841 3.99984C6.45841 4.3415 6.17508 4.62484 5.83341 4.62484H1.66675V5.87484H4.16675C4.50841 5.87484 4.79175 6.15817 4.79175 6.49984C4.79175 6.8415 4.50841 7.12484 4.16675 7.12484H1.66675V8.37484H2.50008C2.84175 8.37484 3.12508 8.65817 3.12508 8.99984C3.12508 9.3415 2.84175 9.62484 2.50008 9.62484H1.66675V10.6665H10.8334C11.7501 10.6665 12.5001 9.9165 12.5001 8.99984V1.49984C12.5001 1.0415 12.1251 0.666504 11.6667 0.666504Z" />
        <path className="fill" fill={color ? color : 'black'} d="M1.73351 3.375H0.833496C0.491829 3.375 0.208496 3.65833 0.208496 4C0.208496 4.34167 0.491829 4.625 0.833496 4.625H1.66683V4C1.66683 3.78333 1.69184 3.575 1.73351 3.375Z" />
        <path className="fill" fill={color ? color : 'black'} d="M0.833496 5.875C0.491829 5.875 0.208496 6.15833 0.208496 6.5C0.208496 6.84167 0.491829 7.125 0.833496 7.125H1.66683V5.875H0.833496Z" />
        <path className="fill" fill={color ? color : 'black'} d="M0.833496 8.375C0.491829 8.375 0.208496 8.65833 0.208496 9C0.208496 9.34167 0.491829 9.625 0.833496 9.625H1.66683V8.375H0.833496Z" />
    </svg>
)


export const CalendarAddIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M11.9582 1.96675V0.666748C11.9582 0.325081 11.6749 0.041748 11.3332 0.041748C10.9915 0.041748 10.7082 0.325081 10.7082 0.666748V1.91675H5.29154V0.666748C5.29154 0.325081 5.0082 0.041748 4.66654 0.041748C4.32487 0.041748 4.04154 0.325081 4.04154 0.666748V1.96675C1.79154 2.17508 0.699865 3.51675 0.533199 5.50841C0.516532 5.75008 0.716532 5.95008 0.949865 5.95008H15.0499C15.2915 5.95008 15.4915 5.74175 15.4665 5.50841C15.2999 3.51675 14.2082 2.17508 11.9582 1.96675Z" />
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M15.5 8.03333V9.48332C15.5 9.99165 15.05 10.3833 14.55 10.3C14.3167 10.2667 14.075 10.2417 13.8333 10.2417C11.3083 10.2417 9.25 12.3 9.25 14.825C9.25 15.2083 9.4 15.7417 9.55833 16.225C9.74166 16.7667 9.34166 17.325 8.76666 17.325H4.66667C1.75 17.325 0.5 15.6583 0.5 13.1583V8.02498C0.5 7.56665 0.875 7.19165 1.33333 7.19165H14.6667C15.125 7.19998 15.5 7.57499 15.5 8.03333Z" />
        <path className="fill" fill={color ? color : 'black'} d="M5.08333 11.4999C4.86667 11.4999 4.65 11.4083 4.49167 11.2583C4.34167 11.0999 4.25 10.8833 4.25 10.6666C4.25 10.4499 4.34167 10.2333 4.49167 10.0749C4.68333 9.88327 4.975 9.79159 5.25 9.84992C5.3 9.85825 5.35 9.87492 5.4 9.89992C5.45 9.91658 5.5 9.94161 5.55 9.97494C5.59166 10.0083 5.63333 10.0416 5.675 10.0749C5.825 10.2333 5.91667 10.4499 5.91667 10.6666C5.91667 10.8833 5.825 11.0999 5.675 11.2583C5.63333 11.2916 5.59166 11.3249 5.55 11.3582C5.5 11.3916 5.45 11.4166 5.4 11.4333C5.35 11.4583 5.3 11.4749 5.25 11.4833C5.19166 11.4916 5.13333 11.4999 5.08333 11.4999Z" />
        <path className="fill" fill={color ? color : 'black'} d="M7.99984 11.5C7.78317 11.5 7.5665 11.4083 7.40817 11.2583C7.25817 11.1 7.1665 10.8833 7.1665 10.6667C7.1665 10.45 7.25817 10.2333 7.40817 10.075C7.72484 9.76667 8.28317 9.76667 8.5915 10.075C8.7415 10.2333 8.83317 10.45 8.83317 10.6667C8.83317 10.8833 8.7415 11.1 8.5915 11.2583C8.43317 11.4083 8.2165 11.5 7.99984 11.5Z" />
        <path className="fill" fill={color ? color : 'black'} d="M5.08333 14.4167C4.86667 14.4167 4.65 14.325 4.49167 14.175C4.34167 14.0167 4.25 13.8001 4.25 13.5834C4.25 13.3667 4.34167 13.1501 4.49167 12.9917C4.575 12.9167 4.65834 12.8584 4.76667 12.8167C5.075 12.6834 5.44167 12.7584 5.675 12.9917C5.825 13.1501 5.91667 13.3667 5.91667 13.5834C5.91667 13.8001 5.825 14.0167 5.675 14.175C5.51667 14.325 5.3 14.4167 5.08333 14.4167Z" />
        <path className="fill" fill={color ? color : 'black'} d="M16.1917 12.475C14.8917 11.175 12.775 11.175 11.475 12.475C10.175 13.775 10.175 15.8916 11.475 17.1916C12.775 18.4916 14.8917 18.4916 16.1917 17.1916C17.4917 15.8916 17.4917 13.775 16.1917 12.475ZM15.5583 15.3C15.45 15.4167 15.2917 15.4833 15.1167 15.4833H14.5V16.1333C14.5 16.3083 14.4333 16.4583 14.3167 16.575C14.2 16.6917 14.05 16.7583 13.875 16.7583C13.5333 16.7583 13.25 16.475 13.25 16.1333V15.4833H12.625C12.2833 15.4833 12 15.2083 12 14.8583C12 14.5167 12.2833 14.2333 12.625 14.2333H13.25V13.6417C13.25 13.3 13.525 13.0167 13.875 13.0167C14.2167 13.0167 14.5 13.3 14.5 13.6417V14.2333H15.1167C15.4667 14.2333 15.7417 14.5167 15.7417 14.8583C15.7417 15.0333 15.675 15.1917 15.5583 15.3Z" />
    </svg>
)

export const CalendarIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M4.14183 17.3334H11.8502C14.0918 17.3334 15.9085 15.5167 15.9085 13.2751V5.97508C15.9085 3.73341 14.0918 1.91675 11.8502 1.91675H4.14183C1.90016 1.91675 0.0834961 3.73341 0.0834961 5.97508V13.2667C0.0834961 15.5167 1.90016 17.3334 4.14183 17.3334Z" />
        <path className="fill" fill={color ? color : 'black'} d="M4.9082 4.24175C4.5582 4.24175 4.2832 3.95842 4.2832 3.61675V1.29175C4.2832 0.950081 4.5582 0.666748 4.9082 0.666748C5.2582 0.666748 5.5332 0.950081 5.5332 1.29175V3.60842C5.5332 3.95842 5.2582 4.24175 4.9082 4.24175Z" />
        <path className="fill" fill={color ? color : 'black'} d="M11.0918 4.24175C10.7418 4.24175 10.4668 3.95842 10.4668 3.61675V1.29175C10.4668 0.941748 10.7501 0.666748 11.0918 0.666748C11.4418 0.666748 11.7168 0.950081 11.7168 1.29175V3.60842C11.7168 3.95842 11.4418 4.24175 11.0918 4.24175Z" />
        <path className="fill" fill={color ? color : 'black'} d="M10.3166 10.425H4.1333C3.7833 10.425 3.5083 10.1417 3.5083 9.80005C3.5083 9.45838 3.79163 9.17505 4.1333 9.17505H10.3166C10.6666 9.17505 10.9416 9.45838 10.9416 9.80005C10.9416 10.1417 10.6666 10.425 10.3166 10.425Z" />
        <path className="fill" fill={color ? color : 'black'} d="M7.99997 13.5166H4.1333C3.7833 13.5166 3.5083 13.2333 3.5083 12.8916C3.5083 12.5416 3.79163 12.2666 4.1333 12.2666H7.99997C8.34997 12.2666 8.62497 12.5499 8.62497 12.8916C8.62497 13.2333 8.34997 13.5166 7.99997 13.5166Z" />
    </svg>
)

export const AirconIcon = ({ color, ...rest }: Props) => (
    <svg {...rest} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill" fill={color ? color : 'black'} d="M5.7584 8.87488C3.4084 9.04155 3.41673 12.4582 5.7584 12.6249H11.3167C11.9917 12.6332 12.6417 12.3749 13.1417 11.9249C14.7917 10.4832 13.9084 7.59155 11.7418 7.31655C10.9668 2.61655 4.17509 4.39988 5.78342 8.87488" />
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M6.49984 17.9583C2.9415 17.9583 0.0415039 15.0583 0.0415039 11.5C0.0415039 11.1583 0.324837 10.875 0.666504 10.875C1.00817 10.875 1.2915 11.1583 1.2915 11.5C1.2915 13.9667 3.00816 16.0334 5.31649 16.575L5.0915 16.2C4.9165 15.9 5.00818 15.5167 5.30818 15.3417C5.59984 15.1667 5.99151 15.2583 6.16651 15.5583L7.0415 17.0167C7.15817 17.2083 7.15817 17.45 7.04984 17.6417C6.93317 17.8333 6.72484 17.9583 6.49984 17.9583Z" />
        <path className="fill" fill={color ? color : 'black'} opacity="0.4" d="M17.3332 7.12508C16.9915 7.12508 16.7082 6.84175 16.7082 6.50008C16.7082 4.03341 14.9915 1.96673 12.6832 1.42506L12.9082 1.80007C13.0832 2.10007 12.9915 2.48343 12.6915 2.65843C12.3999 2.83343 12.0082 2.74175 11.8332 2.44175L10.9582 0.983419C10.8415 0.791752 10.8415 0.550085 10.9499 0.358419C11.0582 0.158419 11.2665 0.041748 11.4915 0.041748C15.0499 0.041748 17.9499 2.94175 17.9499 6.50008C17.9582 6.84175 17.6749 7.12508 17.3332 7.12508Z" />
    </svg>
)


export const PlusScheduleIcon = ({ width = 12, height = 12, color = '#404040' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
        <path d="M6 11.375C3.035 11.375 0.625 8.965 0.625 6C0.625 3.035 3.035 0.625 6 0.625C8.965 0.625 11.375 3.035 11.375 6C11.375 8.965 8.965 11.375 6 11.375ZM6 1.375C3.45 1.375 1.375 3.45 1.375 6C1.375 8.55 3.45 10.625 6 10.625C8.55 10.625 10.625 8.55 10.625 6C10.625 3.45 8.55 1.375 6 1.375Z" fill={color} />
        <path d="M8 6.375H4C3.795 6.375 3.625 6.205 3.625 6C3.625 5.795 3.795 5.625 4 5.625H8C8.205 5.625 8.375 5.795 8.375 6C8.375 6.205 8.205 6.375 8 6.375Z" fill={color} />
        <path d="M6 8.375C5.795 8.375 5.625 8.205 5.625 8V4C5.625 3.795 5.795 3.625 6 3.625C6.205 3.625 6.375 3.795 6.375 4V8C6.375 8.205 6.205 8.375 6 8.375Z" fill={color} />
    </svg>
)

export const PromoIcon = ({ }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="36" height="34" viewBox="0 0 36 34" fill="none">
        <g filter="url(#filter0_d_13289_320988)">
            <path d="M27.3 14.8399C27.69 14.8399 28 14.5299 28 14.1399V13.2099C28 9.10986 26.75 7.85986 22.65 7.85986H13.35C9.25 7.85986 8 9.10986 8 13.2099V13.6799C8 14.0699 8.31 14.3799 8.7 14.3799C9.6 14.3799 10.33 15.1099 10.33 16.0099C10.33 16.9099 9.6 17.6299 8.7 17.6299C8.31 17.6299 8 17.9399 8 18.3299V18.7999C8 22.8999 9.25 24.1499 13.35 24.1499H22.65C26.75 24.1499 28 22.8999 28 18.7999C28 18.4099 27.69 18.0999 27.3 18.0999C26.4 18.0999 25.67 17.3699 25.67 16.4699C25.67 15.5699 26.4 14.8399 27.3 14.8399ZM15 12.8799C15.55 12.8799 16 13.3299 16 13.8799C16 14.4299 15.56 14.8799 15 14.8799C14.45 14.8799 14 14.4299 14 13.8799C14 13.3299 14.44 12.8799 15 12.8799ZM21 19.8799C20.44 19.8799 19.99 19.4299 19.99 18.8799C19.99 18.3299 20.44 17.8799 20.99 17.8799C21.54 17.8799 21.99 18.3299 21.99 18.8799C21.99 19.4299 21.56 19.8799 21 19.8799ZM21.9 13.4799L15.17 20.2099C15.02 20.3599 14.83 20.4299 14.64 20.4299C14.45 20.4299 14.26 20.3599 14.11 20.2099C13.82 19.9199 13.82 19.4399 14.11 19.1499L20.84 12.4199C21.13 12.1299 21.61 12.1299 21.9 12.4199C22.19 12.7099 22.19 13.1899 21.9 13.4799Z" fill="#F9B500" />
            <path d="M27.5 14.1399C27.5 14.2537 27.4139 14.3399 27.3 14.3399C26.1239 14.3399 25.17 15.2937 25.17 16.4699C25.17 17.646 26.1239 18.5999 27.3 18.5999C27.4139 18.5999 27.5 18.686 27.5 18.7999C27.5 20.8323 27.182 21.9858 26.5089 22.6588C25.8359 23.3318 24.6824 23.6499 22.65 23.6499H13.35C11.3176 23.6499 10.1641 23.3318 9.49105 22.6588C8.81802 21.9858 8.5 20.8323 8.5 18.7999V18.3299C8.5 18.216 8.58614 18.1299 8.7 18.1299C9.87212 18.1299 10.83 17.19 10.83 16.0099C10.83 14.8337 9.87614 13.8799 8.7 13.8799C8.58614 13.8799 8.5 13.7937 8.5 13.6799V13.2099C8.5 11.1775 8.81802 10.024 9.49105 9.35092C10.1641 8.67788 11.3176 8.35986 13.35 8.35986H22.65C24.6824 8.35986 25.8359 8.67788 26.5089 9.35092C27.182 10.024 27.5 11.1775 27.5 13.2099V14.1399ZM16.5 13.8799C16.5 13.0537 15.8261 12.3799 15 12.3799C14.1606 12.3799 13.5 13.057 13.5 13.8799C13.5 14.706 14.1739 15.3799 15 15.3799C15.8394 15.3799 16.5 14.7027 16.5 13.8799ZM19.49 18.8799C19.49 19.7092 20.1671 20.3799 21 20.3799C21.8459 20.3799 22.49 19.6961 22.49 18.8799C22.49 18.0537 21.8161 17.3799 20.99 17.3799C20.1639 17.3799 19.49 18.0537 19.49 18.8799ZM15.5236 20.5634L22.2536 13.8334C22.7388 13.3482 22.7388 12.5516 22.2536 12.0663C21.7683 11.581 20.9717 11.581 20.4864 12.0663L13.7564 18.7963C13.2712 19.2816 13.2712 20.0782 13.7564 20.5634C14.0052 20.8121 14.3245 20.9299 14.64 20.9299C14.9555 20.9299 15.2748 20.8121 15.5236 20.5634Z" stroke="#DEA100" />
        </g>
        <defs>
            <filter id="filter0_d_13289_320988" x="0" y="0.859863" width="36" height="32.29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="4" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13289_320988" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13289_320988" result="shape" />
            </filter>
        </defs>
    </svg>
}

export const PromoAppliedIcon = ({ }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <g filter="url(#filter0_d_13307_168636)">
            <path d="M18 6.99951C12.49 6.99951 8 11.4895 8 16.9995C8 22.5095 12.49 26.9995 18 26.9995C23.51 26.9995 28 22.5095 28 16.9995C28 11.4895 23.51 6.99951 18 6.99951ZM22.78 14.6995L17.11 20.3695C16.97 20.5095 16.78 20.5895 16.58 20.5895C16.38 20.5895 16.19 20.5095 16.05 20.3695L13.22 17.5395C12.93 17.2495 12.93 16.7695 13.22 16.4795C13.51 16.1895 13.99 16.1895 14.28 16.4795L16.58 18.7795L21.72 13.6395C22.01 13.3495 22.49 13.3495 22.78 13.6395C23.07 13.9295 23.07 14.3995 22.78 14.6995Z" fill="#00A19A" />
            <path d="M23.1336 15.0531L23.1336 15.0531L23.1395 15.047C23.6136 14.5566 23.6221 13.7745 23.1336 13.286C22.6483 12.8007 21.8517 12.8007 21.3664 13.286L16.58 18.0724L14.6336 16.126C14.1483 15.6407 13.3517 15.6407 12.8664 16.126C12.3812 16.6112 12.3812 17.4078 12.8664 17.8931L15.6964 20.7231C15.9291 20.9558 16.2459 21.0895 16.58 21.0895C16.9141 21.0895 17.2309 20.9558 17.4636 20.7231L23.1336 15.0531ZM8.5 16.9995C8.5 11.7657 12.7661 7.49951 18 7.49951C23.2339 7.49951 27.5 11.7657 27.5 16.9995C27.5 22.2334 23.2339 26.4995 18 26.4995C12.7661 26.4995 8.5 22.2334 8.5 16.9995Z" stroke="#00645E" />
        </g>
        <defs>
            <filter id="filter0_d_13307_168636" x="0" y="-0.000488281" width="36" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="4" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13307_168636" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13307_168636" result="shape" />
            </filter>
        </defs>
    </svg>
}

export const CurrentLocationIcon = ({ color, className }: Props) => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g id="location">
            <path id="Vector" d="M8 13.5C4.96667 13.5 2.5 11.0333 2.5 8C2.5 4.96667 4.96667 2.5 8 2.5C11.0333 2.5 13.5 4.96667 13.5 8C13.5 11.0333 11.0333 13.5 8 13.5ZM8 3.5C5.52 3.5 3.5 5.52 3.5 8C3.5 10.48 5.52 12.5 8 12.5C10.48 12.5 12.5 10.48 12.5 8C12.5 5.52 10.48 3.5 8 3.5Z" fill={color} />
            <path id="Vector_2" d="M8 10.5C6.62 10.5 5.5 9.38 5.5 8C5.5 6.62 6.62 5.5 8 5.5C9.38 5.5 10.5 6.62 10.5 8C10.5 9.38 9.38 10.5 8 10.5ZM8 6.5C7.17333 6.5 6.5 7.17333 6.5 8C6.5 8.82667 7.17333 9.5 8 9.5C8.82667 9.5 9.5 8.82667 9.5 8C9.5 7.17333 8.82667 6.5 8 6.5Z" fill={color} />
            <path id="Vector_3" d="M8 3.16659C7.72667 3.16659 7.5 2.93992 7.5 2.66659V1.33325C7.5 1.05992 7.72667 0.833252 8 0.833252C8.27333 0.833252 8.5 1.05992 8.5 1.33325V2.66659C8.5 2.93992 8.27333 3.16659 8 3.16659Z" fill={color} />
            <path id="Vector_4" d="M2.66634 8.5H1.33301C1.05967 8.5 0.833008 8.27333 0.833008 8C0.833008 7.72667 1.05967 7.5 1.33301 7.5H2.66634C2.93967 7.5 3.16634 7.72667 3.16634 8C3.16634 8.27333 2.93967 8.5 2.66634 8.5Z" fill={color} />
            <path id="Vector_5" d="M8 15.1666C7.72667 15.1666 7.5 14.9399 7.5 14.6666V13.3333C7.5 13.0599 7.72667 12.8333 8 12.8333C8.27333 12.8333 8.5 13.0599 8.5 13.3333V14.6666C8.5 14.9399 8.27333 15.1666 8 15.1666Z" fill={color} />
            <path id="Vector_6" d="M14.6663 8.5H13.333C13.0597 8.5 12.833 8.27333 12.833 8C12.833 7.72667 13.0597 7.5 13.333 7.5H14.6663C14.9397 7.5 15.1663 7.72667 15.1663 8C15.1663 8.27333 14.9397 8.5 14.6663 8.5Z" fill={color} />
        </g>
    </svg>
}

export const TargetIcon = ({ width = 16, height = 16, color = "#1954A1" }: Props) => {
    return <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="location">
            <path id="Vector" d="M8 13.5C4.96667 13.5 2.5 11.0333 2.5 8C2.5 4.96667 4.96667 2.5 8 2.5C11.0333 2.5 13.5 4.96667 13.5 8C13.5 11.0333 11.0333 13.5 8 13.5ZM8 3.5C5.52 3.5 3.5 5.52 3.5 8C3.5 10.48 5.52 12.5 8 12.5C10.48 12.5 12.5 10.48 12.5 8C12.5 5.52 10.48 3.5 8 3.5Z" fill={color} />
            <path id="Vector_2" d="M8 10.5C6.62 10.5 5.5 9.38 5.5 8C5.5 6.62 6.62 5.5 8 5.5C9.38 5.5 10.5 6.62 10.5 8C10.5 9.38 9.38 10.5 8 10.5ZM8 6.5C7.17333 6.5 6.5 7.17333 6.5 8C6.5 8.82667 7.17333 9.5 8 9.5C8.82667 9.5 9.5 8.82667 9.5 8C9.5 7.17333 8.82667 6.5 8 6.5Z" fill={color} />
            <path id="Vector_3" d="M8 3.16659C7.72667 3.16659 7.5 2.93992 7.5 2.66659V1.33325C7.5 1.05992 7.72667 0.833252 8 0.833252C8.27333 0.833252 8.5 1.05992 8.5 1.33325V2.66659C8.5 2.93992 8.27333 3.16659 8 3.16659Z" fill={color} />
            <path id="Vector_4" d="M2.66634 8.5H1.33301C1.05967 8.5 0.833008 8.27333 0.833008 8C0.833008 7.72667 1.05967 7.5 1.33301 7.5H2.66634C2.93967 7.5 3.16634 7.72667 3.16634 8C3.16634 8.27333 2.93967 8.5 2.66634 8.5Z" fill={color} />
            <path id="Vector_5" d="M8 15.1666C7.72667 15.1666 7.5 14.9399 7.5 14.6666V13.3333C7.5 13.0599 7.72667 12.8333 8 12.8333C8.27333 12.8333 8.5 13.0599 8.5 13.3333V14.6666C8.5 14.9399 8.27333 15.1666 8 15.1666Z" fill={color} />
            <path id="Vector_6" d="M14.6663 8.5H13.333C13.0597 8.5 12.833 8.27333 12.833 8C12.833 7.72667 13.0597 7.5 13.333 7.5H14.6663C14.9397 7.5 15.1663 7.72667 15.1663 8C15.1663 8.27333 14.9397 8.5 14.6663 8.5Z" fill={color} />
        </g>
    </svg>
}

export const CounterPlusIcon = ({ color = "#404040", width = 16, height = 17 }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 16" fill="none">
        <path d="M11.1924 8.47554H8.81148V10.8565C8.81148 10.9828 8.76131 11.1039 8.672 11.1932C8.5827 11.2825 8.46158 11.3327 8.33529 11.3327C8.20899 11.3327 8.08787 11.2825 7.99857 11.1932C7.90927 11.1039 7.8591 10.9828 7.8591 10.8565V8.47554H5.47814C5.35185 8.47554 5.23073 8.42537 5.14143 8.33607C5.05212 8.24676 5.00195 8.12564 5.00195 7.99935C5.00195 7.87306 5.05212 7.75193 5.14143 7.66263C5.23073 7.57333 5.35185 7.52316 5.47814 7.52316H7.8591V5.14221C7.8591 5.01591 7.90927 4.89479 7.99857 4.80549C8.08787 4.71619 8.20899 4.66602 8.33529 4.66602C8.46158 4.66602 8.5827 4.71619 8.672 4.80549C8.76131 4.89479 8.81148 5.01591 8.81148 5.14221V7.52316H11.1924C11.3187 7.52316 11.4398 7.57333 11.5291 7.66263C11.6185 7.75193 11.6686 7.87306 11.6686 7.99935C11.6686 8.12564 11.6185 8.24676 11.5291 8.33607C11.4398 8.42537 11.3187 8.47554 11.1924 8.47554Z" fill={color} />
    </svg>
}

export const CounterMinusIcon = ({ color = "#404040", width = 16, height = 16 }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
        <path d="M10.8584 8.66536H5.14416C5.01787 8.66536 4.89674 8.59513 4.80744 8.4701C4.71814 8.34508 4.66797 8.17551 4.66797 7.9987C4.66797 7.82189 4.71814 7.65232 4.80744 7.52729C4.89674 7.40227 5.01787 7.33203 5.14416 7.33203H10.8584C10.9847 7.33203 11.1059 7.40227 11.1952 7.52729C11.2845 7.65232 11.3346 7.82189 11.3346 7.9987C11.3346 8.17551 11.2845 8.34508 11.1952 8.4701C11.1059 8.59513 10.9847 8.66536 10.8584 8.66536Z" fill={color} />
    </svg>
}

export const NoticeIcon = ({ color = '#404040', width = 20, height = 20, className }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path d="M9.99996 1.66669C5.39996 1.66669 1.66663 5.40002 1.66663 10C1.66663 14.6 5.39996 18.3334 9.99996 18.3334C14.6 18.3334 18.3333 14.6 18.3333 10C18.3333 5.40002 14.6 1.66669 9.99996 1.66669ZM9.99996 12.0834C8.84996 12.0834 7.91663 11.15 7.91663 10C7.91663 8.85002 8.84996 7.91669 9.99996 7.91669C11.15 7.91669 12.0833 8.85002 12.0833 10C12.0833 11.15 11.15 12.0834 9.99996 12.0834Z" />
    </svg>
}

export const CustomerDetailIcon = ({ }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M20 30.3332H12C4.76002 30.3332 1.66669 27.2398 1.66669 19.9998V11.9998C1.66669 4.75984 4.76002 1.6665 12 1.6665H18.6667C19.2134 1.6665 19.6667 2.11984 19.6667 2.6665C19.6667 3.21317 19.2134 3.6665 18.6667 3.6665H12C5.85335 3.6665 3.66669 5.85317 3.66669 11.9998V19.9998C3.66669 26.1465 5.85335 28.3332 12 28.3332H20C26.1467 28.3332 28.3334 26.1465 28.3334 19.9998V13.3332C28.3334 12.7865 28.7867 12.3332 29.3334 12.3332C29.88 12.3332 30.3334 12.7865 30.3334 13.3332V19.9998C30.3334 27.2398 27.24 30.3332 20 30.3332Z" fill="#1954A1" />
        <path d="M29.3334 14.3341H24C19.44 14.3341 17.6667 12.5608 17.6667 8.0008V2.66747C17.6667 2.26747 17.9067 1.89413 18.28 1.74747C18.6534 1.58747 19.08 1.6808 19.3734 1.9608L30.04 12.6275C30.32 12.9075 30.4134 13.3475 30.2534 13.7208C30.0934 14.0941 29.7334 14.3341 29.3334 14.3341ZM19.6667 5.0808V8.0008C19.6667 11.4408 20.56 12.3341 24 12.3341H26.92L19.6667 5.0808Z" fill="#1954A1" />
        <path d="M17.3333 18.3335H9.33331C8.78665 18.3335 8.33331 17.8802 8.33331 17.3335C8.33331 16.7868 8.78665 16.3335 9.33331 16.3335H17.3333C17.88 16.3335 18.3333 16.7868 18.3333 17.3335C18.3333 17.8802 17.88 18.3335 17.3333 18.3335Z" fill="#1954A1" />
        <path d="M14.6666 23.6665H9.33331C8.78665 23.6665 8.33331 23.2132 8.33331 22.6665C8.33331 22.1198 8.78665 21.6665 9.33331 21.6665H14.6666C15.2133 21.6665 15.6666 22.1198 15.6666 22.6665C15.6666 23.2132 15.2133 23.6665 14.6666 23.6665Z" fill="#1954A1" />
    </svg>
}

export const ServiceListIcon = ({ }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M25.3333 30.3337C24.7866 30.3337 24.3333 29.8803 24.3333 29.3337V14.667C24.3333 14.1203 24.7866 13.667 25.3333 13.667C25.88 13.667 26.3333 14.1203 26.3333 14.667V29.3337C26.3333 29.8803 25.88 30.3337 25.3333 30.3337Z" fill="#1954A1" />
        <path d="M25.3333 10.3337C24.7866 10.3337 24.3333 9.88033 24.3333 9.33366V2.66699C24.3333 2.12033 24.7866 1.66699 25.3333 1.66699C25.88 1.66699 26.3333 2.12033 26.3333 2.66699V9.33366C26.3333 9.88033 25.88 10.3337 25.3333 10.3337Z" fill="#1954A1" />
        <path d="M16 30.3337C15.4533 30.3337 15 29.8803 15 29.3337V22.667C15 22.1203 15.4533 21.667 16 21.667C16.5467 21.667 17 22.1203 17 22.667V29.3337C17 29.8803 16.5467 30.3337 16 30.3337Z" fill="#1954A1" />
        <path d="M16 18.3337C15.4533 18.3337 15 17.8803 15 17.3337V2.66699C15 2.12033 15.4533 1.66699 16 1.66699C16.5467 1.66699 17 2.12033 17 2.66699V17.3337C17 17.8803 16.5467 18.3337 16 18.3337Z" fill="#1954A1" />
        <path d="M6.66669 30.3337C6.12002 30.3337 5.66669 29.8803 5.66669 29.3337V14.667C5.66669 14.1203 6.12002 13.667 6.66669 13.667C7.21335 13.667 7.66669 14.1203 7.66669 14.667V29.3337C7.66669 29.8803 7.21335 30.3337 6.66669 30.3337Z" fill="#1954A1" />
        <path d="M6.66669 10.3337C6.12002 10.3337 5.66669 9.88033 5.66669 9.33366V2.66699C5.66669 2.12033 6.12002 1.66699 6.66669 1.66699C7.21335 1.66699 7.66669 2.12033 7.66669 2.66699V9.33366C7.66669 9.88033 7.21335 10.3337 6.66669 10.3337Z" fill="#1954A1" />
        <path d="M9.33333 15.667H4C3.45333 15.667 3 15.2137 3 14.667C3 14.1203 3.45333 13.667 4 13.667H9.33333C9.88 13.667 10.3333 14.1203 10.3333 14.667C10.3333 15.2137 9.88 15.667 9.33333 15.667Z" fill="#1954A1" />
        <path d="M28 15.667H22.6667C22.12 15.667 21.6667 15.2137 21.6667 14.667C21.6667 14.1203 22.12 13.667 22.6667 13.667H28C28.5467 13.667 29 14.1203 29 14.667C29 15.2137 28.5467 15.667 28 15.667Z" fill="#1954A1" />
        <path d="M18.6666 18.333H13.3333C12.7866 18.333 12.3333 17.8797 12.3333 17.333C12.3333 16.7863 12.7866 16.333 13.3333 16.333H18.6666C19.2133 16.333 19.6666 16.7863 19.6666 17.333C19.6666 17.8797 19.2133 18.333 18.6666 18.333Z" fill="#1954A1" />
    </svg>
}

export const TrashIcon = ({ width = 20, height = 20, color = '#1954A1' }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path d="M17.5005 5.60937C17.4839 5.60937 17.4589 5.60937 17.4339 5.60937C13.0255 5.1677 8.62554 5.00104 4.26721 5.4427L2.56721 5.60937C2.21721 5.6427 1.90887 5.3927 1.87554 5.0427C1.84221 4.6927 2.09221 4.3927 2.43387 4.35937L4.13387 4.1927C8.56721 3.7427 13.0589 3.9177 17.5589 4.35937C17.9005 4.3927 18.1505 4.70104 18.1172 5.0427C18.0922 5.3677 17.8172 5.60937 17.5005 5.60937Z" fill={color} />
        <path d="M7.08314 4.76602C7.0498 4.76602 7.01647 4.76602 6.9748 4.75768C6.64147 4.69935 6.40814 4.37435 6.46647 4.04102L6.6498 2.94935C6.78314 2.14935 6.96647 1.04102 8.90814 1.04102H11.0915C13.0415 1.04102 13.2248 2.19102 13.3498 2.95768L13.5331 4.04102C13.5915 4.38268 13.3581 4.70768 13.0248 4.75768C12.6831 4.81602 12.3581 4.58268 12.3081 4.24935L12.1248 3.16602C12.0081 2.44102 11.9831 2.29935 11.0998 2.29935H8.91647C8.03314 2.29935 8.01647 2.41602 7.89147 3.15768L7.6998 4.24102C7.6498 4.54935 7.38314 4.76602 7.08314 4.76602Z" fill={color} />
        <path d="M12.6745 18.9586H7.32451C4.41618 18.9586 4.29951 17.3503 4.20784 16.0503L3.66618 7.65864C3.64118 7.31697 3.90784 7.01697 4.24951 6.99197C4.59951 6.97531 4.89118 7.23364 4.91618 7.57531L5.45784 15.967C5.54951 17.2336 5.58284 17.7086 7.32451 17.7086H12.6745C14.4245 17.7086 14.4578 17.2336 14.5412 15.967L15.0828 7.57531C15.1078 7.23364 15.4078 6.97531 15.7495 6.99197C16.0912 7.01697 16.3578 7.30864 16.3328 7.65864L15.7912 16.0503C15.6995 17.3503 15.5828 18.9586 12.6745 18.9586Z" fill={color} />
        <path d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z" fill={color} />
        <path d="M12.0829 11.041H7.91626C7.57459 11.041 7.29126 10.7577 7.29126 10.416C7.29126 10.0743 7.57459 9.79102 7.91626 9.79102H12.0829C12.4246 9.79102 12.7079 10.0743 12.7079 10.416C12.7079 10.7577 12.4246 11.041 12.0829 11.041Z" fill={color} />
    </svg>
}

export const InfoCircleIcon = ({ color = '#1954A1', ...rest }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 20 20" fill="none">
        <path d="M10 1.66675C5.40835 1.66675 1.66669 5.40841 1.66669 10.0001C1.66669 14.5917 5.40835 18.3334 10 18.3334C14.5917 18.3334 18.3334 14.5917 18.3334 10.0001C18.3334 5.40841 14.5917 1.66675 10 1.66675ZM9.37502 6.66675C9.37502 6.32508 9.65835 6.04175 10 6.04175C10.3417 6.04175 10.625 6.32508 10.625 6.66675V10.8334C10.625 11.1751 10.3417 11.4584 10 11.4584C9.65835 11.4584 9.37502 11.1751 9.37502 10.8334V6.66675ZM10.7667 13.6501C10.725 13.7584 10.6667 13.8417 10.5917 13.9251C10.5084 14.0001 10.4167 14.0584 10.3167 14.1001C10.2167 14.1417 10.1084 14.1667 10 14.1667C9.89169 14.1667 9.78335 14.1417 9.68335 14.1001C9.58335 14.0584 9.49169 14.0001 9.40835 13.9251C9.33335 13.8417 9.27502 13.7584 9.23335 13.6501C9.19169 13.5501 9.16669 13.4417 9.16669 13.3334C9.16669 13.2251 9.19169 13.1167 9.23335 13.0167C9.27502 12.9167 9.33335 12.8251 9.40835 12.7417C9.49169 12.6667 9.58335 12.6084 9.68335 12.5667C9.88335 12.4834 10.1167 12.4834 10.3167 12.5667C10.4167 12.6084 10.5084 12.6667 10.5917 12.7417C10.6667 12.8251 10.725 12.9167 10.7667 13.0167C10.8084 13.1167 10.8334 13.2251 10.8334 13.3334C10.8334 13.4417 10.8084 13.5501 10.7667 13.6501Z" fill={color} />
    </svg>
}

export const ManagePaymentIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M5.2402 22.1737C4.98686 22.1737 4.73353 22.0803 4.53353 21.8803C4.14686 21.4937 4.14686 20.8537 4.53353 20.467L20.4669 4.53365C20.8535 4.14699 21.4935 4.14699 21.8802 4.53365C22.2669 4.92032 22.2669 5.56032 21.8802 5.94699L5.94686 21.8937C5.7602 22.0803 5.49353 22.1737 5.2402 22.1737Z" fill="#1954A1" />
        <path d="M14.8 25.3725C14.5467 25.3725 14.2933 25.2792 14.0933 25.0792C13.7067 24.6925 13.7067 24.0525 14.0933 23.6659L15.6933 22.0659C16.08 21.6792 16.72 21.6792 17.1067 22.0659C17.4933 22.4525 17.4933 23.0925 17.1067 23.4792L15.5067 25.0792C15.32 25.2659 15.0667 25.3725 14.8 25.3725Z" fill="#1954A1" />
        <path d="M18.3873 21.7864C18.134 21.7864 17.8806 21.693 17.6806 21.493C17.294 21.1064 17.294 20.4664 17.6806 20.0797L20.8673 16.893C21.254 16.5064 21.894 16.5064 22.2806 16.893C22.6673 17.2797 22.6673 17.9197 22.2806 18.3064L19.094 21.493C18.9073 21.6797 18.6406 21.7864 18.3873 21.7864Z" fill="#1954A1" />
        <path d="M14.7999 30.3334C13.4933 30.3334 12.1866 29.5334 10.5999 27.9467L4.05326 21.4C0.866593 18.2134 0.879926 16.16 4.09326 12.9467L12.9466 4.09338C16.1599 0.880048 18.2133 0.866715 21.3999 4.05338L27.9466 10.6C31.1333 13.7867 31.1199 15.84 27.9066 19.0534L19.0533 27.9067C17.4399 29.5201 16.1199 30.3334 14.7999 30.3334ZM17.1999 3.66672C16.5066 3.66672 15.6266 4.24005 14.3599 5.50672L5.50659 14.36C4.23993 15.6267 3.66659 16.5067 3.66659 17.1867C3.66659 17.88 4.19993 18.72 5.46659 19.9867L12.0133 26.5334C13.2799 27.8 14.1066 28.3334 14.7999 28.3334C14.7999 28.3334 14.7999 28.3334 14.8133 28.3334C15.5066 28.3334 16.3733 27.76 17.6399 26.4934L26.4933 17.64C27.7599 16.3734 28.3333 15.4934 28.3333 14.8134C28.3333 14.12 27.7999 13.28 26.5333 12.0134L19.9866 5.46671C18.7333 4.20005 17.8933 3.66672 17.1999 3.66672Z" fill="#1954A1" />
        <path d="M29.3333 30.3335H2.66663C2.11996 30.3335 1.66663 29.8802 1.66663 29.3335C1.66663 28.7868 2.11996 28.3335 2.66663 28.3335H29.3333C29.88 28.3335 30.3333 28.7868 30.3333 29.3335C30.3333 29.8802 29.88 30.3335 29.3333 30.3335Z" fill="#1954A1" />
    </svg>
}

export const CircleCheckIcon = ({ color, ...rest }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 12 12" fill="none">
        <path d="M6 1C3.245 1 1 3.245 1 6C1 8.755 3.245 11 6 11C8.755 11 11 8.755 11 6C11 3.245 8.755 1 6 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z" fill={color} />
    </svg>
}

export const EditIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M4.61666 16.2666C4.10832 16.2666 3.63332 16.0916 3.29166 15.7666C2.85832 15.3583 2.64999 14.7416 2.72499 14.075L3.03332 11.375C3.09166 10.8666 3.39999 10.1916 3.75832 9.82496L10.6 2.58329C12.3083 0.774959 14.0917 0.72496 15.9 2.43329C17.7083 4.14163 17.7583 5.92496 16.05 7.73329L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2416C4.87499 16.25 4.74999 16.2666 4.61666 16.2666ZM13.275 2.42496C12.6333 2.42496 12.075 2.82496 11.5083 3.42496L4.66666 10.675C4.49999 10.85 4.30832 11.2666 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9916 4.52499 15.0416 4.79999 15L7.48332 14.5416C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86663C16.1667 5.76663 16.5417 4.74996 15.0333 3.33329C14.3667 2.69163 13.7917 2.42496 13.275 2.42496Z" fill="#1954A1" />
        <path d="M14.45 9.12504C14.4333 9.12504 14.4083 9.12504 14.3917 9.12504C11.7917 8.8667 9.7 6.8917 9.3 4.30837C9.25 3.9667 9.48334 3.65004 9.825 3.5917C10.1667 3.5417 10.4833 3.77504 10.5417 4.1167C10.8583 6.13337 12.4917 7.68337 14.525 7.88337C14.8667 7.9167 15.1167 8.22504 15.0833 8.5667C15.0417 8.88337 14.7667 9.12504 14.45 9.12504Z" fill="#1954A1" />
        <path d="M17.5 18.958H2.5C2.15833 18.958 1.875 18.6747 1.875 18.333C1.875 17.9913 2.15833 17.708 2.5 17.708H17.5C17.8417 17.708 18.125 17.9913 18.125 18.333C18.125 18.6747 17.8417 18.958 17.5 18.958Z" fill="#1954A1" />
    </svg>
}

export const CopyIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M11.1 22.75H6.9C2.99 22.75 1.25 21.01 1.25 17.1V12.9C1.25 8.99 2.99 7.25 6.9 7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V17.1C16.75 21.01 15.01 22.75 11.1 22.75ZM6.9 8.75C3.8 8.75 2.75 9.8 2.75 12.9V17.1C2.75 20.2 3.8 21.25 6.9 21.25H11.1C14.2 21.25 15.25 20.2 15.25 17.1V12.9C15.25 9.8 14.2 8.75 11.1 8.75H6.9Z" fill="#757575" />
        <path d="M17.1 16.75H16C15.59 16.75 15.25 16.41 15.25 16V12.9C15.25 9.8 14.2 8.75 11.1 8.75H8C7.59 8.75 7.25 8.41 7.25 8V6.9C7.25 2.99 8.99 1.25 12.9 1.25H17.1C21.01 1.25 22.75 2.99 22.75 6.9V11.1C22.75 15.01 21.01 16.75 17.1 16.75ZM16.75 15.25H17.1C20.2 15.25 21.25 14.2 21.25 11.1V6.9C21.25 3.8 20.2 2.75 17.1 2.75H12.9C9.8 2.75 8.75 3.8 8.75 6.9V7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V15.25Z" fill="#757575" />
    </svg>
}

export const MoneyIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M11.1668 14.5167H9.07513C7.70846 14.5167 6.60013 13.3667 6.60013 11.9501C6.60013 11.6084 6.88346 11.3251 7.22513 11.3251C7.5668 11.3251 7.85013 11.6084 7.85013 11.9501C7.85013 12.6751 8.40013 13.2667 9.07513 13.2667H11.1668C11.7085 13.2667 12.1585 12.7834 12.1585 12.2001C12.1585 11.4751 11.9001 11.3334 11.4751 11.1834L8.1168 10.0001C7.4668 9.77506 6.5918 9.29173 6.5918 7.80006C6.5918 6.51673 7.60013 5.4834 8.83346 5.4834H10.9251C12.2918 5.4834 13.4001 6.6334 13.4001 8.05006C13.4001 8.39173 13.1168 8.67506 12.7751 8.67506C12.4335 8.67506 12.1501 8.39173 12.1501 8.05006C12.1501 7.32506 11.6001 6.7334 10.9251 6.7334H8.83346C8.2918 6.7334 7.8418 7.21673 7.8418 7.80006C7.8418 8.52506 8.10013 8.66673 8.52513 8.81673L11.8835 10.0001C12.5335 10.2251 13.4085 10.7084 13.4085 12.2001C13.4001 13.4751 12.4001 14.5167 11.1668 14.5167Z" fill="#36A9E1" />
        <path d="M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z" fill="#36A9E1" />
        <path d="M10.0003 18.9584C5.05866 18.9584 1.04199 14.9417 1.04199 10.0001C1.04199 5.05841 5.05866 1.04175 10.0003 1.04175C14.942 1.04175 18.9587 5.05841 18.9587 10.0001C18.9587 14.9417 14.942 18.9584 10.0003 18.9584ZM10.0003 2.29175C5.75033 2.29175 2.29199 5.75008 2.29199 10.0001C2.29199 14.2501 5.75033 17.7084 10.0003 17.7084C14.2503 17.7084 17.7087 14.2501 17.7087 10.0001C17.7087 5.75008 14.2503 2.29175 10.0003 2.29175Z" fill="#36A9E1" />
    </svg>
}

export const ClockOutlineIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10.0003 18.9584C5.05866 18.9584 1.04199 14.9417 1.04199 10.0001C1.04199 5.05841 5.05866 1.04175 10.0003 1.04175C14.942 1.04175 18.9587 5.05841 18.9587 10.0001C18.9587 14.9417 14.942 18.9584 10.0003 18.9584ZM10.0003 2.29175C5.75033 2.29175 2.29199 5.75008 2.29199 10.0001C2.29199 14.2501 5.75033 17.7084 10.0003 17.7084C14.2503 17.7084 17.7087 14.2501 17.7087 10.0001C17.7087 5.75008 14.2503 2.29175 10.0003 2.29175Z" fill="#36A9E1" />
        <path d="M13.0919 13.275C12.9835 13.275 12.8752 13.25 12.7752 13.1833L10.1919 11.6416C9.55019 11.2583 9.0752 10.4166 9.0752 9.67497V6.2583C9.0752 5.91663 9.35853 5.6333 9.7002 5.6333C10.0419 5.6333 10.3252 5.91663 10.3252 6.2583V9.67497C10.3252 9.97497 10.5752 10.4166 10.8335 10.5666L13.4169 12.1083C13.7169 12.2833 13.8085 12.6666 13.6335 12.9666C13.5085 13.1666 13.3002 13.275 13.0919 13.275Z" fill="#36A9E1" />
    </svg>
}

export const PendingPaymentIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M12.0827 13.9585H7.91602C7.57435 13.9585 7.29102 13.6752 7.29102 13.3335C7.29102 12.9918 7.57435 12.7085 7.91602 12.7085H12.0827C12.4243 12.7085 12.7077 12.9918 12.7077 13.3335C12.7077 13.6752 12.4243 13.9585 12.0827 13.9585Z" fill="#757575" />
        <path className="fill" d="M4.33275 8.71689C4.17442 8.71689 4.01609 8.65022 3.89109 8.53356C3.71609 8.35856 3.65775 8.08356 3.75775 7.85022L5.30775 4.15022C5.34109 4.07522 5.35775 4.02522 5.38275 3.98356C6.61609 1.14189 8.19109 0.450223 10.9744 1.51689C11.1328 1.57522 11.2578 1.70022 11.3244 1.85856C11.3911 2.01689 11.3911 2.19189 11.3244 2.35022L8.88275 8.01689C8.78275 8.24189 8.55775 8.39189 8.30775 8.39189H5.93275C5.45775 8.39189 5.00775 8.48356 4.57442 8.66689C4.49942 8.70022 4.41609 8.71689 4.33275 8.71689ZM8.84109 2.29189C7.80775 2.29189 7.17442 2.96689 6.51609 4.50022C6.50775 4.52522 6.49109 4.55022 6.48275 4.57522L5.39109 7.16689C5.57442 7.15022 5.74942 7.14189 5.93275 7.14189H7.89109L9.89942 2.47522C9.50775 2.35022 9.15775 2.29189 8.84109 2.29189Z" fill="#757575" />
        <path className="fill" d="M15.2415 8.55843C15.1832 8.55843 15.1165 8.5501 15.0582 8.53343C14.7499 8.44177 14.4082 8.39177 14.0582 8.39177H8.3082C8.09986 8.39177 7.89986 8.28343 7.7832 8.10843C7.67486 7.93343 7.64986 7.70844 7.7332 7.51677L10.1499 1.90843C10.2749 1.60843 10.6332 1.40843 10.9499 1.50843C11.0499 1.54177 11.1415 1.58343 11.2415 1.6251L13.2082 2.4501C14.3582 2.9251 15.1249 3.4251 15.6249 4.0251C15.7249 4.14177 15.8082 4.26677 15.8832 4.39177C15.9749 4.53343 16.0582 4.70844 16.1165 4.88344C16.1415 4.94177 16.1832 5.0501 16.2082 5.16677C16.4415 5.9501 16.3249 6.9251 15.8249 8.1751C15.7249 8.40843 15.4915 8.55843 15.2415 8.55843ZM9.2582 7.14177H14.0665C14.3332 7.14177 14.5915 7.16677 14.8499 7.20843C15.0832 6.48343 15.1332 5.9251 14.9999 5.4751C14.9832 5.4001 14.9665 5.36677 14.9582 5.33344C14.9082 5.2001 14.8749 5.1251 14.8332 5.05843C14.7749 4.96677 14.7332 4.89177 14.6665 4.81677C14.3082 4.38344 13.6749 3.98344 12.7332 3.6001L11.0832 2.90843L9.2582 7.14177Z" fill="#757575" />
        <path className="fill" d="M13.25 18.9583H6.75C6.51667 18.9583 6.3 18.9416 6.08333 18.9166C3.15833 18.7166 1.49167 17.0499 1.29167 14.0916C1.26667 13.9083 1.25 13.6833 1.25 13.4583V11.8333C1.25 9.95827 2.36667 8.2666 4.09167 7.5166C4.675 7.2666 5.3 7.1416 5.94167 7.1416H14.075C14.55 7.1416 15.0083 7.20827 15.4333 7.3416C17.3917 7.93327 18.7667 9.78327 18.7667 11.8333V13.4583C18.7667 13.6416 18.7583 13.8166 18.75 13.9833C18.5667 17.2416 16.6667 18.9583 13.25 18.9583ZM5.93333 8.3916C5.45833 8.3916 5.00833 8.48327 4.575 8.6666C3.30833 9.2166 2.49167 10.4583 2.49167 11.8333V13.4583C2.49167 13.6333 2.50833 13.8083 2.525 13.9749C2.68333 16.3499 3.85 17.5166 6.19167 17.6749C6.4 17.6999 6.56667 17.7166 6.74167 17.7166H13.2417C15.9917 17.7166 17.3417 16.5083 17.475 13.9249C17.4833 13.7749 17.4917 13.6249 17.4917 13.4583V11.8333C17.4917 10.3249 16.4833 8.97494 15.05 8.53327C14.7417 8.4416 14.4 8.3916 14.05 8.3916H5.93333Z" fill="#757575" />
        <path className="fill" d="M1.86719 12.4582C1.52552 12.4582 1.24219 12.1748 1.24219 11.8332V9.3915C1.24219 6.7665 3.10052 4.49983 5.66719 3.99983C5.89219 3.95817 6.12552 4.0415 6.27552 4.2165C6.41719 4.3915 6.45885 4.6415 6.36719 4.84983L4.90885 8.33317C4.84219 8.48317 4.72552 8.59983 4.58385 8.6665C3.31719 9.2165 2.50052 10.4582 2.50052 11.8332C2.49219 12.1748 2.21719 12.4582 1.86719 12.4582ZM4.66719 5.68317C3.60052 6.28317 2.82552 7.33317 2.58385 8.55817C2.95052 8.18317 3.37552 7.8665 3.85885 7.63317L4.66719 5.68317Z" fill="#757575" />
        <path className="fill" d="M18.133 12.4581C17.7913 12.4581 17.508 12.1748 17.508 11.8331C17.508 10.3248 16.4996 8.97481 15.0663 8.53315C14.8996 8.48315 14.758 8.36648 14.683 8.20815C14.608 8.04982 14.5996 7.86648 14.6663 7.70815C15.058 6.73315 15.158 6.02481 14.9996 5.47481C14.983 5.39981 14.9663 5.36648 14.958 5.33315C14.8496 5.09148 14.908 4.80815 15.0996 4.62482C15.2913 4.44148 15.583 4.39981 15.8163 4.52481C17.633 5.47481 18.758 7.34148 18.758 9.39148V11.8331C18.758 12.1748 18.4746 12.4581 18.133 12.4581ZM16.0413 7.57481C16.5663 7.81648 17.033 8.15815 17.4246 8.56648C17.2663 7.74981 16.8746 7.00815 16.2996 6.42481C16.258 6.77481 16.1746 7.15815 16.0413 7.57481Z" fill="#757575" />
    </svg>
}

export const TechnicianCarryCashIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M10.8743 13.5419H9.30769C8.19936 13.5419 7.29102 12.6085 7.29102 11.4585C7.29102 11.1169 7.57435 10.8335 7.91602 10.8335C8.25768 10.8335 8.54102 11.1169 8.54102 11.4585C8.54102 11.9169 8.88269 12.2919 9.30769 12.2919H10.8743C11.1993 12.2919 11.4577 12.0002 11.4577 11.6419C11.4577 11.1919 11.3327 11.1252 11.0494 11.0252L8.54102 10.1502C8.00768 9.96685 7.29102 9.57519 7.29102 8.35019C7.29102 7.30853 8.11603 6.4502 9.12436 6.4502H10.691C11.7993 6.4502 12.7077 7.38353 12.7077 8.53353C12.7077 8.8752 12.4243 9.15853 12.0827 9.15853C11.741 9.15853 11.4577 8.8752 11.4577 8.53353C11.4577 8.0752 11.116 7.7002 10.691 7.7002H9.12436C8.79936 7.7002 8.54102 7.99186 8.54102 8.35019C8.54102 8.80019 8.66601 8.86685 8.94934 8.96685L11.4577 9.84186C11.991 10.0252 12.7077 10.4169 12.7077 11.6419C12.7077 12.6919 11.8827 13.5419 10.8743 13.5419Z" fill="#757575" />
        <path className="fill" d="M10 14.375C9.65833 14.375 9.375 14.0917 9.375 13.75V6.25C9.375 5.90833 9.65833 5.625 10 5.625C10.3417 5.625 10.625 5.90833 10.625 6.25V13.75C10.625 14.0917 10.3417 14.375 10 14.375Z" fill="#757575" />
        <path className="fill" d="M9.99935 18.9582C5.05768 18.9582 1.04102 14.9415 1.04102 9.99984C1.04102 5.05817 5.05768 1.0415 9.99935 1.0415C10.341 1.0415 10.6243 1.32484 10.6243 1.6665C10.6243 2.00817 10.341 2.2915 9.99935 2.2915C5.74935 2.2915 2.29102 5.74984 2.29102 9.99984C2.29102 14.2498 5.74935 17.7082 9.99935 17.7082C14.2493 17.7082 17.7077 14.2498 17.7077 9.99984C17.7077 9.65817 17.991 9.37484 18.3327 9.37484C18.6744 9.37484 18.9577 9.65817 18.9577 9.99984C18.9577 14.9415 14.941 18.9582 9.99935 18.9582Z" fill="#757575" />
        <path className="fill" d="M17.4993 6.45833H14.166C13.8243 6.45833 13.541 6.175 13.541 5.83333V2.5C13.541 2.15833 13.8243 1.875 14.166 1.875C14.5077 1.875 14.791 2.15833 14.791 2.5V5.20833H17.4993C17.841 5.20833 18.1243 5.49167 18.1243 5.83333C18.1243 6.175 17.841 6.45833 17.4993 6.45833Z" fill="#757575" />
        <path className="fill" d="M14.1659 6.45853C14.0076 6.45853 13.8492 6.40019 13.7242 6.27519C13.4826 6.03353 13.4826 5.63353 13.7242 5.39186L17.8909 1.2252C18.1326 0.983529 18.5326 0.983529 18.7742 1.2252C19.0159 1.46686 19.0159 1.86686 18.7742 2.10853L14.6076 6.27519C14.4826 6.40019 14.3242 6.45853 14.1659 6.45853Z" fill="#757575" />
    </svg>
}

export const CustomerIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M10 18.9581C9.41667 18.9581 8.825 18.7331 8.38333 18.2915L6.95831 16.8831C6.60831 16.5331 6.12501 16.3415 5.63334 16.3415H5C3.275 16.3415 1.875 14.9498 1.875 13.2415V4.14978C1.875 2.44145 3.275 1.0498 5 1.0498H15C16.725 1.0498 18.125 2.44145 18.125 4.14978V13.2415C18.125 14.9498 16.725 16.3415 15 16.3415H14.3667C13.875 16.3415 13.3917 16.5415 13.0417 16.8831L11.6167 18.2915C11.175 18.7331 10.5833 18.9581 10 18.9581ZM5 2.29146C3.96667 2.29146 3.125 3.12477 3.125 4.14144V13.2331C3.125 14.2581 3.96667 15.0831 5 15.0831H5.63334C6.45834 15.0831 7.25831 15.4165 7.84164 15.9915L9.26666 17.3998C9.675 17.7998 10.3333 17.7998 10.7417 17.3998L12.1666 15.9915C12.75 15.4165 13.55 15.0831 14.375 15.0831H15C16.0333 15.0831 16.875 14.2498 16.875 13.2331V4.14144C16.875 3.11644 16.0333 2.29146 15 2.29146H5Z" fill="#757575" />
        <path className="fill" d="M10.0003 8.95851C8.58361 8.95851 7.43359 7.80849 7.43359 6.39183C7.43359 4.97516 8.58361 3.8252 10.0003 3.8252C11.4169 3.8252 12.567 4.97516 12.567 6.39183C12.567 7.80849 11.4169 8.95851 10.0003 8.95851ZM10.0003 5.0752C9.27527 5.0752 8.68359 5.66683 8.68359 6.39183C8.68359 7.11683 9.27527 7.70851 10.0003 7.70851C10.7253 7.70851 11.317 7.11683 11.317 6.39183C11.317 5.66683 10.7253 5.0752 10.0003 5.0752Z" fill="#757575" />
        <path className="fill" d="M13.3327 13.6751C12.991 13.6751 12.7077 13.3918 12.7077 13.0501C12.7077 11.9001 11.491 10.9585 9.99935 10.9585C8.50768 10.9585 7.29102 11.9001 7.29102 13.0501C7.29102 13.3918 7.00768 13.6751 6.66602 13.6751C6.32435 13.6751 6.04102 13.3918 6.04102 13.0501C6.04102 11.2085 7.81602 9.7085 9.99935 9.7085C12.1827 9.7085 13.9577 11.2085 13.9577 13.0501C13.9577 13.3918 13.6743 13.6751 13.3327 13.6751Z" fill="#757575" />
    </svg>
}

export const QuotationIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M14.7829 18.9585H6.36627C6.10794 18.9585 5.87461 18.8002 5.78294 18.5502C5.69127 18.3002 5.75793 18.0335 5.95793 17.8585C6.54127 17.3419 6.88294 16.6002 6.88294 15.8252C6.88294 14.3335 5.66627 13.1169 4.1746 13.1169C3.55793 13.1169 2.9746 13.3252 2.48294 13.7169C2.29127 13.8669 2.04127 13.8919 1.82461 13.7919C1.60794 13.6919 1.47461 13.4669 1.47461 13.2252V9.58354C1.47461 7.50854 3.15794 5.8252 5.23294 5.8252H14.7996C16.8746 5.8252 18.5579 7.50854 18.5579 9.58354V10.7835C18.5579 11.1252 18.2746 11.4085 17.9329 11.4085H16.2496C15.9579 11.4085 15.6913 11.5169 15.4996 11.7169L15.4913 11.7252C15.2579 11.9502 15.1496 12.2586 15.1746 12.5752C15.2246 13.1252 15.7496 13.5669 16.3496 13.5669H17.9329C18.2746 13.5669 18.5579 13.8502 18.5579 14.1919V15.1835C18.5413 17.2752 16.8579 18.9585 14.7829 18.9585ZM7.6496 17.7085H14.7829C16.1663 17.7085 17.2913 16.5835 17.2913 15.2002V14.8335H16.3329C15.0746 14.8335 14.0079 13.9002 13.9079 12.7002C13.8413 12.0169 14.0913 11.3419 14.5913 10.8502C15.0246 10.4086 15.6079 10.1669 16.2329 10.1669H17.2913V9.59188C17.2913 8.20855 16.1663 7.08354 14.7829 7.08354H5.21627C3.83293 7.08354 2.70794 8.20855 2.70794 9.59188V12.1586C3.17461 11.9752 3.66627 11.8752 4.16627 11.8752C6.34961 11.8752 8.12461 13.6502 8.12461 15.8335C8.12461 16.4919 7.95793 17.1419 7.6496 17.7085Z" fill="#757575" />
        <path className="fill" d="M2.08398 10.2166C1.74232 10.2166 1.45898 9.93329 1.45898 9.59162V6.5333C1.45898 5.29164 2.23399 4.15825 3.39232 3.72492L10.009 1.22492C10.6923 0.974921 11.459 1.06664 12.0507 1.4833C12.6423 1.89997 13.0007 2.57496 13.0007 3.29162V6.45828C13.0007 6.79995 12.7173 7.08328 12.3757 7.08328C12.034 7.08328 11.7507 6.79995 11.7507 6.45828V3.29162C11.7507 2.97496 11.6007 2.69162 11.3423 2.50829C11.0757 2.32495 10.7506 2.28328 10.4506 2.39161L3.83399 4.89161C3.15066 5.14994 2.70898 5.79164 2.70898 6.5333V9.59162C2.70898 9.93329 2.42565 10.2166 2.08398 10.2166Z" fill="#757575" />
        <path className="fill" d="M16.334 14.8331C15.0757 14.8331 14.009 13.8998 13.909 12.6998C13.8424 12.0082 14.0924 11.3332 14.5924 10.8415C15.0174 10.4082 15.6007 10.1665 16.2257 10.1665H17.959C18.784 10.1915 19.4174 10.8415 19.4174 11.6415V13.3582C19.4174 14.1582 18.784 14.8081 17.984 14.8331H16.334ZM17.9424 11.4165H16.234C15.9424 11.4165 15.6757 11.5248 15.484 11.7248C15.2424 11.9582 15.1257 12.2748 15.159 12.5915C15.209 13.1415 15.734 13.5831 16.334 13.5831H17.9674C18.0757 13.5831 18.1757 13.4832 18.1757 13.3582V11.6415C18.1757 11.5165 18.0757 11.4248 17.9424 11.4165Z" fill="#757575" />
        <path className="fill" d="M11.6673 10.625H5.83398C5.49232 10.625 5.20898 10.3417 5.20898 10C5.20898 9.65833 5.49232 9.375 5.83398 9.375H11.6673C12.009 9.375 12.2923 9.65833 12.2923 10C12.2923 10.3417 12.009 10.625 11.6673 10.625Z" fill="#757575" />
        <path className="fill" d="M4.16732 19.7917C1.98398 19.7917 0.208984 18.0167 0.208984 15.8333C0.208984 14.6167 0.75065 13.4916 1.69232 12.7416C2.39232 12.1833 3.27565 11.875 4.16732 11.875C6.35065 11.875 8.12565 13.65 8.12565 15.8333C8.12565 16.9667 7.63398 18.05 6.78398 18.8C6.05065 19.4417 5.12565 19.7917 4.16732 19.7917ZM4.16732 13.125C3.55065 13.125 2.96732 13.3333 2.47565 13.725C1.83399 14.2333 1.45898 15.0083 1.45898 15.8333C1.45898 17.325 2.67565 18.5417 4.16732 18.5417C4.81732 18.5417 5.45065 18.3 5.95898 17.8667C6.54231 17.35 6.87565 16.6167 6.87565 15.8333C6.87565 14.3417 5.65898 13.125 4.16732 13.125Z" fill="#757575" />
        <path className="fill" d="M3.33258 17.2915C3.12425 17.2915 2.91592 17.1832 2.79925 16.9915C2.62425 16.6915 2.71591 16.3082 3.01591 16.1332L3.75758 15.6915V14.7915C3.75758 14.4498 4.04091 14.1665 4.38258 14.1665C4.72425 14.1665 5.00758 14.4498 5.00758 14.7915V16.0415C5.00758 16.2582 4.89091 16.4665 4.70758 16.5748L3.66591 17.1998C3.55758 17.2665 3.44092 17.2915 3.33258 17.2915Z" fill="#757575" />
    </svg>
}

export const OrderIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M10 7.2915C9.91667 7.2915 9.84167 7.27484 9.75833 7.2415C9.525 7.14984 9.375 6.9165 9.375 6.6665V1.6665C9.375 1.32484 9.65833 1.0415 10 1.0415C10.3417 1.0415 10.625 1.32484 10.625 1.6665V5.15817L11.225 4.55817C11.4667 4.3165 11.8667 4.3165 12.1083 4.55817C12.35 4.79984 12.35 5.19984 12.1083 5.4415L10.4417 7.10817C10.325 7.22484 10.1583 7.2915 10 7.2915Z" fill="#757575" />
        <path className="fill" d="M10.0005 7.29154C9.84219 7.29154 9.68385 7.2332 9.55885 7.1082L7.89219 5.44154C7.65052 5.19987 7.65052 4.79987 7.89219 4.5582C8.13385 4.31654 8.53385 4.31654 8.77552 4.5582L10.4422 6.22487C10.6839 6.46654 10.6839 6.86654 10.4422 7.1082C10.3172 7.2332 10.1589 7.29154 10.0005 7.29154Z" fill="#757575" />
        <path className="fill" d="M13.3333 18.9583H6.66667C1.875 18.9583 1.875 16.4167 1.875 14.1667V13.3333C1.875 11.475 1.875 9.375 5.83333 9.375C6.825 9.375 7.19167 9.61667 7.70833 10C7.73333 10.025 7.76667 10.0417 7.79167 10.075L8.64167 10.975C9.35833 11.7333 10.6583 11.7333 11.375 10.975L12.225 10.075C12.25 10.05 12.275 10.025 12.3083 10C12.825 9.60833 13.1917 9.375 14.1833 9.375C18.1417 9.375 18.1417 11.475 18.1417 13.3333V14.1667C18.125 17.35 16.5167 18.9583 13.3333 18.9583ZM5.83333 10.625C3.125 10.625 3.125 11.475 3.125 13.3333V14.1667C3.125 16.45 3.125 17.7083 6.66667 17.7083H13.3333C15.8167 17.7083 16.875 16.65 16.875 14.1667V13.3333C16.875 11.475 16.875 10.625 14.1667 10.625C13.5667 10.625 13.4417 10.7 13.0833 10.9667L12.275 11.825C11.675 12.4583 10.8667 12.8083 10 12.8083C9.13333 12.8083 8.325 12.4583 7.725 11.825L6.91667 10.9667C6.55833 10.7 6.43333 10.625 5.83333 10.625Z" fill="#757575" />
        <path className="fill" d="M4.16602 10.6251C3.82435 10.6251 3.54102 10.3417 3.54102 10.0001V6.66672C3.54102 5.05005 3.54102 3.04172 6.60768 2.75005C6.94935 2.71672 7.25768 2.96672 7.29102 3.31672C7.32435 3.65838 7.07435 3.96672 6.72435 4.00005C4.79102 4.17505 4.79102 4.95838 4.79102 6.66672V10.0001C4.79102 10.3417 4.50768 10.6251 4.16602 10.6251Z" fill="#757575" />
        <path className="fill" d="M15.8325 10.625C15.4909 10.625 15.2075 10.3417 15.2075 10V6.66669C15.2075 4.95835 15.2075 4.17502 13.2742 3.99169C12.9325 3.95835 12.6825 3.65002 12.7159 3.30835C12.7492 2.96669 13.0492 2.70835 13.3992 2.75002C16.4659 3.04169 16.4659 5.05002 16.4659 6.66669V10C16.4575 10.3417 16.1742 10.625 15.8325 10.625Z" fill="#757575" />
    </svg>
}

export const SchedulerIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M6.66602 4.7915C6.32435 4.7915 6.04102 4.50817 6.04102 4.1665V1.6665C6.04102 1.32484 6.32435 1.0415 6.66602 1.0415C7.00768 1.0415 7.29102 1.32484 7.29102 1.6665V4.1665C7.29102 4.50817 7.00768 4.7915 6.66602 4.7915Z" fill="#757575" />
        <path className="fill" d="M13.334 4.7915C12.9923 4.7915 12.709 4.50817 12.709 4.1665V1.6665C12.709 1.32484 12.9923 1.0415 13.334 1.0415C13.6757 1.0415 13.959 1.32484 13.959 1.6665V4.1665C13.959 4.50817 13.6757 4.7915 13.334 4.7915Z" fill="#757575" />
        <path className="fill" d="M17.0827 8.20019H2.91602C2.57435 8.20019 2.29102 7.91686 2.29102 7.5752C2.29102 7.23353 2.57435 6.9502 2.91602 6.9502H17.0827C17.4243 6.9502 17.7077 7.23353 17.7077 7.5752C17.7077 7.91686 17.4243 8.20019 17.0827 8.20019Z" fill="#757575" />
        <path className="fill" d="M13.3333 18.9582H6.66667C3.625 18.9582 1.875 17.2082 1.875 14.1665V7.08317C1.875 4.0415 3.625 2.2915 6.66667 2.2915H13.3333C16.375 2.2915 18.125 4.0415 18.125 7.08317V14.1665C18.125 17.2082 16.375 18.9582 13.3333 18.9582ZM6.66667 3.5415C4.28333 3.5415 3.125 4.69984 3.125 7.08317V14.1665C3.125 16.5498 4.28333 17.7082 6.66667 17.7082H13.3333C15.7167 17.7082 16.875 16.5498 16.875 14.1665V7.08317C16.875 4.69984 15.7167 3.5415 13.3333 3.5415H6.66667Z" fill="#757575" />
        <path className="fill" d="M7.08333 12.0832C6.975 12.0832 6.86666 12.0582 6.76666 12.0165C6.66666 11.9749 6.57501 11.9165 6.49167 11.8415C6.41667 11.7582 6.35832 11.6665 6.31666 11.5665C6.27499 11.4665 6.25 11.3582 6.25 11.2498C6.25 11.0332 6.34167 10.8165 6.49167 10.6582C6.57501 10.5832 6.66666 10.5248 6.76666 10.4832C6.91666 10.4165 7.08334 10.3998 7.25001 10.4332C7.30001 10.4415 7.35 10.4582 7.4 10.4832C7.45 10.4998 7.5 10.5249 7.55 10.5582C7.59166 10.5915 7.63333 10.6249 7.67499 10.6582C7.70833 10.6999 7.74999 10.7415 7.77499 10.7832C7.80832 10.8332 7.83334 10.8832 7.85001 10.9332C7.87501 10.9832 7.89168 11.0332 7.90001 11.0832C7.90834 11.1415 7.91667 11.1915 7.91667 11.2498C7.91667 11.4665 7.82499 11.6832 7.67499 11.8415C7.51666 11.9915 7.3 12.0832 7.08333 12.0832Z" fill="#757575" />
        <path className="fill" d="M9.99935 12.0832C9.78268 12.0832 9.56602 11.9916 9.40769 11.8416C9.37436 11.7999 9.34103 11.7582 9.30769 11.7166C9.27436 11.6666 9.24934 11.6166 9.23267 11.5666C9.20767 11.5166 9.19101 11.4666 9.18267 11.4166C9.17434 11.3582 9.16602 11.3082 9.16602 11.2499C9.16602 11.1416 9.191 11.0332 9.23267 10.9332C9.27434 10.8332 9.33269 10.7416 9.40769 10.6582C9.64102 10.4249 10.016 10.3499 10.316 10.4832C10.4244 10.5249 10.5077 10.5832 10.591 10.6582C10.741 10.8166 10.8327 11.0332 10.8327 11.2499C10.8327 11.3082 10.8244 11.3582 10.816 11.4166C10.8077 11.4666 10.791 11.5166 10.766 11.5666C10.7494 11.6166 10.7243 11.6666 10.691 11.7166C10.6577 11.7582 10.6243 11.7999 10.591 11.8416C10.5077 11.9166 10.4244 11.9749 10.316 12.0166C10.216 12.0582 10.1077 12.0832 9.99935 12.0832Z" fill="#757575" />
        <path className="fill" d="M7.08333 15.0002C6.975 15.0002 6.86666 14.9752 6.76666 14.9336C6.66666 14.8919 6.57501 14.8335 6.49167 14.7585C6.41667 14.6752 6.35832 14.5919 6.31666 14.4836C6.27499 14.3836 6.25 14.2752 6.25 14.1669C6.25 13.9502 6.34167 13.7336 6.49167 13.5752C6.57501 13.5002 6.66666 13.4419 6.76666 13.4002C7.075 13.2669 7.44166 13.3419 7.67499 13.5752C7.70833 13.6169 7.74999 13.6586 7.77499 13.7002C7.80832 13.7502 7.83334 13.8002 7.85001 13.8502C7.87501 13.9002 7.89168 13.9502 7.90001 14.0086C7.90834 14.0586 7.91667 14.1169 7.91667 14.1669C7.91667 14.3836 7.82499 14.6002 7.67499 14.7585C7.51666 14.9085 7.3 15.0002 7.08333 15.0002Z" fill="#757575" />
    </svg>
}

export const TechniciansIcon = ({ width = 20, height = 20, className = "", color = "#757575" }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M10.8327 12.2915H1.66602C1.32435 12.2915 1.04102 12.0082 1.04102 11.6665V4.99984C1.04102 2.8165 2.81602 1.0415 4.99935 1.0415H12.4993C12.841 1.0415 13.1243 1.32484 13.1243 1.6665V9.99984C13.1243 11.2665 12.0993 12.2915 10.8327 12.2915ZM2.29102 11.0415H10.8327C11.4077 11.0415 11.8743 10.5748 11.8743 9.99984V2.2915H4.99935C3.50768 2.2915 2.29102 3.50817 2.29102 4.99984V11.0415Z" fill={color} />
        <path className="fill" d="M15.8327 17.2915H14.9993C14.6577 17.2915 14.3743 17.0082 14.3743 16.6665C14.3743 16.0915 13.9077 15.6248 13.3327 15.6248C12.7577 15.6248 12.291 16.0915 12.291 16.6665C12.291 17.0082 12.0077 17.2915 11.666 17.2915H8.33268C7.99102 17.2915 7.70768 17.0082 7.70768 16.6665C7.70768 16.0915 7.24102 15.6248 6.66602 15.6248C6.09102 15.6248 5.62435 16.0915 5.62435 16.6665C5.62435 17.0082 5.34102 17.2915 4.99935 17.2915H4.16602C2.44102 17.2915 1.04102 15.8915 1.04102 14.1665V11.6665C1.04102 11.3248 1.32435 11.0415 1.66602 11.0415H10.8327C11.4077 11.0415 11.8743 10.5748 11.8743 9.99984V4.1665C11.8743 3.82484 12.1577 3.5415 12.4993 3.5415H14.0327C14.8577 3.5415 15.616 3.98318 16.0244 4.69985L17.4494 7.19151C17.5577 7.38317 17.5577 7.62484 17.4494 7.81651C17.341 8.00817 17.1327 8.12484 16.9077 8.12484H15.8327C15.716 8.12484 15.6243 8.2165 15.6243 8.33317V10.8332C15.6243 10.9498 15.716 11.0415 15.8327 11.0415H18.3327C18.6744 11.0415 18.9577 11.3248 18.9577 11.6665V14.1665C18.9577 15.8915 17.5577 17.2915 15.8327 17.2915ZM15.541 16.0415H15.8327C16.866 16.0415 17.7077 15.1998 17.7077 14.1665V12.2915H15.8327C15.0327 12.2915 14.3743 11.6332 14.3743 10.8332V8.33317C14.3743 7.53317 15.0243 6.87484 15.8327 6.87484L14.941 5.31651C14.7577 4.99151 14.4077 4.7915 14.0327 4.7915H13.1243V9.99984C13.1243 11.2665 12.0993 12.2915 10.8327 12.2915H2.29102V14.1665C2.29102 15.1998 3.13268 16.0415 4.16602 16.0415H4.45769C4.73269 15.0832 5.61602 14.3748 6.66602 14.3748C7.71602 14.3748 8.59934 15.0832 8.87434 16.0415H11.1327C11.4077 15.0832 12.291 14.3748 13.341 14.3748C14.391 14.3748 15.266 15.0832 15.541 16.0415Z" fill={color} />
        <path className="fill" d="M6.66667 18.9583C5.4 18.9583 4.375 17.9333 4.375 16.6667C4.375 15.4 5.4 14.375 6.66667 14.375C7.93333 14.375 8.95833 15.4 8.95833 16.6667C8.95833 17.9333 7.93333 18.9583 6.66667 18.9583ZM6.66667 15.625C6.09167 15.625 5.625 16.0917 5.625 16.6667C5.625 17.2417 6.09167 17.7083 6.66667 17.7083C7.24167 17.7083 7.70833 17.2417 7.70833 16.6667C7.70833 16.0917 7.24167 15.625 6.66667 15.625Z" fill={color} />
        <path className="fill" d="M13.3327 18.9583C12.066 18.9583 11.041 17.9333 11.041 16.6667C11.041 15.4 12.066 14.375 13.3327 14.375C14.5993 14.375 15.6243 15.4 15.6243 16.6667C15.6243 17.9333 14.5993 18.9583 13.3327 18.9583ZM13.3327 15.625C12.7577 15.625 12.291 16.0917 12.291 16.6667C12.291 17.2417 12.7577 17.7083 13.3327 17.7083C13.9077 17.7083 14.3743 17.2417 14.3743 16.6667C14.3743 16.0917 13.9077 15.625 13.3327 15.625Z" fill={color} />
        <path className="fill" d="M18.3333 12.2917H15.8333C15.0333 12.2917 14.375 11.6333 14.375 10.8333V8.33333C14.375 7.53333 15.0333 6.875 15.8333 6.875H16.9083C17.1333 6.875 17.3417 6.99167 17.45 7.19167L18.875 9.69167C18.925 9.78334 18.9583 9.89167 18.9583 10V11.6667C18.9583 12.0083 18.675 12.2917 18.3333 12.2917ZM15.8333 8.125C15.7167 8.125 15.625 8.21667 15.625 8.33333V10.8333C15.625 10.95 15.7167 11.0417 15.8333 11.0417H17.7083V10.1667L16.5417 8.125H15.8333Z" fill={color} />
    </svg>
}

export const CustomerRemarkIcon = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22.7498C11.3 22.7498 10.59 22.4798 10.06 21.9498L8.34998 20.2598C7.92998 19.8398 7.35001 19.6097 6.76001 19.6097H6C3.93 19.6097 2.25 17.9398 2.25 15.8898V4.97974C2.25 2.92974 3.93 1.25977 6 1.25977H18C20.07 1.25977 21.75 2.92974 21.75 4.97974V15.8898C21.75 17.9398 20.07 19.6097 18 19.6097H17.24C16.65 19.6097 16.07 19.8498 15.65 20.2598L13.94 21.9498C13.41 22.4798 12.7 22.7498 12 22.7498ZM6 2.74976C4.76 2.74976 3.75 3.74973 3.75 4.96973V15.8798C3.75 17.1098 4.76 18.0997 6 18.0997H6.76001C7.75001 18.0997 8.70997 18.4998 9.40997 19.1898L11.12 20.8798C11.61 21.3598 12.4 21.3598 12.89 20.8798L14.6 19.1898C15.3 18.4998 16.26 18.0997 17.25 18.0997H18C19.24 18.0997 20.25 17.0998 20.25 15.8798V4.96973C20.25 3.73973 19.24 2.74976 18 2.74976H6Z" fill="#1954A1" />
        <path d="M11.9999 10.7503C10.2999 10.7503 8.91992 9.37029 8.91992 7.67029C8.91992 5.97029 10.2999 4.59033 11.9999 4.59033C13.6999 4.59033 15.08 5.97029 15.08 7.67029C15.08 9.37029 13.6999 10.7503 11.9999 10.7503ZM11.9999 6.09033C11.1299 6.09033 10.4199 6.80029 10.4199 7.67029C10.4199 8.54029 11.1299 9.25031 11.9999 9.25031C12.8699 9.25031 13.58 8.54029 13.58 7.67029C13.58 6.80029 12.8699 6.09033 11.9999 6.09033Z" fill="#1954A1" />
        <path d="M16 16.4103C15.59 16.4103 15.25 16.0703 15.25 15.6603C15.25 14.2803 13.79 13.1504 12 13.1504C10.21 13.1504 8.75 14.2803 8.75 15.6603C8.75 16.0703 8.41 16.4103 8 16.4103C7.59 16.4103 7.25 16.0703 7.25 15.6603C7.25 13.4503 9.38 11.6504 12 11.6504C14.62 11.6504 16.75 13.4503 16.75 15.6603C16.75 16.0703 16.41 16.4103 16 16.4103Z" fill="#1954A1" />
    </svg>
}

export const PromoCodeIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M16.184 9.7915H13.334C12.9923 9.7915 12.709 9.50817 12.709 9.1665V3.3415C12.709 2.72484 12.9507 2.14984 13.384 1.71651C13.8173 1.28317 14.3923 1.0415 15.009 1.0415H15.0173C16.059 1.04984 17.0423 1.45817 17.7923 2.19984C18.5423 2.95817 18.9506 3.95817 18.9506 4.99984V7.0165C18.959 8.67484 17.8423 9.7915 16.184 9.7915ZM13.959 8.5415H16.184C17.1506 8.5415 17.709 7.98317 17.709 7.0165V4.99984C17.709 4.28317 17.4257 3.59984 16.9173 3.08317C16.409 2.58317 15.7257 2.29984 15.0173 2.2915C15.0173 2.2915 15.0173 2.2915 15.009 2.2915C14.734 2.2915 14.4673 2.39984 14.2673 2.59984C14.0673 2.79984 13.959 3.05817 13.959 3.3415V8.5415Z" fill="#757575" />
        <path className="fill" d="M7.50078 19.4415C7.10912 19.4415 6.74245 19.2915 6.46745 19.0082L5.08411 17.6165C5.00911 17.5415 4.89245 17.5332 4.80912 17.5998L3.38412 18.6665C2.94245 18.9998 2.35912 19.0582 1.85912 18.8082C1.35912 18.5582 1.05078 18.0582 1.05078 17.4998V4.99984C1.05078 2.48317 2.49245 1.0415 5.00911 1.0415H15.0091C15.3508 1.0415 15.6341 1.32484 15.6341 1.6665C15.6341 2.00817 15.3508 2.2915 15.0091 2.2915C14.4341 2.2915 13.9674 2.75817 13.9674 3.33317V17.4998C13.9674 18.0582 13.6591 18.5582 13.1591 18.8082C12.6591 19.0582 12.0758 19.0082 11.6341 18.6748L10.2091 17.6082C10.1258 17.5415 10.0091 17.5582 9.94245 17.6248L8.54245 19.0248C8.25912 19.2915 7.89245 19.4415 7.50078 19.4415ZM4.92578 16.3082C5.30912 16.3082 5.68412 16.4498 5.96745 16.7415L7.35078 18.1332C7.40078 18.1832 7.46745 18.1915 7.50078 18.1915C7.53412 18.1915 7.60078 18.1832 7.65078 18.1332L9.05078 16.7332C9.56745 16.2165 10.3841 16.1665 10.9591 16.6082L12.3758 17.6665C12.4674 17.7332 12.5508 17.7082 12.5924 17.6832C12.6341 17.6582 12.7091 17.6082 12.7091 17.4998V3.33317C12.7091 2.95817 12.8008 2.59984 12.9591 2.2915H5.00078C3.15078 2.2915 2.29245 3.14984 2.29245 4.99984V17.4998C2.29245 17.6165 2.36745 17.6665 2.40912 17.6915C2.45912 17.7165 2.54245 17.7332 2.62578 17.6665L4.05078 16.5998C4.30912 16.4082 4.61745 16.3082 4.92578 16.3082Z" fill="#757575" />
        <path className="fill" d="M9.52474 11.7336C9.05807 11.7336 8.69141 11.3586 8.69141 10.9002C8.69141 10.4419 9.06641 10.0669 9.52474 10.0669C9.98307 10.0669 10.3581 10.4419 10.3581 10.9002C10.3581 11.3586 9.98307 11.7336 9.52474 11.7336Z" fill="#757575" />
        <path className="fill" d="M5.47396 8.35856C5.00729 8.35856 4.64062 7.98356 4.64062 7.52523C4.64062 7.06689 5.01563 6.69189 5.47396 6.69189C5.93229 6.69189 6.30729 7.06689 6.30729 7.52523C6.30729 7.98356 5.93229 8.35856 5.47396 8.35856Z" fill="#757575" />
        <path className="fill" d="M5.22448 12.0665C5.06614 12.0665 4.90781 12.0081 4.78281 11.8831C4.54115 11.6415 4.54115 11.2415 4.78281 10.9998L9.33281 6.4498C9.57448 6.20814 9.97448 6.20814 10.2161 6.4498C10.4578 6.69147 10.4578 7.09147 10.2161 7.33314L5.66614 11.8831C5.54948 11.9998 5.38281 12.0665 5.22448 12.0665Z" fill="#757575" />
    </svg>
}

export const AirconListIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path className="fill" d="M13.3333 18.9582H6.66667C3.625 18.9582 1.875 17.2082 1.875 14.1665V5.83317C1.875 2.7915 3.625 1.0415 6.66667 1.0415H13.3333C16.375 1.0415 18.125 2.7915 18.125 5.83317V14.1665C18.125 17.2082 16.375 18.9582 13.3333 18.9582ZM6.66667 2.2915C4.28333 2.2915 3.125 3.44984 3.125 5.83317V14.1665C3.125 16.5498 4.28333 17.7082 6.66667 17.7082H13.3333C15.7167 17.7082 16.875 16.5498 16.875 14.1665V5.83317C16.875 3.44984 15.7167 2.2915 13.3333 2.2915H6.66667Z" fill="#757575" />
        <path className="fill" d="M13.5417 9.7915H6.45833C5.30833 9.7915 4.375 8.85817 4.375 7.70817V5.62484C4.375 4.47484 5.30833 3.5415 6.45833 3.5415H13.5417C14.6917 3.5415 15.625 4.47484 15.625 5.62484V7.70817C15.625 8.85817 14.6917 9.7915 13.5417 9.7915ZM6.45833 4.7915C6 4.7915 5.625 5.1665 5.625 5.62484V7.70817C5.625 8.1665 6 8.5415 6.45833 8.5415H13.5417C14 8.5415 14.375 8.1665 14.375 7.70817V5.62484C14.375 5.1665 14 4.7915 13.5417 4.7915H6.45833Z" fill="#757575" />
        <path className="fill" d="M6.66588 15.2749C6.50755 15.2749 6.34922 15.2166 6.22422 15.0916C5.98255 14.8499 5.98255 14.4499 6.22422 14.2083L8.14088 12.2916C8.38254 12.0499 8.78254 12.0499 9.02421 12.2916C9.26587 12.5333 9.26587 12.9333 9.02421 13.1749L7.10755 15.0916C6.98255 15.2166 6.82422 15.2749 6.66588 15.2749Z" fill="#757575" />
        <path className="fill" d="M8.60793 15.2998C8.4496 15.2998 8.29127 15.2415 8.16627 15.1165L6.24961 13.1998C6.00794 12.9582 6.00794 12.5582 6.24961 12.3165C6.49128 12.0748 6.89127 12.0748 7.13294 12.3165L9.0496 14.2332C9.29126 14.4748 9.29126 14.8748 9.0496 15.1165C8.9246 15.2415 8.76626 15.2998 8.60793 15.2998Z" fill="#757575" />
        <path className="fill" d="M13.7577 13.6086C13.2994 13.6086 12.916 13.2336 12.916 12.7752C12.916 12.3169 13.2827 11.9419 13.741 11.9419H13.7577C14.216 11.9419 14.591 12.3169 14.591 12.7752C14.591 13.2336 14.216 13.6086 13.7577 13.6086Z" fill="#757575" />
        <path className="fill" d="M12.0755 15.4089C11.6172 15.4089 11.2422 15.0422 11.2422 14.5839V14.5672C11.2422 14.1089 11.6172 13.7339 12.0755 13.7339C12.5339 13.7339 12.9089 14.1089 12.9089 14.5672C12.9089 15.0256 12.5339 15.4089 12.0755 15.4089Z" fill="#757575" />
    </svg>
}

export const CustomerRatingIcon = () => {
    return <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.0341 8.24958L27.2666 12.7146C27.5674 13.3321 28.3749 13.9179 29.0558 14.0446L33.0933 14.7096C35.6741 15.1371 36.2758 17.0054 34.4233 18.8738L31.2724 22.0246C30.7499 22.5471 30.4491 23.5763 30.6233 24.3204L31.5258 28.2154C32.2383 31.2871 30.5916 32.4904 27.8841 30.8754L24.0999 28.6271C23.4191 28.2154 22.2791 28.2154 21.5983 28.6271L17.8141 30.8754C15.1066 32.4746 13.4599 31.2871 14.1724 28.2154L15.0749 24.3204C15.2174 23.5604 14.9166 22.5313 14.3941 22.0088L11.2433 18.8579C9.39078 17.0054 9.99245 15.1371 12.5733 14.6938L16.6108 14.0288C17.2916 13.9179 18.0991 13.3163 18.3999 12.6988L20.6324 8.23375C21.8516 5.82708 23.8149 5.82708 25.0341 8.24958Z" fill="#F5CF6A" />
        <path d="M13.3334 9.10419H3.83337C3.18421 9.10419 2.64587 8.56585 2.64587 7.91669C2.64587 7.26752 3.18421 6.72919 3.83337 6.72919H13.3334C13.9825 6.72919 14.5209 7.26752 14.5209 7.91669C14.5209 8.56585 13.9825 9.10419 13.3334 9.10419Z" fill="#F5CF6A" />
        <path d="M8.58337 31.2708H3.83337C3.18421 31.2708 2.64587 30.7325 2.64587 30.0833C2.64587 29.4341 3.18421 28.8958 3.83337 28.8958H8.58337C9.23254 28.8958 9.77087 29.4341 9.77087 30.0833C9.77087 30.7325 9.23254 31.2708 8.58337 31.2708Z" fill="#F5CF6A" />
        <path d="M5.41671 20.1875H3.83337C3.18421 20.1875 2.64587 19.6492 2.64587 19C2.64587 18.3508 3.18421 17.8125 3.83337 17.8125H5.41671C6.06587 17.8125 6.60421 18.3508 6.60421 19C6.60421 19.6492 6.06587 20.1875 5.41671 20.1875Z" fill="#F5CF6A" />
    </svg>
}

export const ChatIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.3916 14.0251L15.7166 16.6584C15.7999 17.3501 15.0582 17.8334 14.4666 17.4751L11.5832 15.7584C11.3832 15.6417 11.3332 15.3917 11.4416 15.1917C11.8582 14.4251 12.0832 13.5584 12.0832 12.6917C12.0832 9.64175 9.46658 7.15841 6.24991 7.15841C5.59158 7.15841 4.94991 7.25841 4.34991 7.45841C4.04158 7.55841 3.74158 7.27508 3.81658 6.95842C4.57491 3.92508 7.49158 1.66675 10.9749 1.66675C15.0416 1.66675 18.3332 4.74175 18.3332 8.53341C18.3332 10.7834 17.1749 12.7751 15.3916 14.0251Z" fill="#1954A1" />
        <path d="M10.8334 12.6918C10.8334 13.6834 10.4667 14.6001 9.85008 15.3251C9.02508 16.3251 7.71675 16.9668 6.25008 16.9668L4.07508 18.2584C3.70841 18.4834 3.24175 18.1751 3.29175 17.7501L3.50008 16.1084C2.38341 15.3334 1.66675 14.0918 1.66675 12.6918C1.66675 11.2251 2.45008 9.93345 3.65008 9.16678C4.39175 8.68345 5.28341 8.40845 6.25008 8.40845C8.78341 8.40845 10.8334 10.3251 10.8334 12.6918Z" fill="#1954A1" />
    </svg>
}