import ListDailySchedule from "components/pages/service-schedules/ListDailySchedule"
import { ResourceType } from "resources-config"

export const DAILY_SCHEDULES_CONFIG: ResourceType = {
    name: 'daily-schedules', meta: {parent: 'service-schedules' ,apiEndpoints: {getList: 'service-schedules/daily-schedules'}, hide: true},
    actions: {
        list: {
            path: 'daily-schedules', page: <ListDailySchedule/>,
            allowedRoles: ['super admin'],
        },       
    }
}