import { useCustom, useModal, useTable } from "@refinedev/core"
import { BaseCard } from "components/cards"
import DailyScheduler, { Subject, SubjectSchedule } from "components/DailyScheduler"
import PageTitle from "components/PageTitle"
import { addHours, format, subHours } from "date-fns"
import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DAILY_SCHEDULES_CONFIG } from "resources-config/service-schedule/daily-schedule-config"
import { changeTz, getFilterValue } from "utils/data-formatter"
import { stringify } from "query-string"
import { formatFullAddress } from 'utils/address';
import { ScheduleHeader } from "components/headers"
import { ScheduleFooter } from "components/footers"
import { AssignTechModal, AddScheduleModal, EditScheduleModal } from "components/custom-modals"
import { getJobTypeColor, scrollToTargetAdjusted } from "utils/scheduler"
import Pill from "components/Pill"
import { getStatistics } from "api/scheduler"
import { padStart } from "lodash"

export default function ListDailySchedule() {
    const navigate = useNavigate();
    const editSchedModal = useModal();
    const teamSchedModal = useModal()
    const addScheduleModal = useModal()
    const [viewedTeam, setViewedTeam] = useState<{ team: MiscObj, date: string, viewTs: number } | null>(null)
    const [currentMonth, setCurrentMonth] = useState<number>(-1)
    const [monthlyStatistic, setMonthlyStatistic] = useState<MiscObj>()
    const [dailyStatistic, setDailyStatistic] = useState<MiscObj | null>(null)
    const [schedules, setschedules] = useState<MiscObj>({})
    const [viewedSchedId, setViewedSchedId] = useState<number | null>(null)
    const {
        tableQueryResult, filters, setFilters,
    } = useTable({
        resource: DAILY_SCHEDULES_CONFIG.name,
        queryOptions: { retry: false, },
        pagination: { mode: 'off' },
        filters: {
            initial: [
                { field: 'tzoffset', operator: 'eq', value: 8 },
                {
                    field: 'startdate', operator: 'eq',
                    value: format(addHours(new Date(), 8), 'yyyy-MM') + '-01'
                }
            ]
        }
    })

    const handleOpenAddSchedule = (date: Date, team: MiscObj) => {
        setViewedTeam({ team: team, date: date.toString(), viewTs: Date.now() })
        addScheduleModal.show()
    }

    const goToToday = useCallback(() => {
        setFilters([{ field: 'startdate', operator: 'eq', value: format(new Date(), 'yyyy-MM-dd') }])
    }, [setFilters])

    const changeView = useCallback((view: string) => {
        const tzoffset = getFilterValue(filters, 'tzoffset')
        const startdate = getFilterValue(filters, 'startdate')
        const query = {
            'filters[0][field]': 'tzoffset',
            'filters[0][operator]': 'eq',
            'filters[0][value]': tzoffset,
            'filters[1][field]': 'view',
            'filters[1][operator]': 'eq',
            'filters[1][value]': view,
            'filters[2][field]': 'startdate',
            'filters[2][operator]': 'eq',
            'filters[2][value]': (
                view === 'month' ? format(new Date(startdate), 'yyyy-MM') + '-01' : startdate
            ),
        }
        navigate(`/service-schedules?${stringify(query)}`)
    }, [filters, navigate])

    useLayoutEffect(() => {
        const scheduleId = getFilterValue(filters, 'schedule')
        if (scrollToTargetAdjusted(`JID${scheduleId}`, 100)) setFilters(filters => filters.filter((filter: any) => filter.field != 'schedule'))
    }, [filters, schedules])

    const changePage = useCallback((goNext: boolean) => {
        const startdate = getFilterValue(filters, 'startdate')
        let currentStartDateObj = new Date(startdate + 'T00:00:00Z')
        if (goNext) currentStartDateObj = addHours(startdate, 24)
        else currentStartDateObj = subHours(startdate, 24)
        setFilters([{ field: 'startdate', operator: 'eq', value: format(currentStartDateObj, 'yyyy-MM-dd') }])
    }, [filters, setFilters])

    const viewTeam = useCallback((team: MiscObj, date: string) => {
        setViewedTeam({ team, date, viewTs: Date.now() })
        teamSchedModal.show()
    }, [teamSchedModal, setViewedTeam])

    const viewSchedule = useCallback((date: string, scheduleId: number) => {
        setViewedSchedId(scheduleId)
        editSchedModal.show()
    }, [setViewedSchedId, editSchedModal])

    useEffect(() => {
        if (tableQueryResult.data) {
            setschedules(tableQueryResult.data.data)
        }
    }, [tableQueryResult])


    useEffect(() => {
        const startDate = getFilterValue(filters, 'startdate')
        refetchAssignments()
        const month = new Date(startDate).getMonth() + 1
        if (month === currentMonth) {
            if (monthlyStatistic && monthlyStatistic.dailyStats[startDate]) {
                setDailyStatistic(monthlyStatistic.dailyStats[startDate])
            } else setDailyStatistic(null)
            return
        }
        setCurrentMonth(month)
        getStatistics(`2024-${padStart(month.toString(), 2, '0')}-01`).then((statistic) => {
            setMonthlyStatistic(statistic)
            if (statistic && statistic.dailyStats[startDate]) {
                setDailyStatistic(statistic.dailyStats[startDate])
            } else setDailyStatistic(null)
        }).catch(err => console.log(err))
    }, [filters])

    const { data: assignments, refetch: refetchAssignments } = useCustom({
        url: `service-schedules/team-assignments`,
        method: "get",
        config: {
            query: {
                date: getFilterValue(filters, 'startdate')
            }
        }
    })

    return <section className="w-full ">
        <PageTitle title='Daily Schedules' />
        <ScheduleHeader startDate={getFilterValue(filters, 'startdate')} view="day" changePage={changePage} changeView={changeView} goToToday={goToToday} />
        <BaseCard className="mt-4 overflow-auto">
            <DailyScheduler handleOpenAddSchedule={handleOpenAddSchedule} date={getFilterValue(filters, 'startdate')} startHour={parseInt(process.env.REACT_APP_START_ORDER || '9')} endHour={parseInt(process.env.REACT_APP_END_ORDER || '18')} multiplier={30}>
                {
                    schedules && schedules.teams ? schedules.teams.map((team: MiscObj, idx: number) => {
                        // @ts-ignore
                        const assignment = assignments && assignments.find((assign: any) => assign.technicianTeam.id == team.id)
                        const technicians = (assignment && assignment.assignedTechnicians) || []
                        return <Subject name={team.name} color={team.colorHex ? team.colorHex : '#001F3F'} key={idx}
                            handleClick={(date) => { viewTeam(team, date) }} team={team} technicians={technicians}
                        >
                            {
                                team.serviceSchedules
                                    .filter((sched: MiscObj) => (sched.order && sched.order.invoices.length))
                                    .map((sched: MiscObj, idx: number) => {
                                        const startDate = changeTz(sched.startDate, 'Asia/Singapore')
                                        const endDate = changeTz(sched.estimatedCompletionDate, 'Asia/Singapore')
                                        const startDateStr = `${format(startDate, 'yyyy-MM-dd')}T${format(startDate, 'HH:mm:ss')}Z`
                                        const endDateStr = `${format(endDate, 'yyyy-MM-dd')}T${format(endDate, 'HH:mm:ss')}Z`
                                        const address = formatFullAddress(sched.order.buildingNumber, sched.order.street, sched.order.postalCode)
                                        const jobTypes: string[] = Array.from(new Set(sched.tasks.map((task: any) => task.item.service.jobType.name)))

                                        return (
                                            <SubjectSchedule key={idx} startDateTime={startDateStr} endDateTime={endDateStr}
                                                handleClick={(date: string) => { viewSchedule(date, +sched.id) }}
                                                isPriority={sched.isPriority}
                                                schedule={sched}
                                                jobTypes={jobTypes}
                                            >
                                                <div className="relative h-full p-3 text-start space-y-1">
                                                    <div className="gap-2 mb-3 flex-ct-y">
                                                        <div className="flex items-center justify-between w-full line-clamp-1">
                                                            <span className="font-medium">OID{sched.order.id} - JID{sched.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className="w-fit line-clamp-1">{format(startDate, 'HH:mm')} - {format(endDate, 'HH:mm')}</div>
                                                    <div>{sched.user.name}</div>
                                                    <div>{address}</div>
                                                    <div className="flex items-center gap-2 flex-wrap">
                                                        {jobTypes.map(type => <Pill {...getJobTypeColor(type)}>{type}</Pill>)}
                                                    </div>
                                                    {sched.order.notes &&
                                                        <div className={`absolute w-full p-3 ${sched.isPriority ? 'bg-[#FDC521]' : 'bg-white'} rounded-b-lg line-clamp-2 text-th-xs text-light bottom-0 left-0`}>
                                                            Notes : {sched.order.notes}
                                                        </div>
                                                    }

                                                </div>
                                            </SubjectSchedule>
                                        )
                                    })
                            }
                        </Subject>
                    }) : <></>
                }
            </DailyScheduler>
        </BaseCard>
        <ScheduleFooter dailyStatistic={dailyStatistic} technicianAssignments={assignments} schedules={schedules} />
        <AssignTechModal modal={teamSchedModal} viewedTeam={viewedTeam} refresh={refetchAssignments} />
        <AddScheduleModal initialOrderId={getFilterValue(filters, 'order')} initialScheduleId={getFilterValue(filters, 'moveSchedule')} {...addScheduleModal} refetchDailySched={tableQueryResult.refetch} viewedTeam={viewedTeam} />
        <EditScheduleModal
            setFilters={setFilters}
            refetchDailySched={tableQueryResult.refetch} modal={editSchedModal} scheduleId={viewedSchedId}
            dateTime={format(getFilterValue(filters, 'startdate'), 'eee, dd MMMM yyyy')}
        />
    </section>
}