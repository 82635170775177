import { Dispatch, SetStateAction } from 'react'
import { useBreadcrumb, useLogout } from "@refinedev/core";
import { ChevronRightIcon, HamburgerIcon, UserCircleIcon } from "components/Svg";
import { Link } from "react-router-dom";
import { Tooltip } from 'react-tooltip';

export const Header = ({ menuShown, setMenuShown }: { menuShown: boolean, setMenuShown: Dispatch<SetStateAction<boolean>> }) => {
    const { mutate: logout } = useLogout()
    const { breadcrumbs } = useBreadcrumb();

    return (
        <div className="header" style={{ width: 'inherit' }}>
            <div className="gap-4 flex-ct-y">
                <button type="button" onClick={() => { setMenuShown(state => !state) }} className="bg-white shadow-lg rounded-lg p-1 absolute -left-4 z-30 tablet-below:hidden">
                    <ChevronRightIcon color="#1954A1" width={16} height={16} className={`scale-150 ${menuShown ? 'rotate-180' : ""}`} />
                </button>
                <button type="button" className="toggle-menu" onClick={() => { setMenuShown(state => !state) }}>
                    <HamburgerIcon className='w-6' />
                </button>
                <ul className="breadcrumb">
                    {breadcrumbs.map((breadcrumb, index) => {
                        return (
                            <li className="item" key={`breadcrumb-${breadcrumb.label}`}>
                                {breadcrumb.href ? (
                                    <Link className="link" to={breadcrumb.href}>{breadcrumb.label}</Link>
                                ) : (
                                    <span className="link">{breadcrumb.label}</span>
                                )}
                                {
                                    breadcrumbs.length === 1 || index === (breadcrumbs.length - 1) ? '' :
                                        <ChevronRightIcon />
                                }
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className='flex-ct-y'>
                <div className="w-fit h-fit" id='admin-dd' data-tooltip-class-name='dd-tooltip'>
                    <UserCircleIcon color='#1954A1' className='w-8' />
                </div>
                <Tooltip anchorSelect={`#admin-dd`} clickable>
                    <ul className="list">
                        <li>
                            <button type="button" onClick={() => { logout() }}>
                                Logout
                            </button>
                        </li>
                    </ul>
                </Tooltip>
            </div>
        </div>
    );
};
