import { useOne } from "@refinedev/core"
import { getServiceOrders } from "api/service-orders"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import { Tab, TabPanel } from "components/Tab"
import { Table, TAction, TActionItem, TBody, Td, Th, THead } from "components/Table"
import { LabeledInfo } from "components/texts"
import { TextTooltip } from "components/tooltips"
import { baseApi } from "providers/customDataProvider"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { USERS_CONFIG } from "resources-config/users-config"
import { toast } from "sonner"
import { formatAddressAny } from "utils/address"
import { formatFullDate } from "utils/date"
import ShowUserOrderList from "./ShowUserOrderList"
import { FormsGrid, TextAreaField } from "components/forms"
import { useForm } from "react-hook-form"
import { getUserRatings, patchEditUser } from "api/users"
import ShowUserRatingList from "./ShowUserRatingList"
import { CustomerRatingIcon } from "components/Svg"

const ShowUser = () => {
    const params = useParams()
    const { control, handleSubmit, setValue } = useForm()
    const { data, refetch: refetchUser, } = useOne({
        resource: USERS_CONFIG.name,
        id: params.id,
    })
    const user = data && data.data
    const [orders, setOrders] = useState<any[]>([])
    const [rating, setRating] = useState<MiscObj>([])
    const [isFetching, setIsFetching] = useState<boolean>(false)

    const toggleActiveUser = useCallback(async () => {
        if (!user) return
        try {
            await baseApi.post('admin/users/toggle-active/' + user.id, { isActive: !user.isActive })
            refetchUser()
            toast.success('Success')
        } catch (error) {
            console.log(error)
        }
    }, [refetchUser, user])

    const submitNotes = handleSubmit(async (data) => {
        if (!user || !user.id) return
        setIsFetching(true)
        try {
            await patchEditUser(user.id.toString(), data)
            toast.success('Internal notes saved')
        }
        catch (err: any) {
            toast.error(err.message)
        }
        finally {
            setIsFetching(false)
        }

    })

    useEffect(() => {
        if (!user || !user.id) return
        setValue('internalNote', user.internalNote)
        getServiceOrders("", 1, 100).then((orders) => {
        }).catch(err => console.log(err))
        getUserRatings(user.id.toString()).then((rating) => {
            setRating(rating)
        }).catch(err => console.log(err))
    }, [user])

    const handleSetDefault = async (userId: any, addressId: number) => {
        try {
            await baseApi.post(`/admin/users/${userId}/addresses/${addressId}/default`)
            toast.success('Default address successfully updated')
        }
        catch (err: any) {
            console.log(err)
            toast.error(err.message)
        } finally {
            refetchUser()
        }
    }

    return <BaseCard>
        {user &&
            <>
                <header className="flex items-center justify-between border-b pb-4">
                    <h1 className="text-th-2xl font-bold">{user.name} - {user.id}</h1>
                    <div className="flex items-center gap-2">
                        <CustomerRatingIcon />
                        <span className="font-medium">{rating && parseFloat(rating.avgRatings || 0).toFixed(2)} </span>
                        <h3 className="text-th-base font-medium text-light">Ratings</h3>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="font-medium">$ {user.balance.toFixed(2) || 0} </span>
                        <h3 className="text-th-base font-medium text-light">Credits</h3>
                    </div>
                    <div className="flex items-center gap-2">
                        <Button tag="a" href={`/users/edit/${user.id}`} shape={'outline'}>
                            Edit Customer
                        </Button>
                        {/* <DropdownToggle label="More" shape="filled" options={actionOptions}>
                            test
                        </DropdownToggle> */}
                    </div>
                </header>
                <section className="grid grid-cols-2 gap-8 relative mt-4">
                    <div className="sticky top-20 self-start flex flex-col gap-4">
                        <FormsGrid col={2}>
                            <LabeledInfo label="Contact Number" className="text-light">
                                {user.phoneNumber}
                            </LabeledInfo>
                            <LabeledInfo label="Email" className="text-light">
                                {user.email}
                            </LabeledInfo>
                            <LabeledInfo label="Primary Address" className="text-light">
                                {user.addresses.length > 0 ? formatAddressAny(user.addresses.find((address: any) => address.isDefault) || user.addresses[0]) : 'N/A'}
                            </LabeledInfo>
                            <LabeledInfo label="Gender" className="text-light">
                                {!user.gender ? 'N/A' : user.gender}
                            </LabeledInfo>
                            <LabeledInfo label="Birthdate" className="text-light">
                                {formatFullDate(user.birthDate, { showDay: false, showTime: false })}
                            </LabeledInfo>
                            <LabeledInfo label="Join Date" className="text-light">
                                {formatFullDate(user.createdAt, { showDay: false, showTime: false })}
                            </LabeledInfo>
                            <LabeledInfo label="Last Login" className="text-light">
                                {formatFullDate(user.lastLoginAt)}
                            </LabeledInfo>
                        </FormsGrid>
                        {user.deactivateReason &&
                            <LabeledInfo label="Deactivate Reason" className="text-light break-words">
                                {user.deactivateReason}
                            </LabeledInfo>
                        }

                        <form onSubmit={submitNotes}>
                            <TextAreaField rows={5} control={control} label={
                                <div className="flex items-center justify-between w-full">
                                    <span>Internal Notes</span>
                                </div>
                            } fieldName="internalNote" />
                            <span className="pt-1 text-th-xs">Last Updated By: {user.internalNoteLastEditedBy && user.internalNoteLastEditedBy.name} ({formatFullDate(user.internalNoteLastEditedAt)})</span>
                            <Button type="submit" className="w-full mt-2.5" isLoading={isFetching}>Save Notes</Button>
                        </form>
                    </div>
                    <Table>
                        <THead>
                            <Th>Postal Code</Th>
                            <Th>Address</Th>
                            <Th>Address Type</Th>
                            <Th>Notes</Th>
                            <Th>Action</Th>
                        </THead>
                        {(user && user.addresses) ? user.addresses.map((addr: any) => {
                            return <TBody key={addr.id}>
                                <Td>{addr.postalCode}</Td>
                                <Td>{formatAddressAny(addr, { showPostalCode: false })}</Td>
                                <Td>{addr.unitType}</Td>
                                <Td>
                                    <TextTooltip position="top" tooltipClassName="w-[300px] break-words " text={addr.notes} className="line-clamp-1 underline">
                                        {addr.notes}
                                    </TextTooltip>
                                </Td>
                                <TAction id={`user-address-${addr.id}-action`}>
                                    <TActionItem>
                                        <button type="button" onClick={() => handleSetDefault(user.id, addr.id)}>
                                            Set As Default
                                        </button>
                                    </TActionItem>
                                </TAction>
                            </TBody>
                        }) : <></>}

                    </Table>
                </section>
                <section className="mt-12">
                    <Tab>
                        <TabPanel id="orders" title='Order List'>
                            <ShowUserOrderList userId={user.id && user.id.toString()} />
                        </TabPanel>
                        <TabPanel id="credits" title='Available Credits'>
                            WIP
                        </TabPanel>
                        <TabPanel id="rating" title='Customer Ratings'>
                            <ShowUserRatingList rating={rating} userId={user.id && user.id.toString()} />
                        </TabPanel>
                    </Tab>
                </section>
            </>
        }
    </BaseCard>
}

export default ShowUser