import { baseApi } from "providers/customDataProvider"

export const getRescheduleRequests = async (filter?: 'accepted' | 'pending' | 'rejected') => {
    const res = await baseApi.get(`/admin/service-schedules/tech-reschedules?status=${filter || ''}`)
    const rescheduleRequest = res.data
    return rescheduleRequest
}

export const acceptRescheduleRequest = async (scheduleId: string) => {
    const res = await baseApi.patch(`/admin/service-schedules/tech-reschedules/${scheduleId}/accept`)
    return res.data.message == 'Success'
}

export const rejectRescheduleRequest = async (scheduleId: string) => {
    const res = await baseApi.patch(`/admin/service-schedules/tech-reschedules/${scheduleId}/reject`)
    return res.data.message == 'Success'
}