import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"

import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { TextField } from 'components/forms'
import useDisableBtn from 'utils/useDisableBtn'
import { handleModalMutation } from 'utils/handle-mutation'
import { PUBLIC_HOLIDAYS_CONFIG } from 'resources-config/public-holiday-config'
import { Modal } from 'components/popups'
import { getErrorNotif, getSuccessNotif } from 'utils/data-formatter'

type FieldType = {
    date: string
}

export default function ListPublicHoliday(){
    const createPubHolidayModal = useModal();
    const editPubHolidayModal = useModal();
    const [editedPubHoliday, setEditedPubHoliday] = useState<{[key: string]: any}>({})

    const {tableQueryResult, pageSize, current, setCurrent} = useTable({
        queryOptions: {retry: false,},
        pagination: {current: 1, pageSize: 20},
        resource: PUBLIC_HOLIDAYS_CONFIG.name,
    })      
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    return (<>
        <PageTitle/>
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft></TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={PUBLIC_HOLIDAYS_CONFIG.name} action='create'>
                        <Button onClick={() => {createPubHolidayModal.show()}}>Add holiday</Button>
                        <CreatePubHolidayModal modal={createPubHolidayModal}/>
                    </CanAccess>  
                </TabletWidgetRight>
            </TabletWidgets>         
            <Table>
                <THead>
                    <Th>ID</Th><Th>Date</Th><Th>Description</Th><Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((holiday, index) => (
                    <TBody key={index}>
                        <Td>{holiday.id}</Td>
                        <Td>{holiday.date}</Td>
                        <Td>{holiday.description}</Td>
                        <TAction id={`edit-pub-holiday-${holiday.id}`}>
                            <TActionItem access={{resource: PUBLIC_HOLIDAYS_CONFIG.name, action: 'edit'}}>
                                <button type='button' className='text-sm' onClick={() => {
                                    setEditedPubHoliday(holiday)
                                    editPubHolidayModal.show()
                                }}>
                                    Edit
                                </button>                                    
                            </TActionItem>
                        </TAction>
                    </TBody>                        
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination 
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0} 
                    clickHandler={changePage} range={5}
                />              
            </div>             
            <EditPublicHolidayModal modal={editPubHolidayModal} pubHoliday={editedPubHoliday}/>        
        </BaseCard>
    </>)
}

const CreatePubHolidayModal = ({modal}: {modal: any}) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, setError,
        control, clearErrors
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: PUBLIC_HOLIDAYS_CONFIG.name,
            successNotification(){
                return getSuccessNotif('create', 'public holiday')
            },
            errorNotification(data){
                return getErrorNotif('create', 'public holiday', data)
            }               
        },
    }); 
    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])     
    return (
        <Modal heading={'Create Public Holiday'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField type='date' control={control} fieldName='date' label={'Date'} required/>
                <TextField control={control} fieldName='description' label={'Description'} required/>
                <div className='justify-end flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

const EditPublicHolidayModal = (
    {modal, pubHoliday}: 
    {modal: any, pubHoliday: {[key: string]: string}}
) => {
    const [btnDisabled, disableBtn] = useDisableBtn()
    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, reset, setError,
        control, clearErrors
    } = useForm<FieldType>({
        defaultValues: {name: pubHoliday.name},
        refineCoreProps: {
            resource: PUBLIC_HOLIDAYS_CONFIG.name, 
            action:  !pubHoliday.id ? 'create' : 'edit', id: pubHoliday.id || 0,
            successNotification(){
                return getSuccessNotif('update', 'public holiday')
            },
            errorNotification(data){
                return getErrorNotif('update', 'public holiday', data)
            }              
        },     
    }); 
    useEffect(() => {
        const {id, ...fields} = pubHoliday
        reset(fields)
    }, [pubHoliday, reset])

    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])      

    return (
        <Modal heading={'Edit Public Holiday'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField type='date' control={control} fieldName='date' label={'Date'} required/>
                <TextField control={control} fieldName='description' label={'Description'} required/>
                <div className='justify-end flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

