import { format as dateFormat } from "date-fns"

export default function changeTz(date: string | Date, tz: 'Asia/Jakarta' | 'Asia/Singapore', format?: string) {
    try {
        let dateStr: string | null = null
        if (typeof date === 'string') {
            dateStr = new Date(date).toLocaleString('en-US', { timeZone: tz })
        }
        else {
            dateStr = date.toLocaleString('en-US', { timeZone: tz })
        }
        if (format) {
            return dateFormat(dateStr, format)
        }
        return dateStr
    } catch (err) {
        // console.log(err)
        return '-'
    }
}