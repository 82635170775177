import { useEffect, useMemo, useState } from "react"
import { BaseFormProps } from "./types"
import { useController } from "react-hook-form"
import { getFieldErrMsg } from "utils/handle-api-error"
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

interface SelectFieldProps extends BaseFormProps {
    options: { value: any, label: string }[],
    setValue?: FormHookType['setValue'],
    defaultCfg?: {
        value: SelectSingleVal
        setValue: FormHookType['setValue']
    }
    onCreateOption?: (inputValue: string) => void
    disabled?: boolean
    enableMenuPortalTarget?: boolean
}

export default function SelectField({
    fieldName, control, options, errorCfg, defaultCfg,
    label, setValue, desc, className, disabled, placeholder, enableMenuPortalTarget = true, onCreateOption, key, ...rest
}: SelectFieldProps) {
    const reactSelectId = useMemo(() => (Date.now().toString()), [])
    const [isMounted, setIsMounted] = useState(false);
    const [isDefaultSet, setIsDefaultSet] = useState(false);
    const { field, fieldState } = useController({ name: fieldName, control, rules: { required: rest.required ? `${label ? label : 'Field'} is required` : undefined } });
    let classes = 'form-group' + (className ? ' ' + className : '')
    classes += rest.required ? ' required' : ''

    useEffect(() => {
        setIsMounted(true)
    }, []);

    // useEffect(() => {
    //     if (defaultCfg && defaultCfg.setValue && defaultCfg.value !== undefined) {
    //         defaultCfg.setValue(fieldName, defaultCfg.value ? defaultCfg.value.value : null)
    //     }
    // }, [defaultCfg?.value, defaultCfg?.setValue])

    useEffect(() => {
        if (!isDefaultSet && defaultCfg?.setValue && defaultCfg.value !== undefined) {
            defaultCfg.setValue(fieldName, defaultCfg.value ? defaultCfg.value.value : null);
            setIsDefaultSet(true); // Mark that the default value is set
        }
    }, [defaultCfg, fieldName, isDefaultSet]);

    if (!isMounted) {
        return null
    }
    return (
        <span className={classes}>
            {label ? <label className='form-label'>{label}</label> : ''}
            {onCreateOption ?
                <CreatableSelect
                    onCreateOption={onCreateOption}
                    id={reactSelectId} ref={field.ref}
                    options={options} value={options.find(opt => opt.value === field.value) || null}
                    defaultValue={defaultCfg && defaultCfg.value ? defaultCfg.value : null}
                    onChange={opt => field.onChange(opt ? opt.value : null)}
                    isOptionDisabled={(option: any) => option.isDisabled}
                    menuPortalTarget={enableMenuPortalTarget ? document.body : undefined}
                    isClearable
                    placeholder={placeholder}
                    isDisabled={disabled}
                    styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            borderWidth: '1.4px', borderStyle: 'solid',
                            borderColor: state.isFocused ? '#EAECF0 !important' : '#EAECF0 !important',
                            borderRadius: '8px', boxShadow: "none",
                            outline: 'none', padding: '0em 0.2em',
                        }),
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                /> :
                <Select
                    id={reactSelectId} ref={field.ref}
                    options={options} value={options.find(opt => opt.value === field.value) || null}
                    defaultValue={defaultCfg && defaultCfg.value ? defaultCfg.value : null}
                    onChange={opt => field.onChange(opt ? opt.value : null)}
                    isOptionDisabled={(option: any) => option.isDisabled}
                    menuPortalTarget={enableMenuPortalTarget ? document.body : undefined}
                    isClearable
                    placeholder={placeholder}
                    isDisabled={disabled}
                    styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            borderWidth: '1.4px', borderStyle: 'solid',
                            borderColor: state.isFocused ? '#EAECF0 !important' : '#EAECF0 !important',
                            borderRadius: '8px', boxShadow: "none",
                            outline: 'none', padding: '0em 0.2em',
                        }),
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                />
            }
            <span className="flex flex-col">
                {!desc ? <></> : <span className="desc">{desc}</span>}
                {
                    !fieldState.error || (errorCfg && errorCfg.hideErrMsg) ? <></> :
                        <span className="err-msg">
                            {getFieldErrMsg(
                                fieldState.error, fieldName, (errorCfg && errorCfg.errLabel ? errorCfg.errLabel : '')
                            )}
                        </span>
                }
            </span>
        </span>
    )
}