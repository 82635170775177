import { CalendarAddIcon, SchedulerIcon } from "components/Svg"
import ListServiceSchedule from "components/pages/service-schedules/ListServiceSchedule"
import { ResourceType } from "resources-config"

export const SERVICE_SCHEDULES_CONFIG: ResourceType = {
    name: 'service-schedules', meta: { labelAsChild: 'Calendar', label: 'Scheduler', icon: <SchedulerIcon />, apiEndpoints: { getList: 'service-schedules/statistics' } },
    actions: {
        list: {
            path: 'service-schedules', page: <ListServiceSchedule />,
            allowedRoles: ['super admin'],
        },
    }
}