export default function TabletWidgets({children}: {children?: any}){
    return (
        <div className='justify-between gap-3 mb-4 flex-ct-y text-th-xs tablet-below:flex-col-reverse'>
            {children}
        </div> 
    )
}

export function TabletWidgetLeft({children}: {children?: any}){
    return (
        <div className="max-w-[18rem] w-full tablet-below:max-w-none flex-ct-y">
            {children}
        </div>
    )
}

export function TabletWidgetRight({children}: {children?: any}){
    return (
        <div className='justify-end gap-3 flex-ct-y shrink-0 tablet-below:w-full'>{children}</div>    
    )
}