import ListServiceCategory from "components/pages/service-categories/ListServiceCategory"
import { ResourceType } from "resources-config"

export const SERVICE_CATEGORIES_CONFIG: ResourceType = {
    name: 'service-categories', meta: {parent: 'services', label: 'Service Categories'},
    actions: {
        list: {
            path: 'service-categories', page: <ListServiceCategory/>,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }        
    }
}

