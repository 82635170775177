import { CalendarIcon, OrderIcon } from "components/Svg"
import CreateServiceOrder from "components/pages/service-orders/CreateServiceOrder"
import ListServiceOrder from "components/pages/service-orders/ListServiceOrder"
import ShowServiceOrder from "components/pages/service-orders/ShowServiceOrder"
import { ResourceType } from "resources-config"

export const SERVICE_ORDER_CONFIG: ResourceType = {
    name: 'service-orders', meta: { labelAsChild: 'List Orders', icon: <OrderIcon />, label: 'Orders' },
    actions: {
        list: {
            path: 'service-orders', page: <ListServiceOrder />,
            allowedRoles: ['super admin'],
        },
        create: {
            path: 'service-orders/create', page: <CreateServiceOrder />,
            allowedRoles: ['super admin'],
        },
        show: {
            path: 'service-orders/:id', page: <ShowServiceOrder />,
            allowedRoles: ['super admin'],
        },
    }
}