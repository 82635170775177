import { ExclamationIcon, FlagIcon, PinCheckIcon, TimeRunIcon, TruckIcon } from "components/Svg";

export function SchedOtwIcon({ className, size, shadow, ...rest }: { className?: string, size?: string, shadow?: boolean, [key: string]: any }) {
    let classes = 'flex-col-ct-all rounded-full bg-[#CD7B2E] px-4' + (className ? ` ${className}` : '')
    classes += (shadow ? ` shadow-[0px_4px_4px_0px_rgba(0,0,0,0.1)_inset]` : '')
    if (size) {
        if (!rest.style) {
            rest.style = {}
        }
        rest.style.width = size
        rest.style.height = size
    }
    return (
        <span className={classes} {...rest}>
            <TruckIcon width={16} height={16} color='white' />
        </span>
    )
}

export function SchedArrivedIcon({ className, size, shadow, ...rest }: { className?: string, size?: string, shadow?: boolean, [key: string]: any }) {
    let classes = 'flex-col-ct-all rounded-full bg-[#3267E3] px-4' + (className ? ` ${className}` : '')
    classes += (shadow ? ` shadow-[0px_4px_4px_0px_rgba(0,0,0,0.1)_inset]` : '')
    if (size) {
        if (!rest.style) {
            rest.style = {}
        }
        rest.style.width = size
        rest.style.height = size
    }
    return (
        <span className={classes} {...rest}>
            <PinCheckIcon width={16} height={16} color='white' />
        </span>
    )
}

export function SchedStartIcon({ className, size, shadow, ...rest }: { className?: string, size?: string, shadow?: boolean, [key: string]: any }) {
    let classes = 'flex-col-ct-all rounded-full bg-[#36A9E1] px-4' + (className ? ` ${className}` : '')
    classes += (shadow ? ` shadow-[0px_4px_4px_0px_rgba(0,0,0,0.1)_inset]` : '')
    if (size) {
        if (!rest.style) {
            rest.style = {}
        }
        rest.style.width = size
        rest.style.height = size
    }
    return (
        <span className={classes} {...rest}>
            <TimeRunIcon width={16} height={16} color='white' />
        </span>
    )
}

export function SchedFinishIcon({ className, size, shadow, ...rest }: { className?: string, size?: string, shadow?: boolean, [key: string]: any }) {
    let classes = 'flex-col-ct-all rounded-full bg-[#00A19A] px-4' + (className ? ` ${className}` : '')
    classes += (shadow ? ` shadow-[0px_4px_4px_0px_rgba(0,0,0,0.1)_inset]` : '')
    if (size) {
        if (!rest.style) {
            rest.style = {}
        }
        rest.style.width = size
        rest.style.height = size
    }
    return (
        <span className={classes} {...rest}>
            <FlagIcon width={16} height={16} color='white' />
        </span>
    )
}

export function SchedClashedIcon({ className, size, shadow, ...rest }: { className?: string, size?: string, shadow?: boolean, [key: string]: any }) {
    let classes = 'flex-col-ct-all rounded-full bg-[#CB3A31] px-4' + (className ? ` ${className}` : '')
    classes += (shadow ? ` shadow-[0px_4px_4px_0px_rgba(0,0,0,0.1)_inset]` : '')
    if (size) {
        if (!rest.style) {
            rest.style = {}
        }
        rest.style.width = size
        rest.style.height = size
    }
    return (
        <span className={classes} {...rest}>
            <ExclamationIcon width={16} height={16} color='white' />
        </span>
    )
}

interface SchedStatusProps {
    schedule: {
        completionDate: string | null
        teamArrivalDate: string | null
        teamOnTheWayDate: string | null
        teamStartDate: string | null
    } | null | MiscObj
    size?: string,
    shadow?: boolean
}

export function SchedStatusIcon({ schedule, size, shadow }: SchedStatusProps) {
    if (!schedule) {
        return <></>
    }
    if (schedule.completionDate) {
        return (
            <SchedFinishIcon size={size || '1.3rem'} shadow={shadow || false} />
        )
    }
    else if (schedule.teamStartDate) {
        return (
            <SchedStartIcon size={size || '1.3rem'} shadow={shadow || false} />
        )
    }
    else if (schedule.teamArrivalDate) {
        return (
            <SchedArrivedIcon size={size || '1.3rem'} shadow={shadow || false} />
        )
    }

    else if (schedule.teamOnTheWayDate) {
        return (
            <SchedOtwIcon size={size || '1.3rem'} shadow={shadow || false} />
        )
    }
    else {
        return <></>
    }
}

interface SchedStatusLegendsProps {
    size?: string
    className?: string
    [key: string]: any
}

export function SchedStatusLegends({ size, className, ...rest }: SchedStatusLegendsProps) {
    let classes = 'flex-ct-y gap-2' + (className ? ` ${className}` : '')

    return (
        <div className={classes} {...rest}>
            <div className="gap-1 flex-ct-y">
                <SchedOtwIcon size={size || '1.3rem'} />
                On the way
            </div>
            <div className="gap-1 flex-ct-y">
                <SchedArrivedIcon size={size || '1.3rem'} />
                Arrived
            </div>
            <div className="gap-1 flex-ct-y">
                <SchedStartIcon size={size || '1.3rem'} />
                Started
            </div>
            <div className="gap-1 flex-ct-y">
                <SchedFinishIcon size={size || '1.3rem'} />
                Finish
            </div>
        </div>
    )
}