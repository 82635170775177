import { LogicalFilter, useTable } from "@refinedev/core"
import Badge from "components/Badge"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import { TextField } from "components/forms"
import PageTitle from "components/PageTitle"
import Pagination from "components/Pagination"
import Pill from "components/Pill"
import { Table, THead, Th, TBody, Td, TAction, TActionItem, } from "components/Table"
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets"
import { differenceInDays, isAfter } from "date-fns"
import { useCallback } from "react"
import { NavLink } from "react-router-dom"
import { QUOTATION_CONFIG } from "resources-config/quotation/quotation-config"
import { formatFullDate } from "utils/date"
import useTableQuery from "utils/useTableQuery"


const ListQuotation = () => {
    const {
        tableQueryResult, pageSize, current, setCurrent, sorters, setSorters,
        filters, setFilters
    } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: QUOTATION_CONFIG.name,
        filters: {
            initial: [{ field: 'status', operator: 'eq', value: 'unconfirmed' }]
        }
    })
    const { control, doFilter } = useTableQuery(filters, setFilters)

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const handleSetFilters = (overdue?: boolean) => {
        setFilters([
            {
                field: 'overdue',
                value: overdue === undefined ? undefined : overdue,
                operator: "eq"
            }
        ])
    }

    return (<>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField
                        control={control} fieldName='search' placeholder="Search by ID or customer's name"
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <div className="flex gap-2">
                        <Button shape={'outline'} onClick={() => handleSetFilters()}>
                            All
                        </Button>
                        <Button shape={'outline'} onClick={() => handleSetFilters(true)}>
                            Overdue
                        </Button>
                        <Button shape={'outline'} onClick={() => handleSetFilters(false)}>
                            Draft
                        </Button>
                    </div>
                    {/* <Button shape='outline' onClick={filterModal.show}>
                        <FilterIcon />Filter
                    </Button> */}
                    <Button href='/quotations/create'>+ New quotation</Button>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th>Status</Th>
                    <Th>Date</Th>
                    <Th>Valid Until</Th>
                    <Th>Number</Th>
                    <Th>Customer</Th>
                    <Th>Total Amount</Th>
                    <Th>Action</Th>
                </THead>
                {/* @ts-ignore */}
                {tableQueryResult && tableQueryResult.data ? (tableQueryResult.data.data.data).map((quotation: any, index: number) =>
                    <TBody>
                        <Td>
                            {differenceInDays(new Date, quotation.validUntil) > 0 ?
                                <Badge color="#BB032A" className="justify-center">
                                    Overdue {differenceInDays(new Date, quotation.validUntil)} Days
                                </Badge> :
                                <Badge color="#535158" className="justify-center">
                                    Draft
                                </Badge>
                            }
                        </Td>
                        <Td>{formatFullDate(quotation.quotationDate, { showDay: false, showTime: false })}</Td>
                        <Td>{formatFullDate(quotation.validUntil, { showDay: false, showTime: false })}</Td>
                        <Td>QID-{quotation.id}</Td>
                        <Td>{quotation.user.name}</Td>
                        <Td>$ {parseFloat(quotation.totalPriceAfterDiscount).toFixed(2)}</Td>
                        <TAction id={`quotation-${quotation.id}`}>
                            <TActionItem>
                                <NavLink to={`/quotations/${quotation.id}`}>View</NavLink>
                            </TActionItem>
                        </TAction>
                    </TBody>
                )
                    : <></>
                }
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.totalPages || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard>
    </>)
}

export default ListQuotation