import { addHours, addMinutes, areIntervalsOverlapping, differenceInMinutes, format, isBefore, isEqual, isWithinInterval, startOfDay } from "date-fns"
import { CSSProperties, useMemo } from "react"
import { PlusScheduleIcon } from "./Svg"
import { SchedClashedIcon, SchedStatusIcon } from "ScheduleStatus"
import { formatFullAddress } from "utils/address"
import { ScheduleTooltip, TextTooltip } from "./tooltips"
import { TechnicianAvatar } from "./avatars"

interface DailySchedulerType {
    children?: any
    date: string
    startHour: number
    endHour: number
    multiplier: number // minutes
    handleOpenAddSchedule: (date: Date, team: MiscObj) => void
}
export default function DailyScheduler({ date, startHour, endHour, multiplier, children, handleOpenAddSchedule }: DailySchedulerType) {
    const subjecSchedules = useMemo(() => {
        if (!children) {
            return []
        }
        if (Array.isArray(children)) {
            let childArr = children.flat()
            return childArr.filter(child => child.props && child.props.tagIdentifier === 'Subject')
        }
        else {
            if (children.props && children.props.tagIdentifier === 'Subject') return [children]
            return []
        }
    }, [children])

    return (
        <div className='daily-scheduler relative overflow-x-auto max-w-[calc(100vw_-_380px)]'>
            <HourLabels startHour={startHour} endHour={endHour} multiplier={multiplier} />
            <div className="all-schedules">
                <div className='daily-scheduler skeleton'>
                    {
                        subjecSchedules.map((subject: MiscObj, idx: number) => {
                            return <div key={idx} className="team-schedule">
                                <div className="team" style={{ background: subject.props.color, opacity: 0.1 }}></div>
                                <Hours team={subject.props.team} handleOpenAddSchedule={handleOpenAddSchedule} date={date} startHour={startHour}
                                    endHour={endHour} multiplier={multiplier}
                                    color={subject.props.color} skeletonOnly
                                />
                            </div>
                        })
                    }
                </div>
                {
                    subjecSchedules.map((subject: MiscObj, idx: number) => {
                        return <div key={idx} className="team-schedule">
                            <div className="team">
                                <button onClick={() => {
                                    if (subject.props.handleClick) {
                                        subject.props.handleClick(date)
                                    }
                                }} type="button"
                                    className="text-th-sm w-full border-2 rounded-full py-2 px-4 font-medium flex items-center justify-between"
                                    style={{ borderColor: subject.props.color }}>
                                    <span>
                                        {subject.props.name}
                                    </span>
                                    <TextTooltip position={idx == subjecSchedules.length - 1 ? 'left' : 'bottom'} className="flex items-center gap-2" text={subject.props.technicians.map((tech: any) => `${tech.role == 1 ? '(Leader) ' : ''}${tech.technician.name}`).join(', ')}>
                                        {subject.props.technicians.map((tech: any) =>
                                            <TechnicianAvatar className="w-5 h-5 scale-[1.2]" imageSrc={tech.technician.profilePicture} color="#1954A1" />
                                        )}
                                    </TextTooltip>
                                </button>
                            </div>
                            <Hours team={subject.props.team} date={date} handleOpenAddSchedule={handleOpenAddSchedule} startHour={startHour}
                                endHour={endHour} multiplier={multiplier}
                                color={'white'} subjectSchedules={subject.props.children}
                            />
                        </div>
                    })
                }
            </div>
        </div>
    )
}

interface SubjectType {
    color: string
    name: string
    handleClick?: (date: string) => void,
    children?: any
    technicians: MiscObj[]
    team: MiscObj
}

export function Subject(props: SubjectType) {
    return <></>
}

Subject.defaultProps = {
    tagIdentifier: 'Subject'
}

interface SubjectScheduleType {
    startDateTime: string
    endDateTime: string
    schedule: any
    children?: any
    isPriority: boolean
    overlapCounts?: number
    jobTypes: string[]
    handleClick?: (date: string) => void
}

export function SubjectSchedule(props: SubjectScheduleType) {
    return <></>
}

SubjectSchedule.defaultProps = {
    tagIdentifier: 'SubjectSchedule'
}

interface HoursType {
    date: string
    startHour: number
    endHour: number
    multiplier: number // minutes
    color: string
    team: MiscObj
    skeletonOnly?: boolean
    subjectSchedules?: any
    handleOpenAddSchedule: (date: Date, team: MiscObj) => void
}

const Hours = ({ date, startHour, endHour, multiplier, color, skeletonOnly, subjectSchedules, handleOpenAddSchedule, team }: HoursType) => {
    const formatSubjectSchedules = useMemo(() => {
        if (!Array.isArray(subjectSchedules)) return subjectSchedules

        const formattedSubjectSchedules = subjectSchedules.map((subSched: any, index: any) => {
            let overlapCount = 0
            const currentStartDate = subSched.props.startDateTime
            const currentEndDate = subSched.props.endDateTime
            const currentScheduleId = subSched.props.schedule.id


            subjectSchedules.forEach(subSched => {

                if (subSched.props.schedule.id != currentScheduleId && subSched.props.schedule.completionDate == null && (
                    areIntervalsOverlapping(
                        {
                            start: currentStartDate,
                            end: currentEndDate
                        },
                        {
                            start: subSched.props.startDateTime,
                            end: subSched.props.endDateTime
                        }
                    )
                )) overlapCount += 1
            })


            return {
                ...subSched,
                props: {
                    ...subSched.props,
                    overlapCounts: overlapCount,
                }
            }
        })

        return formattedSubjectSchedules
    }, [subjectSchedules])

    const hours = useMemo(() => {
        let startDay = date + 'T00:00:00Z'
        let startTime = addHours(startDay, startHour)
        const endTime = addHours(startDay, endHour)
        const hours = []
        while (isBefore(startTime, endTime) || isEqual(startTime, endTime)) {
            hours.push(startTime)
            startTime = addMinutes(startTime, multiplier)
        }
        return hours
    }, [startHour, endHour, multiplier, date])


    return (<>
        {hours.map((hour, idx) => (
            <div key={idx} className="schedule-container" style={skeletonOnly ? { background: color, opacity: 0.1 } : {}}>
                <Schedule
                    currDateTime={hour} color={color} multiplier={multiplier}
                    subjectSchedules={formatSubjectSchedules} dateTimeIdx={idx} hoursLength={hours.length}
                />
                <button type="button" className="add-schedule" onClick={() => handleOpenAddSchedule(hour, team)}>
                    <PlusScheduleIcon width={16} height={16} />
                </button>
            </div>
        ))}
    </>)
}

interface HourLabelsType {
    startHour: number
    endHour: number
    multiplier: number // minutes
}

const HourLabels = ({ startHour, endHour, multiplier }: HourLabelsType) => {
    const hours = useMemo(() => {
        let startDay = startOfDay(new Date())
        let startTime = addHours(startDay, startHour)
        const endTime = addHours(startDay, endHour)
        const hours = []
        while (isBefore(startTime, endTime) || isEqual(startTime, endTime)) {
            hours.push(startTime)
            startTime = addMinutes(startTime, multiplier)
        }
        return hours
    }, [startHour, endHour, multiplier])

    return (<>
        <div className="hour-labels">
            {hours.map((hour, idx) => (
                idx === 0 ?
                    <div key={idx} className="hour-label mt-[3.4rem]">
                        {format(new Date(hour), 'HH:mm')}
                    </div> :
                    <div key={idx} className="hour-label">
                        {format(new Date(hour), 'HH:mm')}
                    </div>
            ))}
        </div>
    </>)
}

interface ScheduleType {
    currDateTime: Date
    color: string
    multiplier: number
    subjectSchedules?: any,
    handleClick?: (date: string) => void,
    dateTimeIdx: number,
    hoursLength: number,
}

export function Schedule({ currDateTime, color, multiplier, subjectSchedules, dateTimeIdx, hoursLength }: ScheduleType) {

    const schedules: null | SubjectScheduleType[] = useMemo(() => {
        if (Array.isArray(subjectSchedules)) {
            const subjectSchedulesArr = subjectSchedules.flat()
            const schedules = subjectSchedulesArr.filter((item: any) => {

                return (
                    item.props && item.props.tagIdentifier === 'SubjectSchedule'
                    && isEqual(new Date(item.props.startDateTime), currDateTime)
                )
            })
            return schedules.map(schedules => schedules.props)
        }
        else if (
            subjectSchedules && subjectSchedules.props && subjectSchedules.props.tagIdentifier === 'SubjectSchedule' &&
            isEqual(new Date(subjectSchedules.props.startDateTime), currDateTime)
        ) {
            return subjectSchedules.props
        }
        return null
    }, [subjectSchedules])

    return <div className="rounded-lg text-body relative w-[90%] h-auto">
        {schedules && schedules.sort(() => -1).map((schedule, index) => {
            let slotOccupied = 0

            const order = schedule.schedule.order
            const address = formatFullAddress(order.buildingNumber, order.street, order.postalCode)

            if (schedule) {
                const minutesDiff = differenceInMinutes(new Date(schedule.endDateTime), currDateTime)
                let _slotOccupied = Math.ceil(minutesDiff / multiplier)
                if ((dateTimeIdx + slotOccupied) > hoursLength) {
                    slotOccupied = hoursLength - dateTimeIdx
                }
                slotOccupied = _slotOccupied
            }


            const overlapCounts = schedule ? (schedule.overlapCounts || 0) : 0
            const isOverlapping = overlapCounts > 0 && schedule.schedule.completionDate == null && schedule.schedule.teamOnTheWayDate == null && schedule.schedule.teamStartDate == null && schedule.schedule.teamArrivalDate == null
            const height = `${slotOccupied * 110}px`
            const width = 100 / (index + 1)
            const marginLeft = 100 - width
            const zIndex = index
            const styles: CSSProperties = {
                height: height,
                width: `${width}%`,
                marginLeft: `${marginLeft}%`,
                zIndex: zIndex
            }

            if (schedule && isEqual(new Date(schedule.startDateTime), currDateTime)) {
                return (
                    <div id={`JID${schedule.schedule.id}`} className={`absolute ${schedule.isPriority ? 'bg-[#FDC521]' : 'bg-white'} rounded-lg border-2 border-neutral group`} style={styles}>
                        <ScheduleTooltip
                            startDate={schedule.startDateTime} endDate={schedule.endDateTime}
                            address={address} schedule={schedule}
                            isOverlapping={isOverlapping}
                            jobTypes={schedule.jobTypes}
                        />
                        {schedule.children}
                        {
                            schedule && schedule.handleClick ?
                                <>
                                    <button className="absolute top-0 left-0 z-10 w-full h-full" type="button" onClick={() => {
                                        if (schedule.handleClick) {
                                            schedule.handleClick(schedule.startDateTime)
                                        }
                                    }}></button>
                                    <div className="top-2 right-2 absolute">
                                        {isOverlapping ?
                                            <SchedClashedIcon size={'1.5rem'} shadow={false} /> :
                                            <SchedStatusIcon schedule={schedule.schedule} size="1.5rem" />
                                        }
                                    </div>
                                </>
                                : <></>
                        }
                    </div>
                )
            }
        })}
    </div>
}