import { useForm } from "react-hook-form"
import PromoCard from "./PromoCard"
import { useEffect, useState } from "react"
import { useModal } from "@refinedev/core"
import Modal, { ModalProps } from "components/popups/Modal"
import promoData from "temp/promoData"
import { TextField } from "components/forms"
import { Button } from "components/buttons"
import { PopUp } from "components/popups"
import { CloseIcon } from "components/Svg"
import { changeTz } from "utils/data-formatter"
import { PromoInterface } from "./types"

type Props = ModalProps & {
    selectedPromo?: PromoInterface
    onPromoInputSuccess: (promo?: PromoInterface) => void
}

const PromoList = (props: Props) => {
    const { selectedPromo, onPromoInputSuccess, ...rest } = props
    const { control, handleSubmit, watch, setError, formState, reset, clearErrors, setValue } = useForm()
    const termsModal = useModal()
    const [termsPromo, setTermsPromo] = useState<PromoInterface>()

    const promo = watch('promoCode')

    useEffect(() => {
        if (rest.visible) {
            if (selectedPromo) {
                setValue('promoCode', selectedPromo.code)
            } else {
                reset()
            }
            clearErrors('promoCode')
        }
    }, [rest.visible])

    const checkVoucher = handleSubmit(({ promoCode }: any) => {
        const promo = promoData.find(promo => promo.code == promoCode)
        if (promo) {
            selectPromo(promo)
        } else {
            setError('promoCode', { message: 'Voucher not found. Voucher code may be incorrect or Voucher is no longer valid' })
        }
    })

    const selectPromo = (promo?: PromoInterface) => {
        onPromoInputSuccess(promo)
        if (promo) {
            // toast.success('Voucher code has been applied')
            rest.close()
        }
    }

    return <>
        <Modal drawerView {...rest} width="30rem">
            <form className="flex flex-col" onSubmit={checkVoucher}>
                <label htmlFor="promo" className="text-th-xs text-light pb-2">Input Voucher Code</label>
                <div className="flex items-start gap-4">
                    <TextField id='promo' control={control} fieldName="promoCode" className="grow" />
                    <Button disabled={!promo || formState.errors.promo != undefined} shape="filled" type="submit" className="text-th-xs py-2.5">
                        Use
                    </Button>
                </div>
                <section className="space-y-4 mt-6 overflow-auto max-h-[60vh]">
                    {promoData.map(promo =>
                        <PromoCard
                            key={promo.code}
                            {...promo}
                            isSelected={selectedPromo != undefined && selectedPromo.code == promo.code}
                            onSelect={(_promo?: PromoInterface) => selectPromo(_promo)}
                            promo={promo}
                            openTerms={() => {
                                setTermsPromo(promo)
                                termsModal.show()
                            }}
                        />
                    )}
                </section>
            </form>
        </Modal>
        {rest.visible &&
            <PopUp withoutPadding visible={termsModal.visible} width="30rem" containerClassName="max-h-[80vh] overflow-auto" drawerView mobileFullScreen hideDrawerBar>
                <section className="p-5 bg-primary rounded-t-md relative pb-28">
                    <div className=" flex items-center justify-between text-white">
                        <h5 className="font-medium">Terms & Conditions</h5>
                        <button type="button" onClick={termsModal.close} className="w-8 h-8">
                            <CloseIcon color="white" />
                        </button>
                    </div>
                    {termsPromo &&
                        <PromoCard
                            {...termsPromo}
                            promo={termsPromo}
                            className="absolute -bottom-1/2 z-10 w-11/12 left-1/2 -translate-x-1/2"
                        />
                    }
                </section>

                {termsPromo ?
                    <section className="relative bg-white rounded-b-md p-5 mt-24 space-y-8 overflow-auto">
                        <div className="flex flex-col gap-1.5">
                            <span className="font-medium">Validity Period</span>
                            <span className="text-th-sm text-body">{changeTz(termsPromo.startDate, 'Asia/Singapore')} - {changeTz(termsPromo.endDate, 'Asia/Singapore')}</span>
                        </div>
                        <div className="flex flex-col gap-1.5">
                            <span className="font-medium">Promotion Description</span>
                            <span className="text-th-sm text-body">{termsPromo.description}</span>
                        </div>
                    </section> : <></>
                }
            </PopUp>
        }
    </>
}

export default PromoList