import { ReactNode, useEffect, useRef, useState } from "react"
import { ChevronDownIcon } from "./Svg"

interface AccordionProps {
    heading: JSX.Element | JSX.Element[] | string
    leading?: ReactNode
    tag?: keyof JSX.IntrinsicElements, // Button tag
    children?: any,
    styling?: 'base' | 'card'
    headerColor?: string
    headerBackground?: string
    className?: string, // Button classes
    [attrName: string]: any // HTML attributes  
    defaultActive?: boolean
    noPadding?: boolean
}
export default function Accordion(props: AccordionProps) {
    const { heading, leading, tag, className, children, styling, defaultActive = false, headerColor, headerBackground, noPadding = false, ...rest } = props
    const [expanded, setExpanded] = useState(defaultActive)
    const contentRef = useRef<HTMLDivElement | null>(null)
    const Tag = tag || 'div'
    let classes = 'accordion' + (className ? ` ${className}` : '')
    classes += `${styling === 'card' ? ' card' : ''}`

    useEffect(() => {
        if (contentRef.current) {
            if (expanded) {
                contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px'
            }
            else {
                contentRef.current.style.maxHeight = '0px'
            }
        }
    }, [expanded, contentRef])

    useEffect(() => {
        if (contentRef.current) {
            const observer = new MutationObserver((mutation) => {
                if (contentRef.current) {
                    const currentMaxHeight = contentRef.current.style.maxHeight
                    const scrollHeight = contentRef.current.scrollHeight + 'px'
                    if (currentMaxHeight !== '0px') {
                        if (currentMaxHeight !== scrollHeight) {
                            contentRef.current.style.maxHeight = scrollHeight
                        }
                    }
                }
            })
            observer.observe(contentRef.current, {
                childList: true, subtree: true, attributes: true
            });

        }
    }, [contentRef])
    return (
        <Tag className={classes} {...rest}>
            <div className="header" style={{ background: headerBackground, color: headerColor }}>
                <h6 className="heading">{heading}</h6>
                <div className="flex items-center gap-2">
                    <button className="toggle-btn" type="button" onClick={() => { setExpanded(state => !state) }}>
                        <span className="sr-only">Close</span>
                    </button>
                    {leading}
                    <ChevronDownIcon className='w-5' color={headerColor ? headerColor : "#757575"} />
                </div>
            </div>
            <div className="content" ref={contentRef}>
                <div className={`body ${noPadding ? 'no-padding' : ''}`}>{children}</div>
            </div>
        </Tag>
    )
}