import { baseApi } from "providers/customDataProvider"



export const getServices = async (keyword = "") => {
    const res = await baseApi.get(`/admin/services?pageSize=100${keyword ? `&search=${keyword}` : ''}`)
    const services = res.data.list
    return services
}

export const getActiveServices = async () => {
    const res = await baseApi.get('/admin/services?pageSize=100')
    const services = res.data.list.filter((service: any) => service.isActive)
    return services
}

export const getServiceDetails = async (id: string) => {
    const res = await baseApi.get(`/admin/services/${id}`)
    const serviceDetail = res.data
    return serviceDetail
}

export const deleteServiceGallery = async (serviceId: string, galleryId: string) => {
    const res = await baseApi.delete(`/admin/services/${serviceId}/gallery/${galleryId}`)
    return res.data.message == 'Success'
}

export const patchServiceOrdering = async (serviceId: string, order: number) => {
    const res = await baseApi.patch(`/admin/services/${serviceId}/order`, {
        order: order
    })
    return res.data.message == 'Success'
}