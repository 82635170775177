import { Dispatch, SetStateAction, useState } from 'react';
import { PlaceInterface } from 'utils/map';
import { CoordinateType } from './types';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

type Props = {
    searchFunction: (lat: number, lng: number) => void
    coordinate: CoordinateType
    setCoordinate: Dispatch<SetStateAction<CoordinateType>>
    place: PlaceInterface
    containerClassName?: string
}

const MapLocationPicker = (props: Props) => {
    const { searchFunction, coordinate, setCoordinate, containerClassName } = props
    const [map, setMap] = useState<google.maps.Map>()
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''
    })

    const onMarkerMove = (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
            searchFunction(e.latLng.lat(), e.latLng.lng())
            setCoordinate({
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            })
        }
    }

    return isLoaded ? (
        <GoogleMap
            onLoad={(map) => {
                // const swSgBound = new window.google.maps.LatLng(1.1304753, 103.6920359)
                // const neSgBound = new window.google.maps.LatLng(1.4504753, 104.0120359)
                // map.fitBounds(new window.google.maps.LatLngBounds(swSgBound, neSgBound))
                setMap(map)
            }}
            mapContainerClassName={`w-full ${containerClassName}`}
            center={coordinate}
            zoom={18}
            options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                maxZoom: 18,
                fullscreenControl: false,
            }}
        >
            <Marker onDragEnd={onMarkerMove} position={coordinate} draggable />
        </GoogleMap>
    ) : <></>
}

export default MapLocationPicker

