import { claimInvoiceHistory, deleteInvoiceHistory } from "api/invoice";
import { Button } from "components/buttons";
import InvoicePreview from "components/documents/invoices/InvoicePreview";
import { ManagePaymentIcon, ChevronDownIcon } from "components/Svg";
import { useState } from "react";
import { toast } from "sonner";
import { formatFullDate } from "utils/date";
import { scrollToTargetAdjusted } from "utils/scheduler";

type Props = {
    invoice: MiscObj
    order?: MiscObj
    refetchOrder: () => void
    openRecord: () => void
}

const InvoiceCard = (props: Props) => {
    const { invoice, order, refetchOrder, openRecord } = props
    const [isShown, setIsShown] = useState(false)
    const itemIds = invoice.items.map((item: any) => item.id)
    // const invoiceItems = invoice.isFirstInvoice ? firstInvoiceItems : state.order!.items.filter((item: any) => itemIds.includes(item.id))
    const invoiceItems = order ? order.items.filter((item: any) => itemIds.includes(item.id)) : []

    const handleRemovePayment = async (invoiceId: string, invoiceHistoryId: string) => {
        try {
            if (await deleteInvoiceHistory(invoiceId, invoiceHistoryId)) {
                toast.success('Successfully removed payment')
            } else {
                toast.error('Failed to remove payment.')
            }
            refetchOrder()
        } catch (err: any) {
            toast.error(err.message)
        }
    }

    const handleClaimPayment = async (invoiceId: string, invoiceHistoryId: string) => {
        try {
            if (await claimInvoiceHistory(invoiceId, invoiceHistoryId)) {
                toast.success('Successfully claimed payment')
            } else {
                toast.error('Failed to claim payment.')
            }
            refetchOrder()
        } catch (err: any) {
            toast.error(err.message)
        }
    }

    return <div className="bg-white relative z-10 border rounded-lg p-4" id={`invoice-${invoice.id}`}>
        <div className="flex items-start gap-3 border-b pb-4">
            <div>
                <ManagePaymentIcon />
            </div>
            <div className="grow flex flex-col gap-3">
                <div className="flex items-center justify-between">
                    <h3 className="text-th-lg text-light">Manage payments #{invoice.id}</h3>
                    <div className="flex items-center gap-2">
                        <Button shape="outline" onClick={() => {setIsShown(state => !state); setTimeout(() => scrollToTargetAdjusted(`invoiceEle${invoice.id}`), 100)}}>
                            <span>See Invoice</span>
                            <ChevronDownIcon className={invoice.isShown ? 'rotate-180' : ''} width={24} height={24} />
                        </Button>
                        {invoice.paymentGtwRes != null && invoice.paymentGtwRes.url &&
                            <Button onClick={() => { navigator.clipboard.writeText(invoice.paymentGtwRes.url); toast.success('Payment link successfully copied !'); }}>
                                Copy Payment Link
                            </Button>
                        }

                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                        <span className="font-medium">Amount due: <span className="font-normal">$ {invoice.amountDue.toFixed(2)}</span> - <button type='button' onClick={openRecord} className="text-primary">Record a payment</button> <span className="font-normal">manually</span></span>
                        <span><span className="font-medium">Status:</span> {invoice.amountDue == 0 ? 'Paid fully' : invoice.amountDue != invoice.amount ? 'Partially Paid' : 'Not Paid'}</span>
                    </div>
                    <span>
                        <span className="font-medium">{invoice.paymentMethod} Fully Paid Date: </span>{formatFullDate(invoice.paidAt)}
                    </span>
                </div>
            </div>
        </div>
        <div className="border-b py-4 px-[2.6rem] flex flex-col">
            {/* <span className="text-light">Recommended to Insulate drainage pipe 16mm with silicon application for 1 unit wall mounted</span> */}
            <div className="flex flex-col gap-1.5">
                {invoice && invoice.invoiceHistories && invoice.invoiceHistories.map((invHis: any) => {
                    return <div className="flex flex-col text-th-sm text-light items-start">
                        <span>
                            <span className="font-medium text-black">{invHis.isManual ? 'Manual' : 'Hitpay'} {invHis.type == 1 ? 'Payment' : invHis.type == 2 ? 'Refund' : 'Compensation'} : </span>
                            {formatFullDate(invHis.paidAt)} - A {invHis.type == 1 ? 'payment' : invHis.type == 2 ? 'refund' : 'compensation'} for
                            <span className="font-medium text-black"> $ {invHis.amount.toFixed(2)} </span>
                            was made using {invHis.paymentMethod} by
                            <span className="font-medium text-black"> {invHis.createdBy}</span>
                        </span>
                        {invHis.notes &&
                            <span>Notes : {invHis.notes}</span>
                        }
                        <div className="flex items-center gap-4">
                            {invHis.cancelledBy == null && invHis.type != 2 && invHis.isManual && invHis.paymentMethod != 'onsite' &&
                                <Button shape="raw" type="button" confirm onClick={() => handleRemovePayment(invoice.id, invHis.id)} className="text-primary font-medium">Remove {invHis.type == 1 ? 'Payment' : 'Compensation'}</Button>
                            }
                            {invHis.paymentMethod == 'onsite' && !invHis.claimedAt &&
                                <Button shape="raw" type="button" confirm onClick={() => handleClaimPayment(invoice.id, invHis.id)} className="text-primary font-medium">Claim Payment</Button>
                            }
                        </div>
                        {invHis.cancelledBy != null &&
                            <span>{invHis.cancelledBy.name} removed this {invHis.type == 1 ? 'payment' : invHis.type == 2 ? 'refund' : 'compensation'} on {formatFullDate(invHis.updatedAt)}</span>
                        }
                        {invHis.paymentMethod == 'onsite' && invHis.claimedAt &&
                            <span>{invHis.claimedBy.name} claimed this payment on {formatFullDate(invHis.claimedAt)}</span>
                        }
                    </div>
                })}
            </div>
        </div>
        {isShown &&
            <InvoicePreview order={order} items={invoiceItems} invoice={invoice} user={order && order.user} />
        }
    </div>
}

export default InvoiceCard