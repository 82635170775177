import { useModal } from "@refinedev/core"
import { getActiveServices } from "api/service"
import { Button } from "components/buttons"
import { CreateEditCard } from "components/cards"
import { FormsSection, FormsGrid, TextField, SelectField, AsyncSelectField, TextAreaField, CounterField, CountryPhoneInput } from "components/forms"
import AddressLocation from "components/location-picker/AddressLocation"
import FindAddressPopup from "components/location-picker/FindAddressPopup"
import PinpointPopup from "components/location-picker/PinpointPopup"
import { CustomerDetailIcon, ServiceListIcon, PlusScheduleIcon, TrashIcon } from "components/Svg"
import { AutoLink, FieldInfo } from "components/texts"
import { flatten } from "lodash"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useMemo, useState } from "react"
import { UseFieldArrayReturn } from "react-hook-form"
import { IoAddCircle } from "react-icons/io5"
import { toast } from "sonner"
import useSWR from "swr"
import { formatBuildingAddress, formatCountry, formatAddressAny } from "utils/address"
import { calculateGST } from "utils/discounts"
import { PlaceInterface } from "utils/map"
import { universalPhoneRegex } from "utils/regex"

type Props = {
    control: FormHookType['control']
    formId: string
    onFinishHandler: () => void
    setValue: FormHookType['setValue']
    watch: FormHookType['watch']
    resetField: FormHookType['resetField']
    cardHeading: string
    isFetching: boolean
    isEdit?: boolean
    discountFieldArr: UseFieldArrayReturn<any, "discounts", "id">
    itemFieldArr: UseFieldArrayReturn<any, "quotationItemCarts", "id">
}

const FormFieldQuotation = (props: Props) => {
    const { control, setValue, watch, resetField, formId, onFinishHandler, cardHeading, isFetching, isEdit = false, discountFieldArr, itemFieldArr } = props

    const { data: services = [] } = useSWR('active-services', getActiveServices)
    const [selectedPlace, setSelectedPlace] = useState<PlaceInterface>()
    const [addressList, setAddressList] = useState<any[]>([])
    const findAddressModal = useModal()
    const pinpointModal = useModal()

    const discounts = watch('discounts')
    const items = watch('quotationItemCarts')
    const userWatch = watch('userId')
    const itemSelect = watch('itemSelect')

    const { grandTotal, subTotal } = useMemo(() => {
        if (!items) return { grandTotal: 0, subTotal: 0 }
        const subTotal = items.map((item: any) => item.price ? parseFloat(item.price) : 0).reduce((acc: any, current: any) => acc + current, 0)
        const grandTotal = discounts ? discounts.map((disc: any) => +disc.total * -1).reduce((acc: number, current: number) => acc + current, subTotal) : subTotal
        return { grandTotal, subTotal }
    }, [itemFieldArr, discountFieldArr])

    const loadQuotationItems = async (inputValue: string) => {
        const res = await baseApi.get(`/admin/quotations/items?itemName=${inputValue}`)
        return res.data.data.map((item: any) => ({ value: item.id, label: item.name }))
    }

    const handleSetAddress = (place: PlaceInterface) => {
        setValue('latitude', place.lat)
        setValue('longitude', place.lng)
        pinpointModal.close()
        findAddressModal.close()
        setSelectedPlace(place)
        setValue('street', place.street)
        setValue('postalCode', place.postalCode)
        setValue('buildingNumber', place.buildingNumber)
    }

    const handleAddItem = async (id: number, name?: string) => {
        if (id == -1) {
            itemFieldArr.append({ quotationItemId: -1, name: name, description: '', quantity: 1, pricePerUnit: 0 })
        } else {
            try {
                const res = await baseApi.get(`/admin/quotations/items/${id}`)
                itemFieldArr.append({ quotationItemId: +res.data.id, name: res.data.name, description: res.data.description, quantity: 1, pricePerUnit: res.data.pricePerUnit })
            } catch (err) {
                console.log(err)
                toast.error('Failed adding item')
            }
        }
    }

    const handleAddDiscount = () => {
        discountFieldArr.append({ description: '', amount: 0, type: 1, total: 0 })
    }

    useEffect(() => {
        if (!userWatch || userWatch == -1) return
        baseApi.get(`/admin/users/${userWatch}`).then(response => {
            setValue('name', response.data.name)
            setValue('phoneNumber', response.data.phoneNumber.replace('+', ''))
            setValue('email', response.data.email)
            setAddressList(response.data.addresses ? response.data.addresses : [])
        })
    }, [userWatch])

    // useEffect(() => {
    //     if (quotationDateWatch)
    //         setValue('validUntil', format(addDays(new Date(quotationDateWatch), 30), 'yyyy-MM-dd'))
    // }, [quotationDateWatch])

    return <form onSubmit={onFinishHandler} id={formId}>
        <CreateEditCard
            cardHeading={cardHeading}
            actions={
                <div className="flex items-end gap-6">
                    <div className="flex items-center gap-2">
                        <label htmlFor="quotationDate" className="font-medium">Quotation Date <span className="text-danger">*</span></label>
                        <TextField type="date" required control={control} fieldName="quotationDate" />
                    </div>
                    <div className="flex items-center gap-2">
                        <label htmlFor="validUntil" className="font-medium">Valid Until <span className="text-danger">*</span></label>
                        <TextField type="date" required control={control} fieldName="validUntil" />
                    </div>
                    <Button type="button" form={formId} confirm disabled={itemFieldArr.fields.length == 0} isLoading={isFetching}>Save and Continue</Button>
                </div>
            }
        >

            <div className="flex flex-col gap-4 mt-4">
                <FormsSection Icon={<CustomerDetailIcon />} title="Customer Details" className="space-y-4">
                    <FormsGrid col={4}>
                        <UserField setValue={setValue} control={control} isEdit={isEdit} />
                        <CountryPhoneInput control={control} fieldName="phoneNumber" label={'Contact Number'} required disabled={userWatch != -1} />
                        <TextField control={control} disabled={userWatch != -1} label={'Customer Name'} required fieldName="name" />
                        <TextField control={control} disabled={userWatch != -1} label={'Customer Email'} fieldName="email" regex="email" />
                    </FormsGrid>
                    {(userWatch == -1 || isEdit) ?
                        <>
                            {!isEdit &&
                                <AddressLocation
                                    className="w-full"
                                    onClick={findAddressModal.show}
                                    name={selectedPlace ? formatBuildingAddress(selectedPlace.buildingNumber, selectedPlace.street) : 'Not Selected'}
                                    address={selectedPlace ? formatCountry(selectedPlace.country, selectedPlace.postalCode) : 'Not Selected'}
                                />
                            }
                            <FormsGrid FormsGrid col={4}>
                                <TextField control={control} label={'Postal Code'} required fieldName="postalCode" />
                                <TextField control={control} label={'Block Number'} required fieldName="buildingNumber" />
                                <TextField control={control} label={'Street Name'} required fieldName="street" />
                                <TextField control={control} label={'Building Name'} fieldName="buildingName" />
                                <TextField control={control} label={'Floor Number'} fieldName="floorNumber" />
                                <TextField control={control} label={'Unit Number'} fieldName="unitNumber" />
                                <SelectField
                                    options={flatten(UNIT_TYPES.map(categories => categories.types.map(type => ({ value: type, label: `${categories.category} - ${type}` }))))}
                                    control={control} label={'Address Type'} required fieldName="unitType" />
                                {!isEdit ?
                                    <TextField control={control} label={'Address Label'} required fieldName="addressLabel" /> :
                                    <TextField control={control} label={'Notes'} fieldName="notes" />
                                }
                            </FormsGrid>
                        </> :
                        <>
                            {(userWatch) &&
                                <div className="space-y-1.5">
                                    <SelectField
                                        fieldName="addressId"
                                        label={'Address'}
                                        required
                                        placeholder={'Select customer address'}
                                        options={addressList.map(address => ({ value: parseInt(address.id), label: `${formatAddressAny(address)}` }))}
                                        control={control}
                                    />
                                    <div className="flex justify-end">
                                        <AutoLink href={`/users/edit/${userWatch}`} className="text-primary flex justify-end items-center gap-2">
                                            <IoAddCircle />
                                            <span>
                                                Add new Address
                                            </span>
                                        </AutoLink>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </FormsSection>
                <FormsSection Icon={<ServiceListIcon />} title="Service List">
                    <div className="space-y-4">
                        {
                            itemFieldArr.fields ? itemFieldArr.fields.map((item: MiscObj, idx: number) => (
                                <ItemFields
                                    setValue={setValue}
                                    resetField={resetField}
                                    services={services}
                                    key={item.id}
                                    item={item}
                                    index={idx}
                                    control={control}
                                    fieldArr={itemFieldArr}
                                    watch={watch}
                                />
                            )) : <></>
                        }
                        <div className="border-b pb-4 flex items-end gap-4">
                            <AsyncSelectField
                                control={control}
                                loadOptions={loadQuotationItems}
                                options={services.map((service: any) => ({ value: +service.id, label: service.name }))}
                                fieldName={`itemSelect`}
                                className="w-full"
                                defaultCfg={{ value: null, setValue: setValue }}
                                label='New Item'
                                onCreateOption={(inputValue: string) => { handleAddItem(-1, inputValue) }}
                                placeholder='Please type item name or select an item'
                            />
                            <Button onClick={() => handleAddItem(+itemSelect)} disabled={!itemSelect} shape="outline" className="text-theme-xxs whitespace-nowrap">
                                <PlusScheduleIcon color="#1954A1" /> Add Item
                            </Button>
                        </div>
                        <div className="flex flex-col items-end gap-4">
                            <FieldInfo horizontal labelWeight="normal" label="Subtotal" fieldClassName="bg-gray-100" value={`$ ${+subTotal.toFixed(2)}`} />
                            <FieldInfo horizontal labelWeight="normal" label="Include GST" fieldClassName="bg-gray-100" value={`$ ${calculateGST(subTotal).toFixed(2)}`} />
                            {discounts && discounts.length == 0 &&
                                <button type="button" onClick={handleAddDiscount} className="text-primary flex justify-end items-center gap-2">
                                    <IoAddCircle />
                                    <span>
                                        Add Discount
                                    </span>
                                </button>
                            }
                            {discountFieldArr.fields ? discountFieldArr.fields.map((item, index) =>
                                <DiscountFields setValue={setValue}
                                    resetField={resetField}
                                    services={services}
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    control={control}
                                    fieldArr={discountFieldArr}
                                    watch={watch}
                                    subTotal={subTotal || 0}
                                />
                            ) : <></>}
                            <FieldInfo horizontal label="Grand Total" fieldClassName="bg-gray-100" value={`$ ${grandTotal ? grandTotal.toFixed(2) : ''}`} />
                        </div>
                    </div>
                </FormsSection>
                {!isEdit &&
                    <>
                        <FindAddressPopup visible={findAddressModal.visible} openPinPoint={pinpointModal.show} handleSetAddress={handleSetAddress} close={findAddressModal.close} />
                        <PinpointPopup visible={pinpointModal.visible} close={pinpointModal.close} openFindAddress={findAddressModal.show} handleSetAddress={handleSetAddress} />
                    </>
                }
            </div>
        </CreateEditCard>
    </form>


}

function UserField({ control, setValue, isEdit }: { control: FormHookType['control'], setValue: FormHookType['setValue'], isEdit?: boolean }) {
    const getUser = async (search: string) => {
        const { data } = await baseApi.get(`/admin/users?search=${search}&activeStatus=active`)
        return data.list.map((user: MiscObj) => ({
            value: +user.id, label: `${user.name} - ${user.phoneNumber}`
        }))
    }

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getUser(inputValue))
    })

    const handleOnCreate = (inputValue: string) => {
        // setValue('user', -1)
        if (universalPhoneRegex.test(inputValue)) {
            setValue('name', null)
            setValue('phoneNumber', inputValue.replace('+', ''))

        }
        else {
            setValue('name', inputValue)
            setValue('phoneNumber', '65')
        }
        setValue('email', null)
        setValue('userId', -1)
    }

    return (
        <AsyncSelectField control={control}
            fieldName={`userId`} required
            onCreateOption={isEdit ? undefined : handleOnCreate}
            label='Search Customer' placeholder="Search by name or phone number" loadOptions={promiseOptions}
        />
    )
}

type ItemFieldProps = {
    index: number,
    control: FormHookType['control'],
    fieldArr: FormHookType['fieldArr'],
    watch: FormHookType['watch'],
    services: any[]
    resetField: FormHookType['resetField']
    setValue: FormHookType['setValue']
    item: MiscObj
}

function ItemFields({ index, control, fieldArr, watch, services, resetField, setValue, item }: ItemFieldProps) {
    const name = `quotationItemCarts.${index}.`
    const quantityWatch = watch(`${name}quantity`)
    const pricePerUnitWatch = watch(`${name}pricePerUnit`)
    const priceWatch = watch(`${name}price`)

    useEffect(() => {
        setValue(`${name}price`, (quantityWatch * pricePerUnitWatch).toFixed(2))
    }, [quantityWatch, pricePerUnitWatch])

    return (
        <div className="space-y-4 border-b pt-4 pb-8">
            <div className="grid grid-cols-6 place-items-start gap-3">
                <div className="break-all">
                    {item.name}
                </div>
                <div className="col-span-5  w-full">
                    <div className="flex flex-col gap-4">
                        <div className="grid grid-cols-5 gap-4 ">
                            <TextAreaField
                                control={control}
                                fieldName={`${name}description`}
                                placeholder='Enter item description'
                                className="col-span-2"
                                rows={8}
                            />
                            <CounterField
                                control={control}
                                fieldName={`${name}quantity`}
                                required
                                placeholder="Select quantity"
                            />
                            <TextField
                                control={control}
                                fieldName={`${name}pricePerUnit`}
                                placeholder='Price'
                                defaultValue={0}
                            />
                            <div className="flex items-center gap-2 place-self-start">
                                <FieldInfo
                                    fieldClassName="bg-gray-100"
                                    value={`$ ${priceWatch ? parseFloat(priceWatch).toFixed(2) : '0.00'}`}
                                />
                                <button type="button" onClick={() => fieldArr.remove(index)}>
                                    <TrashIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DiscountFields({ index, control, fieldArr, item, watch, setValue, subTotal }: ItemFieldProps & { subTotal: number }) {
    const name = `discounts.${index}.`
    const discountWatch = watch(`${name}total`)
    const typeWatch = watch(`${name}type`)
    const amountWatch = watch(`${name}amount`)

    useEffect(() => {
        let total = 0
        if (typeWatch == 1) {
            total = amountWatch || 0
        } else if (typeWatch == 2) {
            total = amountWatch ? amountWatch * subTotal / 100 : 0
        } else {
            total = 0
        }
        setValue(`${name}total`, total)
    }, [typeWatch, amountWatch])

    return <div className="w-full grid grid-cols-6 items-center gap-4">
        <div>
            Discount
        </div>
        <div className="col-span-4 grid grid-cols-4 gap-4">
            <TextField
                control={control}
                fieldName={`${name}description`}
                placeholder="Enter a description (optional)"
                className="col-span-2"
            />
            <TextField
                control={control}
                fieldName={`${name}amount`}
                placeholder="Enter amount"
            />
            <SelectField
                control={control}
                fieldName={`${name}type`}
                placeholder="Discount type"
                options={[{ value: 1, label: '$' }, { value: 2, label: '%' }]}
            />
        </div>
        <div className="flex items-center gap-2 place-self-start">
            <FieldInfo
                fieldClassName="bg-gray-100"
                value={`-$ ${discountWatch ? parseFloat(discountWatch || '0').toFixed(2) : '0.00'}`}
            />
            <button type="button" onClick={() => fieldArr.remove(index)}>
                <TrashIcon />
            </button>
        </div>
    </div>
}

const UNIT_TYPES = [
    {
        category: 'Residential',
        types: ['HDB', 'Condominium', 'Landed']
    },
    {
        category: 'Commercial',
        types: ['Office', 'Shop', 'Factory', 'Warehouse', 'Business Park']
    },
]

const PAYMENT_METHODS_OPTIONS = [
    {
        label: 'Online Payment - 15 Mins Expiry',
        value: 1
    },
    {
        label: 'Online Payment - No Expiry',
        value: 2
    },
    {
        label: 'Pay On Site',
        value: 3
    },
]

export default FormFieldQuotation