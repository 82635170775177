import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useOne } from '@refinedev/core';
import { Button } from 'components/buttons';
import { BaseCard } from 'components/cards';
import QuotationDocument from 'components/pdfs/QuotationDocument';
import { InfoCircleIcon, CustomerDetailIcon, ServiceListIcon } from 'components/Svg';
import { LabeledInfo } from 'components/texts';
import { TextTooltip } from 'components/tooltips';
import { baseApi } from 'providers/customDataProvider';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { QUOTATION_CONFIG } from 'resources-config/quotation/quotation-config';
import { toast } from 'sonner';
import { formatAddressAny } from 'utils/address';
import { formatFullDate } from 'utils/date';


const ShowQuotation = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { data, refetch: refetchQuotation } = useOne({
        resource: QUOTATION_CONFIG.name,
        id: params.id
    })
    const quotation = data && data.data.quotation

    const handleConvertToInvoice = async () => {
        try {
            await baseApi.post(`/admin/quotations/${params.id}/confirm`)
            navigate(`/invoices/${params.id}`)
            toast.success('Quotation successfully converted to invoice')
        } catch (err: any) {
            toast.error('Failed converting quotation to invoice.', err.message)
        }
    }


    return <BaseCard>
        {quotation &&
            <>
                <header className="flex items-center justify-between border-b pb-4">
                    <h1 className="text-th-2xl font-bold">QUOTATION #QID-{quotation.id}</h1>
                    <div className="flex items-center gap-2">

                    </div>
                </header>
                <section className="grid grid-cols-5 mt-4 gap-4">
                    <LabeledInfo label="Customer" >
                        <Link target='_blank' to={`/users/${quotation.user.id}`} className="flex items-center gap-2">
                            <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{quotation.user.name}</span>
                            <TextTooltip text={quotation.user.name}>
                                <InfoCircleIcon width={16} height={16} />
                            </TextTooltip>
                        </Link>

                    </LabeledInfo>
                    <LabeledInfo label="Address" className="flex items-center gap-2">
                        <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{formatAddressAny(quotation)}</span>
                        <TextTooltip text={formatAddressAny(quotation)}>
                            <InfoCircleIcon width={16} height={16} />
                        </TextTooltip>
                    </LabeledInfo>
                    <LabeledInfo label="Total Amount">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">$ {parseFloat(quotation.totalPriceAfterDiscount).toFixed(2)}</span>
                    </LabeledInfo>
                    <LabeledInfo label="Quotation Date">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">{formatFullDate(quotation.quotationDate, { showTime: false, showDay: false })}</span>
                    </LabeledInfo>
                    <LabeledInfo label="Valid Until">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">{formatFullDate(quotation.validUntil, { showTime: false, showDay: false })}</span>
                    </LabeledInfo>
                </section>
                <section className="relative mt-8 space-y-6">
                    <div className="border-l-[3px] absolute h-full top-0 left-6" />
                    <div className="bg-white relative z-10">
                        <div className="border rounded-lg p-4 flex items-center justify-between">
                            <div className='flex flex-col gap-1'>
                                <div className="flex items-center gap-3">
                                    <CustomerDetailIcon />
                                    <h3 className="text-th-lg text-light">Create Quotation</h3>
                                </div>
                                <span><span className="font-medium">Created at:</span> {formatFullDate(quotation.createdAt)}</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <Button shape='outline' href={`/quotations/${quotation.id}/edit`}>
                                    Edit Quotation
                                </Button>
                                <Button confirm onClick={handleConvertToInvoice}>
                                    Convert to Invoice
                                </Button>
                            </div>

                        </div>
                    </div>

                    <div className="bg-white relative z-10 border rounded-lg p-4">
                        <div className='flex items-center justify-between'>
                            <div className="flex items-start gap-3">
                                <div>
                                    <ServiceListIcon />
                                </div>
                                <div className="grow flex flex-col gap-3">
                                    <div className="flex items-center justify-between">
                                        <h3 className="text-th-lg text-light">Item Lists</h3>
                                    </div>
                                    <span className="font-medium">There are {quotation.quotationItemCarts.length} items</span>
                                </div>
                            </div>
                            {quotation &&
                                <PDFDownloadLink download={false} target='_blank' document={<QuotationDocument quotation={quotation} />}>
                                    <Button>
                                        Download Quotation
                                    </Button>
                                </PDFDownloadLink>
                            }

                        </div>
                        {quotation &&
                            <PDFViewer className='w-full aspect-[1/1.1] mt-8' children={<QuotationDocument quotation={quotation} />} />
                        }
                    </div>
                </section>
            </>
        }
    </BaseCard>
}

export default ShowQuotation