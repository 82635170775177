import { getUsers } from "api/users";
import { FormsGrid, TextField, TextAreaField, Checkbox, MultiSelectField, AsyncMultiSelectField } from "components/forms";
import RadioField from "components/forms/RadioField";
import { CopyIcon } from "components/Svg";
import { flatten } from "lodash";
import { baseApi } from "providers/customDataProvider";
import { useMemo } from "react";
import { FieldArray } from "react-hook-form";
import { toast } from "sonner";
import useSWR from "swr";
import { getAvailableMonths } from "utils/date";

const PROPERTY_TYPES = [
    {
        category: 'Residential',
        types: ['HDB', 'Condominium', 'Landed']
    },
    {
        category: 'Commercial',
        types: ['Office', 'Shop', 'Factory', 'Warehouse', 'Business Park']
    },
]


type Props = {
    formId: string
    watch: FormHookType['watch']
    control: FormHookType['control']
    setValue: FormHookType['setValue']
    onSubmit: any
    criteriaFieldArr: FieldArray
    defaultSelectValues?: MiscObj[]
    isEdit?: boolean
}

const FormFieldPromo = (props: Props) => {
    const { formId, control, watch, onSubmit, criteriaFieldArr, defaultSelectValues = [], setValue, isEdit = false } = props
    const { type, code, criteria } = watch()
    const { data: servicesList = [] } = useSWR('services-details', async () => {
        const res = await baseApi.get('/admin/service-promos/find-frequencies')
        return res.data
    })

    const includedUsersWatch = watch('includedUsers')
    const excludedUsersWatch = watch('excludedUsers')

    const getUsersOptions = async (inputValue: string) => {
        const users = await getUsers(inputValue)
        return users.map((user: any) => ({ value: +user.id, label: user.name }))
    }

    const servicesOptions: { value: any, label: string }[] = useMemo(() => {
        return servicesList.filter((service: any) => service.category).map((service: any) => ({
            value: `${service.service.id}-${service.frequency > 1 ? 'contract' : 'non'}-${service.category.id}`,
            label: `${service.service.name} - ${service.category.name}${service.frequency > 1 ? ' (Contract)' : ''}`
        }))
    }, [servicesList])

    const defaults = useMemo(() => {
        let birthdayMonthsDefaults: any = defaultSelectValues.find(def => def.name == 'birthdayMonths')
        birthdayMonthsDefaults = birthdayMonthsDefaults ? birthdayMonthsDefaults.defaultValues : null

        let excludedServicesDefaults: any = defaultSelectValues.find(def => def.name == 'excludedServices')
        excludedServicesDefaults = excludedServicesDefaults ? excludedServicesDefaults.defaultValues : null

        let excludedUsersDefaults: any = defaultSelectValues.find(def => def.name == 'excludedUsers')
        excludedUsersDefaults = excludedUsersDefaults ? excludedUsersDefaults.defaultValues : null

        let includedUsersDefaults: any = defaultSelectValues.find(def => def.name == 'includedUsers')
        includedUsersDefaults = includedUsersDefaults ? includedUsersDefaults.defaultValues : null

        let gendersDefaults: any = defaultSelectValues.find(def => def.name == 'genders')
        gendersDefaults = gendersDefaults ? gendersDefaults.defaultValues : null

        let propertyDefaults: any = defaultSelectValues.find(def => def.name == 'propertyTypes')
        propertyDefaults = propertyDefaults ? propertyDefaults.defaultValues : null

        return { birthdayMonthsDefaults, excludedServicesDefaults, excludedUsersDefaults, includedUsersDefaults, gendersDefaults, propertyDefaults }
    }, [defaultSelectValues])

    return <form id={formId} className="max-w-[900px] space-y-6" onSubmit={onSubmit}>
        <FormsGrid col={2} className=" items-center gap-6">
            <span className="font-medium">Promo Code Type</span>
            <div className="flex items-center gap-4 ">
                <RadioField required fieldName="type" control={control} defaultValue={2} options={[{ value: 2, label: 'Percentage Discount' }, { value: 1, label: 'Amount Percentage' }]} />
            </div>
            <span className="font-medium">Percentage or Amount</span>
            <div className="relative">
                <TextField isNumeric type='number' required control={control} fieldName="amount" />
                <span className="font-medium absolute top-1/2 -translate-y-1/2 -right-10">{type == 2 ? '%' : 'SGD'}</span>
            </div>
            <span className="font-medium">
                Promo Title
            </span>
            <div className="relative">
                <TextField required control={control} fieldName="title" />
            </div>
            <span className="font-medium">
                Promo Description
            </span>
            <TextAreaField control={control} fieldName="description" />
            <span className="font-medium">Promo Code Name</span>
            <div className="relative">
                <TextField required control={control} fieldName="code" />
                {code &&
                    <button type="button" onClick={() => { navigator.clipboard.writeText(code); toast.success('Code successfully copied !') }} className="absolute right-2 top-1/2 -translate-y-1/2">
                        <CopyIcon />
                    </button>
                }
            </div>
        </FormsGrid>

        <h3 className="font-medium mt-12">Fill in criteria</h3>
        <FormsGrid col={2} className="mt-4 gap-6 items-center">
            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'startDate'} label={'Start Date'} className="gap-2.5" />
            <TextField required control={control} disabled={criteria && !criteria.find((c: string) => c == 'startDate')} fieldName="startDate" type='date' />

            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'endDate'} label={'End Date'} className="gap-2.5" />
            <TextField required control={control} disabled={criteria && !criteria.find((c: string) => c == 'endDate')} fieldName="endDate" type='date' />

            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'quotaApplied'} label={'Number of uses (Quota)'} className="gap-2.5" />
            <TextField required control={control} disabled={criteria && !criteria.find((c: string) => c == 'quotaApplied')} fieldName="quota" type='number' />

            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'minimumSpend'} label={'Minimum spend ($)'} className="gap-2.5" />
            <TextField required control={control} disabled={criteria && !criteria.find((c: string) => c == 'minimumSpend')} fieldName="minimumSpend" type='number' />

            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'excludedServices'} label={'Exclude specific service'} className="gap-2.5" />
            {(defaults.excludedServicesDefaults || !isEdit) &&
                <MultiSelectField
                    required control={control}
                    fieldName="excludedServices"
                    disabled={criteria && !criteria.find((c: string) => c == 'excludedServices')}
                    options={servicesOptions}
                    defaultCfg={{
                        value: defaults.excludedServicesDefaults,
                        setValue: setValue
                    }}
                />
            }

            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'excludedUsers'} label={'Exclude certain users'} className="gap-2.5" />
            {(defaults.excludedUsersDefaults || !isEdit) &&
                <AsyncMultiSelectField
                    required control={control}
                    fieldName="excludedUsers"
                    disabled={criteria && !criteria.find((c: string) => c == 'excludedUsers')}
                    loadOptions={getUsersOptions}
                    defaultCfg={{
                        value: defaults.excludedUsersDefaults,
                        setValue: setValue
                    }}
                    filterOption={(option) => !includedUsersWatch.includes(+option.value)}
                />
            }
            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'includedUsers'} label={'Include certain users'} className="gap-2.5" />
            {(defaults.includedUsersDefaults || !isEdit) &&
                <AsyncMultiSelectField
                    required control={control}
                    fieldName="includedUsers"
                    disabled={criteria && !criteria.find((c: string) => c == 'includedUsers')}
                    loadOptions={getUsersOptions}
                    defaultCfg={{
                        value: defaults.includedUsersDefaults,
                        setValue: setValue
                    }}
                    filterOption={(option) => !excludedUsersWatch.includes(+option.value)}
                />
            }

            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'genders'} label={'Gender'} className="gap-2.5" />
            {(defaults.gendersDefaults || !isEdit) &&
                <MultiSelectField
                    control={control}
                    disabled={criteria && !criteria.find((c: string) => c == 'genders')}
                    fieldName="genders" options={[{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }]}
                    defaultCfg={{
                        value: defaults.gendersDefaults,
                        setValue: setValue
                    }}
                />
            }


            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'propertyTypes'} label={'Property Types'} className="gap-2.5" />
            {(defaults.propertyDefaults || !isEdit) &&
                <MultiSelectField
                    control={control}
                    disabled={criteria && !criteria.find((c: string) => c == 'propertyTypes')}
                    fieldName="propertyTypes"
                    options={flatten(PROPERTY_TYPES.map(categories => categories.types.map(type => ({ value: type, label: `${categories.category} - ${type}` }))))}
                    defaultCfg={{
                        value: defaults.propertyDefaults,
                        setValue: setValue
                    }}
                />
            }

            <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'birthdayMonths'} label={'Birthday Month'} className="gap-2.5" />
            {(defaults.birthdayMonthsDefaults || !isEdit) &&
                <MultiSelectField
                    control={control}
                    disabled={criteria && !criteria.find((c: string) => c == 'birthdayMonths')}
                    fieldName="birthdayMonths"
                    options={getAvailableMonths().map((month) => ({ value: month.value, label: month.name }))}
                    defaultCfg={{
                        value: defaults.birthdayMonthsDefaults,
                        setValue: setValue
                    }}
                />
            }

        </FormsGrid>
        <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'forHaventBookedUser'} label={"New user (haven't booked)"} className="gap-2.5" />
        <Checkbox fieldArr={criteriaFieldArr} control={control} fieldName="criteria" cbVal={'forHaventBookedUserInMonths'} label={"Users that haven't booked in the last 6 months"} className="gap-2.5" />
    </form>
}

export default FormFieldPromo