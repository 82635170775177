import { Dispatch, SetStateAction } from "react"
import { CoordinateType } from "components/location-picker/types"


export const nearbySearch = (lat: number, lng: number, placesService: google.maps.places.PlacesService, setCoordinate?: Dispatch<SetStateAction<CoordinateType>>, setSelectedPlace?: Dispatch<SetStateAction<PlaceInterface | null | undefined>>) => {
    if (setCoordinate)
        setCoordinate({
            lat: lat,
            lng: lng
        })
    placesService?.nearbySearch({
        location: {
            lat: lat,
            lng: lng
        },
        radius: 50,
    }, (place) => {
        if (!place || place.length == 0) {
            // toast.error('Failed to get location. Try moving the pin to a building.')
            return
        }
        const selectedPlace = place && place.find((p) => p.types?.includes('point_of_interest') && p.vicinity != "" && p.vicinity != 'Singapore')
        if (selectedPlace) {
            placesService.getDetails(
                {
                    placeId: selectedPlace.place_id || ''
                },
                (placeDetails) => {
                    if (setSelectedPlace)
                        setSelectedPlace(extractPlaceDetails(placeDetails))
                }
            )
        }
    })
}

export interface PlaceInterface {
    id?: string
    lat?: number
    lng?: number
    name?: string
    address?: string
    street?: string
    postalCode?: string
    buildingNumber?: string
    country?: string
    floorNumber?: string
    unitNumber?: string
}

export const extractPlaceDetails = (placeDetails: google.maps.places.PlaceResult | null): PlaceInterface => {

    try {
        const streetByRoute = placeDetails?.address_components?.find((component) => component.types.includes('route'))
        const streetByNeighborhood = placeDetails?.address_components?.find((component) => component.types.includes('neighborhood'))
        const street = streetByRoute ? streetByRoute : streetByNeighborhood
        const buildingNumber = placeDetails?.address_components?.find((component) => component.types.includes('street_number'))
        const postalCode = placeDetails?.address_components?.find((component) => component.types.includes('postal_code'))
        const country = placeDetails?.address_components?.find((component) => component.types.includes('country'))
        const subPremise = placeDetails?.address_components?.find((component) => component.types.includes('subpremise'))
        const floorNumber = subPremise ? subPremise.long_name.split('-')[0] : ''
        const unitNumber = subPremise ? subPremise.long_name.split('-')[1] : ''
        return (
            {
                id: placeDetails?.place_id,
                lat: placeDetails?.geometry?.location?.lat(),
                lng: placeDetails?.geometry?.location?.lng(),
                name: placeDetails?.name,
                address: placeDetails?.formatted_address,
                street: street ? street.long_name : '',
                buildingNumber: buildingNumber ? buildingNumber.long_name : '',
                postalCode: postalCode ? postalCode.long_name : '',
                country: country ? country.long_name : '',
                floorNumber: floorNumber,
                unitNumber: unitNumber,
            }
        )
    }
    catch (err) {
        console.log(err)
        return ({
            id: placeDetails?.place_id,
            lat: placeDetails?.geometry?.location?.lat(),
            lng: placeDetails?.geometry?.location?.lng(),
            name: placeDetails?.name,
            address: placeDetails?.formatted_address,
        })
    }
}

