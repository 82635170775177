import { Button } from "../buttons"
import { Tooltip } from 'react-tooltip'

interface DropdownProps {
    id: string
    toggleText: string
    shape?: 'filled' | 'outline'
    color?: 'primary' | 'gray', // Button color
    children?: any,
    className?: string, // Button classes
    [attrName: string]: any // HTML attributes  
}
export default function Dropdown({id, toggleText, className, children, ...rest}: DropdownProps){
    let classes = 'dropdown' + (className ? ` ${className}` : '')

    return (
        <div className={classes}>
            <Button id={id} {...rest} data-tooltip-place='bottom-start' data-tooltip-class-name='dd-tooltip'>
                {toggleText}
            </Button>
            <Tooltip anchorSelect={`#${id}`} clickable>
                <ul className="list">{children}</ul>
            </Tooltip>            
        </div>
    )
}

export const DDItem = ({children, className}: {children: any, className?: string}) => {
    let classes = 'item' + (className ? ` ${className}` : '')
    return <li className={classes}>{children}</li>
}

