import { AutoLink } from "./texts"

interface BadgeProps {
    children: any
    tag?: keyof JSX.IntrinsicElements, // Button tag
    color?: 'primary' | 'danger' | 'warning' | 'success' | 'disable' | string
    textColor?: string
    fullOpacityBackground?: boolean
    className?: string, // Button classes
    [attrName: string]: any // HTML attributes  
}
export default function Badge({ children, tag, color, className, textColor, fullOpacityBackground = false, ...rest }: BadgeProps) {
    const Tag = tag || 'span'
    let classes = 'badge' + (className ? ` ${className}` : '')
    if (color) {
        if (['primary', 'danger', 'warning', 'success', 'disable'].includes(color)) {
            classes += (color ? ` ${color}` : ' primary')
        }
        else {
            rest.style = { ...rest.style, backgroundColor: color + (fullOpacityBackground ? '' : '33'), color: textColor ? textColor : color }
        }
    }

    if (rest.href || rest.tag === 'a') {
        return (
            <AutoLink className={classes} href={rest.href} {...rest}>
                {children}
            </AutoLink>
        )
    }
    return (
        <Tag className={classes} {...rest}>
            {children}
        </Tag>
    )
}