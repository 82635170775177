import Pill from "components/Pill"
import { format } from "date-fns"
import { createPortal } from "react-dom"
import { SchedClashedIcon, SchedStatusIcon } from "ScheduleStatus"
import { changeTz } from "utils/data-formatter"
import localToUTC from "utils/data-formatter/local-to-utc"
import { getJobTypeColor } from "utils/scheduler"

type Props = {
    startDate: string
    endDate: string
    address: string
    schedule: MiscObj
    isOverlapping: boolean
    jobTypes: string[]
}

const ScheduleTooltip = (props: Props) => {
    const { schedule, startDate, endDate, address, isOverlapping, jobTypes } = props
    const formatStartDate = changeTz(localToUTC(startDate), 'Asia/Singapore')
    const formatEndDate = changeTz(localToUTC(endDate), 'Asia/Singapore')
    return <div className="group-hover:block hidden gap-1.5 bg-yellow-100 fixed right-4 top-20 w-[300px] z-50">
        <div className="bg-black rounded-lg text-white shadow-lg p-4 text-start flex flex-col relative z-50">
            <span className="font-medium">OID {schedule.schedule.order.id} - JID {schedule.schedule.id}</span>
            <span>{format(formatStartDate, 'HH:mm')} - {format(formatEndDate, 'HH:mm')}</span>
            <span>{schedule.schedule.user.name}</span>
            <span>{address}</span>
            <div className="flex items-center gap-2 flex-wrap">
                {jobTypes.map(type => <Pill {...getJobTypeColor(type)}>{type}</Pill>)}
            </div>
            <div className="top-2 right-2 absolute">
                {isOverlapping ?
                    <SchedClashedIcon size={'1.5rem'} shadow={false} /> :
                    <SchedStatusIcon schedule={schedule.schedule} size="1.5rem" />
                }
            </div>
            {schedule.schedule.order.notes &&
                <div className="mt-4">
                    Notes: {schedule.schedule.order.notes}
                </div>
            }
        </div>
    </div>
}

export default ScheduleTooltip