import { CubeIcon, DocumentIcon, TruckShipIcon } from "components/Svg"
import CreateService from "components/pages/services/CreateService"
import EditService from "components/pages/services/EditService"
import ListService from "components/pages/services/ListService"
import { ResourceType } from "resources-config"

export const SERVICES_CONFIG: ResourceType = {
    name: 'services', meta: { labelAsChild: 'All Products', label: 'Products', icon: <CubeIcon /> },
    actions: {
        list: {
            path: 'services', page: <ListService />,
            allowedRoles: ['super admin']
        },
        create: {
            path: 'services/create', page: <CreateService />,
            allowedRoles: ['super admin']
        },
        edit: {
            path: 'services/edit/:id', page: <EditService />,
            allowedRoles: ['super admin']
        }
    }
}

