import { useModalReturnType } from "@refinedev/core"
import { postAddSchedule, postReschedule } from "api/service-schedules"
import { Button } from "components/buttons"
import { AsyncSelectField, SelectField, Checkbox } from "components/forms"
import { Modal } from "components/popups"
import { baseApi } from "providers/customDataProvider"
import { useState, useMemo, useEffect } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import { toast } from "sonner"
import { numOrderSuffix } from "utils/data-formatter"
import localToUTC from "utils/data-formatter/local-to-utc"
import { formatFullDate } from "utils/date"

type AddScheduleModalProps = useModalReturnType & {
    refetchDailySched: () => void
    viewedTeam: { team: MiscObj, date: string, viewTs: number } | null
    initialOrderId?: string
    initialScheduleId?: string
}
function AddScheduleModal(props: AddScheduleModalProps) {
    const { refetchDailySched, viewedTeam, initialOrderId, initialScheduleId, ...rest } = props
    const { control, handleSubmit, watch, setValue, setError, reset } = useForm()
    const [orderSchedules, setOrderSchedules] = useState<any>()
    const [orderDefaultValue, setOrderDefaultValue] = useState<MiscObj>()
    const [scheduleDefaultValue, setScheduleDefaultValue] = useState<MiscObj>()

    const fieldArr = useFieldArray({ control: control, name: 'items' })
    const orderId = watch('order')
    const scheduleId = watch('period')

    const scheduleOptions = useMemo(() => {
        if (!orderSchedules || !orderSchedules.items || orderSchedules.items.length == 0) return []
        const contractItem = orderSchedules.items.find((item: any) => item.frequency > 1)
        const mainItem = contractItem ? contractItem : orderSchedules.items[0]
        const options: any = []
        Array(mainItem.frequency).fill(0).forEach((_, index) => {
            const scheduleTask = mainItem.scheduleTasks.find((scheduleTask: any) => scheduleTask.schedule.period == index)
            options.push({
                label: `${numOrderSuffix(index + 1)} Job ${scheduleTask ? `ID ${scheduleTask.schedule.id} (${formatFullDate(scheduleTask.schedule.startDate)})` : '(Unscheduled)'}`,
                value: scheduleTask ? +scheduleTask.schedule.id : (index + 1) * -1,
            })
        })

        return options

    }, [orderSchedules])

    useEffect(() => {
        if (rest.visible) {
            setValue('technicianTeam', viewedTeam && viewedTeam.team.id)
            setValue('startDate', viewedTeam && localToUTC(new Date(viewedTeam.date)))
            if (initialOrderId)
                setValue('order', initialOrderId)
        } else {
            reset()
            setOrderSchedules(undefined)
            setOrderDefaultValue(undefined)
            setScheduleDefaultValue(undefined)
        }
    }, [rest.visible])

    useEffect(() => {
        if (+scheduleId < 1 || !orderSchedules) {
            fieldArr.replace([])
            return
        }
        let selectedItems: number[] = orderSchedules.items
            .filter((item: any) => item.scheduleTasks[0] && item.scheduleTasks[0].schedule && item.scheduleTasks[0].schedule.id == scheduleId)
            .map((item: any) => +item.id)
        fieldArr.replace(selectedItems)
    }, [scheduleId])

    useEffect(() => {
        if (!orderId) return
        setValue('period', '')
        baseApi.get(`/admin/service-schedules/get-order-schedule-by-order/${orderId}`)
            .then(async schedules => {
                setOrderSchedules(schedules.data)
                if (initialOrderId && !orderDefaultValue) {
                    const orders = await baseApi.get(`/admin/service-orders?search=${initialOrderId}`)
                    const selectedOrder = orders.data.list ? orders.data.list[0] : null
                    setOrderDefaultValue({ label: `${selectedOrder ? `${selectedOrder.user.name} - ` : ''}OID ${initialOrderId}`, value: initialOrderId })
                }
                if (initialScheduleId && !scheduleDefaultValue) {
                    setScheduleDefaultValue({
                        label: `Job ID ${initialScheduleId}`,
                        value: +initialScheduleId
                    })
                }
            }).catch(err => {
                console.log(err)
                setOrderSchedules(undefined)
            })
    }, [orderId])

    const getOrderOptions = async (search: string): Promise<any[]> => {
        const orders = await baseApi.get(`/admin/service-orders?search=${search}`)
        return orders.data.list.map((order: any) => ({ value: order.id, label: `${order.user.name} - OID${order.id}` }))
    }

    const addSchedule = handleSubmit(async (data: any) => {
        if (data.items.length == 0) {
            toast.error('Please select an item !')
            return
        }
        try {
            if (data.period < 0) {
                if (await postAddSchedule({
                    ...data,
                    period: (data.period * -1) - 1,
                    user: parseInt(orderSchedules.user.id),
                    order: parseInt(data.order),
                })) toast.success('Schedule successfully created')
            } else {
                const scheduleTasks = orderSchedules.items[0].scheduleTasks
                const schedule = scheduleTasks ? scheduleTasks.find((st: any) => st.schedule.id == data.period) : undefined
                if (!schedule) toast.error('Schedule not found')
                if (await postReschedule({
                    ...data,
                    period: parseInt(schedule.schedule.period),
                    schedule: parseInt(data.period),
                    user: parseInt(orderSchedules.user.id),
                    order: parseInt(data.order),
                })) toast.success('Reschedule success')
            }
        } catch (err: any) {
            toast.error(err.message)
            console.log(err)
        } finally {
            refetchDailySched()
            rest.close()
        }
    })

    return <Modal heading={'Add Schedule'} {...rest} width="30rem">

        {viewedTeam ?
            <form onSubmit={addSchedule} className="space-y-4" id='add-schedule-form'>
                <p>
                    Place a Schedule on <span className="font-medium">{viewedTeam?.team.name}</span> for <span className="font-medium">{formatFullDate(localToUTC(new Date(viewedTeam.date!)))}</span>
                </p>
                {(orderDefaultValue || !initialOrderId) &&
                    <AsyncSelectField
                        control={control}
                        fieldName="order"
                        loadOptions={getOrderOptions}
                        label={'Order'}
                        required
                        defaultCfg={{
                            setValue: setValue,
                            value: orderDefaultValue ? { label: orderDefaultValue.label, value: orderDefaultValue.value } : null
                        }}
                    />
                }
                {(scheduleDefaultValue || !initialScheduleId) && scheduleOptions && scheduleOptions.length > 0 &&
                    <SelectField
                        disabled={!orderSchedules}
                        label={'Job'}
                        control={control}
                        fieldName='period'
                        options={scheduleOptions}
                        required
                        defaultCfg={{
                            setValue: setValue,
                            value: scheduleDefaultValue ? { label: scheduleDefaultValue.label, value: scheduleDefaultValue.value } : null
                        }}
                    />
                }

                {
                    orderSchedules && orderSchedules.items.map((item: MiscObj, index: number) => (
                        <div className="justify-between flex-ct-y" key={index}>
                            <Checkbox
                                key={index}
                                fieldArr={fieldArr}
                                control={control}
                                fieldName="items"
                                cbVal={+item.id}
                                label={`${item.service?.name}${item.serviceCategory ? ` (${item.serviceCategory.name})` : ''}`}
                            />
                            <span className="font-medium text-th-xs text-disable">
                                {item.frequency || 0}x time(s) {item.frequency || 0} unit(s)
                            </span>
                        </div>
                    ))
                }
                <Button confirm shape="filled" disabled={!orderId || !scheduleId} type='button' form={'add-schedule-form'} className="w-full mt-8">Submit</Button>
            </form> : <></>
        }

    </Modal>
}

export default AddScheduleModal