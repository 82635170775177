import { useController } from "react-hook-form";
import { BaseFormProps } from "./types";
import { getFieldErrMsg } from "utils/handle-api-error";

interface TextFieldProps extends BaseFormProps {
    isNumeric?: boolean,
    handleEnter?: (e: any) => void
    regex?: 'email' | 'phoneNumber'
}

export default function TextField({
    fieldName, control, isNumeric, handleEnter,
    label, desc, className, errorCfg, regex, ...rest
}: TextFieldProps) {
    let classes = 'form-group' + (className ? ' ' + className : '')
    classes += rest.required ? ' required' : ''
    if (handleEnter) {
        rest.onKeyUp = (e: any) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
                handleEnter(e)
            }
        }
    }
    let pattern;
    switch (regex) {
        case 'email':
            pattern = {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email',
            }
            break
    }
    const { field, fieldState } = useController({
        name: fieldName, control, rules: {
            pattern: pattern,
        }
    });

    return (
        <span className={classes}>
            {label ? <label className='form-label'>{label}</label> : ''}
            <input className="form-control" onChange={field.onChange}
                onBlur={(e: any) => {
                    let value: string = e.target.value
                    if (isNumeric) {
                        if (isNaN(parseFloat(value.toString()))) value = ''
                    }
                    field.onChange(value)
                }}
                name={field.name} value={field.value || ''} ref={field.ref}
                {...rest}
            />
            <span className="flex flex-col">
                {!desc ? <></> : <span className="desc">{desc}</span>}
                {
                    !fieldState.error || (errorCfg && errorCfg.hideErrMsg) ? <></> :
                        <span className="err-msg">
                            {getFieldErrMsg(
                                fieldState.error, fieldName, (errorCfg && errorCfg.errLabel ? errorCfg.errLabel : '')
                            )}
                        </span>
                }
            </span>
        </span>
    )
}