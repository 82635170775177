import { RESOURCE_CONFIG } from "resources-config"

export type PermissionsType = {
    [resource: string]: {[role: string]: string | string[]}
}

class RolePermissions{
    private readonly permissions: PermissionsType = RESOURCE_CONFIG.getPermissions()

    checkPermissions = (resource: string, action: string, role: string | null) => {
        if(role === null || role === undefined){
            return false
        }
        role = role.toLocaleLowerCase()
        const resourcePermissions = this.permissions[resource]
        if(!resourcePermissions){
            return true
        }
        if(!resourcePermissions[role]){
            return false
        }
        if(resourcePermissions[role] === '*'){
            return true
        }
        if(resourcePermissions[role].includes(action)){
            return true
        }
        return false
    }
}


export default RolePermissions