import Pill from "components/Pill"
import { CircleCheckIcon } from "components/Svg";
import { capitalize } from "lodash";
import { useMemo } from "react"
import { formatFullAddress } from "utils/address"
import { formatFullDate } from 'utils/date';
import { calculateGST } from "utils/discounts";


type Props = {
    order?: MiscObj
    invoice?: MiscObj
    user?: MiscObj
    items?: MiscObj[]
}

const InvoicePreview = (props: Props) => {
    const { order, invoice, user, items = [] } = props
    const itemIds = useMemo(() => {
        if (!invoice) return []
        return invoice.items.map((item: any) => item.id)
    }, [invoice])

    const {
        grandTotal = 0,
        promoCode = "",
        credit = 0,
        subTotal = 0,
        gst = 0
    } = useMemo(() => {
        if (!order || !invoice || !user) return {}

        const grandTotal = invoice.amount
        const promoDiscount = invoice.isFirstInvoice ? order.discountAmount : 0
        const promoCode = order.promo && order.promo.code
        const credit = invoice.balanceUsed
        const subTotal = grandTotal + credit + promoDiscount
        const gst = calculateGST(subTotal)
        return {
            grandTotal, promoCode, credit, subTotal, gst
        }
    }, [order, invoice, user])

    if (!order || !invoice || !user) return <></>

    return <div className="max-w-[800px] py-24 tablet-below:py-0 mx-auto" id={`invoiceEle${invoice.id}`}>
        <section className="flex items-end justify-between">
            <div>
                <h1 className="font-medium text-th-lg">Invoice</h1>
                <div className="flex items-center gap-2 mt-1">
                    <span className="text-body">Purchase Date</span>
                    <span className="font-medium">{formatFullDate(invoice.paidAt)}</span>
                </div>
            </div>
            <div className="space-y-4 flex flex-col items-end">
                <img src={'/images/logo-clean.webp'} alt="Airple logo" className="object-contain w-[8rem] h-[2.4rem]" />
                <Pill backgroundColor="#EBF1F9" textColor="#1954A1" borderWidth="1px" fontSize="1rem" className="rounded-md border border-accent">
                    Booking ID <span className="font-medium">{order.id}</span>
                </Pill>
            </div>
        </section>
        <section className="flex items-start gap-4 mt-6">
            <div className="grow">
                <h2 className="font-medium text-th-lg">Customer Details</h2>
                <div className="border border-primary-2-surface mt-2 rounded-md p-2.5 text-th-base">
                    <div className="flex items-center justify-start gap-2 border-b border-dashed border-primary-2-surface pb-2.5">
                        <div className="flex flex-col gap-0.5">
                            <span className="text-light">Name</span>
                            <span className="font-medium">{user.name}</span>
                        </div>
                        <div className="flex flex-col gap-0.5">
                            <span className="text-light">Email</span>
                            <span className="font-medium">{user.email ?? '-'}</span>
                        </div>
                        <div className="flex flex-col gap-0.5">
                            <span className="text-light">Phone Number</span>
                            <span className="font-medium">{user.phoneNumber}</span>
                        </div>
                    </div>
                    <div className="pt-2.5 flex flex-col gap-0.5">
                        <span className="text-light">Service Location</span>
                        <span>{order.buildingName}</span>
                        <span>{formatFullAddress(order.buildingNumber, order.street, order.postalCode)}</span>
                        {order.notes &&
                            <span>Notes: {order.notes}</span>
                        }
                    </div>
                </div>
            </div>
            {invoice.paidAt &&
                <Pill className="font-medium rounded-md" backgroundColor="#00A19A" textColor="white" Icon={<CircleCheckIcon width={16} height={16} color="white" />}>
                    PAID
                </Pill>
            }

        </section>
        <section className="mt-8">
            <h2 className="font-medium">Payment Details</h2>
            <div className="w-full border border-primary-2-surface mt-2 rounded-md text-theme-base">
                <div className="bg-primary-2-surface grid grid-cols-10 place-items-center gap-2 text-center">
                    <span className="py-1">No</span>
                    <span className="py-1 col-span-3">Service Item</span>
                    <span className="py-1 col-span-2">Category</span>
                    <span className="py-1 col-span-2">Quantity</span>
                    <span className="py-1 col-span-2">Total</span>
                </div>
                {items.filter((item: any) => itemIds.includes(item.id.toString())).map((item: any, index: number) =>
                    <div className="grid grid-cols-10 place-items-center gap-2" key={item.id}>
                        <span className="py-1 text-center">{index + 1}</span>
                        <span className="py-1 col-span-3 place-self-start">
                            {item.service.name} {item.frequency > 1 ? <>(Contract {item.frequency} Times a year)</> : <></>}
                        </span>
                        <span className="py-1 col-span-2 text-center">
                            {item.serviceCategory ?
                                <>{item.serviceCategory.name}</> : <>-</>
                            }
                        </span>
                        <span className="py-1 col-span-2 text-center">
                            {item.quantity} unit{item.quantity > 1 ? "s" : ''}
                        </span>
                        <span className="py-1 col-span-2 text-end font-medium w-full pr-2.5">
                            $ {item.price.toFixed(2)}
                        </span>
                    </div>
                )}
                {invoice.schedules.length > 0 &&
                    <div className="grid grid-cols-10 place-items-center gap-2">
                        <span className="py-1 text-center">{itemIds.length + 1}</span>
                        <span className="py-1 col-span-3 place-self-start">
                            Priority Booking
                        </span>
                        <span className="py-1 col-span-2">
                            -
                        </span>
                        <span className="py-1 col-span-2 text-center">
                            {invoice.schedules.length} time{invoice.schedules.length > 1 ? 's' : ''}
                        </span>
                        <span className="py-1 col-span-2 text-end font-medium w-full pr-2.5">
                            $ {(invoice.schedules.length * 8).toFixed(2)}
                        </span>
                    </div>
                }
                <div className="p-2.5 border-t border-primary-2-surface flex flex-col space-y-1">
                    <div className="flex items-center justify-between">
                        <span>Subtotal <span className="font-medium">({invoice.items.length + invoice.schedules.length} items)</span></span>
                        <span className="font-medium">$ {subTotal.toFixed(2)}</span>
                    </div>
                    <div className="flex items-center justify-between text-light">
                        <span>Includes GST</span>
                        <span>$ {gst.toFixed(2)}</span>
                    </div>
                    {(invoice.isFirstInvoice && promoCode) ?
                        <div className="flex items-center justify-between">
                            <span>Promo Code <span className="text-accent">({promoCode})</span></span>
                            <span className="font-medium text-accent">-$ {order.discountAmount.toFixed(2)}</span>
                        </div> : <></>
                    }
                    {credit ?
                        <div className="flex items-center justify-between">
                            <span>Credit <span className="font-medium">({invoice.items.length + invoice.schedules.length} items)</span></span>
                            <span className="font-medium">-$ {credit.toFixed(2)}</span>
                        </div> : <></>
                    }
                    <div className="flex items-center justify-between">
                        <span>Total</span>
                        <span className="font-medium">$ {grandTotal.toFixed(2)}</span>
                    </div>
                    {invoice.invoiceHistories.map((invHis: any) => {
                        let createdBy = invHis.createdBy.split('_')[0]
                        createdBy = createdBy && createdBy != 'system' ? `by ${capitalize(createdBy)}` : ''
                        return <div key={invHis.id} className="flex items-center justify-between">
                            <span>{capitalize(invHis.paymentMethod)} {invHis.type == 1 ? 'Payment' : 'Refund'} {createdBy} ({formatFullDate(invHis.createdAt, { showDay: false, showTime: false })})</span>
                            <span className="font-medium">{invHis.type == 1 ? '-' : ''}$ {invHis.amount.toFixed(2)}</span>
                        </div>
                    })}
                </div>
                <div className="bg-primary-2-surface p-2.5 flex items-center justify-between">
                    <span className="font-medium">Amount Due</span>
                    <span className="font-medium text-accent">$ {invoice.amountDue && invoice.amountDue.toFixed(2)}</span>
                </div>
            </div>
        </section>
        <section className="mt-20 flex items-start justify-between text-theme-base">
            <div className="flex flex-col gap-0.5 text-light">
                <img src={'/images/logo-clean.webp'} alt="Airple logo" className="object-contain w-[4rem] h-[1.2rem]" />
                <span className="text-body font-medium">AIRPLE Construction Pte. Ltd.</span>
                <span>Company Reg 201722322H</span>
                <span>GST Registration number: 201722322H</span>
                <span>2 Yishun Industrial Street 1 #02-32,</span>
                <span>Northpoint Bizhub Singapore (768159)</span>
            </div>
            <div className="flex flex-col gap-0.5 text-light">
                <span className="text-body font-medium">Ask AIRPLE</span>
                <div className="flex items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 7 7" fill="none">
                        <g clipPath="url(#clip0_1390_135485)">
                            <path d="M3.10701 3.12848L3.53007 2.74087C3.64574 2.63468 3.72705 2.49635 3.76354 2.34362C3.80004 2.1909 3.79007 2.03075 3.7349 1.88373L3.55449 1.40198C3.48709 1.22213 3.35365 1.07474 3.18137 0.989844C3.00908 0.904947 2.81091 0.888927 2.62723 0.945048C1.95128 1.15185 1.43172 1.78013 1.59165 2.52619C1.69682 3.017 1.89811 3.63307 2.27941 4.28854C2.6615 4.94557 3.09795 5.43087 3.47216 5.77199C4.03702 6.28604 4.84454 6.15763 5.3641 5.67312C5.50337 5.54327 5.58788 5.36518 5.6004 5.17518C5.61291 4.98517 5.55249 4.79754 5.43146 4.65054L5.10058 4.24875C5.00078 4.12725 4.86691 4.03839 4.7162 3.9936C4.56548 3.94882 4.40481 3.95015 4.25486 3.99744L3.70811 4.16958C3.56692 4.02386 3.44318 3.86219 3.33941 3.68783C3.23926 3.5115 3.1612 3.3235 3.10701 3.12808V3.12848Z" fill="#404040" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1390_135485">
                                <rect width="6.30252" height="6.30252" fill="white" transform="translate(0.445312 0.361328)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>+65 8385 68888</span>
                </div>
                <div className="flex items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 7 7" fill="none">
                        <g clipPath="url(#clip0_1390_135489)">
                            <path d="M5.96127 3.85568C5.96128 3.36184 5.8066 2.88041 5.51895 2.47899C5.2313 2.07758 4.82514 1.77635 4.35752 1.61761C3.88989 1.45888 3.38428 1.45061 2.91171 1.59397C2.43914 1.73733 2.02335 2.02512 1.72273 2.41691C1.42212 2.8087 1.25178 3.28482 1.23564 3.77839C1.2195 4.27197 1.35837 4.7582 1.63275 5.16879C1.90713 5.57939 2.30324 5.89372 2.76543 6.06765C3.22763 6.24157 3.73269 6.26635 4.20969 6.1385" stroke="#404040" strokeWidth="0.52521" strokeLinecap="round" />
                            <path d="M3.5973 4.90553C4.17743 4.90553 4.64772 4.43524 4.64772 3.85511C4.64772 3.27498 4.17743 2.80469 3.5973 2.80469C3.01716 2.80469 2.54688 3.27498 2.54688 3.85511C2.54688 4.43524 3.01716 4.90553 3.5973 4.90553Z" stroke="#404040" strokeWidth="0.52521" />
                            <path d="M4.64844 3.06836V4.25008C4.64844 4.4242 4.71761 4.59119 4.84073 4.71431C4.96385 4.83743 5.13083 4.90659 5.30495 4.90659C5.47907 4.90659 5.64605 4.83743 5.76917 4.71431C5.89229 4.59119 5.96146 4.4242 5.96146 4.25008V3.85617" stroke="#404040" strokeWidth="0.52521" strokeLinecap="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1390_135489">
                                <rect width="6.30252" height="6.30252" fill="white" transform="translate(0.445312 0.705078)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>ask@airple.com.sg</span>
                </div>
            </div>
        </section>
    </div>
}

export default InvoicePreview