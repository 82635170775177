import { isBefore } from "date-fns";


export const getFirstInvoice = (order?: MiscObj) => {
    if (!order) {
        return null;
    }
    const firstInvoice = order.invoices.find((invoice: MiscObj) => invoice.isFirstInvoice)
    if (firstInvoice) {
        return firstInvoice
    }
    return null
}

export const getAmountDue = (invoices: MiscObj[]) => {
    return invoices.map((invoice: any) => invoice.amountDue).reduce((acc: any, current: any) => acc + current, 0)
}

export const getClosestDueDate = (invoices: MiscObj[]) => {
    if (!invoices || invoices.length == 0) return null
    let dueDate: string | null = null;
    invoices.forEach((invoice: any) => {
        if (!dueDate) {
            if (invoice.paidAt != null) return
            dueDate = invoice.expiredAt
            return
        }
        if (isBefore(invoice.expiredAt, dueDate) && invoice.paidAt == null) dueDate = invoice.expiredAt
    })

    return dueDate
}

export const getScheduleStatusColor = (isNewItem: boolean, schedule?: MiscObj, isRejected = false) => {
    let status = '';
    let badgeBackgroundColor = '';
    let backgroundColor = '';
    let badgeTextColor = '';
    let badgeBorderColor = 'transparent'

    try {
        if (isNewItem) {
            status = 'New Item'
            badgeBackgroundColor = 'transparent'
            badgeTextColor = '#1954A1'
            backgroundColor = '#F0F3FF'
            badgeBorderColor = '#164176'
        }
        else if (isRejected) {
            status = 'Rejected'
            badgeBackgroundColor = 'transparent'
            badgeTextColor = '#CB3A31'
            backgroundColor = '#FFF4F2'
            badgeBorderColor = '#CB3A31'
        }
        else if (!schedule) {
            status = 'Unscheduled'
            badgeBackgroundColor = 'transparent'
            badgeTextColor = '#5B4407'
            backgroundColor = '#FFF9F2'
            badgeBorderColor = '#EECEB0'
        }
        else if (schedule.completionDate) {
            status = 'Done'
            badgeBackgroundColor = '#00A19A'
            badgeTextColor = '#FFFFFF'
            backgroundColor = 'transparent'
        }
        else if (schedule.startDate) {
            status = 'On Schedule'
            badgeBackgroundColor = '#EBF1F9'
            badgeTextColor = '#1954A1'
            backgroundColor = 'transparent'
        }
    }
    catch (err) {
        console.log(err)
    } finally {
        return { status, badgeBackgroundColor, badgeTextColor, backgroundColor, badgeBorderColor }
    }


}