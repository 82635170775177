import "client-only";

export function getLocalStorage(key: string){
    let value = null
    if(typeof window !== "undefined"){
        value = localStorage.getItem(key);
    }
    return value ? JSON.parse(value) : null
}

export function setLocalStorage(key: string, value:any){
    if(typeof window !== "undefined"){
        localStorage.setItem(key, JSON.parse(value));
    }
}