

// src/CountdownTimer.js
import { differenceInSeconds } from 'date-fns';
import { padStart } from 'lodash';
import React, { useState, useEffect } from 'react';

const calculateTimeDifference = (targetDate: Date, mode: 'increment' | 'decrement') => {
    const now = new Date();
    const target = new Date(targetDate);
    let difference;

    if (mode === 'increment') {
        difference = differenceInSeconds(now, target);
    } else {
        difference = differenceInSeconds(target, now);
    }
    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor((difference % 3600) / 60);
    const seconds = Math.floor(difference % 60);


    return { hours, minutes, seconds };
};

const CountdownTimer = ({ date, mode = 'decrement' }: { date: Date, mode?: 'increment' | 'decrement' }) => {
    const [time, setTime] = useState(() => calculateTimeDifference(date, mode));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(calculateTimeDifference(date, mode));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [date, mode]);

    return (
        <div>
            {padStart(time.hours.toString(), 2, '0')}:{padStart(time.minutes.toString(), 2, '0')}:{padStart(time.seconds.toString(), 2, '0')}
        </div>
    );
};

export default CountdownTimer;