import { Modal } from "components/popups"
import { useModalReturnType } from '@refinedev/core';
import { FormsGrid, SelectField, TextAreaField, TextField } from "components/forms";
import { useForm } from "react-hook-form";
import { Button } from "components/buttons";
import { useEffect, useState } from "react";
import { postInvoiceHistory } from "api/invoice";
import { formatISO } from "date-fns";
import { toast } from "sonner";
import { baseApi } from "providers/customDataProvider";


type Props = {
    modal: useModalReturnType
    viewedInvoice?: MiscObj
    refetchOrder: () => void
    isQuotationHistory?: boolean
    quotationId?: string
}

const PAYMENT_METHODS_OPTIONS = [
    {
        label: 'Cash',
        value: 'Cash',
    },
    {
        label: 'Bank Transfer',
        value: 'Bank Transfer'
    },
    {
        label: 'Credits',
        value: 'Credits'
    }
]

const PAYMENT_TYPE_OPTIONS = [
    {
        label: 'Payment',
        value: 1,
    },
    {
        label: 'Refund',
        value: 2
    },
    {
        label: 'Compensation',
        value: 3
    }
]

const RecordPaymentModal = (props: Props) => {
    const { modal, viewedInvoice, refetchOrder, quotationId } = props
    const { control, handleSubmit, reset, formState: { errors } } = useForm()
    const [isFetching, setIsFetching] = useState(false)

    console.log(errors)

    useEffect(() => {
        if (!modal.visible)
            reset()
    }, [modal.visible])

    const submitRecordPayment = handleSubmit(async (data) => {
        if (!viewedInvoice) return
        setIsFetching(true)
        try {
            const body = {
                ...data,
                amount: parseFloat(data.amount),
                paidAt: formatISO(new Date(data.paidAt)).toString()
            }
            if (quotationId) {
                await baseApi.post(`/admin/quotations/${quotationId}/invoices/${viewedInvoice.id}/invoice-histories`, body)
            } else {
                await postInvoiceHistory(viewedInvoice.id, body)
            }

            toast.success('Record payment success')
            modal.close()
            refetchOrder()
        }
        catch (err: any) {
            console.log(err.message)
            const [_, ...message] = err.message[0].split(' ')
            toast.error(message.join(' '))
        }
        finally {
            setIsFetching(false)
        }
    })

    return <Modal visible={modal.visible} close={modal.close} heading={'Record a payment for this invoice'}>
        <form onSubmit={submitRecordPayment} id="record-payment-form">
            <p className="text-light">Record a payment you&apos;ve already received, such as cash or bank payment.</p>
            <FormsGrid col={1} className="mt-8">
                <TextField type='datetime-local' control={control} fieldName="paidAt" label={'Date'} required />
                <SelectField label={'Record type'} required control={control} fieldName="type" placeholder="Select a payment record type..." options={PAYMENT_TYPE_OPTIONS} />
                <SelectField label={'Method'} required control={control} fieldName="paymentMethod" placeholder="Select a payment method..." options={PAYMENT_METHODS_OPTIONS} />
                <TextField type='number' fieldName="amount" control={control} required placeholder="$ 0.00" label={'Amount'} />
                <TextAreaField control={control} fieldName="notes" label={'Memo / notes'} />
                <div className="flex items-center justify-end gap-2">
                    <Button disabled={isFetching} shape="outline" onClick={modal.close}>
                        Cancel
                    </Button>
                    <Button disabled={isFetching} confirm type="button" form={'record-payment-form'}>
                        Submit
                    </Button>
                </div>
            </FormsGrid>
        </form>
    </Modal>
}

export default RecordPaymentModal