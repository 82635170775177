import PageTitle from "components/PageTitle";
import { useForm } from "@refinedev/react-hook-form"
import { USERS_CONFIG } from "resources-config/users-config";
import { CreateEditCard } from "components/cards";
import { Button } from "components/buttons";
import { useParams } from "react-router-dom";
import FormFieldUser from "./FormFieldUser";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";
import { getErrorNotif, getSuccessNotif } from "utils/data-formatter";

export default function EditUser() {
    const params = useParams()
    const [btnDisabled, disableBtn] = useState(false)
    const [defaultUnitType, setDefaultUnitType] = useState<string[]>()
    const [showForm, setShowForm] = useState(false)
    const {
        refineCore: { onFinish, queryResult, mutationResult }, handleSubmit, control,
        setError, clearErrors, setValue, watch
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: USERS_CONFIG.name,
            action: 'edit', id: params.id,
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/users/edit/${data.data.id}`
                }
                return getSuccessNotif('update', 'user', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('update', 'user', data)
            },
        }
    });

    const onFinishHandler: any = (data: FieldType) => {
        onFinish({
            ...data,
            email: data.email ?? null
        })
    }


    useEffect(() => {
        if (!queryResult || !queryResult.data || showForm) return

        setDefaultUnitType(queryResult.data.data.addresses.map((addr: any) => addr.unitType))
        setShowForm(true)
    }, [queryResult])

    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    return (<>
        <PageTitle title="Edit User" />
        <CreateEditCard
            cardHeading="Edit User"
            actions={<>
                <Button type='submit' form='user-form' isLoading={btnDisabled}>Save</Button>
            </>}
        >
            <FormFieldUser
                formId={'user-form'}
                queryResult={queryResult}
                control={control}
                handleSubmit={handleSubmit}
                onFinishHandler={onFinishHandler}
                setValue={setValue}
                watch={watch}
                defaultUnitType={defaultUnitType}
                isEdit={true}
                showForm={showForm}
            />
        </CreateEditCard>
    </>)
}

type FieldType = {
    name: string
    email: string
    phoneNumber: string,
    addresses: { [key: string]: string }[]
}