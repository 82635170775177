import ListPublicHoliday from "components/pages/public-holidays/ListPublicHoliday"
import { ResourceType } from "resources-config"

export const PUBLIC_HOLIDAYS_CONFIG: ResourceType = {
    name: 'public-holidays', meta: {parent: 'service-schedules', label: "Public Holidays"},
    actions: {
        list: {
            path: 'public-holidays', page: <ListPublicHoliday/>,
            allowedRoles: ['super admin'],
        },         
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }          
    }
}

