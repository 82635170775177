import Dashboard from "components/pages/Dashboard"
import { TaskBoardIcon } from "components/Svg"
import { ResourceType } from "resources-config"

export const DASHBOARD_CONFIG: ResourceType = {
    name: 'dashboard',
    meta: {
        icon: <TaskBoardIcon />,
        label: 'Task Board'
    },
    actions: {
        list: {
            path: '/', page: <Dashboard />,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }
    }
}

