import { baseApi } from "providers/customDataProvider"


export const getStatistics = async (startDate: string | Date, view = 'month', page = 1, pageSize = 10, tzoffset = 8) => {
    const res = await baseApi.get(`/admin/service-schedules/statistics?page=${page}&pageSize=${pageSize}&startdate=${startDate}&tzoffset=${tzoffset}&view=${view}&`)
    const data = res.data
    return data
}

export const getPriorityBookingConflictedJob = async (scheduleId: string) => {
    const res = await baseApi.get(`/admin/service-schedules/priority-bookings/${scheduleId}/conflicted-schedules`)
    const data = res.data
    return data
}

export const getRescheduleNextSchedule = async (scheduleId: string) => {
    const res = await baseApi.get(`/admin/service-schedules/tech-reschedules/${scheduleId}/next-schedule`)
    const data = res.data
    return data
}

export const getItemRequestConflictedSchedule = async (itemId: string) => {
    const res = await baseApi.get(`/admin/service-orders/item-requests/${itemId}/conflicted-schedules`)
    const data = res.data
    return data
}