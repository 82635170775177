import { Button } from "components/buttons"
import { CreateEditCard } from "components/cards"
import FormFieldPromo from "./FormFieldPromo"
import { postCreatePromo } from "api/promo"
import { toast } from "sonner"
import { useFieldArray, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"


const CreateServicePromo = () => {
    const { control, watch, handleSubmit, setValue } = useForm()
    const criteriaFieldArr = useFieldArray({ control: control, name: 'criteria' })
    const navigate = useNavigate()

    const createPromo = handleSubmit(async (data) => {
        try {
            const formatData = {
                ...data,
                type: parseInt(data.type),
                quota: parseInt(data.quota),
                forHaventBookedUser: data.criteria && data.criteria.find((c: string) => c == 'forHaventBookedUser') != undefined,
                forHaventBookedUserInMonths: data.criteria && data.criteria.find((c: string) => c == 'forHaventBookedUserInMonths') != undefined,
                excludedServices: data.excludedServices && data.excludedServices.map((exc: any) => ({ id: parseInt(exc.split('-')[0]), isContract: exc.split('-')[1] == 'contract', categoryId: parseInt(exc.split('-')[2]) })) || [],
                excludedUsers: data.excludedUsers || [],
                includedUsers: data.includedUsers || [],
                genders: data.genders || [],
                quotaApplied: data.criteria && data.criteria.find((c: string) => c == 'quotaApplied') != undefined,
                propertyTypes: data.propertyTypes || [],
                birthdayMonths: data.birthdayMonths || [],
            }
            await postCreatePromo(formatData)
            toast.success('Promo created successfully !')
            navigate('/promos')
        } catch (err: any) {
            console.log(err)
            toast.error('Failed to create promo. ' + err.message)
        }
    })

    return <CreateEditCard cardHeading="New Promo Code" actions={<Button confirm form='create-promo-form'>Save and Publish</Button>}>
        <FormFieldPromo
            formId="create-promo-form"
            control={control}
            watch={watch}
            onSubmit={createPromo}
            criteriaFieldArr={criteriaFieldArr}
            setValue={setValue}
        />
    </CreateEditCard>
}

export default CreateServicePromo
