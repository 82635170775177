import { useModal, useOne } from "@refinedev/core"
import { postItemRequest } from "api/item-requests"
import { Button } from "components/buttons"
import { BaseCard, OrderItemCard } from "components/cards"
import { AddEditItemModal } from "components/custom-modals"
import Pill from "components/Pill"
import { AdminIcon, CustomerDetailIcon, CustomerRemarkIcon, InfoCircleIcon, PlusScheduleIcon, ServiceListIcon, TechniciansIcon } from "components/Svg"
import { FieldInfo, LabeledInfo } from "components/texts"
import { TextTooltip } from "components/tooltips"
import { format, isBefore } from "date-fns"
import { baseApi } from "providers/customDataProvider"
import { useEffect, useLayoutEffect, useReducer, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { orderDetailReducer, orderDetailInitialState, OrderDetailKind } from "reducers/orderDetailReducer"
import { SERVICE_ORDER_CONFIG } from "resources-config/service-order/service-order-config"
import { toast } from "sonner"
import { formatAddressAny } from "utils/address"
import { capitalizeStr, getOrderStatus } from "utils/data-formatter"
import { formatFullDate } from "utils/date"
import { getAmountDue, getClosestDueDate, getFirstInvoice } from "utils/order"
import { scrollToTargetAdjusted } from "utils/scheduler"
import RecordPaymentModal from "../../custom-modals/RecordPaymentModal"
import { deleteInvoiceHistory } from "api/invoice"
import InvoiceCard from "components/cards/InvoiceCard"

export default function ShowServiceOrder() {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { data, refetch: refetchOrder } = useOne({
        resource: SERVICE_ORDER_CONFIG.name,
        id: params.id
    })
    const [viewedInvoice, setViewedInvoice] = useState<MiscObj>()
    const [state, dispatch] = useReducer(orderDetailReducer, orderDetailInitialState)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const addEditItemModal = useModal()
    const recordPaymentModal = useModal()
    const isDirty = state.newItemQueue.length != 0
    const upsellTechnicians = state.order && state.order.upsellTechnicians && state.order.upsellTechnicians.length > 0 && state.order.upsellTechnicians.map((tech: any) => `${tech.technician.name}${tech.role == 1 ? ' (Leader)' : ''}`).join(', ') || ''

    const cancel = async () => {
        if (state.order) {
            try {
                await baseApi.post(`/admin/service-orders/cancel/${state.order.id}`)
                refetchOrder()
                toast.success('Order Cancelled')
            }
            catch (err: any) {
                toast.error(err.message)
            }
        }
    }

    useLayoutEffect(() => {
        const target = searchParams.get('scroll')
        if (scrollToTargetAdjusted(target || '', 100)) setSearchParams({})
    }, [state.order])

    const saveOrder = async () => {
        if (!state.order) return
        setIsSaving(true)
        try {
            await postItemRequest({
                items: state.newItemQueue,
                order: parseInt(state.order.id)
            })
            dispatch({ type: OrderDetailKind.CLEAR_NEW_ITEM })
            await refetchOrder()
            toast.success('Items successfully added.')
        }
        catch (err: any) {
            console.log(err)
            toast.error('Failed adding item.' + err.message)
        }
        finally {
            setIsSaving(false)
        }
    }

    const handleAddEditItem = (data: any, serviceDetail: MiscObj, id?: string) => {
        if (!id) {
            dispatch({ type: OrderDetailKind.ADD_NEW_ITEM, payload: { ...serviceDetail, ...data, } })
        }
        else {
            dispatch({ type: OrderDetailKind.PATCH_NEW_ITEM, payload: { ...serviceDetail, ...data, id, } })
        }
        addEditItemModal.close()
    }

    const openAddEdit = (id?: string) => {
        addEditItemModal.show()
        if (id) {
            const selectedNewItem = state.newItemQueue.find(item => item.id == id) || {}
            dispatch({
                type: OrderDetailKind.SET_DEFAULT_EDIT_VALUE,
                payload: {
                    id: selectedNewItem.id,
                    service: selectedNewItem.service,
                    category: selectedNewItem.category,
                    frequency: selectedNewItem.frequency,
                    quantity: selectedNewItem.quantity,
                    pricePerUnit: selectedNewItem.pricePerUnit,
                    price: selectedNewItem.price,
                    notes: selectedNewItem.notes
                }
            })
        }
        else {
            dispatch({ type: OrderDetailKind.SET_DEFAULT_EDIT_VALUE, payload: null })
        }
    }


    useEffect(() => {
        const order = data?.data
        const firstInvoice = getFirstInvoice(order)
        dispatch({ type: OrderDetailKind.SET_ORDER, payload: order })
        dispatch({ type: OrderDetailKind.SET_FIRST_INVOICE, payload: firstInvoice })
        dispatch({ type: OrderDetailKind.SET_AMOUNT_DUE, payload: order && getAmountDue(order.invoices) })
        dispatch({ type: OrderDetailKind.SET_DUE_DATE, payload: order && getClosestDueDate(order.invoices) })
        dispatch({ type: OrderDetailKind.SET_STATUS, payload: order ? getOrderStatus(order, firstInvoice) : null })
        dispatch({ type: OrderDetailKind.SET_IS_PAY_ONSITE, payload: firstInvoice && firstInvoice.paymentMethod == 'onsite' })
    }, [data])

    return <BaseCard>
        {state.order &&
            <>
                <header className="flex items-center justify-between border-b pb-4">
                    <h1 className="text-th-2xl font-bold">ORDER #OID-{state.order.id}</h1>
                    <div className="flex items-center gap-2">
                        <Button shape="outline" confirm onClick={cancel}>
                            Cancel Order
                        </Button>
                        <Button confirm isLoading={isSaving} onClick={saveOrder} disabled={!isDirty}>
                            Save Order
                        </Button>
                        {/* <DropdownToggle options={[{ text: 'Cancel', onClick: cancel, visible: state.order.cancelledAt == null }]} >
                            More Actions
                        </DropdownToggle> */}
                    </div>
                </header>
                <section className="grid grid-cols-5 mt-4 gap-4">
                    <LabeledInfo label="Status" className="flex flex-wrap items-start gap-2">
                        {state.status && <Pill backgroundColor={state.status.backgroundColor} textColor={state.status.color} fontSize={10}>{state.status.text}</Pill>}
                        {state.firstInvoice && state.firstInvoice.isPaidOnSite &&
                            <Pill backgroundColor="#DCDCDE" textColor="#535158" fontSize={10}>Pay On Site</Pill>
                        }
                    </LabeledInfo>
                    <LabeledInfo label="Customer">
                        <Link target="_blank" to={`/users/${state.order.user.id}`} className="flex items-center gap-2">
                            <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{state.order.user.name}</span>
                            <TextTooltip text={state.order.user.name}>
                                <InfoCircleIcon width={16} height={16} />
                            </TextTooltip>
                        </Link>
                    </LabeledInfo>

                    <LabeledInfo label="Address" className="flex items-center gap-2">
                        <span className="text-primary text-th-lg font-medium whitespace-nowrap overflow-hidden text-ellipsis">{formatAddressAny(state.order)}</span>
                        <TextTooltip text={formatAddressAny(state.order)}>
                            <InfoCircleIcon width={16} height={16} />
                        </TextTooltip>
                    </LabeledInfo>
                    <LabeledInfo label="Amount due">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">$ {state.amountDue.toFixed(2) ?? '0.00'}</span>
                    </LabeledInfo>
                    <LabeledInfo label="Due Date">
                        <span className="text-primary text-th-lg whitespace-nowrap overflow-hidden text-ellipsis">{state.dueDate ? format(state.dueDate, 'dd MMMM yyyy') : '-'}</span>
                    </LabeledInfo>
                </section>

                <section className="relative mt-8 space-y-6">
                    <div className="border-l-[3px] absolute h-full top-0 left-6" />
                    <div className="bg-white relative z-10">
                        <div className="border rounded-lg p-4 flex items-center justify-between">
                            <div className="flex items-center gap-3">
                                <CustomerDetailIcon />
                                <h3 className="text-th-lg text-light">Create Order</h3>
                            </div>
                            <span><span className="font-medium">Created at:</span> {formatFullDate(state.order.createdAt)}</span>
                        </div>
                    </div>

                    {state.order.notes &&
                        <div className="bg-white relative z-10">
                            <div className="border rounded-lg p-4 flex flex-col">
                                <div className="flex items-center gap-3">
                                    <CustomerRemarkIcon />
                                    <h3 className="text-th-lg text-light">Customer's Remarks</h3>
                                </div>
                                <div className="mt-2">
                                    {state.order.notes}
                                </div>
                            </div>
                        </div>
                    }

                    {state.order.user.internalNote &&
                        <div className="bg-white relative z-10">
                            <div className="border rounded-lg p-4 flex flex-col">
                                <div className="flex items-center gap-3">
                                    <CustomerRemarkIcon />
                                    <h3 className="text-th-lg text-light">Internal Notes</h3>
                                </div>
                                <div className="mt-2">
                                    {state.order.user.internalNote}
                                </div>
                            </div>
                        </div>
                    }

                    {upsellTechnicians &&
                        <div className="bg-white relative z-10">
                            <div className="border rounded-lg p-4 flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                    <TechniciansIcon color="#1945A1" width={28} height={28} />
                                    <h3 className="text-th-lg text-light">Upsell Technicians</h3>
                                </div>
                                <div className="py-4">
                                    {upsellTechnicians ? <>Upsell by <span className="font-medium">{upsellTechnicians}</span></> : ''}
                                </div>
                            </div>
                        </div>
                    }

                    {state.order && state.order.leadSource &&
                        <div className="bg-white relative z-10">
                            <div className="border rounded-lg p-4 flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                    <AdminIcon color="#1945A1" width={28} height={28} />
                                    <h3 className="text-th-lg text-light">Lead Source</h3>
                                </div>
                                <div className="py-4">
                                    <span className="font-medium">{state.order.leadSource.name}</span>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="bg-white relative z-10 border rounded-lg p-4">
                        <div className="flex items-start gap-3 border-b pb-4">
                            <div>
                                <ServiceListIcon />
                            </div>
                            <div className="grow flex flex-col gap-3">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-th-lg text-light">Service List</h3>
                                    {state.status && state.status.text != 'Completed' && state.status.text != 'Cancelled' && state.status.text != 'Refunded' &&
                                        <Button shape="outline" onClick={() => openAddEdit()}>
                                            <PlusScheduleIcon width={24} height={24} color="#1954A1" />
                                            <span>Add Item</span>
                                        </Button>
                                    }

                                </div>
                                <div className="flex items-center justify-between">
                                    <span className="font-medium">There are {state.order.items.length} items</span>
                                    <span><span className="font-medium">Last Edited:</span> {formatFullDate(state.order.updatedAt)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            {state.order.items
                                .filter((item: any) => item.approvedAt != null && item.rejectedAt == null)
                                .sort((b: any, a: any) => isBefore(a.createdAt, b.createdAt) ? 1 : -1)
                                .map((item: any) =>
                                    <OrderItemCard
                                        key={item.id}
                                        name={item.service.name}
                                        icon={item.service.icon}
                                        frequency={item.frequency}
                                        refetchOrder={refetchOrder}
                                        orderId={state.order && state.order.id}
                                        {...item}
                                    />
                                )}
                            {state.newItemQueue.map((item: any) => {
                                const selectedFrequency = item.frequencies.find((freq: any) => freq.id == item.frequency)
                                return <OrderItemCard
                                    {...item}
                                    key={item.id}
                                    frequency={selectedFrequency ? selectedFrequency.frequency : 0}
                                    serviceCategory={selectedFrequency ? selectedFrequency.category : {}}
                                    isNewItem={true}
                                    showPrice={true}
                                    handleDelete={(id: string) => dispatch({ type: OrderDetailKind.REMOVE_NEW_ITEM, payload: id })}
                                    openEdit={openAddEdit}
                                    refetchOrder={refetchOrder}
                                    orderId={state.order && state.order.id}
                                />
                            })}
                            {state.order.items.filter((item: any) => item.rejectedAt != null).map((item: any) =>
                                <OrderItemCard
                                    key={item.id}
                                    name={item.service.name}
                                    frequency={item.frequency}
                                    refetchOrder={refetchOrder}
                                    orderId={state.order && state.order.id}
                                    {...item}
                                />
                            )}
                        </div>
                    </div>

                    {state.order && state.order.invoices.sort((a: any, b: any) => a.id - b.id).map((invoice: any) => {
                        return <InvoiceCard
                            invoice={invoice}
                            order={state.order}
                            refetchOrder={() => refetchOrder()}
                            openRecord={() => { recordPaymentModal.show(); setViewedInvoice(invoice); }}
                        />
                    })}
                </section>
                {/* Floating DOM */}
                <RecordPaymentModal modal={recordPaymentModal} viewedInvoice={viewedInvoice} refetchOrder={() => { refetchOrder() }} />
                <AddEditItemModal modal={addEditItemModal} onFinish={handleAddEditItem} defaultValue={state.defaultEditValue} />
            </>
        }
    </BaseCard>
}