import type { AuthBindings } from "@refinedev/core";
import axios from "axios";

const authProvider: AuthBindings = {
    login: async ({ email, password, deviceToken, oldDeviceToken }) => {
        try {
            const baseApi = process.env.REACT_APP_API_BASE_URL || ''
            const { data } = await axios.post(`${baseApi}/auth/admin/login`, {
                email: email, password: password, deviceToken: deviceToken, oldDeviceToken: deviceToken != oldDeviceToken ? oldDeviceToken : undefined
            }, {
                withCredentials: true
            })
            localStorage.setItem('user', JSON.stringify(data.user))
            return {
                success: true,
                redirectTo: '/'
            }
        } catch (error) {
            return {
                success: false
            }
        }
    },
    check: async (params: any) => {
        return {
            authenticated: true
        }
    },
    logout: async (params: any) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || ''
        await axios.post(`${baseApi}/auth/admin/logout`, {}, {
            withCredentials: true
        })
        localStorage.removeItem('user')
        return {
            success: true,
            redirectTo: '/login'
        }
    },
    onError: async (error) => {
        if (error.statusCode === 401 || error.statusCode === 403) {
            return {
                logout: true,
                redirectTo: "/login",
                error,
            };
        }
        return {};
    },
};

export default authProvider;