import { format } from "date-fns"
import { stringify } from "query-string"

export const buildDailyScheduleQuery = (date: any = new Date(), scheduleId?: string, orderId?: string, moveSchedule?: string) => {
    const query = stringify({
        'filters[0][field]': 'tzoffset',
        'filters[0][operator]': 'eq',
        'filters[0][value]': 8,
        'filters[1][field]': 'startdate',
        'filters[1][operator]': 'eq',
        'filters[1][value]': format(date, 'yyyy-MM-dd'),
        'filters[2][field]': scheduleId && 'schedule',
        'filters[2][operator]': scheduleId && 'eq',
        'filters[2][value]': scheduleId,
        'filters[3][field]': orderId && 'order',
        'filters[3][operator]': orderId && 'eq',
        'filters[3][value]': orderId,
        'filters[4][field]': moveSchedule && 'moveSchedule',
        'filters[4][operator]': moveSchedule && 'eq',
        'filters[4][value]': moveSchedule,
    })
    return query
}

export const scrollToTargetAdjusted = (id: string, offset = 45) => {
    var element = document.getElementById(id);
    if (!element) return false
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.scrollY - offset;
    element.classList.add('animate-pulse')
    element.classList.add('border-primary')
    element.classList.add('border-2')
    setTimeout(() => {
        if (element) {
            element.classList.remove('animate-pulse')
            element.classList.remove('border-primary')
            element.classList.remove('border-2')
        }
    }, 5000);

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
    return true
}

export const getJobTypeColor = (type: string) => {
    let backgroundColor = ''
    let textColor = ''

    switch (type) {
        case 'Service':
            backgroundColor = 'rgba(219, 123, 0, 0.15)'
            textColor = '#DB7B00'
            break
        case 'Contract':
            backgroundColor = 'rgba(255, 226, 39, 0.15)'
            textColor = '#A28C00'
            break
        case '':
            backgroundColor = 'rgba(52, 152, 219, 0.15)'
            textColor = '#3498DB'
            break
        case '':
            backgroundColor = 'rgba(100, 42, 182, 0.15)'
            textColor = '#642AB6'
            break
        default:
            break
    }



    return { backgroundColor, textColor }
}