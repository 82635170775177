import { FieldError, FieldErrors, FieldValues } from "react-hook-form"

export default function handleApiError(
    errorData: any, setError: FormFieldType['setError'],
    clearErrors: FormFieldType['clearErrors']
){
    // clearErrors()
    if(errorData){
        if(errorData.statusCode === 400){
            errorData.message.forEach((errorMsg: string) => {
                writeError(setError, errorMsg)
            })
        }
    }
}

function writeError(setError: FormFieldType['setError'], message: string){
    // Split the message
    const msgArr = message.split(' ')
    // Get the field
    const field = msgArr[0]
    // Get the field label
    const fieldArr = field.split('.')
    const fieldLabel = fieldArr[fieldArr.length - 1]
    // Set the error
    setError(field, {type: '400', message: `${fieldLabel} ${msgArr.slice(1).join(' ')}`})
}


export function getFieldErrMsg(error: FieldError | undefined, field: string, label?: string){
    if(error && error.message){
        let msg = error.message
        if(label) msg = msg.replace(field, label)
        msg = msg.toLowerCase()
        return msg.charAt(0).toUpperCase() + msg.slice(1)
    }
    return ''
}

export function findFieldErrMsg(errors: FieldErrors<FieldValues>, field: string, label?: string){
    let error: any = undefined
    for (const key in errors) {
        if(key === field){
            error = errors[key]
        }
    }
    return getFieldErrMsg(error, field, label)
}