interface FieldsGridProps{
    children: any,
    col?: number
    className?: string,
    [attrName: string]: any // HTML attributes  
}

export default function FormsGrid({children, col, className, ...rest}: FieldsGridProps){
    let classes = `forms-grid col-${col || 2}` + (className ? ` ${className}` : '')
    return (
        <div className={classes} {...rest}>{children}</div>
    )
}