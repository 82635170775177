import { useModalReturnType, useCustom } from "@refinedev/core"
import { Button } from "components/buttons"
import { AsyncMultiSelectField } from "components/forms"
import { Modal } from "components/popups"
import { format } from "date-fns"
import { baseApi } from "providers/customDataProvider"
import { useState, useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"

function AssignTechModal({ modal, viewedTeam, refresh }: { modal: useModalReturnType, viewedTeam: { team: MiscObj, date: string, viewTs: number } | null, refresh: () => void }) {
    const { control, setValue, getValues, handleSubmit, watch } = useForm()
    const [defaultValueLeaders, setDefaultValueLeaders] = useState<SelectMultiVal | undefined | null>(undefined)
    const [defaultValueAssistants, setDefaultValueAssistants] = useState<SelectMultiVal | undefined | null>(undefined)

    const technicians = watch()

    const selectedTechnicians = useMemo(() => {
        const leaderTechnicians = technicians.leaderTechnicians || []
        const assistantTechnicians = technicians.assistantTechnicians || []
        return [...leaderTechnicians, ...assistantTechnicians]
    }, [technicians])

    const { data, isFetching, isFetched, refetch } = useCustom({
        url: `service-schedules/team-assignments`,
        method: "get",
        config: {
            query: {
                team: viewedTeam?.team.id || 0,
                date: viewedTeam?.date || ''
            }
        }
    })

    const getJobType = async (search: string) => {
        const { data } = await baseApi.get(`/admin/technicians?search=${search}`)
        return data.list.map((tech: MiscObj) => ({
            value: +tech.id, label: `${tech.name}${tech.vrn ? ` - ${tech.vrn}` : ''}`
        }))
    }

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getJobType(inputValue))
    })

    const saveAssignment = handleSubmit(async (formData) => {
        try {
            if (viewedTeam && data) {
                const assignments: any = data
                const body = {
                    // assignedTechnicians: [...formData.leaderTechnicians, ...formData.assistantTechnicians],
                    assignedTechnicians: [...formData.leaderTechnicians.map((lt: any) => ({ id: lt, role: 1 })), ...formData.assistantTechnicians.map((at: any) => ({ id: at }))],
                    technicianTeam: +viewedTeam.team.id,
                    date: viewedTeam.date
                }
                if (assignments && assignments[0]) {
                    await baseApi.patch(`admin/service-schedules/team-assignments/${assignments[0].id}`, body)
                }
                else {
                    await baseApi.post(`admin/service-schedules/team-assignments`, body)
                }
                refresh()
                modal.close()
            }
        } catch (err: any) {
            console.log(err.message)
            toast.error('Failed saving assignment. ' + err.message)
        }

    })


    useEffect(() => {
        refetch()
    }, [viewedTeam?.viewTs])

    useEffect(() => {
        if (!viewedTeam) return
        const assignments: any = data
        if (isFetching || !assignments) {
            setDefaultValueAssistants(null)
            setDefaultValueLeaders(null)
            return
        }
        else if (isFetched && assignments) {
            setDefaultValueAssistants(
                assignments && assignments[0] ?
                    assignments[0].assignedTechnicians.filter((at: any) => at.role != 1 && at.technician != null).map((val: MiscObj) => ({ value: +val.technician.id, label: `${val.technician.name}${val.technician.vrn ? ` - ${val.technician.vrn}` : ''}` })) : []
            )
            setDefaultValueLeaders(
                assignments && assignments[0] ?
                    assignments[0].assignedTechnicians.filter((at: any) => at.role == 1 && at.technician != null).map((val: MiscObj) => ({ value: +val.technician.id, label: `${val.technician.name}${val.technician.vrn ? ` - ${val.technician.vrn}` : ''}` })) : []
            )
        }
    }, [isFetching, isFetched, data])

    return (
        <Modal heading={'Assign Technicians'} visible={modal.visible} close={modal.close} width="30rem">
            <div className="flex flex-col gap-6">
                <div>
                    Assign technicians below to <b>{viewedTeam?.team.name}</b> for <b>{viewedTeam ? format(viewedTeam.date, 'dd MMMM yyyy') : ''}</b>
                </div>
                {
                    (defaultValueAssistants && defaultValueLeaders) ?
                        <>
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="leaderTechnicians" loadOptions={promiseOptions}
                                label={'Team Leaders'}
                                defaultCfg={{
                                    value: defaultValueLeaders,
                                    setValue: setValue
                                }}
                                filterOption={({ value }) => !selectedTechnicians.includes(value)}
                            />
                            <AsyncMultiSelectField
                                control={control}
                                fieldName="assistantTechnicians" loadOptions={promiseOptions}
                                label={'Assistants'}
                                defaultCfg={{
                                    value: defaultValueAssistants,
                                    setValue: setValue
                                }}
                                filterOption={({ value }) => !selectedTechnicians.includes(value)}
                            />
                        </>
                        : <></>
                }
                <Button confirm onClick={saveAssignment}>Save</Button>
            </div>
        </Modal>
    )
}

export default AssignTechModal