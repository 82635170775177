import { DocumentTitleHandler } from "@refinedev/react-router-v6"

export default function PageTitle({title}: {title?: string}){
    return (
        <DocumentTitleHandler handler={(props) => documentTitleHandler(props, title)}/>
    )
}

function documentTitleHandler(props: any, title?: string){
    const titlePrefix = 'Airple Admin'
    if(title){
        return `${title} | ${titlePrefix}`
    }
    let autoGeneratedTitle = props && props.autoGeneratedTitle ? props.autoGeneratedTitle : 'Page'
    return autoGeneratedTitle.replace('| refine', '') + ` | ${titlePrefix}`
}