import { Font, StyleSheet, Document, Image, Link, Page, Text, View } from "@react-pdf/renderer";
import { formatFullAddress } from "utils/address";
import { formatFullDate } from "utils/date";
import { calculateGST } from "utils/discounts";


Font.register({
    family: "Gotham",
    fonts: [
        {
            src: '/fonts/ttf/Gotham-Book.ttf',
            format: 'truetype',
            fontWeight: 400,
        },
        {
            src: '/fonts/ttf/Gotham-Medium.ttf',
            format: 'truetype',
            fontWeight: 500,
        },
        {
            src: '/fonts/ttf/Gotham-Bold.ttf',
            format: 'truetype',
            fontWeight: 700,
        },
    ],
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Gotham',
        padding: '32px 40px 140px 40px',
        color: '#404040'
    },
    headerView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    pill: {
        backgroundColor: '#EBF1F9',
        color: '#1954A1',
        border: '1px solid #1954A1',
        borderRadius: '5px',
        padding: '15px 9px 10px 9px'
    },
    paidBadge: {
        color: 'white',
        backgroundColor: '#00A19A',
        padding: '8px 10px 8px 10px',
        fontWeight: 'bold',
        borderRadius: 5,
        fontSize: 12,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    tableContainer: {
        border: '1px solid #ECF0F8',
        borderRadius: 5,
        marginTop: 12,
        fontSize: 9,
        textAlign: 'center'
    },
    tableHeader: {
        backgroundColor: '#ECF0F8',
        padding: '16px 10px 3px 10px',
        flexDirection: 'row',
    },
    tableRow: {
        padding: '12px 10px 3px 10px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footer: {
        width: '100%',
        padding: '32px 40px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 9,
        color: '#757575',
        marginTop: 80,
        position: 'absolute',
        bottom: 0
    },
});

const QuotationDocument = ({ quotation }: { quotation: MiscObj }) => {

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.headerView}>
                <View>
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                        Quotation
                    </Text>
                    <View style={{ fontSize: 9, marginTop: 16, flexDirection: 'row', gap: 6, alignItems: 'center' }}>
                        <Text>
                            Quotation Date
                        </Text>
                        <Text style={{ fontWeight: 'medium' }}>{formatFullDate(quotation.quotationDate, { showTime: false })}</Text>
                    </View>
                    <View style={{ fontSize: 9, marginTop: 16, flexDirection: 'row', gap: 6, alignItems: 'center' }}>
                        <Text>
                            Valid Until
                        </Text>
                        <Text style={{ fontWeight: 'medium' }}>{formatFullDate(quotation.validUntil, { showTime: false })}</Text>
                    </View>
                </View>
                <View style={{ alignItems: 'flex-end' }}>
                    <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}`}>
                        <Image src={'/images/logo-clean.png'} style={{ width: 90, height: 20, objectFit: 'contain' }} />
                    </Link>
                    <View style={{ ...styles.pill, marginTop: 13, fontSize: 9, flexDirection: 'row', gap: 6, alignItems: 'center', textDecoration: 'none' }}>
                        <Text>Quotation ID</Text>
                        <Text style={{ fontWeight: 'medium' }}>{quotation.id}</Text>
                    </View>
                </View>
            </View>
            <View style={{ ...styles.headerView, alignItems: 'flex-start', marginTop: 32, gap: 24 }}>
                <View style={{ fontSize: 9, flexGrow: 1 }}>
                    <Text style={{ fontSize: 12, fontWeight: 'medium', marginTop: 14 }}>
                        Customer Details
                    </Text>
                    <View style={{ flexGrow: 1, padding: '18px 10px', border: '1px solid #ECF0F8', borderRadius: 5, marginTop: 14 }}>
                        <View style={{ flexDirection: 'row', gap: 15, borderBottom: '1px dashed #ECF0F8', paddingBottom: 20 }}>
                            <View style={{ gap: 12 }}>
                                <Text style={{ color: '#757575' }}>Name</Text>
                                <Text style={{ fontWeight: 'medium' }}>{quotation.user.name}</Text>
                            </View>
                            <View style={{ gap: 12 }}>
                                <Text style={{ color: '#757575' }}>Email</Text>
                                <Text style={{ fontWeight: 'medium' }}>{quotation.user.email ?? '-'}</Text>
                            </View>
                            <View style={{ gap: 12 }}>
                                <Text style={{ color: '#757575' }}>Phone Number</Text>
                                <Text style={{ fontWeight: 'medium' }}>{quotation.user.phoneNumber.replace('+65', '')}</Text>
                            </View>
                        </View>
                        <View style={{ marginTop: 20, flexDirection: 'column', gap: 12 }}>
                            <Text style={{ color: '#757575' }}>
                                Service Location
                            </Text>
                            {quotation.buildingName &&
                                <Text>
                                    {quotation.buildingName}
                                </Text>
                            }
                            <Text>
                                {formatFullAddress(quotation.buildingNumber, quotation.street, quotation.postalCode)}
                            </Text>
                            {quotation.notes &&
                                <Text>
                                    {quotation.notes}
                                </Text>
                            }
                        </View>
                    </View>
                </View>
                {/* {isPaid === true &&
                    <View style={styles.paidBadge}>
                        <Image src={'/images/circle_check.png'} style={{ width: 12, height: 12, objectFit: 'contain' }} />
                        <Text style={{ marginTop: 8 }}>
                            PAID
                        </Text>
                    </View>
                } */}
            </View>
            <Text style={{ fontSize: 12, marginTop: 32, fontWeight: 'medium' }}>
                Payment Details
            </Text>
            <View style={styles.tableContainer}>
                {/* HEADER */}
                <View style={styles.tableHeader}>
                    <View style={{ flex: 1, }}>
                        <Text style={{ lineHeight: 1 }}>
                            No
                        </Text>
                    </View>
                    <View style={{ flex: 2, }}>
                        <Text style={{ lineHeight: 1 }}>
                            Service Item
                        </Text>
                    </View>
                    <View style={{ flex: 2 }}>
                        <Text style={{ lineHeight: 1 }}>
                            Quantity
                        </Text>
                    </View>
                    <View style={{ flex: 2, }}>
                        <Text style={{ lineHeight: 1, textAlign: "right", paddingRight: 32 }}>
                            Total
                        </Text>
                    </View>
                </View>
                {quotation.quotationItemCarts.map((item: any, index: number) =>
                    <View wrap={false} key={item.id} style={styles.tableRow}>
                        <View style={{ flex: 1, marginLeft: 4 }}>
                            <Text style={{ lineHeight: 1 }}>
                                {index + 1}
                            </Text>
                        </View>
                        <View style={{ flex: 2, marginLeft: 12 }}>
                            <Text style={{ lineHeight: 1 }}>
                                {item.quotationItem.name}
                            </Text>
                        </View>
                        <View style={{ flex: 2, marginLeft: 14 }}>
                            <Text style={{ lineHeight: 1, textAlign: 'center' }}>
                                {item.quantity} qty
                            </Text>
                        </View>
                        <View style={{ flex: 2, textAlign: 'right', paddingRight: 24 }}>
                            <Text style={{ fontWeight: 'medium', lineHeight: 1 }}>
                                $ {(item.quantity * item.pricePerUnit).toFixed(2)}
                            </Text>
                        </View>
                    </View>
                )}
                <View style={{ padding: '18px 32px 6px 32px', borderTop: '1px solid #ECF0F8', lineHeight: 1.2, gap: 7 }}>
                    <View wrap={false} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ textAlign: 'left' }}>Subtotal <Text style={{ fontWeight: 'medium' }}>({quotation.quotationItemCarts.length} items)</Text></Text>
                        <Text style={{ fontWeight: 'medium' }}>$ {parseFloat(quotation.totalPrice).toFixed(2)}</Text>
                    </View>
                    <View wrap={false} style={{ flexDirection: 'row', justifyContent: 'space-between', color: '#757575' }}>
                        <Text style={{ textAlign: 'left' }}>Includes GST</Text>
                        <Text>$ {calculateGST(parseFloat(quotation.totalPrice)).toFixed(2)}</Text>
                    </View>
                    {(quotation.discount && parseFloat(quotation.discount) > 0) ?
                        <View wrap={false} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={{ textAlign: 'left' }}>Discount</Text>
                            <Text style={{ fontWeight: 'medium' }}>-$ {parseFloat(quotation.discount).toFixed(2)}</Text>
                        </View> : <></>
                    }
                    <View wrap={false} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ textAlign: 'left' }}>Total </Text>
                        <Text style={{ fontWeight: 'medium' }}>$ {(parseFloat(quotation.totalPrice) - parseFloat(quotation.discount || 0)).toFixed(2)}</Text>
                    </View>
                </View>
            </View>
            <View style={{ backgroundColor: '#CD7B2E', color: 'white', fontSize: 9, marginTop: 12, borderRadius: 8, padding: `2px 12px` }}>
                <Text style={{ lineHeight: 1.2, marginTop: 16, }}>
                    This quotation is not reflected in your web and app order history.
                </Text>
                <Text style={{ lineHeight: 1.2, marginTop: 2, }}>
                    Please bear with us as we work on upgrading our system.
                </Text>
            </View>
            {/* Footer */}
            <View style={styles.footer} fixed>
                <View style={{ gap: 13 }}>
                    <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}`} style={{ marginBottom: 8 }}>
                        <Image src={'/images/logo-clean.png'} style={{ width: 40, height: 12, objectFit: 'contain' }} />
                    </Link>
                    <Text style={{ fontWeight: 'medium', color: '#404040' }}>
                        AIRPLE Construction Pte. Ltd.
                    </Text>
                    <Text>
                        Company Reg 201722322H
                    </Text>
                    <Text>
                        GST Registration number: 201722322H
                    </Text>
                    <Text>
                        2 Yishun Industrial Street 1 #02-32,
                    </Text>
                    <Text>
                        North Point Bizhub Singapore (768159)
                    </Text>
                </View>
                <View style={{ gap: 13 }}>
                    <Text style={{ fontWeight: 'medium', color: '#404040' }}>
                        Ask AIRPLE
                    </Text>
                    <View>
                        <Text>
                            +65 8385 68888
                        </Text>
                    </View>
                    <View>
                        <Text>
                            ask@airple.com.sg
                        </Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
}

export default QuotationDocument