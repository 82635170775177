import { baseApi } from "providers/customDataProvider"


export const getUsers = async (keyword = '', pageSize = 10) => {
    const res = await baseApi.get(`/admin/users?pageSize=${pageSize}${keyword ? `&search=${keyword}` : ''}`)
    const users = res.data.list
    return users
}


export const postCreateUser = async (body: MiscObj) => {
    const res = await baseApi.post('/admin/users', body)
    const user = res.data
    return user
}

export const patchEditUser = async (userId: string, body: MiscObj) => {
    const res = await baseApi.patch(`/admin/users/${userId}`, body)
    const user = res.data
    return user
}

export const getUserRatings = async (userId: string) => {
    const res = await baseApi.get(`/admin/users/${userId}/ratings`)
    const user = res.data
    return user
}

