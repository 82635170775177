import Badge from "components/Badge"
import { ComponentProps, Dispatch, DispatchWithoutAction } from "react"
import { Link } from "react-router-dom"
import { numOrderSuffix } from "utils/data-formatter"
import { getScheduleStatusColor } from "utils/order"
import { formatFullDate } from 'utils/date';
import { CheckMarkCircleIcon, EditIcon, TrashIcon } from "components/Svg"
import { useModal } from "@refinedev/core"
import { AddEditItemModal } from "components/custom-modals"
import { buildDailyScheduleQuery } from "utils/scheduler"
import Pill from "components/Pill"
import { TextTooltip } from "components/tooltips"
import { Button } from "components/buttons"
import { cancelItemRequest } from "api/item-requests"
import { toast } from "sonner"

type Props = ComponentProps<'div'> & {
    name: string
    icon?: string
    frequency: number
    serviceCategory: MiscObj
    scheduleTasks?: MiscObj[]
    id: string
    notes: string
    quantity: number
    price: number
    isNewItem?: boolean
    showPrice?: boolean
    handleDelete?: (id: string) => void
    openEdit?: (id: string) => void
    rejectedAt: string | null
    isPriceSetByTechnician: boolean
    isPriceSetByAdmin: boolean
    refetchOrder: () => void
    service: MiscObj
    orderId: string
}

const OrderItemCard = (props: Props) => {
    const {
        name,
        icon,
        frequency,
        scheduleTasks = [],
        id,
        notes,
        serviceCategory,
        quantity,
        price,
        isNewItem = false,
        showPrice = false,
        handleDelete,
        openEdit,
        isPriceSetByTechnician,
        rejectedAt,
        refetchOrder,
        isPriceSetByAdmin,
        orderId,
        service,
        ...rest
    } = props

    console.log(scheduleTasks)


    const handleCancelAdditionalItem = async () => {
        try {
            if (await cancelItemRequest(id)) {
                toast.success('Additional item successfully cancelled')
            }
        }
        catch (err: any) {
            toast.error(err.message)
        } finally {
            refetchOrder()
        }
    }

    return <>
        {Array(isNewItem ? 1 : frequency).fill(0).map((_, index) => {
            const scheduleTask = scheduleTasks[index]
            const statusData = getScheduleStatusColor(isNewItem, scheduleTask && scheduleTask.schedule, rejectedAt != null)
            const completingTechnicians = scheduleTask && scheduleTask.schedule.vbPoints.map((ct: any) => ct.role == 1 ? `${ct.technician.name} (Leader)` : ct.technician.name).join(', ')
            const scheduleHref = `/daily-schedules?${buildDailyScheduleQuery(scheduleTask && scheduleTask.schedule.startDate, scheduleTask && scheduleTask.schedule.id, orderId)}`
            return <div className="flex items-stretch">
                <div {...rest} key={`${id}-${index}`} style={{ background: statusData.backgroundColor }} className="p-4 flex flex-col gap-1.5 grow">
                    <div className="flex items-start justify-between">
                        <div className="flex items-start gap-4">
                            <div className="w-12 h-12 bg-primary-3-surface rounded-lg relative overflow-hidden">
                                {frequency > 1 && <div className="absolute top-0 left-0 bg-contract z-10 text-white w-full text-center font-medium text-[0.58rem]">
                                    Contract
                                </div>
                                }
                                <Link to={`/services/edit/${service.id}`} target="_blank">
                                    <img alt="Service Icon" src={icon ? icon.replace('https:/a', 'https://a') : '/images/airple-icon.png'} className="w-12 h-12 object-contain relative rounded-lg" />
                                </Link>
                            </div>
                            <div className="flex flex-col gap-1">
                                <span>{frequency > 1 && !isNewItem ? `${numOrderSuffix(index + 1)} ` : ''}{name}{isNewItem && frequency > 1 ? ` (${frequency} times)` : ''}</span>
                                <span className="text-th-xs text-light">
                                    {serviceCategory.name} <span className="font-medium">{quantity}</span> unit {!isNewItem && <>∙ <Link to={scheduleHref} className={`text-primary font-medium text-th-sm`}>
                                        {scheduleTask ? formatFullDate(scheduleTask.schedule.startDate, { showDay: false }) : 'Schedule This Service'}
                                    </Link></>}
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center gap-8">
                            <Badge className="text-th-xs" fullOpacityBackground={true} color={statusData.badgeBackgroundColor} textColor={statusData.badgeTextColor} style={{ border: `1px solid ${statusData.badgeBorderColor}` }}>
                                {statusData.status}
                            </Badge>
                            {showPrice &&
                                <div className="flex flex-col gap-1.5 text-th-xs text-end">
                                    <span className="text-th-lg font-medium">$ {price.toFixed(2)}</span>
                                    <span className="text-light">$ {(price / quantity / frequency).toFixed(2)} / unit / service</span>
                                </div>
                            }
                        </div>
                    </div>
                    {(isPriceSetByTechnician || isPriceSetByAdmin) &&
                        <Pill backgroundColor="#EBF1F9" textColor="#1954A1" Icon={<CheckMarkCircleIcon width={12} height={12} color={'#1954A1'} />}>
                            <div className="flex items-center gap-1 pl-1">
                                Additional Service. <TextTooltip text={`Added by ${isPriceSetByTechnician ? completingTechnicians : 'admin'}`} className="font-medium">See Detail</TextTooltip>
                            </div>
                        </Pill>
                    }
                    {notes && <span className="text-th-sm text-light">Note: {notes}</span>}
                </div>
                {isNewItem &&
                    <>
                        <div className="bg-white px-6 flex items-center gap-6">
                            {openEdit &&
                                <button type="button" onClick={() => openEdit(id)}>
                                    <EditIcon />
                                </button>
                            }
                            {handleDelete &&
                                <button type="button" onClick={() => handleDelete(id)}>
                                    <TrashIcon />
                                </button>
                            }
                        </div>
                    </>
                }
                {(isPriceSetByTechnician || isPriceSetByAdmin) && statusData.status != 'Done' && rejectedAt == null &&
                    <>
                        <div className="bg-white px-6 flex items-center gap-6">
                            <TextTooltip text="Cancel Additional Item">
                                <Button confirm onClick={handleCancelAdditionalItem} shape="raw" type="button">
                                    <TrashIcon />
                                </Button>
                            </TextTooltip>
                        </div>
                    </>
                }
            </div>
        })}
    </>
}

export default OrderItemCard