import { UseUpdateReturnType } from "@refinedev/core/dist/hooks/data/useUpdate"
import handleApiError from "./handle-api-error"
import { HttpError, UseCreateReturnType } from "@refinedev/core"
import { FieldValues } from "react-hook-form"

export function handleMutation(
    mutationResult: UseUpdateReturnType<any, HttpError, FieldValues> | UseCreateReturnType<any, HttpError, FieldValues>,
    setError: FormFieldType['setError'], 
    clearErrors: FormFieldType['clearErrors'],
    disableBtn: (disable: boolean) => void,
){
    const {error, isLoading} = mutationResult
    if(isLoading){
        disableBtn(true)
    }
    else{
        if(error){
            handleApiError(error, setError, clearErrors)
        }
        disableBtn(false)    
    }
}

export function handleModalMutation(
    mutationResult: UseUpdateReturnType<any, HttpError, FieldValues> | UseCreateReturnType<any, HttpError, FieldValues>,
    setError: FormHookType['setError'],
    clearErrors: FormHookType['clearErrors'],
    closeMdl: () => void,
    disableBtn: (disable: boolean) => void,
    resetField?: FormHookType['reset']
){
    const {error, isLoading, data, reset} = mutationResult
    if(isLoading){
        disableBtn(true)
    }
    else{
        if(error){
            handleApiError(error, setError, clearErrors)
        }
        else if(data){
            closeMdl()
            reset()
        }
        if(resetField) resetField()
        disableBtn(false)    
    }
}