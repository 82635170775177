import RolePermissions from "utils/rolePermissions";

const accessControlProvider: any = {
    can: async ({
        resource,
        action,
        params,
    }: CanParams): Promise<CanReturnType> => {
        let userJSON = localStorage.getItem('user')
        const user: {[key: string]: any} | null = userJSON ? JSON.parse(userJSON) : null     
        const rolePermissions = new RolePermissions()   
        return {
            can: rolePermissions.checkPermissions(
                resource, action, (user !== null ? user.role : null)
            )
        }
    },
    options: {
        buttons: {
            enableAccessControl: true,
            hideIfUnauthorized: false,
        },
    },
};

type IResourceItem = {
    name: string
    identifier?: string
    meta?: any    
}
type BaseKey = string | number

type CanParams = {
    resource: string;
    action: string;
    params?: {
        resource?: IResourceItem;
        id?: BaseKey;
        [key: string]: any;
    };
};

type CanReturnType = {
    can: boolean;
    reason?: string;
};

export default accessControlProvider