import { OpenNotificationParams } from "@refinedev/core";

export default function getSuccessNotif(
    action: 'create' | 'update' | 'delete',
    label: string,
    actionUrl?: string 

): OpenNotificationParams{
    let key = ''
    let message = 'Success'
    if(actionUrl){
        key += `themeactionable|${actionUrl}`
    }
    else{
        key += 'themedefault'
    }
    switch (action) {
        case 'create':
            message += ' creating'
            break;
        case 'update':
            message += ' updating'
            break;
        case 'delete':
            message += ' removing'
            break;                
        default:
            break;
    }
    message += label ? ` ${label}` : ' resource'
    return {
        type: 'success',
        message: message,
        key: key,
    }
}