import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"


import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { AsyncSelectField, TextField } from 'components/forms'
import { handleModalMutation } from 'utils/handle-mutation'
import useTableQuery from 'utils/useTableQuery'
import { AIRCON_MODEL_CONFIG } from 'resources-config/aircon/aircon-model-config'
import { baseApi } from 'providers/customDataProvider'
import { Modal } from 'components/popups'
import { getErrorNotif, getSuccessNotif } from 'utils/data-formatter'

type FieldType = {
    name: string
}

export default function ListAirconModel(){
    const createAirconModelModal = useModal();
    const editAirconModelModal = useModal();
    const [editedAirconModel, setEditedAirconModel] = useState<{[key: string]: any}>({})

    const {
        tableQueryResult, pageSize, current, setCurrent, filters, setFilters,
        sorters, setSorters
    } = useTable({
        queryOptions: {retry: false,},
        pagination: {current: 1, pageSize: 20},
        resource: AIRCON_MODEL_CONFIG.name,
    })      
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const {control, doFilter} = useTableQuery(filters, setFilters)

    return (<>
        <PageTitle/>
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField 
                        control={control} fieldName='search' placeholder="Search by ID or name" 
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={AIRCON_MODEL_CONFIG.name} action='create'>
                        <Button onClick={() => {createAirconModelModal.show()}}>Add brand</Button>
                        <CreateAirconModelModal modal={createAirconModelModal}/>
                    </CanAccess>  
                </TabletWidgetRight>
            </TabletWidgets>         
            <Table>
                <THead>
                    <Th sorting={{column: 'id', sorters: sorters, setSorters: setSorters}}>ID</Th>
                    <Th sorting={{column: 'name', sorters: sorters, setSorters: setSorters}}>Name</Th>
                    <Th sorting={{column: 'brand', sorters: sorters, setSorters: setSorters}}>Brand</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((model, index) => (
                    <TBody key={index}>
                        <Td>{model.id}</Td>
                        <Td>{model.name}</Td>
                        <Td>{model.brand ? model.brand.name : '--'}</Td>
                        <TAction id={`edit-aircon-model-${model.id}`}>
                            <TActionItem access={{resource: AIRCON_MODEL_CONFIG.name, action: 'edit'}}>
                                <button type='button' className='text-sm' onClick={() => {
                                    setEditedAirconModel(model)
                                    editAirconModelModal.show()
                                }}>
                                    Edit
                                </button>                                      
                            </TActionItem>                              
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination 
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0} 
                    clickHandler={changePage} range={5}
                />             
            </div>             
            <EditAirconModelModal modal={editAirconModelModal} model={editedAirconModel}/>        
        </BaseCard>
    </>)
}

const CreateAirconModelModal = ({modal}: {modal: any}) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult, queryResult }, handleSubmit, setError,
        clearErrors, control, setValue
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: AIRCON_MODEL_CONFIG.name,
            successNotification(){
                return getSuccessNotif('create', 'aircon model')
            },
            errorNotification(data){
                return getErrorNotif('create', 'aircon model', data)
            }              
        },
    }); 
    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])    
    return (
        <Modal heading={'Create Aircon Model'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField required label={'Name'} control={control} fieldName='name'/>
                <BrandField control={control} setValue={setValue} queryResult={queryResult}/>
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

const EditAirconModelModal = (
    {modal, model}: 
    {modal: any, model: {[key: string]: string}}
) => {
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { onFinish, mutationResult, queryResult }, handleSubmit, reset, setError,
        control, clearErrors, setValue
    } = useForm<FieldType>({
        defaultValues: {name: model.name},
        refineCoreProps: {
            resource: AIRCON_MODEL_CONFIG.name, 
            action:  !model.id ? 'create' : 'edit', id: model.id || 0,
            successNotification(){
                return getSuccessNotif('update', 'aircon model')
            },
            errorNotification(data){
                return getErrorNotif('update', 'aircon model', data)
            }                
        },     
    }); 

    useEffect(() => {
        const {id, ...fields} = model
        reset(fields)
    }, [model, reset])

    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])
    return (
        <Modal heading={'Edit Aircon Model'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(data => onFinish({...data, brand: +data.brand}))} className="flex flex-col gap-4 text-th-sm">
                <TextField required label={'Name'} control={control} fieldName='name'/>
                <BrandField control={control} setValue={setValue} queryResult={queryResult}/>
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

const BrandField = ({control, queryResult, setValue}: {
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    const getBrands = async (search: string) => {
        const {data} = await baseApi.get(`/admin/aircon-brands?search=${search}`)
        return data.list.map((brand: MiscObj) => ({
            value: brand.id, label: brand.name
        }))
    }
    
    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getBrands(inputValue))
    })

    useEffect(() => {
        if(defaultValue === undefined){
            const defaultVal = (
                queryResult.data ? 
                (
                    queryResult.data.data.brand ?
                    {
                        value: queryResult.data.data.brand.id,
                        label: queryResult.data.data.brand.name,
                    } : null
                ) : 
                null
            )
            if(queryResult.fetchStatus === 'idle' && queryResult.isFetched === true){
                setDefaultValue(defaultVal)
            }
            else if(queryResult.fetchStatus === 'idle' && queryResult.isFetched === false){
                setDefaultValue(null)
            }
        }

    }, [queryResult, defaultValue])  

    return (
        defaultValue === undefined ? <></> :
        <AsyncSelectField control={control}
            fieldName={`brand`}
            label='Brand' loadOptions={promiseOptions}
            defaultCfg={{value: defaultValue, setValue: setValue}}            
        />
    )
}
