import { useForm } from "@refinedev/react-hook-form"
import { TECHNICIANS_CONFIG } from "resources-config/technicians-config";
import { useParams } from "react-router-dom";

import PageTitle from "components/PageTitle";
import FormFieldTechnician, { TechnicianFieldType } from "./FormFieldTechnician";
import { CreateEditCard } from "components/cards";
import { Button } from "components/buttons";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";
import { formatToFormData, getErrorNotif, getSuccessNotif } from "utils/data-formatter";

export default function EditTechnician() {
    const params = useParams()
    const [btnDisabled, disableBtn] = useState(false)
    const [defaultSelectValues, setDefaultSelectValues] = useState<MiscObj[]>([])
    const [showForm, setShowForm] = useState(false)

    const {
        refineCore: { queryResult, mutationResult, onFinish }, handleSubmit, register,
        control, setError, clearErrors, setValue, watch
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: TECHNICIANS_CONFIG.name,
            action: 'edit', id: params.id,
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/technicians/edit/${data.data.id}`
                }
                return getSuccessNotif('update', 'technician', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('update', 'technician', data)
            },
        },
    });
    const onFinishHandler: SubmitHandler<FieldValues> = (data) => {
        const formData = formatToFormData(data, ['profilePicture', 'skills'])
        onFinish(formData)
    }

    useEffect(() => {
        if (showForm == true) return

        if (!queryResult || !queryResult.data || !queryResult.data.data) {
            setDefaultSelectValues([])
            return
        }
        const defaultValues: MiscObj[] = []

        defaultValues.push({
            name: 'skills',
            defaultValues: queryResult.data.data.skills.map((skill: any) => ({ value: skill.id, label: skill.name }))
        })

        defaultValues.push({
            name: 'profilePicture',
            defaultValues: {
                id: null,
                src: queryResult.data.data.profilePicture
            }
        })

        setDefaultSelectValues(defaultValues)
        setShowForm(true)
    }, [queryResult])

    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    return (<>
        <PageTitle title='Edit Technician' />
        <CreateEditCard
            cardHeading="Edit Technician"
            actions={<>
                {/* <Button shape="outline" isLoading={btnDisabled}>Deactivate</Button> */}
                <Button type='submit' form='technician-form' isLoading={btnDisabled}>Save</Button>
            </>}
        >
            <FormFieldTechnician
                action="edit"
                register={register}
                formId={'technician-form'}
                handleSubmit={handleSubmit}
                onFinishHandler={onFinishHandler}
                control={control}
                queryResult={queryResult}
                setValue={setValue}
                watch={watch}
                showForm={showForm}
                defaultValues={defaultSelectValues}
            />
        </CreateEditCard>
    </>)
}