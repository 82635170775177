import { useForm } from "@refinedev/react-hook-form"
import { TECHNICIANS_CONFIG } from "resources-config/technicians-config";

import PageTitle from "components/PageTitle";
import FormFieldTechnician from "./FormFieldTechnician";
import { CreateEditCard } from "components/cards";
import { Button } from "components/buttons";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import { handleMutation } from "utils/handle-mutation";
import { formatToFormData, getErrorNotif, getSuccessNotif } from "utils/data-formatter";

export default function CreateTechnician() {
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { queryResult, mutationResult, onFinish }, handleSubmit, register,
        control, setError, clearErrors, setValue, watch
    } = useForm<MiscObj>({
        refineCoreProps: {
            resource: TECHNICIANS_CONFIG.name,
            action: 'create',
            successNotification(data) {
                let actionUrl = undefined
                if (data && data.data) {
                    actionUrl = `/technicians/edit/${data.data.id}`
                }
                return getSuccessNotif('create', 'technician', actionUrl)
            },
            errorNotification(data) {
                return getErrorNotif('create', 'technician', data)
            },
        },
    });
    const onFinishHandler: SubmitHandler<FieldValues> = (data) => {
        const formData = formatToFormData(data, ['profilePicture', 'skills'])
        onFinish(formData)
    }
    useEffect(() => {
        handleMutation(mutationResult, setError, clearErrors, disableBtn)
    }, [mutationResult, setError, clearErrors, disableBtn])

    return (<>
        <PageTitle title='Create Technician' />
        <CreateEditCard
            cardHeading="Create Technician"
            actions={<>
                <Button type='submit' form='technician-form' isLoading={btnDisabled}>Save</Button>
            </>}
        >
            <FormFieldTechnician
                action="create"
                formId={'technician-form'}
                handleSubmit={handleSubmit}
                onFinishHandler={onFinishHandler}
                control={control}
                queryResult={queryResult}
                register={register}
                setValue={setValue}
                watch={watch}
            />
        </CreateEditCard>
    </>)
}