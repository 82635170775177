import { TechniciansIcon, UserHexIcon } from "components/Svg"
import CreateTechnician from "components/pages/technicians/CreateTechnician"
import EditTechnician from "components/pages/technicians/EditTechnician"
import ListTechnician from "components/pages/technicians/ListTechnician"
import { ResourceType } from "resources-config"

export const TECHNICIANS_CONFIG: ResourceType = {
    name: 'technicians', meta: { labelAsChild: 'Personnel', icon: <TechniciansIcon /> },
    actions: {
        list: {
            path: 'technicians', page: <ListTechnician />,
            allowedRoles: ['super admin']
        },
        create: {
            path: 'technicians/create', page: <CreateTechnician />,
            allowedRoles: ['super admin']
        },
        edit: {
            path: 'technicians/edit/:id', page: <EditTechnician />,
            allowedRoles: ['super admin']
        },
    }
}