import { AdminIcon } from "components/Svg"
import ListLeadSource from "components/pages/lead-sources/ListLeadSource"
import { ResourceType } from "resources-config"

export const LEAD_SOURCES_CONFIG: ResourceType = {
    name: 'lead-sources', meta: { icon: <AdminIcon /> },
    actions: {
        list: {
            path: 'lead-sources', page: <ListLeadSource />,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }
    }
}

