import { NavLink } from "react-router-dom"

export interface AutoLinkType {
    children?: any,
    href?: string,
    className?: string,
    [attrName: string]: any // HTML attributes  
}

// Generate an anchor tag or Next link based on the URL
export default function AutoLink({children, href, className, ...rest}: AutoLinkType){
    if(href?.charAt(0) === '/'){
        return (
            <NavLink to={href || '/'} className={className} {...rest}>{children}</NavLink>
        )
    }
    return (
        <a href={href || '/'} className={className || ''} {...rest}>{children}</a>
    )
}