import { baseApi } from "providers/customDataProvider"


export const getServiceOrderDetail = async (orderId: string) => {
    const res = await baseApi.get(`/admin/service-orders/${orderId}`)
    const order = res.data
    return order
}

export const getServiceOrders = async (keyword = "", page = 1, pageSize = 10) => {
    const res = await baseApi.get(`/admin/service-orders?page=${page}&pageSize=${pageSize}&search=${keyword}`)
    const orders = res.data.list
    return orders
}

export const patchServiceOrder = async (id: string, body: MiscObj) => {
    const res = await baseApi.patch(`/admin/service-orders/${id}`, body)
}