import ListZone from "components/pages/zones/ListZone"
import { ResourceType } from "resources-config"

export const ZONES_CONFIG: ResourceType = {
    name: 'zones', meta: {parent: 'technicians'},
    actions: {
        list: {
            path: 'zones', page: <ListZone/>,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },      
        edit: {
            allowedRoles: ['super admin']
        },           
    }
}