import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"

import { SERVICE_JOB_TYPES_CONFIG } from 'resources-config/service-job-types-config'

import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { handleModalMutation } from 'utils/handle-mutation'
import { ColorPicker, TextField } from 'components/forms'
import { Modal } from 'components/popups'
import { getErrorNotif, getSuccessNotif } from 'utils/data-formatter'

type FieldType = {
    name: string,
    colorHex: string
}

export default function ListServiceJobType(){
    const createServiceJobTypeModal = useModal();
    const editServiceJobTypeModal = useModal();
    const [editedServiceJobType, setEditedServiceJobType] = useState<{[key: string]: any}>({})

    const {tableQueryResult, pageSize, current, setCurrent} = useTable({
        queryOptions: {retry: false,},
        pagination: {current: 1, pageSize: 20},
        resource: SERVICE_JOB_TYPES_CONFIG.name,
    })      
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    return (<>
        <PageTitle/>
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft></TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={SERVICE_JOB_TYPES_CONFIG.name} action='create'>
                        <Button onClick={() => {createServiceJobTypeModal.show()}}>
                            Add service job type
                        </Button>
                        <CreateServiceJobTypeModal modal={createServiceJobTypeModal}/>
                    </CanAccess>  
                </TabletWidgetRight>
            </TabletWidgets>         
            <Table>
                <THead>
                    <Th>ID</Th><Th>Name</Th><Th>Color</Th><Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((serviceJobType, index) => (
                    <TBody key={index}>
                        <Td>{serviceJobType.id}</Td>
                        <Td>{serviceJobType.name}</Td>
                        <Td>
                            <span className='inline-block w-6 h-6 rounded-full' style={{background: serviceJobType.colorHex || 'transparent'}}>
                            </span>
                        </Td>
                        <TAction id={`edit-service-job-type-${serviceJobType.id}`}>
                            <TActionItem access={{resource: SERVICE_JOB_TYPES_CONFIG.name, action: 'edit'}}>
                                <button type='button' className='text-sm' onClick={() => {
                                    setEditedServiceJobType(serviceJobType)
                                    editServiceJobTypeModal.show()
                                }}>
                                    Edit
                                </button>                                      
                            </TActionItem>                            
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination 
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0} 
                    clickHandler={changePage} range={5}
                />           
            </div>             
            <EditServiceJobTypeModal modal={editServiceJobTypeModal} serviceJobType={editedServiceJobType}/>        
        </BaseCard>
    </>)
}

const CreateServiceJobTypeModal = ({modal}: {modal: any}) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult }, 
        handleSubmit, setError, clearErrors, control
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: SERVICE_JOB_TYPES_CONFIG.name,
            successNotification(){
                return getSuccessNotif('create', 'service job')
            },
            errorNotification(data){
                return getErrorNotif('create', 'service job', data)
            }              
        },     
    }); 
    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])        
    return (
        <Modal heading={'Create Service Job Type'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField 
                    control={control} fieldName='name' 
                    label={'Name'} required
                />
                <ColorPicker 
                    control={control} fieldName='colorHex' label={'Color Hex'}
                    required errorCfg={{errLabel: 'Color Hex'}}
                /> 
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

const EditServiceJobTypeModal = (
    {modal, serviceJobType}: 
    {modal: any, serviceJobType: {[key: string]: string}}
) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, 
        setError,clearErrors, control, reset
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: SERVICE_JOB_TYPES_CONFIG.name, 
            action:  !serviceJobType.id ? 'create' : 'edit', id: serviceJobType.id || 0,
            successNotification(){
                return getSuccessNotif('update', 'service job')
            },
            errorNotification(data){
                return getErrorNotif('update', 'service job', data)
            }                
        },     
    }); 

    useEffect(() => {
        const {id, ...fields} = serviceJobType
        reset(fields)
    }, [serviceJobType, reset])

    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])   
    return (
        <Modal heading={'Edit Service Job Type'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField 
                    control={control} fieldName='name' 
                    label={'Name'} required
                />
                <ColorPicker 
                    control={control} fieldName='colorHex' label={'Color Hex'}
                    required errorCfg={{errLabel: 'Color Hex'}}
                /> 
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

