import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { BaseFormProps } from './types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { getFieldErrMsg } from 'utils/handle-api-error';

type AsyncSelectFieldProps = BaseFormProps & {
    loadOptions: (inputValue: string) => Promise<any[]>,
    setValue?: FormHookType['setValue'],
    defaultValue?: SelectSingleVal
    placeholder?: string
    onCreateOption?: (inputValue: string) => void
    onInputChange?: () => void
    defaultCfg?: {
        value: SelectSingleVal
        setValue: FormHookType['setValue']
    }
}

export default function AsyncSelectField({
    fieldName, control, loadOptions, defaultValue, errorCfg, defaultCfg,
    label, setValue, desc, className, placeholder, onCreateOption, ...rest
}: AsyncSelectFieldProps) {
    const reactSelectId = useMemo(() => (Date.now().toString()), [])
    const [isMounted, setIsMounted] = useState(false);
    const [isDefaultSet, setIsDefaultSet] = useState(false);
    const { field, fieldState } = useController({ name: fieldName, control, });
    let classes = 'form-group' + (className ? ' ' + className : '')
    classes += rest.required ? ' required' : ''

    useEffect(() => setIsMounted(true), []);

    // useEffect(() => {
    //     if (defaultCfg && defaultCfg.setValue && defaultCfg.value !== undefined) {
    //         defaultCfg.setValue(fieldName, defaultCfg.value ? defaultCfg.value.value : null)
    //     }
    // }, [defaultCfg?.value, defaultCfg?.setValue])

    useEffect(() => {
        if (!isDefaultSet && defaultCfg?.setValue && defaultCfg.value !== undefined) {
            defaultCfg.setValue(fieldName, defaultCfg.value ? defaultCfg.value.value : null);
            setIsDefaultSet(true); // Mark that the default value is set
        }
    }, [defaultCfg, fieldName, isDefaultSet]);

    if (!isMounted) {
        return null
    }
    return (
        <span className={classes}>
            {label ? <label className='form-label'>{label}</label> : ''}
            {onCreateOption ?
                <AsyncCreatableSelect
                    id={reactSelectId}
                    ref={field.ref}
                    onCreateOption={onCreateOption}
                    placeholder={placeholder}
                    loadOptions={loadOptions}
                    // loadOptions={debounce(loadOptions, 250)} 
                    defaultOptions
                    defaultValue={defaultCfg && defaultCfg.value ? defaultCfg.value : null}
                    onChange={(opt) => field.onChange(opt ? opt.value : null)}
                    isOptionDisabled={(option: any) => option.isDisabled}
                    menuPortalTarget={document.body}
                    required={rest.required}
                    // @ts-ignore
                    isValidNewOption={onCreateOption ? undefined : () => false}
                    isClearable
                    styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            borderWidth: '1.4px', borderStyle: 'solid',
                            borderColor: state.isFocused ? '#EAECF0 !important' : '#EAECF0 !important',
                            borderRadius: '8px', boxShadow: "none",
                            outline: 'none', padding: '0em 0.2em'
                        }),
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                /> :
                <AsyncSelect
                    id={reactSelectId}
                    ref={field.ref}
                    placeholder={placeholder}
                    loadOptions={loadOptions}
                    // loadOptions={debounce(loadOptions, 250)} 
                    defaultOptions
                    defaultValue={defaultCfg && defaultCfg.value ? defaultCfg.value : null}
                    onChange={(opt) => field.onChange(opt ? opt.value : null)}
                    isOptionDisabled={(option: any) => option.isDisabled}
                    menuPortalTarget={document.body}
                    required={rest.required}
                    isClearable
                    styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            borderWidth: '1.4px', borderStyle: 'solid',
                            borderColor: state.isFocused ? '#EAECF0 !important' : '#EAECF0 !important',
                            borderRadius: '8px', boxShadow: "none",
                            outline: 'none', padding: '0em 0.2em'
                        }),
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                />
            }
            <span className="flex flex-col">
                {!desc ? <></> : <span className="desc">{desc}</span>}
                {
                    !fieldState.error || (errorCfg && errorCfg.hideErrMsg) ? <></> :
                        <span className="err-msg">
                            {getFieldErrMsg(
                                fieldState.error, fieldName, (errorCfg && errorCfg.errLabel ? errorCfg.errLabel : '')
                            )}
                        </span>
                }
            </span>
        </span>
    )
}