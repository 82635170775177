import { ComponentProps } from "react"

type Props = ComponentProps<'div'> & {
    imageSrc: string,
    color?: string,
}

const Technichian = (props: Props) => {
    const { imageSrc, color, className = "w-14 h-14", ...rest } = props


    return <div className={`relative rounded-full overflow-hidden ${className}`} {...rest}>
        <img src={imageSrc && imageSrc.replace('https:/a', 'https://a') || '/images/airple-icon.png'} className="w-full h-full object-cover" alt="Technician photo" />
    </div>
}

export default Technichian