import { BaseFormProps } from "./types"
import { CounterMinusIcon, CounterPlusIcon } from "components/Svg"
import { useController } from "react-hook-form"

type Props = BaseFormProps & {
    unitType?: string
}


const CounterField = (props: Props) => {
    const { label, control, fieldName, id, defaultValue = 0, unitType = 'units', disabled, required } = props
    const { field: { onChange, value } } = useController({ name: fieldName, control: control, defaultValue: defaultValue })

    const handleIncrement = () => {
        onChange(value + 1)
    }

    const handleDecrement = () => {
        if (value == 1) return
        onChange(value - 1)
    }

    return <div className={`form-group ${required ? 'required' : ''}`}>
        {label &&
            <label htmlFor={id} className="form-label">
                {label}
            </label>
        }
        <div className={`border-2 border-[#EAECF0] rounded-lg flex justify-center items-center gap-4 py-[0.4rem] px-3 ${disabled ? 'opacity-50 bg-gray-200' : ''}`}>
            <button disabled={disabled} type="button" onClick={handleDecrement} className="border border-body rounded-full">
                <CounterMinusIcon width={16} height={16} />
            </button>
            <span>{value}</span>
            <button disabled={disabled} type="button" onClick={handleIncrement} className="border border-body rounded-full">
                <CounterPlusIcon width={16} height={16} />
            </button>
            <span>{unitType}</span>
        </div>
    </div>
}

export default CounterField