import { parseISO, toDate, addMinutes } from 'date-fns';

function localToUTC(date: string | Date): Date {
    try {
        let localDate: Date;

        // If the input is a string, parse it to a Date object
        if (typeof date === 'string') {
            localDate = toDate(parseISO(date));
        } else {
            // If the input is already a Date object, use it directly
            localDate = date;
        }

        // Get the local timezone offset in minutes
        const timezoneOffset = -480;

        // Adjust the local date to UTC by subtracting the offset
        const utcDate = addMinutes(localDate, timezoneOffset);

        return utcDate;
    }
    catch (err) {
        console.log(err)
        return new Date(date)
    }

}

export default localToUTC