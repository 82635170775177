export default function formatToFormData(data: MiscObj, formDataFields: string[], objectFields?: string[]){
    const formData = new FormData()
    for (const key in data) {
        if(formDataFields.includes(key) && data[key] && data[key][0]){
            if(data[key].length > 1){
                for (const index in data[key]) {
                    formData.append(key, data[key][index])
                }
            }
            else{
                formData.append(key, data[key][0])
            }
        }
        else{
            if(objectFields && objectFields.includes(key) && data[key]){
                formData.append(key, JSON.stringify(data[key]))
            }
            else{
                formData.append(key, data[key] || '')
            }
        }
    }
    return formData    
}