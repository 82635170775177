import { baseApi } from "providers/customDataProvider"


export const getPriorityBookings = async (filter?: 'pending' | 'rejected' | 'accepted') => {
    const res = await baseApi.get(`/admin/service-schedules/priority-bookings?status=${filter || ''}`)
    const priorityBookings = res.data
    return priorityBookings
}

export const acceptPriorityBooking = async (scheduleId: string) => {
    const res = await baseApi.patch(`/admin/service-schedules/priority-bookings/${scheduleId}/accept`)
    return res.data.message == 'Success'
}

export const rejectPriorityBooking = async (scheduleId: string) => {
    const res = await baseApi.patch(`/admin/service-schedules/priority-bookings/${scheduleId}/reject`)
    return res.data.message == 'Success'
}