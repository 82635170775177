import { TextField, SelectField, AsyncSelectField, FormsGrid } from 'components/forms'
import { FieldValues, UseFormWatch, useFieldArray } from 'react-hook-form'
import { CloseCircleIcon } from 'components/Svg'
import { baseApi } from "providers/customDataProvider"
import { useCallback, useEffect, useState } from "react"
import { Button } from 'components/buttons'
import FormFieldPricings from "./FormFieldPricing"
import Badge from 'components/Badge'
import { Tab, TabPanel } from 'components/Tab'
import Accordion from 'components/Accordion'

export default function FormFieldFrequencies({ register, control, queryResult, watch, setValue }: {
    queryResult: FormFieldType['queryResult']
    register: FormFieldType['register']
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    watch: UseFormWatch<FieldValues>
}) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `frequencies`
    });
    const watchedFields = watch()
    const addFreq = useCallback(() => {
        append({
            frequency: '', label: '', category: null, isRecomended: null,
            shownOnSearch: null, pricings: []
        })
    }, [append])
    return (
        <div className='flex flex-col gap-8 mt-4'>
            {!fields || fields.length === 0 ? <></> :
                watchedFields.frequencies.map((frequency: any, index: number) => {
                    let title = 'Frequency'
                    title += frequency ? ' ' + frequency.frequency : '--'
                    title += frequency.category && frequency.category.label ? ` (${frequency.category.label})` : ''
                    const SearchBadge = (
                        frequency.shownOnSearch ?
                            <Badge color='success' className='text-xs font-normal'>Shown on search</Badge> :
                            <></>
                    )
                    const RecommendBadge = (
                        frequency.isRecomended ?
                            <Badge color='success' className='text-xs font-normal'>Recomended</Badge> :
                            <></>
                    )
                    const category = frequency.category
                    let CatBadge = <></>
                    if (category && typeof category === 'string') {
                        const catValSplit = category.split('__')
                        CatBadge = (
                            <Badge color='disable' className='text-xs font-normal'>{catValSplit[1]}</Badge>
                        )
                    }

                    return (
                        <Accordion styling='card' key={index} heading={
                            <span className='gap-2 flex-ct-y'>
                                <button type='button' onClick={() => { remove(index) }}>
                                    <CloseCircleIcon className='w-4' color='#868686' />
                                    <span className='sr-only'>Remove frequency</span>
                                </button>
                                <span className='gap-3 flex-ct-y'>
                                    {title}
                                    {CatBadge}
                                    {RecommendBadge}
                                    {SearchBadge}
                                </span>
                            </span>
                        }>
                            <div className='flex flex-col gap-4'>
                                <Tab>
                                    <TabPanel id={`freq-detail-${index}`} title='Details'>
                                        <FormsGrid col={2} className='mb-4'>
                                            <TextField label='Frequency' required control={control}
                                                fieldName={`frequencies.${index}.frequency`} isNumeric={true}
                                            />
                                            <TextField label='Label' control={control} fieldName={`frequencies.${index}.label`} />
                                        </FormsGrid>
                                        <FormsGrid col={2} className='mb-4'>
                                            <TextField label='Promotional Text' control={control} fieldName={`frequencies.${index}.promotionalText`} />
                                            <TextField label='Highlight Text' control={control} fieldName={`frequencies.${index}.highlightText`} />
                                        </FormsGrid>
                                        <FormsGrid col={3} className='mb-4'>
                                            <CategoryField index={index} queryResult={queryResult} control={control} setValue={setValue} />
                                            <IsRecomendedField index={index} queryResult={queryResult} control={control} setValue={setValue} />
                                            <ShownOnSearchField index={index} queryResult={queryResult} control={control} setValue={setValue} />
                                        </FormsGrid>
                                        <FormsGrid col={2}>
                                            <TextField label='Start Duration' required control={control}
                                                fieldName={`frequencies.${index}.startDuration`} isNumeric={true}
                                            />
                                            <TextField label='Duration Multiples' required control={control}
                                                fieldName={`frequencies.${index}.durationMultiples`} isNumeric={true}
                                            />
                                        </FormsGrid>
                                    </TabPanel>
                                    <TabPanel id={`freq-pricing-${index}`} title='Pricings'>
                                        <FormFieldPricings
                                            watchedFields={watchedFields}
                                            parentIdx={index}
                                            register={register}
                                            control={control}
                                        />
                                    </TabPanel>
                                </Tab>
                            </div>
                        </Accordion>
                    )
                })}
            <Button type='button' className='mx-auto mt-4 text-th-xs' onClick={addFreq}>
                + Add New Frequency
            </Button>
        </div>
    )
}

const CategoryField = ({ index, control, queryResult, setValue }: {
    index: number
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    const getServiceCat = async (search: string) => {
        const { data } = await baseApi.get(`/admin/service-categories?search=${search}`)
        return data.list.map((serviceUnit: MiscObj) => ({
            value: `${serviceUnit.id}__${serviceUnit.name}`, label: serviceUnit.name
        }))
    }

    const promiseOptions = (inputValue: string) => new Promise<any[]>((resolve) => {
        resolve(getServiceCat(inputValue))
    })

    useEffect(() => {
        if (defaultValue === undefined) {
            const defaultVal = (
                queryResult.data ?
                    (
                        queryResult.data.data.frequencies[index] && queryResult.data.data.frequencies[index].category ?
                            {
                                value: `${queryResult.data.data.frequencies[index].category.id}__${queryResult.data.data.frequencies[index].category.name}`,
                                label: queryResult.data.data.frequencies[index].category.name,
                            } : null
                    ) :
                    null
            )
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(defaultVal)
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }

    }, [queryResult, defaultValue, index])

    return (
        defaultValue === undefined ? <></> :
            <AsyncSelectField control={control}
                required
                fieldName={`frequencies.${index}.category`}
                label='Category' loadOptions={promiseOptions}
                defaultCfg={{ value: defaultValue, setValue: setValue }}
            />
    )
}

const IsRecomendedField = ({ index, control, queryResult, setValue }: {
    index: number
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const data = queryResult.data && queryResult.data.data ? queryResult.data.data : undefined
            const currentValue = data && data.frequencies && data.frequencies[index] ? data.frequencies[index].isRecomended : null

            const defaultVal = (
                currentValue === null ? null : {
                    value: currentValue,
                    label: (
                        currentValue === true ?
                            'True' : 'False'
                    ),
                }
            )
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(defaultVal)
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }
    }, [queryResult, defaultValue, index])

    return (
        defaultValue === undefined ? <></> :
            <SelectField control={control} label='Is Recommended'
                fieldName={`frequencies.${index}.isRecomended`}
                defaultCfg={{ value: defaultValue, setValue: setValue }}
                options={[
                    { value: true, label: 'True' },
                    { value: false, label: 'False' },
                ]}
            />
    )
}

const ShownOnSearchField = ({ index, control, queryResult, setValue }: {
    index: number
    control: FormFieldType['control']
    setValue: FormHookType['setValue']
    queryResult: FormFieldType['queryResult']
}) => {
    const [defaultValue, setDefaultValue] = useState<SelectSingleVal | undefined | null>(undefined)

    useEffect(() => {
        if (defaultValue === undefined) {
            const data = queryResult.data && queryResult.data.data ? queryResult.data.data : undefined
            const currentValue = data && data.frequencies && data.frequencies[index] ? data.frequencies[index].shownOnSearch : null

            const defaultVal = (
                currentValue === null ? null : {
                    value: currentValue,
                    label: (
                        currentValue === true ?
                            'True' : 'False'
                    ),
                }
            )
            if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === true) {
                setDefaultValue(defaultVal)
            }
            else if (queryResult.fetchStatus === 'idle' && queryResult.isFetched === false) {
                setDefaultValue(null)
            }
        }
    }, [queryResult, defaultValue, index])

    return (
        defaultValue === undefined ? <></> :
            <SelectField control={control} label='Shown On Search'
                fieldName={`frequencies.${index}.shownOnSearch`}
                defaultCfg={{ value: defaultValue, setValue: setValue }}
                options={[
                    { value: true, label: 'True' },
                    { value: false, label: 'False' },
                ]}
            />
    )
}