import { useEffect, useRef } from "react"

export interface PopUpProps{
    visible: boolean
    children?: JSX.Element | JSX.Element[]
    width?: string,
    className?: string
    [key: string]: any
}
export default function PopUp(
    {visible, children, width, className, ...rest}: PopUpProps
){
    const ref = useRef<HTMLDivElement | null>(null)
    const timeOutId = useRef<any | null>(null)
    let classes = 'modal' + (className ? ' '+className : '')
    
    useEffect(() => {
        if(ref && ref.current){
            if(visible){
                ref.current.classList.add('is-visible', 'is-shown')
            }
            else{
                ref.current.classList.remove('is-shown')
                timeOutId.current = setTimeout(() => {
                    ref.current?.classList.remove('is-visible')
                }, 300)
            }
        }
    }, [visible, ref, timeOutId])

    useEffect(() => {
        return () => {
            clearTimeout(timeOutId.current)
        }
    }, [timeOutId])
    return (
        <section ref={ref} className={classes} {...rest}>
            <div className="content" style={{width: width || 'auto'}}>
                <div className="body">{children}</div>
            </div>
        </section>
    )
}