import ListInvoice from "components/pages/invoice/ListInvoice"
import ShowInvoice from "components/pages/invoice/ShowInvoice"
import { ResourceType } from "resources-config"

export const INVOICES_CONFIG: ResourceType = {
    name: 'invoices', meta: {
        parent: 'quotations', label: "Invoice",
        apiEndpoints: {
            getList: 'quotations',
        }
    },
    actions: {
        list: {
            path: '/invoices', page: <ListInvoice />,
            allowedRoles: ['super admin']
        },
        show: {
            path: '/invoices/:id', page: <ShowInvoice />,
            allowedRoles: ['super admin']
        }
    }
}

