import ListTechnicianCarryCash from "components/pages/technician-carry-cash/ListTechnicianCarryCash"
import { TaskBoardIcon, TechnicianCarryCashIcon } from "components/Svg"
import { ResourceType } from "resources-config"

export const TECHNICIAN_CARRY_CASH_CONFIG: ResourceType = {
    name: 'technician-carry-cash',
    meta: {
        icon: <TechnicianCarryCashIcon />,
        label: 'Technician Carry Cash',
        apiEndpoints: {
            getList: '/service-invoices/invoice-histories'
        }
    },
    actions: {
        list: {
            path: 'technician-carry-cash', page: <ListTechnicianCarryCash />,
            allowedRoles: ['super admin']
        },
        create: {
            allowedRoles: ['super admin']
        },
        edit: {
            allowedRoles: ['super admin']
        }
    }
}

