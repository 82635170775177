import { HttpError, OpenNotificationParams } from "@refinedev/core";

export default function getErrorNotif(
    action: 'create' | 'update' | 'delete',
    label: string,
    error?: HttpError | undefined

): OpenNotificationParams{
    let message = 'Error'
    switch (action) {
        case 'create':
            message += ' creating'
            break;
        case 'update':
            message += ' updating'
            break;
        case 'delete':
            message += ' removing'
            break;                
        default:
            break;
    }
    message += label ? ` ${label}` : ' resource'
    return {
        type: 'error',
        message: message,
        description: error?.message,
        key: `themedefault`,
    }
}