import { baseApi } from "providers/customDataProvider"


export const postCreatePromo = async (body: MiscObj) => {
    const res = await baseApi.post('/admin/service-promos', body)
    return res.data
}

export const patchEditPromo = async (id: string, body: MiscObj) => {
    const res = await baseApi.patch('/admin/service-promos/' + id, body)
    return res.data
}

export const getPromoDetail = async (id: string) => {
    const res = await baseApi.get(`/admin/service-promos/${id}`)
    return res.data
}