import { QuotationIcon } from "components/Svg"
import CreateQuotation from "components/pages/quotation/CreateQuotation"
import EditQuotation from "components/pages/quotation/EditQuotation"
import ListQuotation from "components/pages/quotation/ListQuotation"
import ShowQuotation from "components/pages/quotation/ShowQuotation"
import { ResourceType } from "resources-config"

export const QUOTATION_CONFIG: ResourceType = {
    name: 'quotations', meta: {
        labelAsChild: 'Quotation', icon: <QuotationIcon />, label: 'Quotation',
    },
    actions: {
        list: {
            path: 'quotations', page: <ListQuotation />,
            allowedRoles: ['super admin'],
        },
        create: {
            path: 'quotations/create', page: <CreateQuotation />,
            allowedRoles: ['super admin'],
        },
        show: {
            path: 'quotations/:id', page: <ShowQuotation />,
            allowedRoles: ['super admin']
        },
        edit: {
            path: 'quotations/:id/edit', page: <EditQuotation />,
            allowedRoles: ['super admin']
        }
    }
}