import { useTable } from "@refinedev/core"
import { Table, TAction, TActionItem, TBody, Td, Th, THead } from "components/Table"
import { NavLink } from "react-router-dom"
import { formatFullDate } from "utils/date"


type Props = {
    rating?: MiscObj
    userId?: any
}

const ShowUserRatingList = (props: Props) => {
    const { rating, userId } = props

    return <>
        <Table>
            <THead>
                <Th>Order & Job ID</Th>
                <Th>Date</Th>
                <Th>Technician</Th>
                <Th>Score</Th>
                <Th>Comment</Th>
                <Th>Actions</Th>
            </THead>
            {(rating && rating.data) ? rating.data.map((data: any) =>
                <TBody key={`rating-${data.id}`}>
                    <Td>{data.schedule ? `OID${data.schedule.order.id} - JID${data.schedule.id}` : '-'}</Td>
                    <Td>{formatFullDate(data.createdAt, { showDay: false, showTime: false })}</Td>
                    <Td>{data.ratedBy.name}</Td>
                    <Td>{data.rating.toFixed(2)}</Td>
                    <Td>
                        Technician comments : {data.otherComments}
                        {data.isRude ? <span className="font-medium"><br /> * Customer is rude</span> : <></>}
                        {data.cannotSpeakEnglish ? <span className="font-medium"><br /> * Customer cannot speak english</span> : <></>}
                        {data.isLookingAttech ? <span className="font-medium"><br /> * Customer is looking at technician</span> : <></>}
                        {data.isRushing ? <span className="font-medium"><br /> * Customer is rushing</span> : <></>}
                    </Td>
                    <TAction id={`rating-${data.id}`}>
                        {data.schedule ?
                            <>
                                <TActionItem>
                                    <NavLink className={'text-sm'} to={`/service-orders/${data.schedule.order.id}`}>View Order Info</NavLink>
                                </TActionItem>
                            </> : <></>
                        }
                    </TAction>
                </TBody>
            ) : <></>}

        </Table>

    </>
}

export default ShowUserRatingList