import { useState, useCallback, useEffect } from 'react'
import { useModal, useTable, CanAccess } from "@refinedev/core"
import { useForm } from "@refinedev/react-hook-form"


import { TAction, TActionItem, TBody, THead, Table, Td, Th } from "components/Table"
import Pagination from 'components/Pagination'
import PageTitle from "components/PageTitle"
import { BaseCard } from 'components/cards'
import { Button } from 'components/buttons'
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from 'components/TableWidgets'
import { TextField } from 'components/forms'
import { handleModalMutation } from 'utils/handle-mutation'
import { AIRCON_BRAND_CONFIG } from 'resources-config/aircon/aircon-brand-config'
import useTableQuery from 'utils/useTableQuery'
import { Modal } from 'components/popups'
import { getErrorNotif, getSuccessNotif } from 'utils/data-formatter'

type FieldType = {
    name: string
}

export default function ListAirconBrand(){
    const createAirconBrandModal = useModal();
    const editAirconBrandModal = useModal();
    const [editedAirconBrand, setEditedAirconBrand] = useState<{[key: string]: any}>({})

    const {
        tableQueryResult, pageSize, current, setCurrent, filters, setFilters,
        sorters, setSorters
    } = useTable({
        queryOptions: {retry: false,},
        pagination: {current: 1, pageSize: 20},
        resource: AIRCON_BRAND_CONFIG.name,
    })      
    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const {control, doFilter} = useTableQuery(filters, setFilters)

    return (<>
        <PageTitle/>
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>
                    <TextField 
                        control={control} fieldName='search' placeholder="Search by ID or name" 
                        className='w-full' handleEnter={doFilter}
                    />
                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <CanAccess resource={AIRCON_BRAND_CONFIG.name} action='create'>
                        <Button onClick={() => {createAirconBrandModal.show()}}>Add brand</Button>
                        <CreateAirconBrandModal modal={createAirconBrandModal}/>
                    </CanAccess>  
                </TabletWidgetRight>
            </TabletWidgets>         
            <Table>
                <THead>
                    <Th sorting={{column: 'id', sorters: sorters, setSorters: setSorters}}>ID</Th>
                    <Th sorting={{column: 'name', sorters: sorters, setSorters: setSorters}}>Name</Th>
                    <Th>Actions</Th>
                </THead>
                {!tableQueryResult.data ? [[]] : tableQueryResult.data.data.map((brand, index) => (
                    <TBody key={index}>
                        <Td>{brand.id}</Td>
                        <Td>{brand.name}</Td>
                        <TAction id={`edit-aircon-brand-${brand.id}`}>
                            <TActionItem access={{resource: AIRCON_BRAND_CONFIG.name, action: 'edit'}}>
                                <button type='button' className='text-sm' onClick={() => {
                                    setEditedAirconBrand(brand)
                                    editAirconBrandModal.show()
                                }}>
                                    Edit
                                </button>                                      
                            </TActionItem>                              
                        </TAction>
                    </TBody>
                ))}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination 
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0} 
                    clickHandler={changePage} range={5}
                />             
            </div>             
            <EditAirconBrandModal modal={editAirconBrandModal} brand={editedAirconBrand}/>        
        </BaseCard>
    </>)
}

const CreateAirconBrandModal = ({modal}: {modal: any}) => {
    const [btnDisabled, disableBtn] = useState(false)
    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, setError,
        clearErrors, control 
    } = useForm<FieldType>({
        refineCoreProps: {
            resource: AIRCON_BRAND_CONFIG.name,
            successNotification(){
                return getSuccessNotif('create', 'aircon brand')
            },
            errorNotification(data){
                return getErrorNotif('create', 'aircon brand', data)
            }
        },
    }); 
    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])    
    return (
        <Modal heading={'Create Aircon Brand'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField required label={'Name'} control={control} fieldName='name'/>
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

const EditAirconBrandModal = (
    {modal, brand}: 
    {modal: any, brand: {[key: string]: string}}
) => {
    const [btnDisabled, disableBtn] = useState(false)

    const {
        refineCore: { onFinish, mutationResult }, handleSubmit, reset, setError,
        control, clearErrors,
    } = useForm<FieldType>({
        defaultValues: {name: brand.name},
        refineCoreProps: {
            resource: AIRCON_BRAND_CONFIG.name, 
            action:  !brand.id ? 'create' : 'edit', id: brand.id || 0,
            successNotification(){
                return getSuccessNotif('update', 'aircon brand')
            },
            errorNotification(data){
                return getErrorNotif('update', 'aircon brand', data)
            }            
        },     
    }); 

    useEffect(() => {
        const {id, ...fields} = brand
        reset(fields)
    }, [brand, reset])

    useEffect(() => {
        handleModalMutation(
            mutationResult, setError, clearErrors, modal.close,
            disableBtn
        )
    }, [mutationResult, setError, clearErrors, disableBtn, modal.close])
    return (
        <Modal heading={'Edit Aircon Brand'} visible={modal.visible} close={modal.close} width='34rem'>
            <form onSubmit={handleSubmit(onFinish)} className="flex flex-col gap-4 text-th-sm">
                <TextField required label={'Name'} control={control} fieldName='name'/>
                <div className='justify-end mt-1 flex-ct-y text-th-xs'>
                    <Button type='submit' isLoading={btnDisabled}>Save</Button>
                </div>
            </form>
        </Modal>        
    )
}

