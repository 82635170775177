import { DocumentIcon } from "components/Svg"
import ListAirconModel from "components/pages/aircon/ListAirconModel"
import { ResourceType } from "resources-config"
import { AIRCON_BRAND_CONFIG } from "./aircon-brand-config"

export const AIRCON_MODEL_CONFIG: ResourceType = {
    name: 'aircon-models', meta: {parent: AIRCON_BRAND_CONFIG.name, label: 'Models'},
    actions: {
        list: {
            path: 'aircon-models', page: <ListAirconModel/>,
            allowedRoles: ['super admin'],
        },
        create: {
            path: 'aircon-models', allowedRoles: ['super admin'],
        },
        edit: {
            path: 'aircon-models', allowedRoles: ['super admin'],
        }            
    }
}