import { format, getDay, isSameMonth, addDays, subDays,  } from "date-fns"
import React, { useMemo } from "react"

type CalendarProps = {
    startDate: string
    children: React.ReactNode | React.ReactNode[]
    handleClick?: (date?: string) => void
    view?: 'month' | 'week' | string
    startWeek?: 'monday' | 'sunday' 
}

export default function Calendar({startDate, children, handleClick, view, startWeek}: CalendarProps){
    let classes = 'calendar'
    classes += view === 'week' ? ' week-view' : ''
    const dateObjs = useMemo(() => {
        let startDateObj = new Date(format(new Date(startDate), 'yyyy-MM-dd'))
        const weekOrder = (
            startWeek === 'monday' ? [1,2,3,4,5,6,0] : [0,1,2,3,4,5,6]
        )
        if(!view || view === 'month'){
            startDateObj = new Date(
                format(startDate, 'yyyy-MM')+'-01'
            )
        }
        let dateObjs: Date[] = []
        let dateObj = new Date(startDateObj)

        if(!view || view === 'month'){
            const startDay = getDay(dateObj)
            const startDayIndex = weekOrder.indexOf(startDay)
            for (let index = 0; index < startDayIndex; index++) {
                dateObjs.push(subDays(dateObj, (index + 1)))
            }
            dateObjs.reverse()
        }

        if(!view || view === 'month'){
            while(isSameMonth(dateObj, startDateObj)){
                dateObjs.push(dateObj)
                dateObj = addDays(dateObj, 1)
            }   
        }     
        else{
            for (let index = 0; index < 7; index++) {
                dateObjs.push(dateObj)
                dateObj = addDays(dateObj, 1)

            }
        }
        if(!view || view === 'month'){
            dateObj = subDays(dateObj, 1)
            const endDay = getDay(dateObj)
            const endDayIndex = weekOrder.indexOf(endDay)
            let addDaysNum = 1
    
            for (let index = endDayIndex; (index < weekOrder.length && index !== 6); index++) {
                dateObjs.push(addDays(dateObj, addDaysNum))
                ++addDaysNum
            }    
        }    
        
        return dateObjs
    }, [startDate, startWeek, view])
    return (
        <div className={classes}>
            <div>
                {
                    startWeek === 'monday' ?
                    <></> :
                    <div className="day">Sun</div>
                }
                <div className="day">Mon</div>               
                <div className="day">Tue</div>               
                <div className="day">Wed</div>
                <div className="day">Thu</div>
            
                <div className="day">Fri</div>
                <div className="day">Sat</div>
                {
                    startWeek === 'monday' ?
                    <div className="day">Sun</div> : <></>
                }
                {
                    dateObjs.map((dateObj, index: number) => (
                        <DateView 
                            date={dateObj} key={index} data={children}
                            handleClick={handleClick}
                        />
                    ))
                }                
            </div>                     
        </div>
    )
}

interface Schedule {
    date: string,
    children: any,
}

export function Schedule({children}: Schedule){
    return <>{children}</>
}

function DateView({date, data, handleClick}: {date: Date, data: any, handleClick?: (date?: string) => void}){
    const scheduleEl = useMemo(() => {
        let scheduleEl = <></>
        if(data){
            if(Array.isArray(data)){
                const targetSched = data.find(dat => dat && dat.props && dat.props.date === format(date, 'yyyy-MM-dd'))
                if(targetSched && targetSched.props){
                    scheduleEl = <>{targetSched.props.children}</>
                }
            }
            else{
                if(data.props && data.props.date === format(date, 'yyyy-MM-dd')){
                    scheduleEl = <>{data.props.children}</>
                }
            }
        }
        return scheduleEl
    }, [data])

    return (
        <div className="date-view">
            <div className="date">
                {format(date, 'dd')}
            </div>
            {scheduleEl}
            {
                handleClick ? 
                <button className="absolute top-0 left-0 w-full h-full" onClick={() => {handleClick(format(date, 'yyyy-MM-dd'))}}></button> : <></>
            }
        </div>
    )
}