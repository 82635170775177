import { Button, ButtonsGroup } from "components/buttons"
import { ButtonItem } from "components/buttons/ButtonsGroup"
import { BaseCard } from "components/cards"
import { ChevronRightIcon, FilterIcon, PlusScheduleIcon } from "components/Svg"
import { format, isSameDay } from "date-fns"
import { padStart } from "lodash"
import { formatFullDate } from "utils/date"

type Props = {
    view: 'month' | 'week' | 'day'
    changeView: (view: string) => void
    goToToday: () => void
    changePage: (direction: boolean) => void
    startDate: string
}

const ScheduleHeader = (props: Props) => {
    const { view, changeView, goToToday, changePage, startDate } = props
    const title = (view == 'month' ? 'Monthly' : view == 'week' ? 'Weekly' : 'Daily') + ' Schedule'
    const dateObjStartDate = new Date(startDate)
    const endWeek = padStart((dateObjStartDate.getDate() + 6).toString(), 2, '0')
    const date = view == 'month' ? format(startDate, 'MMMM yyyy') : view == 'week' ? `${format(startDate, `dd - ${endWeek} MMMM yyyy`)}` : formatFullDate(startDate, { showTime: false, showYear: false })


    return <BaseCard className="grid grid-cols-5 place-items-stretch text-body border bg-white">
        <div className="flex flex-col justify-center">
            <ButtonsGroup color="gray" shape="outline">
                <ButtonItem isActive={isSameDay(startDate, new Date())} onClick={() => { goToToday() }}>Today</ButtonItem>
                <ButtonItem onClick={() => { changePage(false) }}>
                    <ChevronRightIcon width={22.4} height={22.4} className='rotate-[180deg]' />
                </ButtonItem>
                <ButtonItem onClick={() => { changePage(true) }}>
                    <ChevronRightIcon width={22.4} height={22.4} />
                </ButtonItem>
            </ButtonsGroup>
        </div>
        <div>
            <h3 className="font-medium text-th-lg">{date}</h3>
            <h5 className="text-th-sm">{title}</h5>
        </div>
        <div className="border-x-2 col-span-2 flex items-center justify-evenly">
            <ButtonsGroup color="gray" shape="outline">
                <ButtonItem isActive={view == 'month'} onClick={() => { changeView('month') }}>Month</ButtonItem>
                <ButtonItem isActive={view == 'week'} onClick={() => { changeView('week') }}>Week</ButtonItem>
                <ButtonItem isActive={view == 'day'} onClick={() => { goToToday() }}>Day</ButtonItem>
            </ButtonsGroup>
        </div>
        <div className="flex justify-end self-center text-light">
            <Button href='/service-orders/create' tag="a" shape="filled" className="gap-2">
                <PlusScheduleIcon color="white" width={32} height={32} />
                Add New Order
            </Button>
        </div>
    </BaseCard>
}

export default ScheduleHeader