import { LogicalFilter, useTable } from "@refinedev/core"
import { Button } from "components/buttons"
import { BaseCard } from "components/cards"
import PageTitle from "components/PageTitle"
import Pagination from "components/Pagination"
import Pill from "components/Pill"
import { Table, THead, Th, TBody, Td, TAction, TActionItem } from "components/Table"
import TabletWidgets, { TabletWidgetLeft, TabletWidgetRight } from "components/TableWidgets"
import { useCallback } from "react"
import { NavLink } from "react-router-dom"
import { TECHNICIAN_CARRY_CASH_CONFIG } from "resources-config/technician-carry-cash-config"
import { formatFullDate } from "utils/date"


export default function ListTechnicianCarryCash() {
    const { tableQueryResult, pageSize, current, setCurrent, setFilters, filters } = useTable({
        queryOptions: { retry: false, },
        pagination: { current: 1, pageSize: 20 },
        resource: TECHNICIAN_CARRY_CASH_CONFIG.name,
        filters: {
            initial: [{
                field: 'paymentMethod',
                value: 'onsite',
                operator: 'eq'
            }],
        }
    })

    const changePage = useCallback((page: number | string) => {
        setCurrent(+page)
    }, [setCurrent])

    const handleSetFilters = (isClaimed?: boolean) => {
        setFilters([
            {
                field: 'isClaimed',
                value: isClaimed === undefined ? undefined : isClaimed,
                operator: "eq"
            }
        ])
    }

    // @ts-ignore
    const claimFilter = filters.find((filter: LogicalFilter) => filter.field == 'isClaimed')

    return <>
        <PageTitle />
        <BaseCard>
            <TabletWidgets>
                <TabletWidgetLeft>

                </TabletWidgetLeft>
                <TabletWidgetRight>
                    <div className="flex gap-2">
                        <Button shape={!claimFilter ? 'filled' : 'outline'} onClick={() => handleSetFilters()}>
                            All
                        </Button>
                        <Button shape={claimFilter && claimFilter.value == true ? 'filled' : 'outline'} onClick={() => handleSetFilters(true)}>
                            Claimed
                        </Button>
                        <Button shape={claimFilter && claimFilter.value == false ? 'filled' : 'outline'} onClick={() => handleSetFilters(false)}>
                            Unclaimed
                        </Button>
                    </div>
                </TabletWidgetRight>
            </TabletWidgets>
            <Table>
                <THead>
                    <Th>Order ID</Th>
                    <Th>Invoice ID</Th>
                    <Th>Customer Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Technician</Th>
                    <Th>Cash Amount</Th>
                    <Th>Created At</Th>
                    <Th>Status</Th>
                    <Th>Action</Th>
                </THead>
                {tableQueryResult && tableQueryResult.data ? (tableQueryResult.data.data).map((invHis: MiscObj, index) => {
                    return <TBody key={index}>
                        <Td>{invHis.invoice.order.id}</Td>
                        <Td>{invHis.invoice.id}</Td>
                        <Td>{invHis.invoice.order.user.name}</Td>
                        <Td>{invHis.invoice.order.user.phoneNumber}</Td>
                        <Td>{invHis.createdBy}</Td>
                        <Td>$ {invHis.amount.toFixed(2)}</Td>
                        <Td>{formatFullDate(invHis.createdAt)}</Td>
                        <Td>{invHis.claimedAt ?
                            <Pill backgroundColor="#24663B" textColor="#FFFFFF" className="justify-center text-center">Claimed {formatFullDate(invHis.claimedAt, { showDay: false, showTime: false })} <br /> by {invHis.claimedBy.name}</Pill> :
                            <Pill backgroundColor="#BB032A" textColor="#FFFFFF" className="justify-center text-center">Unclaimed</Pill>
                        }
                        </Td>
                        <TAction id={`pending-payment-invoice-${invHis.invoice.id}`}>
                            <TActionItem access={{ resource: TECHNICIAN_CARRY_CASH_CONFIG.name, action: 'edit' }}>
                                <NavLink className={'text-sm'} to={`/service-orders/${invHis.invoice.order.id}?scroll=invoice-${invHis.invoice.id}`}>View Order Info</NavLink>
                            </TActionItem>
                        </TAction>
                    </TBody>
                }) : <></>}
            </Table>
            <div className='justify-end mt-4 flex-ct-y'>
                <Pagination
                    pageSize={pageSize} currentPage={current} totalItems={tableQueryResult.data?.total || 0}
                    clickHandler={changePage} range={5}
                />
            </div>
        </BaseCard >
    </>
}