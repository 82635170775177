interface BaseCardProps {
    children?: any,
    shadowed?: boolean
    tag?: keyof JSX.IntrinsicElements
    className?: string
    withoutPadding?: boolean
    [key: string]: any
}
export default function BaseCard({ children, shadowed, tag, className, withoutPadding, ...rest }: BaseCardProps) {
    let classes = 'base-card' + (className ? ' ' + className : '')
    classes += (shadowed ? ' shadowed' : '')
    classes += (withoutPadding ? ' without-padding' : '')
    const Tag = tag || 'div'
    return (
        <Tag className={classes} {...rest}>
            {children || ''}
        </Tag>
    )
}