import { ComponentProps, ReactNode } from "react"

type Props = ComponentProps<'section'> & {
    Icon?: ReactNode
    leading?: ReactNode
    title: string
}

const FormsSection = (props: Props) => {
    const { title, className, children, leading, Icon, ...rest } = props

    return <section className={`border rounded-lg p-4 pt-0 ${className}`}  {...rest}>
        <div className="flex items-center justify-between border-b mb-4">
            <div className="flex items-center gap-4 py-4">
                {Icon}
                <h3 className="text-light text-th-lg">{title}</h3>
            </div>
            {leading}
        </div>
        {children}
    </section>

}

export default FormsSection