

export const placesServiceConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    debounce: 500,
    options: {
        input: 'en',
        componentRestrictions: {
            country: 'sg'
        }
    }
}

export const MAP_DEFAULT_LATITUDE = 1.3545
export const MAP_DEFAULT_LONGITUDE = 103.9886