import { MouseEvent } from "react"

type Props = {
    label?: string
    value: string
    horizontal?: boolean
    labelWeight?: 'normal' | 'medium'
    className?: string
    fieldClassName?: string
    href?: string
}

const FieldInfo = (props: Props) => {
    const { label, value, horizontal = false, labelWeight = 'medium', className = "", fieldClassName = "", href } = props

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        if (href) window.open(href)
    }
    return <div onClickCapture={handleClick} className={`flex ${horizontal ? 'flex-row items-center whitespace-nowrap' : 'flex-col'} ${href ? 'cursor-pointer' : ''} gap-2 rounded-th-1 ${className}`}>
        {label && <label className={`text-dark ${labelWeight == 'medium' ? 'font-medium' : ''} ${href ? 'underline' : ''} block`}>{label}</label>}
        <input type="text" readOnly className={`px-x-th-form-btn py-y-th-form-btn rounded-th-1 border-2 border-[#EAECF0] transition-colors w-full inline-block ${href ? 'cursor-pointer' : 'cursor-default'}  ${fieldClassName}`} value={value} />
    </div>
}

export default FieldInfo