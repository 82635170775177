import { useEffect, useMemo, useState } from "react";
import { BaseFormProps } from "./types"
import AsyncSelect from 'react-select/async';
import { useController } from "react-hook-form";
import { getFieldErrMsg } from "utils/handle-api-error";
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import AsyncCreatableSelect from "react-select/async-creatable";

interface AsyncMultiSelectFieldProps extends BaseFormProps {
    loadOptions: (inputValue: string) => Promise<any[]>,
    setValue?: FormHookType['setValue'],
    defaultValue?: SelectMultiVal
    defaultCfg?: {
        value: SelectMultiVal
        setValue: FormHookType['setValue']
    }
    creatable?: boolean
    onCreateOption?: (inputValue: string) => void
    filterOption?: (option: FilterOptionOption<any>, inputValue: string) => boolean
}

export default function AsyncMultiSelectField({
    fieldName, control, loadOptions, defaultValue, errorCfg,
    defaultCfg, label, setValue, desc, className, filterOption, onCreateOption, creatable = false, ...rest
}: AsyncMultiSelectFieldProps) {
    const reactSelectId = useMemo(() => (Date.now().toString()), [])
    const [isMounted, setIsMounted] = useState(false);
    const { field, fieldState } = useController({ name: fieldName, control });
    let classes = 'form-group' + (className ? ' ' + className : '')
    classes += rest.required ? ' required' : ''

    useEffect(() => setIsMounted(true), []);

    useEffect(() => {
        if (defaultCfg && defaultCfg.setValue && defaultCfg.value !== undefined) {
            defaultCfg.setValue(fieldName, defaultCfg.value ? defaultCfg.value.map(val => val.value) : [])
        }
    }, [defaultCfg?.value, defaultCfg?.setValue])

    if (!isMounted) {
        return null
    }
    return (
        <span className={classes}>
            {label ? <label className='form-label'>{label}</label> : ''}
            {onCreateOption || creatable ?
                <AsyncCreatableSelect
                    id={reactSelectId}
                    onCreateOption={onCreateOption}
                    ref={field.ref} loadOptions={loadOptions}
                    defaultValue={defaultCfg && defaultCfg.value ? defaultCfg.value : []}
                    defaultOptions
                    onChange={opt => field.onChange(opt.map(opt => opt.value))}
                    isDisabled={rest.disabled}
                    isOptionDisabled={(option: any) => option.isDisabled}
                    menuPortalTarget={document.body}
                    isClearable
                    filterOption={filterOption}
                    isMulti styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            borderWidth: '1.4px', borderStyle: 'solid',
                            borderColor: state.isFocused ? '#EAECF0 !important' : '#EAECF0 !important',
                            borderRadius: '8px', boxShadow: "none",
                            outline: 'none', padding: '0em 0.2em'
                        }),
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        multiValue: (base: any, state: any) => ({
                            ...base,
                            background: '#1954A1',
                            color: 'white',
                            borderRadius: '0.5rem',
                            padding: 5
                        }),
                        multiValueLabel: (base: any, state: any) => ({
                            ...base,
                            color: 'white',
                            fontWeight: '500'
                        })
                    }}
                /> :
                <AsyncSelect
                    id={reactSelectId}
                    ref={field.ref} loadOptions={loadOptions}
                    defaultValue={defaultCfg && defaultCfg.value ? defaultCfg.value : []}
                    defaultOptions
                    onChange={opt => field.onChange(opt.map(opt => opt.value))}
                    isDisabled={rest.disabled}
                    isOptionDisabled={(option: any) => option.isDisabled}
                    menuPortalTarget={document.body}
                    isClearable
                    filterOption={filterOption}
                    isMulti styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            borderWidth: '1.4px', borderStyle: 'solid',
                            borderColor: state.isFocused ? '#EAECF0 !important' : '#EAECF0 !important',
                            borderRadius: '8px', boxShadow: "none",
                            outline: 'none', padding: '0em 0.2em'
                        }),
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        multiValue: (base: any, state: any) => ({
                            ...base,
                            background: '#1954A1',
                            color: 'white',
                            borderRadius: '0.5rem',
                            padding: 5
                        }),
                        multiValueLabel: (base: any, state: any) => ({
                            ...base,
                            color: 'white',
                            fontWeight: '500'
                        })
                    }}
                />
            }
            <span className="flex flex-col">
                {!desc ? <></> : <span className="desc">{desc}</span>}
                {
                    !fieldState.error || (errorCfg && errorCfg.hideErrMsg) ? <></> :
                        <span className="err-msg">
                            {getFieldErrMsg(
                                fieldState.error, fieldName, (errorCfg && errorCfg.errLabel ? errorCfg.errLabel : '')
                            )}
                        </span>
                }
            </span>
        </span>
    )
}